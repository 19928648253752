/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Spinner } from 'components';
import React from 'react';

import { useGetBalanceOf, useGetMintedDsd } from 'clients/api';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';

import Convert, { ConvertProps } from './Convert';

export type RedeemDsdUiProps = ConvertProps;

const RedeemDsd = () => {
  const { accountAddress } = useAuth();

  const { data: mintedDsdData, isLoading: isGetMintedDsdLoading } = useGetMintedDsd(
    {
      accountAddress,
    },
    { enabled: !!accountAddress },
  );

  const { data: userDsdBalanceData } = useGetBalanceOf(
    { accountAddress: accountAddress || '', token: TOKENS.dsd },
    { enabled: !!accountAddress },
  );

  if (!isGetMintedDsdLoading) {
    if (
      accountAddress &&
      mintedDsdData?.mintedDsdWei.gt(userDsdBalanceData?.balanceWei || new BigNumber(0))
    ) {
      return (
        <>
          <Typography variant="h3">Existing Loan</Typography>
        </>
      );
    }

    return <Convert userDsdBalanceWei={userDsdBalanceData?.balanceWei} />;
  }

  // TODO - Handle error state
  return <Spinner />;
};

export default RedeemDsd;
