import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type Asset } from 'types';
import { formatToReadablePercentage } from 'utilities';

import { useGetApyData } from 'clients/api';
import { IconName } from 'components';

export interface UseAssetInfoInput {
  asset?: Asset;
  type: 'supply' | 'borrow';
}

const useAssetInfo = ({ asset }: UseAssetInfoInput) => {
  const { t } = useTranslation();

  const { isFetched: apyLoaded, data: apyData } = useGetApyData();

  const apysForLltoken:
    | {
        dsdRewardsApy: string;
        generalRebaseApy: string;
        esTokenRebaseApy: string;
        esTokenApyOnProvidingCollateral: string;
      }
    | undefined = useMemo(() => {
    if (!apyLoaded) {
      return {
        dsdRewardsApy: '0',
        generalRebaseApy: '0',
        esTokenRebaseApy: '0',
        esTokenApyOnProvidingCollateral: '0',
      };
    }
    if (asset) {
      return apyData?.tokenData[asset.llToken.address];
    }
    return {
      dsdRewardsApy: '0',
      generalRebaseApy: '0',
      esTokenRebaseApy: '0',
      esTokenApyOnProvidingCollateral: '0',
    };
  }, [apyLoaded, asset?.llToken?.address]);

  return useMemo(() => {
    if (!asset) {
      return [];
    }

    // const distributionRows = asset.distributions.map(distribution => ({
    //   label: t('assetInfo.distributionApy', { tokenSymbol: distribution.token.symbol }),
    //   iconSrc: TOKENS.ltoken,
    //   children: formatToReadablePercentage(
    //     type === 'borrow' ? distribution.borrowApyPercentage : distribution.supplyApyPercentage,
    //   ),
    // }));

    // const combinedDistributionApys = getCombinedDistributionApys({
    //   asset,
    // });

    return [
      {
        label: t('account.marketBreakdown.cellGroup.collateralRatio'),
        iconSrc: 'fee' as IconName,
        tooltip: t('account.marketBreakdown.cellGroup.collateralRatioTip'),
        children: formatToReadablePercentage(+asset.collateralFactor * 100),
      },
      {
        // label: type === 'borrow' ? t('assetInfo.borrowApy') : t('assetInfo.supplyApy'),
        label: t('assetInfo.dsdApy'),
        iconSrc: asset.llToken.underlyingToken,
        children: formatToReadablePercentage(
          apysForLltoken ? apysForLltoken.dsdRewardsApy : '0.00',
        ),
      },
      {
        // label: type === 'borrow' ? t('assetInfo.borrowApy') : t('assetInfo.supplyApy'),
        label: t('assetInfo.esltokenApy'),
        iconSrc: asset.llToken.underlyingToken,
        children: formatToReadablePercentage(
          apysForLltoken ? apysForLltoken.esTokenApyOnProvidingCollateral : '0.00',
        ),
      },
      {
        label: t('assetInfo.totalApy'),
        children: formatToReadablePercentage(
          apysForLltoken
            ? +apysForLltoken?.dsdRewardsApy + +apysForLltoken?.esTokenApyOnProvidingCollateral
            : 0.0,
        ),
      },
    ];
  }, [asset, t]);
};

export default useAssetInfo;
