/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import BigNumber from 'bignumber.js';
import { Spinner, Tabs } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'translation';

import { ReactComponent as MetamaskIcon } from 'assets/img/metamask.svg';
import {
  useConvertEsLTOKEN,
  useGetBalanceOf,
  useGetLtokenWithdrawableAmount,
  useGetVestDuration,
  useWithdrawLtoken,
} from 'clients/api';
import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';
import useAddTokenToWallet from 'hooks/useAddTokenToWallet';
import EscrowLToken from 'pages/Stake';

import ClaimFees from './ClaimFees';
import Convert, { ConvertProps } from './Convert';
import Withdraw, { WithdrawProps } from './Withdraw';
import { useStyles } from './styles';

export type VestUiProps = Omit<ConvertProps, 'vestDurationInDays'> &
  WithdrawProps & { vestDuration?: number };

export const VestUi = ({
  ltokenToVrtConversionRatio,
  userVrtBalanceWei,
  convertEsLTOKENLoading,
  convertEsLTOKEN,
  withdrawLtokenLoading,
  withdrawLtoken,
  vestDuration,
  existingVest,
  ltokenWithdrawableAmount,
}: VestUiProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const addTokenToWallet = useAddTokenToWallet();

  const tabsContent = [
    {
      title: t('vest.convert'),
      content: (
        <Convert
          ltokenToVrtConversionRatio={ltokenToVrtConversionRatio}
          userVrtBalanceWei={userVrtBalanceWei}
          convertEsLTOKENLoading={convertEsLTOKENLoading}
          convertEsLTOKEN={convertEsLTOKEN}
          vestDurationInDays={vestDuration ? vestDuration / 86400 : 0}
        />
      ),
    },
    {
      title: t('vest.claim'),
      content: (
        <Withdraw
          ltokenWithdrawableAmount={ltokenWithdrawableAmount}
          withdrawLtokenLoading={withdrawLtokenLoading}
          withdrawLtoken={withdrawLtoken}
          existingVest={existingVest}
        />
      ),
    },
    {
      title: t('vest.escrow'),
      content: <EscrowLToken />,
    },
    {
      title: t('vest.fees'),
      content: <ClaimFees />,
    },
  ];

  const [currIdx, setCurrIdx] = useState(0);

  return (
    <div css={[styles.root]}>
      <Paper css={[styles.tabs, styles.border]}>
        <div css={{ marginBottom: '1em' }}>
          <Typography
            variant="h2"
            css={{ width: 'fit-content', margin: 'auto', position: 'relative' }}
          >
            {currIdx === 0 && `Vest ${brand.escrowTokenSymbol}`}
            {currIdx === 1 && `Claim ${brand.rewardTokenSymbol}`}
            {currIdx === 2 && `Escrow ${brand.rewardTokenSymbol}`}
            {currIdx === 3 && 'Protocol Fees'}
            {currIdx !== 3 && (
              <MetamaskIcon
                css={styles.mmIcon}
                onClick={() => addTokenToWallet(currIdx === 0 ? TOKENS.esLTOKEN : TOKENS.ltoken)}
              />
            )}
          </Typography>
        </div>
        <Tabs tabsContent={tabsContent} onTabChange={idx => setCurrIdx(idx)} />
      </Paper>
    </div>
  );
};

const Vest = () => {
  const { accountAddress } = useAuth();
  const { data: userVrtBalanceData } = useGetBalanceOf(
    { accountAddress: accountAddress || '', token: TOKENS.esLTOKEN },
    { enabled: !!accountAddress },
  );

  const { data: vestDurationAndExisingVest } = useGetVestDuration();

  const { data: { totalWithdrawableAmount: ltokenWithdrawableAmount } = {} } =
    useGetLtokenWithdrawableAmount(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
    );

  const { mutateAsync: convertEsLTOKEN, isLoading: convertEsLTOKENLoading } = useConvertEsLTOKEN();
  const { mutateAsync: withdrawLtoken, isLoading: withdrawLtokenLoading } = useWithdrawLtoken();

  const handleConvertEsLTOKEN = async (amountWei: string) =>
    convertEsLTOKEN({
      amountWei: new BigNumber(amountWei),
    });

  // esLTOKEN -> LTOKEN Conversion Ratio is 1:1
  const conversionRatio = new BigNumber(1);

  if (!vestDurationAndExisingVest) return <Spinner />;

  const [vestDuration, existingVest] = vestDurationAndExisingVest;

  if (conversionRatio) {
    return (
      <VestUi
        ltokenToVrtConversionRatio={conversionRatio}
        userVrtBalanceWei={userVrtBalanceData?.balanceWei}
        convertEsLTOKENLoading={convertEsLTOKENLoading}
        convertEsLTOKEN={handleConvertEsLTOKEN}
        withdrawLtoken={withdrawLtoken}
        vestDuration={vestDuration}
        existingVest={existingVest}
        withdrawLtokenLoading={withdrawLtokenLoading}
        ltokenWithdrawableAmount={ltokenWithdrawableAmount}
      />
    );
  }

  // TODO - Handle error state
  return <Spinner />;
};

export default Vest;
