import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      max-height: 500px;
    `,
    subContainer: css`
      max-height: 350px;
      overflow-y: scroll;
    `,
    title: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    checkbox: css`
      gap: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(2)};
      display: flex;
      align-items: center;
    `,
  };
};
