/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  AccountData,
  EnabledTokenChildProps,
  SelectProtocol,
  SelectToken,
  TokenTextField,
} from 'components';
// import { VError } from 'errors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useTranslation } from 'translation';
import { Asset, Pool, Swap, SwapError, Token, TokenBalance } from 'types';
import {
  areTokensEqual,
  convertTokensToWei,
  formatTokensToReadableValue,
  isFeatureEnabled,
} from 'utilities';

import balancer from 'assets/img/tokens/balancer.svg';
import crv from 'assets/img/tokens/curve.svg';
import pendle from 'assets/img/tokens/pendle.svg';
import yearn from 'assets/img/tokens/yearn.svg';
import { useSupply } from 'clients/api';
import { routes } from 'constants/routing';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';
// import useFormatTokensToReadableValue from 'hooks/useFormatTokensToReadableValue';
import useGetSwapTokenUserBalances from 'hooks/useGetSwapTokenUserBalances';

import { useStyles as useSharedStyles } from '../styles';
import Notice from './Notice';
import SubmitSection from './SubmitSection';
import TEST_IDS from './testIds';
import useForm, { FormValues, UseFormInput } from './useForm';

export const PRESET_PERCENTAGES = [25, 50, 75, 100];

type Protocol = {
  symbol: string;
  asset: string;
  name: string;
};

export interface SupplyFormUiProps extends SupplyFormProps {
  onSubmit: UseFormInput['onSubmit'];
  isSubmitting: boolean;
  tokenBalances?: TokenBalance[];
  isSwapLoading: boolean;
  swap?: Swap;
  swapError?: SwapError;
}

export const SupplyFormUi: React.FC<SupplyFormUiProps> = ({
  asset,
  pool,
  onCloseModal,
  onSubmit,
  isSubmitting,
  isSwapLoading,
  swap,
  swapError,
  enableToken,
  isTokenEnabled,
  isEnableTokenLoading,
}) => {
  const { t, Trans } = useTranslation();
  const sharedStyles = useSharedStyles();
  const history = useHistory();

  const [selectedToken, setSelectedToken] = useState<Token>(asset.llToken.underlyingToken);
  const [selectedProtocol, setSelectedProtocol] = useState<Protocol>({
    symbol: asset.llToken.protocolSymbol,
    asset: asset.llToken.protocol,
    name: asset.llToken.protocolName,
  });

  console.log('-'.repeat(20));
  console.log(
    `%cLogs for ${selectedToken.symbol} Collateral`,
    'color: sky; background: green; font-size: 2em',
  );

  const consoleInfo = {
    'Total deposited': {
      value: formatTokensToReadableValue({
        value: asset.userSupplyBalanceTokens,
        token: asset.llToken.underlyingToken,
      }),
      formula: 'userSupplyBalanceTokens, underlyingToken',
    },
    'Total TVL': {
      value: formatTokensToReadableValue({
        value: asset.supplyBalanceTokens,
        token: asset.llToken.underlyingToken,
      }),
      formula: 'supplyBalanceTokens, underlyingToken',
    },
  };

  console.table(consoleInfo);

  const [formValues, setFormValues] = useState<FormValues>({
    amountTokens: '',
    fromToken: selectedToken,
  });

  const fromTokenUserWalletBalanceTokens = useMemo(
    () => asset.userWalletBalanceTokens,
    [asset, selectedToken],
  );

  const supplaybleTokenBalances: TokenBalance[] = useMemo(() => {
    if (!pool) return [];

    const tokenBalances = pool.assets
      .filter(({ llToken }) => llToken.protocolSymbol === selectedProtocol.symbol)
      .map(token => ({
        token: token.llToken.underlyingToken,
        balanceWei: token.userWalletBalanceTokens,
      }))
      .filter(({ token }) => token !== undefined) as TokenBalance[];

    return tokenBalances;
  }, [pool, selectedProtocol]);

  const { handleSubmit, isFormValid, formError } = useForm({
    asset,
    fromTokenUserWalletBalanceTokens,
    swap,
    swapError,
    onCloseModal,
    onSubmit,
    formValues,
    setFormValues,
  });

  const amountInUsd = useMemo(() => {
    if (!formValues.amountTokens) return new BigNumber(0);
    return new BigNumber(formValues.amountTokens).multipliedBy(asset.tokenPriceDollars);
  }, [formValues.amountTokens, asset.tokenPriceDollars]);

  // const readableFromTokenUserWalletBalanceTokens = useFormatTokensToReadableValue({
  //   value: fromTokenUserWalletBalanceTokens,
  //   token: formValues.fromToken,
  // });

  const handleRightMaxButtonClick = useCallback(() => {
    // Update field value to correspond to user's wallet balance
    setFormValues(currentFormValues => ({
      ...currentFormValues,
      amountTokens: new BigNumber(fromTokenUserWalletBalanceTokens || 0).toFixed(),
    }));
  }, [fromTokenUserWalletBalanceTokens]);

  const handleAssetChange = (token: Token) => {
    const path = generatePath(routes.manageCollateral.path, { underlying: token.address });
    setSelectedToken(token);
    history.push(path);
  };

  const handleProtocolChange = (protocol: Protocol) => {
    // console.log(protocol);
    setSelectedProtocol(protocol);
  };

  useEffect(() => {
    setSelectedToken(asset.llToken.underlyingToken);
  }, [asset]);

  return (
    <>
      <Typography css={{ textAlign: 'center', margin: '0.5em 0' }} variant="h2">
        Add a new position
      </Typography>
      <form
        onSubmit={
          isTokenEnabled
            ? handleSubmit
            : e => {
                e.preventDefault();
                enableToken();
              }
        }
      >
        <div css={sharedStyles.getRow({ isLast: true })}>
          <SelectProtocol
            label="Type of collateral:"
            selectedProtocol={selectedProtocol}
            onChangeSelectedProtocol={(value: Protocol) => handleProtocolChange(value)}
            options={[
              {
                symbol: 'eth',
                asset: TOKENS.eth.asset,
                name: 'ETH backed',
              },
              {
                symbol: 'crv',
                asset: crv,
                name: 'Curve backed',
              },
              {
                symbol: 'balancer',
                asset: balancer,
                name: 'Balancer backed',
              },
              {
                symbol: 'yearn',
                asset: yearn,
                name: 'Yearn backed',
              },
              {
                symbol: 'pendle',
                asset: pendle,
                name: 'Pendle backed',
              },
            ]}
          />
          <SelectToken
            label="Select an asset:"
            selectedToken={selectedToken}
            value="0"
            displayTokenIcon={false}
            onChange={() => {}}
            disabled={false}
            onChangeSelectedToken={value => handleAssetChange(value)}
            tokenBalances={supplaybleTokenBalances}
          />
          <TokenTextField
            data-testid={TEST_IDS.tokenTextField}
            name="amountTokens"
            token={selectedToken}
            value={formValues.amountTokens}
            onChange={amountTokens =>
              setFormValues(currentFormValues => ({
                ...currentFormValues,
                amountTokens,
                // Reset selected fixed percentage
                fixedRepayPercentage: undefined,
              }))
            }
            amountInUsd={amountInUsd}
            disabled={isSubmitting || formError === 'SUPPLY_CAP_ALREADY_REACHED'}
            rightMaxButton={{
              label: t('supplyWithdrawModal.supply.rightMaxButtonLabel'),
              onClick: handleRightMaxButtonClick,
            }}
            hasError={!!formError && Number(formValues.amountTokens) > 0}
            description={
              <Trans
                i18nKey="supplyWithdrawModal.supply.walletBalance"
                components={{
                  White: <span css={sharedStyles.whiteLabel} />,
                }}
                values={{
                  balance: formatTokensToReadableValue({
                    value: fromTokenUserWalletBalanceTokens,
                    token: selectedToken,
                  }),
                }}
              />
            }
          />

          <Notice asset={asset} amount={formValues.amountTokens} />
        </div>

        <AccountData
          asset={asset}
          pool={pool}
          swap={swap}
          amountTokens={new BigNumber(formValues.amountTokens || 0)}
          action="supply"
        />

        {/* submit Button */}
        <SubmitSection
          isTokenEnabled={isTokenEnabled}
          isFormSubmitting={isSubmitting}
          isFormValid={isTokenEnabled ? isFormValid : true}
          isSwapLoading={isSwapLoading || isEnableTokenLoading || false}
          formError={formError}
          toToken={selectedToken}
          fromToken={selectedToken}
          fromTokenAmountTokens={formValues.amountTokens}
        />
      </form>
    </>
  );
};

export interface SupplyFormProps extends EnabledTokenChildProps {
  asset: Asset;
  pool: Pool;
  onCloseModal: () => void;
}

const SupplyForm: React.FC<SupplyFormProps> = ({ asset, pool, onCloseModal, ...rest }) => {
  const { accountAddress } = useAuth();

  const { data: tokenBalances } = useGetSwapTokenUserBalances(
    {
      accountAddress,
    },
    {
      enabled: isFeatureEnabled('integratedSwap'),
    },
  );

  const { mutateAsync: supply, isLoading: isSupplyLoading } = useSupply({
    llToken: asset.llToken,
  });

  const isSubmitting = isSupplyLoading;

  const onSubmit: SupplyFormUiProps['onSubmit'] = async ({
    toLLToken,
    fromToken,
    fromTokenAmountTokens,
  }) => {
    const isSwapping = !areTokensEqual(fromToken, toLLToken.underlyingToken);

    // Handle supply flow
    if (!isSwapping) {
      const amountWei = convertTokensToWei({
        value: new BigNumber(fromTokenAmountTokens.trim()),
        token: fromToken,
      });

      return supply({ amountWei });
    }
  };

  return (
    <SupplyFormUi
      asset={asset}
      pool={pool}
      onCloseModal={onCloseModal}
      tokenBalances={tokenBalances}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isSwapLoading={false}
      {...rest}
    />
  );
};

export default SupplyForm;
