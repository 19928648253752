/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import React from 'react';

import { FarmInfo } from 'clients/api';

import FarmCard from './FarmCard';
import { useStyles } from './styles';

export interface FarmTableProps {
  title?: string;
  vaults: FarmInfo[];
}

export const FarmTable: React.FC<FarmTableProps> = ({ title, vaults }) => {
  const styles = useStyles();
  return (
    <div css={styles.cardCotainer}>
      {title && (
        <Typography css={{ textAlign: 'center' }} variant="h2">
          {title}
        </Typography>
      )}
      <div css={styles.cardContentGrid}>
        {vaults.map(vault => (
          <FarmCard key={vault.contract} vault={vault} />
        ))}
      </div>
    </div>
  );
};
