import sample from 'lodash/sample';
import { EthChainId, Environment } from 'types';

import { BSC_SCAN_URLS } from 'constants/eth';
import { DAPP_HOSTS } from 'constants/dAppHosts';
import { API_ENDPOINT_URLS, RPC_URLS } from 'constants/endpoints';

import brand from './brand';

export interface Config {
  environment: Environment;
  isInLiveEnvironment: boolean;
  chainId: EthChainId;
  isOnTestnet: boolean;
  rpcUrl: string;
  apiUrl: string;
  bscScanUrl: string;
  sentryDsn: string;
  posthog: {
    apiKey: string;
    hostUrl: string;
  };
  featureFlags: {
    faucetDisabled: boolean;
    isolatedPools: boolean;
    integratedSwap: boolean;
  };
}

let environment: Environment = 'local';

if (!window) {
  environment = 'mock';
} else if (DAPP_HOSTS.testnet.includes(window.location.host)) {
  environment = 'testnet';
} else if (DAPP_HOSTS['app-preview'].includes(window.location.host)) {
  environment = 'app-preview';
} /* if (DAPP_HOSTS.mainnet === window.location.host) */ else {
  environment = 'mainnet';
}

const isInLiveEnvironment =
  environment === 'testnet' || environment === 'app-preview' || environment === 'mainnet';

const chainId: EthChainId = brand.chainId
  ? parseInt(String(brand.chainId), 10)
  : EthChainId.MAINNET;

const isOnTestnet = chainId === EthChainId.TESTNET;
const rpcUrl = sample(RPC_URLS[chainId]) as string;
const apiUrl = API_ENDPOINT_URLS[chainId];
const bscScanUrl = BSC_SCAN_URLS[chainId];

const config: Config = {
  environment,
  isInLiveEnvironment,
  chainId,
  isOnTestnet,
  rpcUrl,
  apiUrl,
  bscScanUrl,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
  posthog: {
    apiKey: process.env.REACT_APP_POSTHOG_API_KEY || '',
    hostUrl: process.env.REACT_APP_POSTHOG_HOST_URL || '',
  },
  // Note: never access these directly, use the utility function
  // isFeatureEnabled instead. This is necessary to make testing easier
  featureFlags: {
    faucetDisabled: false, // process.env.REACT_APP_FF_FAUCET === 'false',
    isolatedPools: process.env.REACT_APP_FF_ISOLATED_POOLS === 'true',
    integratedSwap: process.env.REACT_APP_FF_INTEGRATED_SWAP === 'true',
  },
};

export default config;
