import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    body: (hasCollaterals: boolean) => css`
      border: 2px ${hasCollaterals ? 'solid' : 'dashed'} rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      width: 100%;
    `,
    icon: css`
      margin-top: -2px;
      width: ${theme.shape.iconSize.large}px;
      height: ${theme.shape.iconSize.large}px;
      margin-right: ${theme.spacing(2)};
    `,
    protocol: css`
      display: flex;
      flex-direction: 'row';
      gap: '1em';
      align-items: center;
      color: ${theme.palette.text.primary};
    `,
    link: css`
      margin-top: 0.4em;
      &:hover {
        text-decoration: none;
        outline: none;
      }
    `,
    marketLink: css`
      text-decoration: underline;
      color: ${theme.palette.text.primary};

      > * {
        color: inherit;
      }

      :hover {
        color: ${theme.palette.interactive.primary};
      }
    `,
    claimLtokenButton: css`
      margin-left: auto;
      font-size: 0.5em;
    `,
    cardContentGrid: css`
      min-height: 400px;
      .table__table-cards__card-content {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      ${theme.breakpoints.down('sm')} {
        .table__table-cards__card-content {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          row-gap: ${theme.spacing(5)};
        }
      }
    `,
    userPercentOfLimit: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > :first-of-type {
        margin-right: ${theme.spacing(2)};
      }
    `,
    percentOfLimitProgressBar: css`
      width: ${theme.spacing(13)};
    `,
    white: css`
      color: ${theme.palette.text.primary};
    `,
  };
};
