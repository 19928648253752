/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'translation';

import { Modal, ModalProps } from '../Modal';
import { AccountDetails, AccountDetailsProps } from './AccountDetails';
import { WalletList, WalletListProps } from './WalletList';
import { useStyles } from './styles';

export interface AuthModalProps {
  isOpen: boolean;
  onClose: ModalProps['handleClose'];
  onLogin: WalletListProps['onLogin'];
  onLogOut: AccountDetailsProps['onLogOut'];
  onCopyAccountAddress: AccountDetailsProps['onCopyAccountAddress'];
  accountAddress?: string;
}

export const AuthModal: React.FC<AuthModalProps> = ({
  isOpen,
  onClose,
  onLogin,
  onLogOut,
  onCopyAccountAddress,
  accountAddress,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <Modal
      className="venus-modal"
      isOpen={isOpen}
      handleClose={onClose}
      noHorizontalPadding={!accountAddress}
      css={styles.container}
      title={
        <div style={{ padding: 0 }}>
          <h4 css={styles.heading}>
            {!accountAddress
              ? t('authModal.title.connectWallet')
              : t('authModal.title.yourAccount')}
          </h4>
          <Typography variant="body1" css={styles.heading}>
            Choose how you want to connect. If you don’t have a wallet you can select a provider and
            create one.
          </Typography>
        </div>
      }
    >
      {!accountAddress ? (
        <WalletList onLogin={onLogin} />
      ) : (
        <AccountDetails
          accountAddress={accountAddress}
          onCopyAccountAddress={onCopyAccountAddress}
          onLogOut={onLogOut}
        />
      )}
    </Modal>
  );
};
