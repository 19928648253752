import { useEffect, useMemo, useState } from 'react';
// import { useTranslation } from 'translation';
import { LLToken } from 'types';
import { getContractAddress } from 'utilities';

import { getPendingMultiRewards, useGetLtokenVaultPoolCount, useGetPools } from 'clients/api';
import { getLLTokenContract } from 'clients/contracts';
import { LLTokenContract } from 'clients/contracts/types';
import { useMulticall } from 'clients/web3';
// import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';

import { Reward, RewardsGroup } from './types';

const mainPoolComptrollerAddress = getContractAddress('comptroller');

const useGetGroupsArray = ({ lltokens }: { lltokens: LLToken[] }) => {
  console.log('@calling:: useGetGroupsArray');
  const { accountAddress } = useAuth();
  const multicall = useMulticall();
  const [data, setData] = useState<RewardsGroup[]>([]);

  // Get LTOKEN vesting vault pool count
  const { data: getLtokenVaultPoolCountData } = useGetLtokenVaultPoolCount();

  // Get Comptroller addresses of isolated pools
  const { data: getPoolsData, isLoading: isGetPoolsLoading } = useGetPools({
    accountAddress,
  });

  const isolatedPoolComptrollerAddresses = useMemo(
    () =>
      (getPoolsData?.pools || []).reduce<string[]>(
        (acc, pool) => (pool.isIsolated ? [...acc, pool.comptrollerAddress] : acc),
        [],
      ),
    [getPoolsData?.pools.length],
  );

  useEffect(() => {
    if (!lltokens.length || !multicall || !getPoolsData?.pools.length || !accountAddress) {
      return;
    }
    console.log('@calling:: being called');

    Promise.all(
      lltokens.map(async lltoken => {
        const llTokenContract = getLLTokenContract(lltoken) as LLTokenContract;
        const getPendingRewardsData = await getPendingMultiRewards({
          multicall,
          llTokenContract,
          accountAddress: accountAddress || '',
          mainPoolComptrollerAddress,
          isolatedPoolComptrollerAddresses,
          ltokenVestingVaultPoolCount: getLtokenVaultPoolCountData?.poolCount || 0,
        });
        const rewards = getPendingRewardsData.pendingMultiRewardGroups.reduce<Reward[]>(
          (acc, pendingRewardGroup) => {
            // Pools
            if (
              pendingRewardGroup.type !== 'mainPool' &&
              pendingRewardGroup.type !== 'isolatedPool'
            ) {
              return acc;
            }

            const pool = (getPoolsData?.pools || []).find(
              item =>
                item.comptrollerAddress.toLowerCase() ===
                pendingRewardGroup.comptrollerAddress.toLowerCase(),
            );

            if (!pool) {
              return acc;
            }

            const group: Reward = {
              id: pendingRewardGroup.rewardToken.address,
              name: pendingRewardGroup.rewardToken.symbol,
              rewardToken: pendingRewardGroup.rewardToken,
              rewardAmountWei: pendingRewardGroup.rewardAmountWei,
            };

            return [...acc, group];
          },
          [],
        );
        return {
          id: lltoken.address,
          rewards,
        };
      }),
    ).then(setData);
  }, [lltokens.length, getPoolsData?.pools.length, accountAddress, mainPoolComptrollerAddress]);

  return {
    isLoading: isGetPoolsLoading,
    data,
  };
};

export default useGetGroupsArray;
