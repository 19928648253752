import { QueryObserverOptions, useQuery } from 'react-query';

import getLtokenVaultPoolCount, {
  GetLtokenVaultPoolCountOutput,
} from 'clients/api/queries/getLtokenVaultPoolCount';
import { useLtokenVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetLtokenVaultPoolCountOutput,
  Error,
  GetLtokenVaultPoolCountOutput,
  GetLtokenVaultPoolCountOutput,
  FunctionKey.GET_LTOKEN_VAULT_POOLS_COUNT
>;

const useGetLtokenVaultPoolCount = (options?: Options) => {
  const ltokenVaultContract = useLtokenVaultContract();

  return useQuery(
    FunctionKey.GET_LTOKEN_VAULT_POOLS_COUNT,
    () => getLtokenVaultPoolCount({ ltokenVaultContract }),
    options,
  );
};

export default useGetLtokenVaultPoolCount;
