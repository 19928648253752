import React from 'react';
import { Switch as RRSwitch, Redirect, Route } from 'react-router-dom';
import { isFeatureEnabled } from 'utilities';

import 'assets/styles/App.scss';
import { AddCollateral } from 'components/AddCollateral';
import { routes } from 'constants/routing';
// import { useAuth } from 'context/AuthContext';
// import Account from 'pages/Account';
import Dashboard from 'pages/Dashboard';
import Dsd from 'pages/Dsd';
// import Stake from 'pages/Stake';
// import Vaults from 'pages/Vault';
// import Voter from 'pages/Voter';
// import VoterLeaderboard from 'pages/VoterLeaderboard';
// import Ltoken from 'pages/Ltoken';
import Farm from 'pages/Farm';
import Faucet from 'pages/Faucet';
// import Vote from 'pages/Governance';
// import History from 'pages/History';
// import Market from 'pages/Market';
import Migrate from 'pages/Migrate';
import Pool from 'pages/Pool';
import Pools from 'pages/Pools';
// import Proposal from 'pages/Proposal';
// import Swap from 'pages/Swap';
import Vest from 'pages/Vest';

const Switch = () => (
  <RRSwitch>
    <Route exact path={routes.dashboard.path} component={Dashboard} />

    {/* {(accountAddress || isReconnecting) && (
        <Route exact path={routes.account.path} component={Account} />
      )} */}

    {isFeatureEnabled('isolatedPools') && (
      <Route exact path={routes.pools.path} component={Pools} />
    )}

    {isFeatureEnabled('isolatedPools') && <Route exact path={routes.pool.path} component={Pool} />}

    <Route exact path={routes.markets.path} component={Pool} />

    {!isFeatureEnabled('faucetDisabled') && (
      <Route exact path={routes.faucet.path} component={Faucet} />
    )}

    <Route exact path={routes.vest.path} component={Vest} />

    {/* <Route exact path={routes.market.path} component={Market} /> */}

    {/* <Route exact path={routes.vaults.path} component={Vaults} /> */}

    {/* <Route exact path={routes.history.path} component={History} />

      <Route exact path={routes.governance.path} component={Vote} />
      <Route exact path={routes.governanceLeaderBoard.path} component={VoterLeaderboard} />
      <Route exact path={routes.governanceVoter.path} component={Voter} />
      <Route exact path={routes.governanceProposal.path} component={Proposal} />

      <Route exact path={routes.ltoken.path} component={Ltoken} /> */}

    {/* <Route exact path={routes.convertVrt.path} component={ConvertVrt} /> */}

    {/* <Route exact path={routes.swap.path} component={Swap} /> */}

    <Route exact path={routes.dsd.path} component={Dsd} />

    {/* <Route exact path={routes.stake.path} component={Stake} /> */}

    <Route exact path={routes.farm.path} component={Farm} />
    <Route exact path={routes.migrate.path} component={Migrate} />

    <Route exact path={routes.addCollateral.path} component={AddCollateral} />

    <Route exact path={routes.manageCollateral.path} component={AddCollateral} />

    <Redirect to={routes.dashboard.path} />
  </RRSwitch>
);

export default Switch;
