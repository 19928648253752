import BigNumber from 'bignumber.js';
import { ContractReceipt, Signer } from 'ethers';
import { LLToken } from 'types';

import { getLLTokenContract } from 'clients/contracts';
import { VErc20, VBnbToken } from 'types/contracts';

export interface SupplyInput {
  llToken: LLToken;
  amountWei: BigNumber;
  signer?: Signer;
}

export type SupplyOutput = ContractReceipt;

const supply = async ({ signer, llToken, amountWei }: SupplyInput): Promise<SupplyOutput> => {
  // Handle supplying BNB
  if (llToken.underlyingToken.isNative) {
    const tokenContract = getLLTokenContract(llToken, signer) as VBnbToken;

    const transaction = await tokenContract.mint(/* amountWei.toFixed() */);
    return transaction.wait(1);
  }

  // Handle supplying tokens other that BNB
  const tokenContract = getLLTokenContract(llToken, signer) as VErc20;
  const transaction = await tokenContract.mint(amountWei.toFixed());
  return transaction.wait(1);
};

export default supply;
