import BigNumber from 'bignumber.js';

import { COMPOUND_DECIMALS, COMPOUND_MANTISSA } from 'constants/compoundMantissa';
import { DAYS_PER_YEAR } from 'constants/daysPerYear';
// import { BLOCKS_PER_DAY } from 'constants/eth';

const SECONDS_PER_YEAR = DAYS_PER_YEAR * 60 * 60 * 24;

const calculateApr = (
  ratePerSecondMantissa: BigNumber | string | number,
  priceOfRewardTokenUsd: BigNumber | string | number,
  totalSupplyUsd: BigNumber | string | number,
) => {
  const aprPercentage = new BigNumber(ratePerSecondMantissa)
    .div(COMPOUND_MANTISSA)
    .multipliedBy(SECONDS_PER_YEAR)
    .multipliedBy(priceOfRewardTokenUsd)
    .multipliedBy(100)
    .div(totalSupplyUsd)
    .dp(COMPOUND_DECIMALS);

  return {
    aprPercentage: aprPercentage.isGreaterThan(1000000000)
      ? new BigNumber(Infinity)
      : aprPercentage,
  };
};

export default calculateApr;
