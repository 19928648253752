import { useMemo } from 'react';
import { Token, LLToken } from 'types';

import { useAuth } from 'context/AuthContext';

import {
  getComptrollerContract,
  getDsdControllerContract,
  getDsdVaultContract,
  getEsLTOKENContract,
  getLsFundContract,
  getEsLTOKENMinterContract,
  getFarmContract,
  getLtokenVaultContract,
  getLtokenVaultProxyContract,
  getLtokenVaultStoreContract,
  getMultiRewardContract,
  getMulticallContract,
  getPancakeRouterContract,
  getPoolLensContract,
  getSwapRouterContract,
  getTokenContract,
  getLLTokenContract,
  getLsLensContract,
  getVrtVaultProxyContract,
  getMigratorContract,
} from './getters';

export const useTokenContract = (token: Token) => {
  const { signer } = useAuth();
  return useMemo(() => getTokenContract(token, signer || undefined), [signer, token]);
};

export const useLLTokenContract = (llToken: LLToken) => {
  const { signer } = useAuth();
  return useMemo(() => getLLTokenContract(llToken, signer || undefined), [signer, llToken]);
};

export const useDsdControllerContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getDsdControllerContract(signer || undefined), [signer]);
};

export const useDsdVaultContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getDsdVaultContract(signer || undefined), [signer]);
};

export const useComptrollerContract = (address: string) => {
  const { signer } = useAuth();
  return useMemo(() => getComptrollerContract(address, signer || undefined), [signer]);
};

export const useFarmContract = (address: string) => {
  const { signer } = useAuth();
  return useMemo(() => getFarmContract(address, signer || undefined), [signer]);
};

export const useMultiRewardContract = (address: string) => {
  const { signer } = useAuth();
  return useMemo(() => getMultiRewardContract(address, signer || undefined), [signer]);
};

export const useLsLensContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getLsLensContract(signer || undefined), [signer]);
};

export const useEsLTOKENMinterContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getEsLTOKENMinterContract(signer || undefined), [signer]);
};

export const useEsLTOKENContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getEsLTOKENContract(signer || undefined), [signer]);
};

export const useLsFundContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getLsFundContract(signer || undefined), [signer]);
};

export const useLtokenVaultContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getLtokenVaultContract(signer || undefined), [signer]);
};

export const useLtokenVaultStoreContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getLtokenVaultStoreContract(signer || undefined), [signer]);
};

export const useLtokenVaultProxyContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getLtokenVaultProxyContract(signer || undefined), [signer]);
};

export const useVrtVaultProxyContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getVrtVaultProxyContract(signer || undefined), [signer]);
};

export const usePancakeRouterContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getPancakeRouterContract(signer || undefined), [signer]);
};

export const useSwapRouterContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getSwapRouterContract(signer || undefined), [signer]);
};

export const useMulticallContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getMulticallContract(signer || undefined), [signer]);
};

export const useGetPoolLensContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getPoolLensContract(signer || undefined), [signer]);
};

export const useMigratorContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getMigratorContract(signer || undefined), [signer]);
};
