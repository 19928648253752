import BigNumber from 'bignumber.js';
import { ContractCallResults } from 'ethereum-multicall';

import { GetDsdRepayAmountWithInterestsOutput } from './types';

const formatToOutput = ({
  contractCallResults,
}: {
  contractCallResults: ContractCallResults;
}): GetDsdRepayAmountWithInterestsOutput => {
  const [dsdRepayAmountWithInterests] =
    contractCallResults.results.getDsdRepayTotalAmount.callsReturnContext[0].returnValues.map(
      unformattedBigNumber => new BigNumber(unformattedBigNumber.hex),
    );

  return {
    dsdRepayAmountWithInterests,
  };
};

export default formatToOutput;
