import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      justify-content: space-around;
      padding-top: 0;
      ${theme.breakpoints.down('md')} {
        display: block;
        background-color: transparent;
      }
      ${theme.breakpoints.down('xl')} {
        padding: unset;
      }
    `,
    getNetApyColor: ({ netApyPercentage }: { netApyPercentage: number }) =>
      netApyPercentage > 0 ? theme.palette.interactive.success : theme.palette.interactive.error,
    cellGroup: css`
      padding: 1.5em;
      width: 50%;
      max-width: 600px;
      background: rgba(255, 255, 255, 0);
      border: 1px dotted rgba(255, 255, 255, 0.1);
      ${theme.breakpoints.down('xl')} {
        margin-left: ${theme.spacing(2)};
      }
      ${theme.breakpoints.down('md')} {
        margin-top: ${theme.spacing(2)};
        margin-left: unset;
        padding: 1em;
        width: unset;
      }
    `,
    accountHealthConatiner: css`
      width: 50%;
      max-width: 600px;
      display: flex;
      gap: ${theme.spacing(4)};
      flex-direction: column;
      justify-content: space-between;
      ${theme.breakpoints.down('md')} {
        width: unset;
      }
    `,
    accountHealth: css`
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.04);
      padding: 1.5em;
      border-radius: 16px;
      ${theme.breakpoints.down('md')} {
        width: unset;
      }
    `,
    accountHealthProgressBar: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    accountHealthFooter: css`
      display: flex;
      // justify-content: space-between;
      align-items: center;
    `,
    link: css`
      &:hover {
        text-decoration: none;
      }
    `,
    tooltip: css`
      display: flex;
    `,
    infoIcon: css`
      cursor: help;
    `,
    inlineLabel: css`
      margin-right: ${theme.spacing(1)};
      display: flex;
      justify-content: center;
    `,
    progressBar: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    shieldIcon: css`
      margin-right: ${theme.spacing(2)};
    `,
    safeLimit: css`
      margin-right: ${theme.spacing(2)};
    `,
    listItemNewBadge: css`
      border: 1px solid rgba(4, 222, 113, 0.17);
      background-color: ${theme.palette.interactive.success10};
      border-radius: ${theme.spacing(1)};
      height: ${theme.spacing(6)};
      display: flex;
      align-items: center;
      padding: ${theme.spacing(2, 2)};
      margin-left: ${theme.spacing(3)};

      ${theme.breakpoints.down('xl')} {
        display: none;
      }

      ${theme.breakpoints.down('lg')} {
        display: flex;
      }
    `,
    listItemNewBadgeText: css`
      color: ${theme.palette.interactive.success};
      margin-top: 1px;
    `,
  };
};
