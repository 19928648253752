import { MutationObserverOptions, useMutation } from 'react-query';

import {
  ClaimMultiRewardsInput,
  ClaimMultiRewardsOutput,
  claimMultiRewards,
  queryClient,
} from 'clients/api';
import { useMultiRewardContract } from 'clients/contracts/hooks';
import { useAuth } from 'context/AuthContext';

type Options = MutationObserverOptions<
  ClaimMultiRewardsOutput,
  Error,
  Omit<ClaimMultiRewardsInput, 'multiRewardContract'>
>;

const useClaimMultiRewards = (
  multiRewardContractAddress: string,
  llTokenContractaddress: string | string[],
  options?: Options,
) => {
  const multiRewardContract = useMultiRewardContract(multiRewardContractAddress);
  const { accountAddress } = useAuth();

  return useMutation(
    'CLAIM_MULTIREWARDS',
    () =>
      claimMultiRewards({
        multiRewardContract,
      }),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(
          typeof llTokenContractaddress === 'string'
            ? [`GET_PENDING_MULTIREWARDS${llTokenContractaddress}`, accountAddress]
            : llTokenContractaddress.map(tokenAddress => [
                `GET_PENDING_MULTIREWARDS${tokenAddress}`,
                accountAddress,
              ]),
        );
      },
    },
  );
};

export default useClaimMultiRewards;
