/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import { VError } from 'errors';
import React from 'react';
import { useTranslation } from 'translation';
import { Token } from 'types';

// import { areTokensEqual, getContractAddress } from 'utilities';
import { useGetBalanceOf, useWithdrawFromFarms } from 'clients/api';
import { useAuth } from 'context/AuthContext';

import ActionModal, { ActionModalProps } from '../ActionModal';

export interface WithdrawModalProps extends Pick<ActionModalProps, 'handleClose'> {
  stakedToken: Token;
  rewardToken: Token;
  farmAddress: string;
  stakedAmount: BigNumber;
  poolIndex?: number;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  stakedToken,
  rewardToken,
  stakedAmount,
  // poolIndex,
  farmAddress,
  handleClose,
}) => {
  const { t } = useTranslation();
  const { accountAddress } = useAuth();

  const { isLoading: isGetWalletBalanceWeiLoading } = useGetBalanceOf(
    {
      accountAddress: accountAddress || '',
      token: stakedToken,
    },
    {
      enabled: !!accountAddress,
    },
  );

  const { withdraw, isLoading: isWithdrawLoading } = useWithdrawFromFarms({
    stakedToken,
    rewardToken,
    farmAddress,
  });

  const handleWithdraw = async (amountWei: BigNumber) => {
    if (amountWei.gt(stakedAmount)) {
      throw new VError({
        type: 'transaction',
        code: 'BAD_INPUT',
        data: { error: 'BAD_INPUT', info: 'INVALID_AMOUNT' },
      });
    }

    // Send request to stake
    const res = await withdraw({
      amountWei,
    });

    // Close modal
    handleClose();

    return res;
  };

  return (
    <ActionModal
      title={t('withdrawModal.title', { tokenSymbol: `${stakedToken.symbol}-LP` })}
      token={stakedToken}
      handleClose={handleClose}
      availableTokensWei={stakedAmount || new BigNumber(0)}
      isInitialLoading={isGetWalletBalanceWeiLoading}
      onSubmit={handleWithdraw}
      isSubmitting={isWithdrawLoading}
      connectWalletMessage={t('withdrawModal.connectWalletMessage', {
        tokenSymbol: `${stakedToken.symbol}-LP`,
      })}
      availableTokensLabel={t('withdrawFromDsdVaultModal.availableTokensLabel', {
        tokenSymbol: `${stakedToken.symbol}-LP`,
      })}
      submitButtonLabel={t('withdrawFromDsdVaultModal.submitButtonLabel')}
      submitButtonDisabledLabel={t('withdrawFromDsdVaultModal.submitButtonDisabledLabel')}
      successfulTransactionTitle={t('withdrawFromDsdVaultModal.successfulTransactionModal.title')}
      successfulTransactionDescription={t(
        'withdrawFromDsdVaultModal.successfulTransactionModal.description',
      )}
    />
  );
};

export default WithdrawModal;
