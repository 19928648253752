import { TOKENS } from 'constants/tokens';

const l = (t: string) => t.toLowerCase();

const ff =
  (pools: Record<string, string>) =>
  ({ address }: { address: string }) =>
    Object.values(pools).map(l).includes(l(address));

const ta = (pools: Record<string, string>, address: string) => {
  const [tokenAddress] = Object.entries(pools).find(([, add]) => l(address) === l(add)) || ['NA'];
  return tokenAddress;
};

const resolveUrl = async (url: string) => {
  const response = await fetch(url);
  if (response.status !== 200) throw new Error(`Failed request for: ${url}`);
  return { data: await response.json() };
};

export const {
  defiLlamaUrl,
  balancerUrl,
  yearnUrl,
  curveUrl,
  defiLlamaPools,
  balancerPools,
  curvePools,
  yearnPools,
} = {
  defiLlamaUrl: 'https://yields.llama.fi/poolsEnriched',
  balancerUrl: 'https://api.balancer.fi/pools/1',
  yearnUrl: 'https://api.yearn.finance/v1/chains/1/vaults/all',
  curveUrl: 'https://api.curve.fi/api/getSubgraphData/ethereum',

  defiLlamaPools: {
    [l(TOKENS.LSrETH.address)]: 'd4b3c522-6127-4b89-bedf-83641cdcd2eb',
    [l(TOKENS.LSstETH.address)]: '747c1d2a-c668-4682-b9f9-296708a3dd90',
    [l(TOKENS.LSsfrxETH.address)]: '77020688-e1f9-443c-9388-e51ace15cc32',
    [l(TOKENS.LScbETH.address)]: '0f45d730-b279-4629-8e11-ccb5cc3038b4',
  },
  balancerPools: {
    [l(TOKENS.LSwstETHrETHsfrxETHBPT.address)]:
      '0x5aee1e99fe86960377de9f88689616916d5dcabe000000000000000000000467',
    [l(TOKENS.LSBrETHSTABLE.address)]:
      '0x1e19cf2d73a72ef1332c882f20534b6519be0276000200000000000000000112',
    [l(TOKENS.LSBstETHSTABLE.address)]:
      '0x32296969ef14eb0c6d29669c550d4a0449130230000200000000000000000080',
  },
  curvePools: {
    [l(TOKENS.LSsteCRV.address)]: '0xdc24316b9ae028f1497c275eb9192a3ea0f67022',
    [l(TOKENS.LSfrxETHCRV.address)]: '0xa1f8a6807c402e4a15ef4eba36528a3fed24e577',
    // [l(TOKENS.LScrvFRAX.address)]: "0xdcef968d416a41cdac0ed8702fac8128a64241a2",
    // [l(TOKENS.LS3Crv.address)]: "0xbebc44782c7db0a1a60cb6fe97d0b483032ff1c7",
    // [l(TOKENS.LSOETHCRV_f.address)]: "0x94B17476A93b3262d87B9a326965D1E91f9c13E7",
    [l(TOKENS.LSSTETHETH_C_f.address)]: '0x828b154032950c8ff7cf8085d841723db2696056',
    [l(TOKENS.LSst_frxETH_f.address)]: '0x4d9f9D15101EEC665F77210cB999639f760F831E',
  },
  yearnPools: {
    [l(TOKENS.LSyvWETH.address)]: '0xa258C4606Ca8206D8aA700cE2143D7db854D168c',
  },
};

const getExternalApyData = async () => {
  const [yearnData, curveData, ...rest] = await Promise.all([
    resolveUrl(yearnUrl),
    (async () => {
      const { data } = await resolveUrl(curveUrl);
      return data.success ? data.data : { poolList: [] };
    })(),
    ...Object.entries(balancerPools).map(async ([pool, id]) => {
      const { data } = await resolveUrl(`${balancerUrl}/${id}`);
      return { [pool]: (data.apr.min + data.apr.max) / 2 / 100 };
    }),
    ...Object.entries(defiLlamaPools)
      .map(async ([pool, id]) => {
        const { data } = await resolveUrl(`${defiLlamaUrl}?pool=${id}`);
        if (data.status !== 'success') return;
        const [latestStats] = data.data.reverse();
        return { [pool]: latestStats.apy };
      })
      .filter(Boolean),
  ]);

  return [
    ...yearnData.data
      .filter(ff(yearnPools))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map(({ address, apy }: { address: any; apy: any }) => ({
        [ta(yearnPools, address)]: apy.net_apy * 100,
      })),
    ...curveData.poolList
      .filter(ff(curvePools))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map(({ address, latestWeeklyApy }: { address: any; latestWeeklyApy: any }) => ({
        [ta(curvePools, address)]: latestWeeklyApy,
      })),
    ...rest,
  ].reduce((p, c) => ({ ...p, ...c }), {}) as Record<string, number>;
};

export default getExternalApyData;
