/** @jsxImportSource @emotion/react */
import { Paper, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Icon, InfoIcon, Spinner, Tooltip } from 'components';
import React from 'react';
import { convertWeiToTokens } from 'utilities';

import ClaimEsLTOKENRewardButton from 'components/Layout/ClaimEsLTOKENRewardButton';
import useGetGroups from 'components/Layout/ClaimEsLTOKENRewardButton/useGetGroups';
import { PoolAsset } from 'containers/MarketTable/types';

import { useStyles } from './styles';

export interface Cell {
  label: string;
  value: string | number;
  tooltip?: string;
  color?: string;
}

export interface CellGroupProps {
  cells: Cell[];
  smallValues?: boolean;
  title?: string;
  className?: string;
  poolAssets?: PoolAsset[];
}

export const CellGroup: React.FC<CellGroupProps> = ({
  cells,
  title,
  smallValues = false,
  poolAssets = [],
  ...containerProps
}) => {
  const styles = useStyles();
  const { data: pendingRewards, isLoading: isPendingRewardsLoading } = useGetGroups({ poolAssets });

  const totalUsdValueForPendingRewards = React.useMemo(
    () =>
      pendingRewards.reduce<BigNumber>(
        (p, c) => p.plus(new BigNumber(c.usdValue || 0)),
        new BigNumber(0),
      ),
    [pendingRewards],
  );

  return (
    <Paper css={styles.container} {...containerProps}>
      {!!title && <h4 css={styles.title}>{title}</h4>}

      <div css={styles.cellContainer}>
        {cells.map(({ label, value, tooltip, color }) => (
          <div css={styles.cell} key={`cell-group-item-${label}`}>
            <div css={styles.labelContainer}>
              <Typography variant={smallValues ? 'small1' : 'body2'} css={styles.label}>
                {label}
              </Typography>

              {!!tooltip && <InfoIcon tooltip={tooltip} css={styles.labelInfoIcon} />}
            </div>

            <Typography variant={smallValues ? 'body2' : 'body2'} css={styles.getValue({ color })}>
              {value}
            </Typography>
          </div>
        ))}
        {isPendingRewardsLoading ? (
          <Spinner />
        ) : (
          !!pendingRewards.length && (
            <>
              <div css={styles.cell} key="cell-group-item-pendingRewards">
                <div css={styles.labelContainer}>
                  <Typography variant={smallValues ? 'small1' : 'body2'} css={styles.label}>
                    Pending Rewards
                  </Typography>

                  <Tooltip
                    css={styles.labelInfoIcon}
                    title={
                      <>
                        {pendingRewards.map(r => (
                          <div key={r.rewardToken.address}>
                            {convertWeiToTokens({
                              valueWei: r.rewardAmountWei,
                              token: r.rewardToken,
                              returnInReadableFormat: true,
                              minimizeDecimals: 2,
                            })}
                            {r.fromMinter && ' (For Minting DSD)'}
                            {r.fromFees && ' (Protocol Fees)'}
                          </div>
                        ))}
                      </>
                    }
                  >
                    <span>
                      <Icon name="info" />
                    </span>
                  </Tooltip>
                </div>

                <Typography
                  variant={smallValues ? 'body2' : 'body2'}
                  css={styles.getValue({ color: 'white' })}
                >
                  {totalUsdValueForPendingRewards.eq(0)
                    ? '-:-'
                    : `$ ${totalUsdValueForPendingRewards.toFixed(3)}`}
                </Typography>
              </div>
              <div css={styles.cell} key="cell-group-item-button">
                <ClaimEsLTOKENRewardButton
                  css={styles.claimRewardButton}
                  rewards={pendingRewards}
                />
              </div>
            </>
          )
        )}
      </div>
    </Paper>
  );
};

export default CellGroup;

// {
//   label: t('account.marketBreakdown.cellGroup.pendingRewards'),
//   value: formatCentsToReadableValue({
//     value: isEsLTOKENRewardLoading || !esLTOKENReward ? 0 : esLTOKENReward.earnedEsLTOKEN,
//   }),
// },
