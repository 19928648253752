import { ClaimMultiRewardsInput, ClaimMultiRewardsOutput } from './types';

export * from './types';

const claimMultiRewards = async ({
  multiRewardContract,
}: ClaimMultiRewardsInput): Promise<ClaimMultiRewardsOutput> => {
  const transaction = await multiRewardContract.getReward();
  return transaction.wait(1);
};

export default claimMultiRewards;
