import mainContractAddresses from 'constants/contracts/addresses/main.json';
import forkedMainContractAddresses from 'constants/contracts/addresses/main_forked.json';

const getContractAddress =
  process.env.REACT_APP_isForked === 'true'
    ? (contractId: keyof typeof forkedMainContractAddresses) =>
        forkedMainContractAddresses[contractId][1]
    : (contractId: keyof typeof mainContractAddresses) =>
        mainContractAddresses[contractId][1];

// eslint-disable-next-line no-console
console.log('@PROCESS IS_FORKED', process.env.REACT_APP_isForked);

export default getContractAddress;
