import { MutationObserverOptions, useMutation } from 'react-query';

import { useEsLTOKENContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

import escrowLToken, { EscrowLTokenInput, EscrowLTokenOutput } from '.';

const useEscrowLToken = (
  options?: MutationObserverOptions<
    EscrowLTokenOutput,
    Error,
    Omit<EscrowLTokenInput, 'esLtokenContract'>
  >,
) => {
  const esLtokenContract = useEsLTOKENContract();

  return useMutation(
    FunctionKey.EXECUTE_PROPOSAL,
    params =>
      escrowLToken({
        esLtokenContract,
        ...params,
      }),
    options,
  );
};

export default useEscrowLToken;
