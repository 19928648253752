/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  EnableToken,
  FormikSubmitButton,
  LabeledInlineContent, // NoticeWarning,
  Spinner,
} from 'components';
import { ContractReceipt } from 'ethers';
import React, { useMemo } from 'react';
import { useTranslation } from 'translation';
import { Asset } from 'types';
import { convertTokensToWei, convertWeiToTokens, getContractAddress } from 'utilities';

import {
  useGetBalanceOf,
  useGetDsdRepayAmountWithInterests,
  useGetMintedDsd,
  useGetPools,
  useRepayDsd,
} from 'clients/api';
import brand from 'config/brand';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import MAX_UINT256 from 'constants/maxUint256';
import { TOKENS } from 'constants/tokens';
import { AmountForm, AmountFormProps } from 'containers/AmountForm';
import { useAuth } from 'context/AuthContext';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import DsdAccountData from '../DsdAccountData';
import FormikTokenTextFieldWithBalance from '../TextFieldWithBalance';
import { useStyles } from '../styles';
// import RepayFee from './RepayFee';

const dsdControllerContractAddress = getContractAddress('dsdController');

export interface IRepayDsdUiProps {
  disabled: boolean;
  isInitialLoading: boolean;
  isSubmitting: boolean;
  repayDsd: (amountWei: BigNumber) => Promise<ContractReceipt | undefined>;
  userBalanceWei?: BigNumber;
  repayBalanceWei?: BigNumber;
  userMintedWei?: BigNumber;
}

// const isPayingFullRepayBalance = (
//   amount: string,
//   repayBalanceWei: BigNumber | undefined,
// ): boolean => {
//   if (amount && repayBalanceWei) {
//     const amountWei = convertTokensToWei({ value: new BigNumber(amount), token: TOKENS.dsd });
//     return amountWei.isEqualTo(repayBalanceWei);
//   }

//   return false;
// };

export const RepayDsdUi: React.FC<IRepayDsdUiProps> = ({
  disabled,
  userBalanceWei,
  repayBalanceWei,
  userMintedWei,
  isInitialLoading,
  isSubmitting,
  repayDsd,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { accountAddress } = useAuth();
  const { data: getPoolsData, isLoading: isGetPoolsLoading } = useGetPools({
    accountAddress,
  });
  const assets = useMemo(
    () => getPoolsData?.pools.reduce((acc, pool) => [...acc, ...pool.assets], [] as Asset[]),
    [getPoolsData],
  );

  const handleTransactionMutation = useHandleTransactionMutation();

  const limitTokens = React.useMemo(() => {
    const limitWei =
      userBalanceWei && userMintedWei
        ? BigNumber.minimum(userBalanceWei, userMintedWei)
        : new BigNumber(0);

    return convertWeiToTokens({ valueWei: limitWei, token: TOKENS.dsd }).toFixed();
  }, [userBalanceWei?.toFixed(), userMintedWei?.toFixed()]);

  // Convert repay balance (minted + interests) into DSD
  const readableRepayableDsd = useConvertWeiToReadableTokenString({
    valueWei: repayBalanceWei,
    token: TOKENS.dsd,
  });

  const hasRepayableDsd = userMintedWei?.isGreaterThan(0) || false;

  const onSubmit: AmountFormProps['onSubmit'] = async amountTokens => {
    const amountWei = convertTokensToWei({
      value: new BigNumber(amountTokens),
      token: TOKENS.dsd,
    });

    return handleTransactionMutation({
      mutate: () => repayDsd(amountWei),
      successTransactionModalProps: contractReceipt => ({
        title: t('dsd.repayDsd.successfulTransactionModal.title'),
        content: t('dsd.repayDsd.successfulTransactionModal.message'),
        amount: {
          valueWei: amountWei,
          token: TOKENS.dsd,
        },
        transactionHash: contractReceipt.transactionHash,
      }),
    });
  };

  return (
    <ConnectWallet
      message={t('dsd.repayDsd.connectWallet', { tokenSymbol: brand.stableCoinSymbol })}
    >
      <EnableToken
        title={t('dsd.repayDsd.enableToken', { tokenSymbol: brand.stableCoinSymbol })}
        token={TOKENS.dsd}
        spenderAddress={dsdControllerContractAddress}
        noUi
      >
        {({ enableToken, isTokenEnabled, isEnableTokenLoading, disabled: approvalDisabled }) =>
          isInitialLoading ? (
            <Spinner />
          ) : (
            <AmountForm
              onSubmit={isTokenEnabled ? onSubmit : enableToken}
              css={[styles.tabContentContainer, styles.border]}
            >
              {() => (
                <>
                  {!isGetPoolsLoading && assets && <DsdAccountData assets={assets} />}
                  <FormikTokenTextFieldWithBalance
                    disabled={disabled || isSubmitting || !hasRepayableDsd}
                    maxValue={limitTokens}
                    userBalanceWei={userBalanceWei}
                    maxButtonLabel={t('dsd.repayDsd.rightMaxButtonLabel')}
                  />

                  <div css={styles.ctaContainer}>
                    <LabeledInlineContent
                      css={styles.getRow({ isLast: false })}
                      iconSrc={TOKENS.dsd}
                      label={t('dsd.repayDsd.repayDsdBalance', {
                        tokenSymbol: brand.stableCoinSymbol,
                      })}
                      tooltip={t('dsd.repayDsd.repayDsdBalanceTooltip', {
                        tokenSymbol: brand.stableCoinSymbol,
                      })}
                    >
                      {readableRepayableDsd}
                    </LabeledInlineContent>

                    {/* <RepayFee repayAmountTokens={values.amount} /> */}
                  </div>

                  {/* {isPayingFullRepayBalance(values.amount, repayBalanceWei) && (
                    <NoticeWarning
                      css={styles.noticeWarning}
                      description={<Trans i18nKey="dsd.repayDsd.fullRepayWarning" />}
                    />
                  )} */}

                  <FormikSubmitButton
                    loading={isSubmitting || isEnableTokenLoading}
                    disabled={disabled || approvalDisabled}
                    enabledLabel={
                      isTokenEnabled
                        ? t('dsd.repayDsd.submitButtonLabel')
                        : t('dsd.repayDsd.enableToken', { tokenSymbol: brand.stableCoinSymbol })
                    }
                    disabledLabel={
                      isTokenEnabled
                        ? t('dsd.repayDsd.submitButtonDisabledLabel')
                        : t('dsd.repayDsd.enableToken', { tokenSymbol: brand.stableCoinSymbol })
                    }
                    fullWidth
                  />
                </>
              )}
            </AmountForm>
          )
        }
      </EnableToken>
    </ConnectWallet>
  );
};

const RepayDsd: React.FC = () => {
  const { accountAddress } = useAuth();
  const { data: mintedDsdData, isLoading: isGetMintedDsdLoading } = useGetMintedDsd(
    {
      accountAddress,
    },
    {
      enabled: !!accountAddress,
    },
  );

  const { data: repayAmountWithInterests, isLoading: isGetDsdRepayAmountWithInterests } =
    useGetDsdRepayAmountWithInterests(
      {
        accountAddress,
      },
      {
        enabled: !!accountAddress,
      },
    );

  const { data: userDsdBalanceData, isLoading: isGetUserDsdBalance } = useGetBalanceOf(
    {
      accountAddress: accountAddress || '',
      token: TOKENS.dsd,
    },
    {
      enabled: !!accountAddress,
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
    },
  );

  const { mutateAsync: contractRepayDsd, isLoading: isSubmitting } = useRepayDsd();

  const repayDsd: IRepayDsdUiProps['repayDsd'] = async amountWei => {
    const isRepayingFullLoan = amountWei.eq(
      convertTokensToWei({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: repayAmountWithInterests!.dsdRepayAmountWithInterests,
        token: TOKENS.dsd,
      }),
    );

    return contractRepayDsd({
      amountWei: isRepayingFullLoan ? MAX_UINT256 : amountWei,
    });
  };

  return (
    <RepayDsdUi
      disabled={!accountAddress}
      userBalanceWei={userDsdBalanceData?.balanceWei}
      repayBalanceWei={repayAmountWithInterests?.dsdRepayAmountWithInterests}
      userMintedWei={mintedDsdData?.mintedDsdWei}
      isInitialLoading={
        isGetMintedDsdLoading || isGetUserDsdBalance || isGetDsdRepayAmountWithInterests
      }
      isSubmitting={isSubmitting}
      repayDsd={repayDsd}
    />
  );
};

export default RepayDsd;
