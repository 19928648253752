import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

const ITEM_HEIGHT_RATIO = 10;

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: ${theme.spacing(0)};
      background-color: #21302f;
      border-radius: ${theme.spacing(3)};
      box-shadow: 0 4px 15px 0 rgba(22, 23, 30, 0.8);
      overflow: hidden;
      background: rgba(255, 255, 255, 0.04);
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(30px);
      border-radius: 12px;
    `,
    header: css`
      padding: ${theme.spacing(3, 3, 0)};
      margin-bottom: ${theme.spacing(5)};
    `,
    searchField: css`
      margin-bottom: ${theme.spacing(4)};

      > div {
        background-color: ${theme.palette.primary.dark};
      }
    `,
    commonTokenList: css`
      display: flex;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }
    `,
    commonTokenButton: css`
      padding: ${theme.spacing(1, 2)};
      border-color: ${theme.palette.secondary.light};

      :hover:not(:disabled) {
        border-color: ${theme.palette.interactive.primary};
        background-color: ${theme.palette.secondary.light};
      }

      :active:not(:disabled) {
        border-color: ${theme.palette.secondary.light};
        background-color: ${theme.palette.secondary.light};
      }

      :not(:last-of-type) {
        margin-right: ${theme.spacing(2)};
      }
    `,
    list: css`
      max-height: ${theme.spacing(ITEM_HEIGHT_RATIO * 6)};
      overflow-y: auto;
    `,
    item: css`
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: ${theme.spacing(ITEM_HEIGHT_RATIO)};
      padding: ${theme.spacing(0, 3)};

      :hover {
        background-color: ${theme.palette.secondary.light};
      }
    `,
    icon: css`
      margin-top: -2px;
      width: ${theme.shape.iconSize.large}px;
      height: ${theme.shape.iconSize.large}px;
      margin-right: ${theme.spacing(2)};
    `,
    protocol: css`
      display: flex;
      flex-direction: 'row';
      gap: '1em';
      align-items: center;
      color: ${theme.palette.text.primary};
    `,
  };
};
