import BigNumber from 'bignumber.js';
import { Token } from 'types';

import { useStakeInFarm } from 'clients/api';

export interface UseStakeInFarmsInput {
  stakedToken: Token;
  rewardToken: Token;
  farmAddress: string;
}

interface StakeInput {
  amountWei: BigNumber;
}

const useStakeInFarms = ({ stakedToken, rewardToken, farmAddress }: UseStakeInFarmsInput) => {
  const { mutateAsync: stakeInFarm, isLoading } = useStakeInFarm(
    farmAddress,
    stakedToken.address,
    rewardToken.address,
  );

  const stake = async ({ amountWei }: StakeInput) =>
    stakeInFarm({
      amountWei,
    });

  return {
    isLoading,
    stake,
  };
};

export default useStakeInFarms;
