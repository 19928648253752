/** @jsxImportSource @emotion/react */
// import { useTheme } from '@mui/material';
import { ContractReceipt } from 'ethers';
import React from 'react';
import { useTranslation } from 'translation';
import { Token } from 'types';
import { getContractAddress } from 'utilities';

import { useMigrate } from 'clients/api';
import ApproveToken from 'components/ApproveToken';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import { ButtonProps, SecondaryButton } from '../Button';

export const MigrateButtonUi: React.FC<ButtonProps> = ({ ...otherProps }) => (
  <SecondaryButton {...otherProps}>Migrate</SecondaryButton>
);

export const MigrateButton = ({ lptoken, pooltoken }: { lptoken: Token; pooltoken: Token }) => {
  // const { shape } = useTheme();
  const { t } = useTranslation();
  const handleTransactionMutation = useHandleTransactionMutation();

  const { mutateAsync: migrate, isLoading: isMigrating } = useMigrate();

  const handleMigrate = () =>
    handleTransactionMutation({
      mutate: async () => {
        const contractReceipt: ContractReceipt = await migrate({ lptoken });
        return contractReceipt;
      },
      successTransactionModalProps: (contractReceipt: ContractReceipt) => ({
        title: t('migrate.successfulTransactionModal.title'),
        content: t('migrate.successfulTransactionModal.message'),
        transactionHash: contractReceipt.transactionHash,
      }),
    });

  return (
    <>
      <ApproveToken
        token={pooltoken}
        spenderAddress={getContractAddress('migrator')}
        title="approve"
      >
        <MigrateButtonUi
          onClick={() => {
            handleMigrate();
          }}
          disabled={isMigrating}
          variant="primary"
        />
      </ApproveToken>
    </>
  );
};

export default MigrateButton;
