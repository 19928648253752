import BigNumber from 'bignumber.js';

import { EsLTOKENMinter } from 'types/contracts';

export interface GetEsLTOKENRewardInput {
  esLTOKENMinterContract: EsLTOKENMinter;
  accountAddress: string;
}

export type GetEsLTOKENRewardOutput = {
  earnedEsLTOKEN: BigNumber;
};

const getEsLTOKENReward = async ({
  esLTOKENMinterContract,
  accountAddress,
}: GetEsLTOKENRewardInput): Promise<GetEsLTOKENRewardOutput> => {
  const res = await esLTOKENMinterContract.earned(accountAddress);

  return {
    earnedEsLTOKEN: new BigNumber(res.toString()),
  };
};

export default getEsLTOKENReward;
