/** @jsxImportSource @emotion/react */
import { Typography, useMediaQuery } from '@mui/material';
import { Button, Spinner, TextField } from 'components';
import React, { InputHTMLAttributes, useState } from 'react';
import { Link as LocalLink } from 'react-router-dom';
import { useTranslation } from 'translation';
import { Pool } from 'types';
import { isFeatureEnabled } from 'utilities';

import { useGetPools } from 'clients/api';
import { SuperchargePortfolio } from 'components/SuperchargePortfolio';
import brand from 'config/brand';
import { routes } from 'constants/routing';
import { MarketTable, MarketTableProps } from 'containers/MarketTable';
import { useAuth } from 'context/AuthContext';
import { useHideXlDownCss, useShowXlDownCss } from 'hooks/responsive';
import Account from 'pages/Account';
import { BREAKPOINTS } from 'theme/MuiThemeProvider/muiTheme';

import { useStyles } from './styles';
import TEST_IDS from './testIds';
import useFormatPools from './useFormatPools';

interface DashboardUiProps {
  searchValue: string;
  onSearchInputChange: (newValue: string) => void;
  pools: Pool[];
  isFetchingPools?: boolean;
}

export const DashboardUi: React.FC<DashboardUiProps> = ({
  pools,
  isFetchingPools,
  searchValue,
  onSearchInputChange,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const showXlDown = useMediaQuery(`(max-width: ${BREAKPOINTS.values.xl}px)`);
  const { accountAddress } = useAuth();

  const showXlDownCss = useShowXlDownCss();
  const hideXlDownCss = useHideXlDownCss();

  const handleSearchInputChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = changeEvent =>
    onSearchInputChange(changeEvent.currentTarget.value);

  const formattedPools = useFormatPools({
    pools,
    searchValue,
  });

  const supplyMarketTableProps: MarketTableProps = {
    pools: formattedPools,
    isFetching: isFetchingPools,
    marketType: 'supply',
    breakpoint: 'lg',
    onlyMyCollaterals: true,
    columns: [
      'protocol',
      'asset',
      'userSupplyBalance',
      'userWalletBalance',
      'collateralFactor',
      'yield',
      'esRewards',
      'supplyApyLtv',
      // 'assetPrice',
      // 'collateral',
      'supplyBalance',
      // 'reward',
      'manage',
    ],
    initialOrder: {
      orderBy: 'userSupplyBalance',
      orderDirection: 'desc',
    },
  };

  if (isFetchingPools) {
    return <Spinner />;
  }
  if (!accountAddress) {
    return (
      <>
        <Account />
        <SuperchargePortfolio />
      </>
    );
  }

  return (
    <>
      <div css={styles.header}>
        <div css={styles.headerBottomRow}>
          <Typography variant="h3" css={hideXlDownCss}>
            Overview
          </Typography>
          <div css={styles.rightColumn}>
            <Typography css={showXlDownCss} variant="h4">
              Overview
            </Typography>
            <LocalLink to={routes.dsd.path} css={styles.link}>
              <Button variant="primary">Mint or Repay {brand.stableCoinSymbol}</Button>
            </LocalLink>
          </div>
        </div>
      </div>
      <Account />

      {/* <Announcement token={TOKENS.trxold} /> */}

      <div css={styles.header}>
        <TextField
          css={[styles.tabletSearchTextField, showXlDownCss]}
          isSmall
          value={searchValue}
          onChange={handleSearchInputChange}
          placeholder={
            isFeatureEnabled('isolatedPools')
              ? t('dashboard.searchInput.placeholderIsolatedPools')
              : t('dashboard.searchInput.placeholder')
          }
          leftIconSrc="magnifier"
        />

        <div css={styles.headerBottomRow}>
          <Typography variant="h3" css={hideXlDownCss}>
            Your collateral
          </Typography>
          <div css={styles.rightColumn}>
            <Typography css={showXlDownCss} variant="h4">
              Your collateral
            </Typography>
            <LocalLink to={routes.addCollateral.path} css={styles.link}>
              <Button variant="primary">+ Supply Collateral</Button>
            </LocalLink>

            <TextField
              css={[styles.desktopSearchTextField, hideXlDownCss]}
              isSmall
              value={searchValue}
              onChange={handleSearchInputChange}
              placeholder={t('dashboard.searchInput.placeholder')}
              leftIconSrc="magnifier"
            />
          </div>
        </div>
      </div>

      {showXlDown ? (
        <div css={showXlDownCss}>
          <MarketTable {...supplyMarketTableProps} key="dashboard-supply-market-table" />
        </div>
      ) : (
        <div css={[styles.desktopMarketTables, hideXlDownCss]}>
          <MarketTable
            {...supplyMarketTableProps}
            title={t('dashboard.supplyMarketTableTitle')}
            testId={TEST_IDS.supplyMarketTable}
          />
        </div>
      )}

      <br />
      <SuperchargePortfolio />
    </>
  );
};

const Dashboard: React.FC = () => {
  const { accountAddress } = useAuth();

  const [searchValue, setSearchValue] = useState('');

  const { data: getPoolData, isLoading: isGetPoolsLoading } = useGetPools({
    accountAddress,
  });

  return (
    <DashboardUi
      pools={getPoolData?.pools || []}
      isFetchingPools={isGetPoolsLoading}
      searchValue={searchValue}
      onSearchInputChange={setSearchValue}
    />
  );
};

export default Dashboard;
