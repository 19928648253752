/** @jsxImportSource @emotion/react */
import React from 'react';
import { Pool } from 'types';

import { MarketTable } from 'containers/MarketTable';
import { ColumnKey } from 'containers/MarketTable/types';
import { useAuth } from 'context/AuthContext';

export interface TableProps {
  pool: Pool;
}

export const Table: React.FC<TableProps> = ({ pool }) => {
  const { accountAddress } = useAuth();
  const columns: ColumnKey[] = accountAddress
    ? [
        'protocol',
        'asset',
        'supplyBalance',
        'userSupplyBalance',
        'userWalletBalance',
        'collateralFactor',
        'assetPrice',
        'yield',
        'esRewards',
        'supplyApyLtv',
        'liquidity',
        'manage',
      ]
    : [
        'protocol',
        'asset',
        'supplyBalance',
        'collateralFactor',
        'assetPrice',
        'yieldLabel',
        'esRewardsLabel',
        'supplyApyLtv',
        'liquidity',
      ];

  return (
    <MarketTable
      pools={[pool]}
      breakpoint="xl"
      columns={columns}
      initialOrder={{
        orderBy: 'liquidity',
        orderDirection: 'desc',
      }}
    />
  );
};

export default Table;
