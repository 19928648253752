import { useQuery } from 'react-query';

import { useMulticall } from 'clients/web3';
import { BLOCK_TIME_MS } from 'constants/eth';
import FunctionKey from 'constants/functionKey';
import { useAuth } from 'context/AuthContext';

import getFarms from '.';

const useGetFarms = () => {
  const multicall = useMulticall();
  const { accountAddress } = useAuth();
  return useQuery([FunctionKey.GET_FARMS, 'getFarms'], () => getFarms(multicall, accountAddress), {
    refetchInterval: BLOCK_TIME_MS,
  });
};

export default useGetFarms;
