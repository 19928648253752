/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { AccountData, FormikSubmitButton, FormikTokenTextField, toast } from 'components';
import { VError, formatVErrorToReadableString } from 'errors';
import { useField } from 'formik';
import { isObject } from 'lodash';
import React from 'react';
import { useTranslation } from 'translation';
import { Asset, Pool } from 'types';
import { formatToReadablePercentage, formatTokensToReadableValue } from 'utilities';

import { useGetApyData } from 'clients/api';
import { AmountForm, AmountFormProps, ErrorCode } from 'containers/AmountForm';

import { useStyles } from '../styles';
import TEST_IDS from './testIds';

interface SupplyFormUiProps {
  asset: Asset;
  pool: Pool;
  maxInput: BigNumber;
  inputLabel: string;
  enabledButtonKey: string;
  disabledButtonKey: string;
  isTransactionLoading: boolean;
  amountValue: string;
  forModal?: boolean;
}

export const SupplyContent: React.FC<SupplyFormUiProps> = ({
  asset,
  pool,
  maxInput,
  inputLabel,
  enabledButtonKey,
  disabledButtonKey,
  isTransactionLoading,
  amountValue,
  forModal,
}) => {
  const styles = useStyles();
  const { t, Trans } = useTranslation();

  const amount = new BigNumber(amountValue || 0);
  const isAmountValid = amount && !amount.isZero() && !amount.isNaN();

  // eslint-disable-next-line  @typescript-eslint/naming-convention
  const [_inputProps, _metaProps, { setValue: setAmountFieldValue }] = useField('amount');
  const { data: apyData, isLoading: isApyDataLoading } = useGetApyData();

  const netApy = React.useMemo(
    () =>
      apyData?.tokenData && apyData?.tokenData?.[asset.llToken.address]
        ? Object.values(apyData.tokenData[asset.llToken.address]).reduce((apy, value) => {
            if (isObject(value)) return apy;
            return +value + apy;
          }, 0) - +apyData.tokenData[asset.llToken.address].tokenUsdPrice || 0
        : 0,
    [apyData],
  );

  // const emissionApr = React.useMemo(
  //   () =>
  //     apyData?.tokenData && apyData?.tokenData?.[asset.llToken.address]
  //       ? apyData.tokenData[asset.llToken.address].esTokenApyOnProvidingCollateral
  //       : netApy,
  //   [apyData, netApy],
  // );

  return (
    <>
      {!isApyDataLoading && (
        <>
          <AccountData
            forModal
            balance={asset.userSupplyBalanceTokens}
            amountTokens={amount}
            asset={asset}
            pool={pool}
            action="supply"
          />
          <FormikTokenTextField
            data-testid={TEST_IDS.valueInput}
            name="amount"
            token={asset.llToken.underlyingToken}
            disabled={isTransactionLoading}
            rightMaxButton={{
              label: t('supplyWithdrawModal.supply.rightMaxButtonLabel').toUpperCase(),
              onClick: () => setAmountFieldValue(maxInput.toFixed()),
            }}
            balance={maxInput}
            css={styles.input}
            // Only display error state if amount is higher than borrow limit
            displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
          />

          {!forModal && (
            <Typography
              component="div"
              variant="small2"
              css={[styles.greyLabel, styles.getRow({ isLast: true })]}
            >
              <Trans
                i18nKey={inputLabel}
                components={{
                  White: <span css={styles.whiteLabel} />,
                }}
                values={{
                  amount: formatTokensToReadableValue({
                    value: maxInput,
                    token: asset.llToken.underlyingToken,
                  }),
                }}
              />
            </Typography>
          )}

          <div css={styles.bottomNotice}>
            <div>
              <Typography color="white">esLTOKEN emissions APR</Typography>
              <Typography variant="body1">
                Your deposit will earn {formatToReadablePercentage(netApy)} APR, equivalent to 180
                esLTOKEN ($120) per day
              </Typography>
            </div>
            <div css={styles.apr}>
              <Typography variant="h3">{formatToReadablePercentage(netApy)} APR</Typography>
            </div>
          </div>

          <FormikSubmitButton
            fullWidth
            data-testid={TEST_IDS.submitButton}
            disabled={!isAmountValid}
            loading={isTransactionLoading}
            enabledLabel={enabledButtonKey}
            disabledLabel={disabledButtonKey}
          />
        </>
      )}
    </>
  );
};

interface SupplyFormProps extends Omit<SupplyFormUiProps, 'amountValue'> {
  onSubmit: AmountFormProps['onSubmit'];
}

const SupplyForm: React.FC<SupplyFormProps> = ({ onSubmit, maxInput, ...props }) => {
  const onSubmitHandleError: AmountFormProps['onSubmit'] = async (value: string) => {
    try {
      await onSubmit(value);
    } catch (error) {
      let { message } = error as Error;
      if (error instanceof VError) {
        message = formatVErrorToReadableString(error);
        toast.error({
          message,
        });
      }
    }
  };

  return (
    <AmountForm onSubmit={onSubmitHandleError} maxAmount={maxInput.toFixed()}>
      {({ values }) => <SupplyContent maxInput={maxInput} amountValue={values.amount} {...props} />}
    </AmountForm>
  );
};

export default SupplyForm;
