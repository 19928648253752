import { QueryObserverOptions, useQuery } from 'react-query';
import { getContractAddress } from 'utilities';

import getMintedDsd, {
  GetMintedDsdInput,
  GetMintedDsdOutput,
} from 'clients/api/queries/getMintedDsd';
import { useComptrollerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

const mainPoolComptrollerAddress = getContractAddress('comptroller');

type Options = QueryObserverOptions<
  GetMintedDsdOutput,
  Error,
  GetMintedDsdOutput,
  GetMintedDsdOutput,
  FunctionKey.GET_MINTED_DSD
>;

const useGetMintedDsd = (
  { accountAddress }: Omit<GetMintedDsdInput, 'comptrollerContract'>,
  options?: Options,
) => {
  const comptrollerContract = useComptrollerContract(mainPoolComptrollerAddress);

  return useQuery(
    FunctionKey.GET_MINTED_DSD,
    () => getMintedDsd({ accountAddress, comptrollerContract }),
    options,
  );
};

export default useGetMintedDsd;
