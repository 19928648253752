import { MutationObserverOptions, useMutation } from 'react-query';

import { WithdrawLtokenOutput, queryClient, withdrawLtoken } from 'clients/api';
import { useEsLTOKENContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

const useWithdrawLtoken = (options?: MutationObserverOptions<WithdrawLtokenOutput, Error>) => {
  const esLTOKENContract = useEsLTOKENContract();

  return useMutation(
    FunctionKey.WITHDRAW_LTOKEN,
    () =>
      withdrawLtoken({
        esLTOKENContract,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        queryClient.invalidateQueries(FunctionKey.GET_LTOKEN_WITHDRAWABLE_AMOUNT);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useWithdrawLtoken;
