import { QueryObserverOptions, useQuery } from 'react-query';

import getLLTokenBalancesAll, {
  GetLLTokenBalancesAllInput,
  GetLLTokenBalancesAllOutput,
} from 'clients/api/queries/getLLTokenBalancesAll';
import { useLsLensContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetLLTokenBalancesAllOutput,
  Error,
  GetLLTokenBalancesAllOutput,
  GetLLTokenBalancesAllOutput,
  [FunctionKey.GET_V_TOKEN_BALANCES_ALL, Omit<GetLLTokenBalancesAllInput, 'lsLensContract'>]
>;

const useGetLLTokenBalancesAll = (
  { account, llTokenAddresses }: Omit<GetLLTokenBalancesAllInput, 'lsLensContract'>,
  options?: Options,
) => {
  const lsLensContract = useLsLensContract();
  return useQuery(
    [FunctionKey.GET_V_TOKEN_BALANCES_ALL, { account, llTokenAddresses }],
    () => getLLTokenBalancesAll({ lsLensContract, account, llTokenAddresses }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetLLTokenBalancesAll;
