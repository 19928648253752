import { QueryObserverOptions, useQuery } from 'react-query';

import { getDsdRepayAmountWithInterests } from 'clients/api';
import { useMulticall } from 'clients/web3';
import FunctionKey from 'constants/functionKey';

import { GetDsdRepayAmountWithInterestsInput, GetDsdRepayAmountWithInterestsOutput } from './types';

type Options = QueryObserverOptions<
  GetDsdRepayAmountWithInterestsOutput,
  Error,
  GetDsdRepayAmountWithInterestsOutput,
  GetDsdRepayAmountWithInterestsOutput,
  [
    FunctionKey.GET_DSD_REPAY_AMOUNT_WITH_INTERESTS,
    {
      accountAddress: string;
    },
  ]
>;

const useGetDsdRepayAmountWithInterests = (
  { accountAddress }: Omit<GetDsdRepayAmountWithInterestsInput, 'multicall'>,
  options?: Options,
) => {
  const multicall = useMulticall();

  return useQuery(
    [
      FunctionKey.GET_DSD_REPAY_AMOUNT_WITH_INTERESTS,
      {
        accountAddress,
      },
    ],
    () => getDsdRepayAmountWithInterests({ multicall, accountAddress }),
    options,
  );
};

export default useGetDsdRepayAmountWithInterests;
