import { MutationObserverOptions, useMutation } from 'react-query';

// import { TOKENS } from 'constants/tokens';
import { RedeemDsdInput, RedeemDsdOutput, queryClient, redeemDsd } from 'clients/api';
import { useDsdControllerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { DsdController } from 'types/contracts';

const useRedeemDsd = (
  options?: MutationObserverOptions<
    RedeemDsdOutput,
    Error,
    Omit<RedeemDsdInput, 'dsdControllerContract'>
  >,
) => {
  const dsdControllerContract: DsdController = useDsdControllerContract();

  return useMutation(
    FunctionKey.REDEEM_DSD,
    params =>
      redeemDsd({
        dsdControllerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        const accountAddress = await dsdControllerContract.signer.getAddress();

        queryClient.invalidateQueries([
          FunctionKey.GET_TOKEN_BALANCES,
          {
            accountAddress,
          },
        ]);

        queryClient.invalidateQueries([
          FunctionKey.GET_TOKEN_BALANCES,
          {
            accountAddress,
          },
        ]);

        queryClient.invalidateQueries(['GET_REDEEMABLE_DSD', 'getRedeemDsdData']);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useRedeemDsd;
