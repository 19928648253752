import { checkForComptrollerTransactionError } from 'errors/transactionErrors';

import { ClaimEsLTOKENRewardInput, ClaimEsLTOKENRewardOutput } from './types';

export * from './types';

const claimEsLTOKENRewards = async ({
  esLTOKENMinterContract,
}: ClaimEsLTOKENRewardInput): Promise<ClaimEsLTOKENRewardOutput> => {
  const transaction = await esLTOKENMinterContract.getReward();
  const receipt = await transaction.wait(1);
  checkForComptrollerTransactionError(receipt);
  return receipt;
};

export default claimEsLTOKENRewards;
