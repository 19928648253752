import BigNumber from 'bignumber.js';

import { Fund as lsFund } from 'types/contracts';

export interface GetDSDRewardInput {
  lsFundContract: lsFund;
  accountAddress: string;
}

export type GetDSDRewardOutput = {
  earnedDSD: BigNumber;
};

const getDSDReward = async ({
  lsFundContract,
  accountAddress,
}: GetDSDRewardInput): Promise<GetDSDRewardOutput> => {
  const res = await lsFundContract.earned(accountAddress);

  return {
    earnedDSD: new BigNumber(res.toString()),
  };
};

export default getDSDReward;
