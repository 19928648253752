/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import { InfoIcon, Spinner, Toggle } from 'components';
import React from 'react';
// import { Redirect } from 'react-router-dom';
import { MigrationPool } from 'types';

// import { getContractAddress } from 'utilities';
import { useGetMigrationPools } from 'clients/api';
import { useAuth } from 'context/AuthContext';

import Table from './Table';
import { useStyles } from './styles';

export interface MigrateUiProps {
  pools: MigrationPool[];
  loading?: boolean;
}

export const MigrateUi: React.FC<MigrateUiProps> = ({ loading, pools }) => {
  const styles = useStyles();
  const [hideAsset, setHideAsset] = React.useState<boolean>(false);
  const [filteredPools, setFilteredPools] = React.useState<MigrationPool[]>(pools);

  const handleToggle = () => {
    const newPool = [
      {
        ...filteredPools[0],
        assets: filteredPools[0].assets.filter(asset => !asset.userPoolTokenWalletBalance.isZero()),
      },
    ];
    setFilteredPools(!hideAsset ? newPool : pools);
    setHideAsset(!hideAsset);
  };

  return loading ? (
    <>
      <Spinner />
      <div> loading </div>
    </>
  ) : (
    <>
      <div css={styles.headerDescription}>
        <Typography variant="h2" component="div">
          Migrator
        </Typography>
        <Typography variant="body1" component="div">
          <Toggle
            onChange={() => handleToggle()}
            value={hideAsset}
            label="Hide assets I don’t have"
            tooltip="hide asset"
          />
        </Typography>
      </div>

      <Table pools={filteredPools} />

      <div css={styles.note}>
        <InfoIcon tooltip="" css={styles.icon} />
        <div>
          <Typography variant="body1" component="div">
            LSDx provides a{' '}
            <Typography variant="body2" component="span">
              one-click Migrate option
            </Typography>{' '}
            to port your LP positions.
          </Typography>
          <Typography variant="body1" component="div">
            It does this by unstaking your position from Curve, Convex and other supporter platforms
            and depositing it as collateral to LSDx, boosting the APRs you earn on these positions.
            Head over to our docs to learn more!
          </Typography>
        </div>
      </div>
    </>
  );
};

const Migrate = () => {
  const { accountAddress } = useAuth();
  const { data: getPoolData, isLoading: isGetPoolLoading } = useGetMigrationPools({
    accountAddress,
  });

  if (!getPoolData) {
    return <Spinner />;
  }

  return <>{getPoolData && <MigrateUi loading={isGetPoolLoading} pools={getPoolData.pools} />}</>;
};

export default Migrate;
