import BigNumber from 'bignumber.js';
import { checkForDsdControllerTransactionError } from 'errors';
import { ContractReceipt } from 'ethers';

import { DsdController } from 'types/contracts';

export interface MintDsdInput {
  dsdControllerContract: DsdController;
  amountWei: BigNumber;
}

export type MintDsdOutput = ContractReceipt;

const mintDsd = async ({
  dsdControllerContract,
  amountWei,
}: MintDsdInput): Promise<MintDsdOutput> => {
  const transaction = await dsdControllerContract.mintDSD(amountWei.toFixed());
  const receipt = await transaction.wait(1);
  return checkForDsdControllerTransactionError(receipt);
};

export default mintDsd;
