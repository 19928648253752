/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import { Spinner } from 'components';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Asset, Pool } from 'types';

import { useGetPools } from 'clients/api';
import { routes } from 'constants/routing';
import AssetAccessor from 'containers/AssetAccessor';
import { useAuth } from 'context/AuthContext';
import SupplyForm from 'hooks/useSupplyWithdrawModal/Modal/SupplyForm';
import useFormatPools from 'pages/Dashboard/useFormatPools';

import CollateralSelector from './CollateralSelector';

interface AddCollateralUiProps {
  searchValue: string;
  onSearchInputChange: (newValue: string) => void;
  pools: Pool[];
  isFetchingPools?: boolean;
}

const AddCollateralUi: React.FC<AddCollateralUiProps> = ({
  pools,
  searchValue,
  isFetchingPools,
  onSearchInputChange,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const params: { underlying: string } = useParams();

  const formattedPools = useFormatPools({
    pools,
    searchValue,
  });

  interface PoolAsset extends Asset {
    pool: Pool;
  }

  const poolAssets = useMemo(
    () =>
      formattedPools.reduce((acc, pool) => {
        const newPoolAssets: PoolAsset[] = pool.assets.map(asset => ({
          ...asset,
          pool,
        }));

        return acc.concat(newPoolAssets);
      }, [] as PoolAsset[]),
    [formattedPools],
  );

  const found = useMemo(() => {
    if (!params?.underlying) return;
    // console.log('@param:', params);

    return poolAssets?.find(
      token =>
        token.llToken?.underlyingToken?.address?.toLowerCase() ===
        params?.underlying?.toLowerCase(),
    );
  }, [poolAssets, params.underlying]);

  // console.log('found::', found);

  if (!params?.underlying) {
    return (
      <CollateralSelector
        searchValue={searchValue}
        onSearchInputChange={onSearchInputChange}
        poolAssets={poolAssets}
      />
    );
  }

  if (isFetchingPools) {
    return <Spinner />;
  }

  if (!found) {
    setTimeout(() => {
      history.push(routes.addCollateral.path);
    }, 3000);

    return (
      <Typography variant="h2" css={{ textAlign: 'center', marginTop: '1em' }}>
        Invalid Token. redirecting you in 3 secs.
      </Typography>
    );
  }

  return (
    <div
      style={{
        padding: '2em',
        border: '1px solid rgba(255, 255, 255, 0.04)',
        borderRadius: '16px',
        maxWidth: '720px',
        margin: '0 auto',
      }}
    >
      <AssetAccessor
        llToken={found.llToken}
        poolComptrollerAddress={found.pool.comptrollerAddress}
        connectWalletMessage={t('supplyWithdrawModal.supply.connectWalletMessage')}
        enableTokenMessage={t('supplyWithdrawModal.supply.enableToken.title', {
          symbol: found.llToken.underlyingToken.symbol,
        })}
        assetInfoType="supply"
      >
        {props => (
          <SupplyForm {...props} onCloseModal={() => history.push(routes.dashboard.path)} />
        )}
      </AssetAccessor>
    </div>
  );
};

export const AddCollateral: React.FC = () => {
  const { accountAddress } = useAuth();

  const { data: getPoolData, isLoading: isGetPoolsLoading } = useGetPools({
    accountAddress,
  });
  const [searchValue, setSearchValue] = useState('');

  return (
    <AddCollateralUi
      pools={getPoolData?.pools || []}
      isFetchingPools={isGetPoolsLoading}
      searchValue={searchValue}
      onSearchInputChange={setSearchValue}
    />
  );
};
