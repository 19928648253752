import { QueryObserverOptions, useQuery } from 'react-query';

import getVestDuration, { GetVestDurationOutput } from 'clients/api/queries/getVestDuration';
import { useEsLTOKENContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { useAuth } from 'context/AuthContext';

type Options = QueryObserverOptions<
  GetVestDurationOutput,
  Error,
  GetVestDurationOutput,
  GetVestDurationOutput,
  FunctionKey.GET_VEST_DURATION
>;

const useGetVestDuration = (options?: Options) => {
  const { accountAddress } = useAuth();
  const esLTOKENContract = useEsLTOKENContract();
  return useQuery(
    FunctionKey.GET_VEST_DURATION,
    () => getVestDuration({ esLTOKENContract, accountAddress }),
    options,
  );
};

export default useGetVestDuration;
