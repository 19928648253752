import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      margin-top: ${theme.spacing(4)};

      ${theme.breakpoints.down('md')} {
        margin-top: ${theme.spacing(8)};
      }
    `,
    modal: css`
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
      border-radius: ${theme.spacing(4)};
      padding:${theme.spacing(6)};
    `,
    modalContainer: css`
      max-width:640px;
      backdrop-filter: blur(0px) !important;
      border: 0px solid rgba(255, 255, 255, 0.1) !important;
      ${theme.breakpoints.down('xxl')} {
        backdrop-filter: blur(5px) !important;
      }
    `,
    input: css`
      margin-bottom: ${theme.spacing(1)};
    `,
    whiteLabel: css`
      color: ${theme.palette.text.primary};
    `,
    greyLabel: css`
      color: ${theme.palette.text.secondary};
    `,
    bottomNotice: css`
      display: flex;
      padding: ${theme.spacing(4)} ${theme.spacing(1)};
    `,
    apr: css`
      display: flex;
      padding: ${theme.spacing(2)} ${theme.spacing(6)};
      border-radius: ${theme.spacing(2)};
      border: 1px solid rgba(255, 255, 255, 0.1);
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
    `,
    notice: css`
      margin-top: ${theme.spacing(3)};
      padding: ${theme.spacing(4)};
    `,
    getRow: ({ isLast }: { isLast: boolean }) => css`
      margin-bottom: ${theme.spacing(isLast ? 6 : 3)};

      ${theme.breakpoints.down('md')} {
        span {
          font-size: ${theme.typography.small1.fontSize};
        }
      }
    `,
    isolatedAssetWarning: css`
      margin-bottom: ${theme.spacing(6)};
    `,
  };
};
