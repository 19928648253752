/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { ConnectWallet, PrimaryButton, TokenIcon } from 'components';
import { ContractReceipt } from 'ethers';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';

import { UserVest } from 'clients/api';
import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import { DisableLunaUstWarningContext } from 'context/DisableLunaUstWarning';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import { useStyles } from '../styles';

export interface WithdrawProps {
  ltokenWithdrawableAmount: BigNumber | undefined;
  withdrawLtoken: () => Promise<ContractReceipt>;
  withdrawLtokenLoading: boolean;
  existingVest: UserVest[];
}

const Withdraw: React.FC<WithdrawProps> = ({
  ltokenWithdrawableAmount,
  withdrawLtoken,
  withdrawLtokenLoading,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { hasLunaOrUstCollateralEnabled, openLunaUstWarningModal } = useContext(
    DisableLunaUstWarningContext,
  );

  const readableLtokenAvailable = useConvertWeiToReadableTokenString({
    valueWei: ltokenWithdrawableAmount,
    token: TOKENS.ltoken,
  });

  const handleTransactionMutation = useHandleTransactionMutation();

  const onSubmit = () => {
    // Block action is user has LUNA or UST enabled as collateral
    if (hasLunaOrUstCollateralEnabled) {
      openLunaUstWarningModal();
      return;
    }

    return handleTransactionMutation({
      mutate: withdrawLtoken,
      successTransactionModalProps: contractReceipt => ({
        title: t('convertVrt.successfulConvertTransactionModal.title'),
        transactionHash: contractReceipt.transactionHash,
        content: (
          <div css={styles.successModalConversionAmounts}>
            <TokenIcon token={TOKENS.ltoken} css={styles.successModalToken} />
            <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
              {readableLtokenAvailable}
            </Typography>
          </div>
        ),
      }),
    });
  };

  return (
    <div css={styles.root}>
      <ConnectWallet
        message={t('convertVrt.connectWalletToWithdrawLtoken', {
          rewardToken: brand.rewardTokenSymbol,
        })}
      >
        <section css={styles.title}>
          <Typography variant="h3">{readableLtokenAvailable}</Typography>
          <Typography variant="small2">{t('convertVrt.withdrawableAmount')}</Typography>
        </section>
        <PrimaryButton
          disabled={
            !ltokenWithdrawableAmount ||
            ltokenWithdrawableAmount.isZero() ||
            ltokenWithdrawableAmount.isNaN()
          }
          fullWidth
          onClick={onSubmit}
          loading={withdrawLtokenLoading}
        >
          {t('convertVrt.withdrawLtoken', { rewardToken: brand.rewardTokenSymbol })}
        </PrimaryButton>
      </ConnectWallet>
    </div>
  );
};

export default Withdraw;
