/** @jsxImportSource @emotion/react */
import { Announcement, Modal, ModalProps, TabContent, Tabs, TokenIconWithSymbol } from 'components';
import React from 'react';
import { useTranslation } from 'translation';
import { LLToken } from 'types';
import { isTokenActionEnabled } from 'utilities';

import AssetAccessor from 'containers/AssetAccessor';

import SupplyModal from './Supply';
import SupplyForm from './SupplyForm';
import WithdrawModal from './Withdraw';
import { useStyles } from './styles';

export interface SupplyWithdrawProps {
  onClose: ModalProps['handleClose'];
  llToken: LLToken;
  poolComptrollerAddress: string;
  onlySupply?: boolean;
  onlyWithdraw?: boolean;
}

/**
 * The fade effect on this component results in that it is still rendered after the asset has been set to undefined
 * when closing the modal.
 */
export const SupplyWithdrawModal: React.FC<SupplyWithdrawProps> = ({
  llToken,
  onClose,
  poolComptrollerAddress,
  onlySupply,
  onlyWithdraw,
}) => {
  const { t } = useTranslation();

  const tabsContent: TabContent[] = [];
  const styles = useStyles();

  if (
    isTokenActionEnabled({
      token: llToken.underlyingToken,
      action: 'supply',
    })
  ) {
    tabsContent.push({
      title: t('supplyWithdrawModal.supplyTabTitle'),
      content: (
        <SupplyModal
          onClose={onClose}
          llToken={llToken}
          poolComptrollerAddress={poolComptrollerAddress}
        />
      ),
    });
  }

  if (
    isTokenActionEnabled({
      token: llToken.underlyingToken,
      action: 'withdraw',
    })
  ) {
    tabsContent.push({
      title: t('supplyWithdrawModal.withdrawTabTitle'),
      content: (
        <WithdrawModal
          onClose={onClose}
          llToken={llToken}
          poolComptrollerAddress={poolComptrollerAddress}
        />
      ),
    });
  }

  return (
    <Modal
      isOpen
      handleClose={onClose}
      title={
        <TokenIconWithSymbol forModal title="Manage" token={llToken.underlyingToken} variant="h4" />
      }
      css={styles.modalContainer}
    >
      <>
        <Announcement token={llToken.underlyingToken} />

        {onlySupply && (
          <AssetAccessor
            llToken={llToken}
            poolComptrollerAddress={poolComptrollerAddress}
            connectWalletMessage={t('supplyWithdrawModal.supply.connectWalletMessage')}
            enableTokenMessage={t('supplyWithdrawModal.supply.enableToken.title', {
              symbol: llToken.underlyingToken.symbol,
            })}
            assetInfoType="supply"
          >
            {props => <SupplyForm {...props} onCloseModal={onClose} />}
          </AssetAccessor>
        )}

        {onlyWithdraw && (
          <WithdrawModal
            onClose={onClose}
            llToken={llToken}
            forModal
            poolComptrollerAddress={poolComptrollerAddress}
          />
        )}

        {!onlySupply && !onlyWithdraw && tabsContent.length > 0 && (
          <Tabs tabsContent={tabsContent} />
        )}
      </>
    </Modal>
  );
};

export default SupplyWithdrawModal;
