/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  EnableToken, // EnableToken,
  FormikSubmitButton,
  FormikTokenTextField,
  Icon,
  TokenIcon,
  TokenTextField,
  toast,
} from 'components';
import { ContractReceipt } from 'ethers';
import { noop } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'translation';
import {
  convertTokensToWei,
  convertWeiToTokens,
  formatTokensToReadableValue,
  getContractAddress, // getContractAddress,
} from 'utilities';

import { EscrowLTokenInput } from 'clients/api';
import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import { AmountForm, ErrorCode } from 'containers/AmountForm';
import { DisableLunaUstWarningContext } from 'context/DisableLunaUstWarning';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { useStyles } from '../styles';

export interface ConvertProps {
  userLTOKENBalanceWei?: BigNumber;
  escrowLoading: boolean;
  escrow(amount: Pick<EscrowLTokenInput, 'amount'>): Promise<ContractReceipt>;
}

const esLTokenAddress = getContractAddress('esLTOKEN');

const Convert: React.FC<ConvertProps> = ({ userLTOKENBalanceWei, escrowLoading, escrow }) => {
  const styles = useStyles();
  const { t, Trans } = useTranslation();

  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const { hasLunaOrUstCollateralEnabled, openLunaUstWarningModal } = useContext(
    DisableLunaUstWarningContext,
  );

  const readableUserLTokenBalance = useMemo(() => {
    const userLTokenBalanceTokens =
      userLTOKENBalanceWei &&
      convertWeiToTokens({
        valueWei: userLTOKENBalanceWei,
        token: TOKENS.ltoken,
      });

    return formatTokensToReadableValue({
      value: userLTokenBalanceTokens,
      token: TOKENS.ltoken,
    });
  }, [userLTOKENBalanceWei?.toFixed()]);

  useConvertWeiToReadableTokenString({
    valueWei: userLTOKENBalanceWei,
    token: TOKENS.ltoken,
  });

  const calculateLtokenFromVrt = useCallback(
    (value: BigNumber) =>
      !value.isNaN() && !value.isZero() ? new BigNumber(value) : new BigNumber(0),
    [],
  );

  const onSubmit = async (lTokenAmount: string) => {
    // Block action is user has LUNA or UST enabled as collateral
    if (hasLunaOrUstCollateralEnabled) {
      openLunaUstWarningModal();
      return;
    }

    try {
      const lTokenAmountWei = convertTokensToWei({
        value: new BigNumber(lTokenAmount),
        token: TOKENS.ltoken,
      });

      const contractReceipt = await escrow({ amount: lTokenAmountWei.toFixed() });
      // Display successful transaction modal

      const ltokenAmountWei = calculateLtokenFromVrt(lTokenAmountWei);

      openSuccessfulTransactionModal({
        title: t('convertVrt.successfulConvertTransactionModal.title'),
        transactionHash: contractReceipt.transactionHash,
        content: (
          <div css={styles.successModalConversionAmounts}>
            <TokenIcon token={TOKENS.ltoken} css={styles.successModalToken} />

            <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
              {convertWeiToTokens({
                valueWei: lTokenAmountWei,
                token: TOKENS.ltoken,
                returnInReadableFormat: true,
              })}
            </Typography>
            <Icon name="arrowShaft" css={styles.successModalArrow} />

            <TokenIcon token={TOKENS.esLTOKEN} css={styles.successModalToken} />

            <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
              {ltokenAmountWei &&
                convertWeiToTokens({
                  valueWei: ltokenAmountWei,
                  token: TOKENS.esLTOKEN,
                  returnInReadableFormat: true,
                })}
            </Typography>
          </div>
        ),
      });
    } catch (err) {
      toast.error({ message: (err as Error).message });
    }
  };

  const userLTokenBalance =
    userLTOKENBalanceWei &&
    convertWeiToTokens({ valueWei: userLTOKENBalanceWei, token: TOKENS.ltoken }).toFixed();

  return (
    <div css={styles.root}>
      <ConnectWallet
        message={t('lockLTOKEN.connectWalletToStake', {
          rewardToken: brand.rewardTokenSymbol,
        })}
      >
        <EnableToken
          title={t('lockLTOKEN.enableLTOKEN', {
            brandName: brand.name,
            rewardToken: brand.rewardTokenSymbol,
          })}
          token={TOKENS.ltoken}
          spenderAddress={esLTokenAddress}
        >
          {/* <section css={styles.title}>
            <Typography variant="h3">{readableLtokenAvailable}</Typography>
            <Typography variant="small2">{t('convertVrt.ltokenADsdlable', { rewardToken: brand.rewardTokenSymbol })}</Typography>
          </section> */}

          <AmountForm onSubmit={onSubmit} maxAmount={userLTokenBalance} css={styles.form}>
            {({ values, setFieldValue }) => {
              const ltokenValue = calculateLtokenFromVrt(new BigNumber(values.amount))
                ?.dp(TOKENS.esLTOKEN.decimals)
                .toFixed();
              return (
                <>
                  <div css={styles.inputSection}>
                    <Typography variant="small2" css={[styles.inputLabel, styles.fontWeight600]}>
                      {t('lockLTOKEN.stakeLTOKEN', { rewardToken: brand.rewardTokenSymbol })}
                    </Typography>
                    <FormikTokenTextField
                      token={TOKENS.ltoken}
                      name="amount"
                      css={styles.input}
                      description={
                        <Trans
                          i18nKey="convertVrt.balance"
                          components={{
                            White: <span css={styles.whiteLabel} />,
                          }}
                          values={{
                            amount: readableUserLTokenBalance,
                          }}
                        />
                      }
                      rightMaxButton={
                        userLTokenBalance
                          ? {
                              label: t('convertVrt.max'),
                              onClick: () => setFieldValue('amount', userLTokenBalance),
                            }
                          : undefined
                      }
                      displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
                    />
                  </div>
                  <div css={styles.inputSection}>
                    <Typography variant="small2" css={[styles.inputLabel, styles.fontWeight600]}>
                      {t('convertVrt.youWillReceive')}
                    </Typography>
                    <TokenTextField
                      token={TOKENS.ltoken}
                      name="ltoken"
                      css={styles.input}
                      description={`1 ${brand.rewardTokenSymbol} = 1 ${brand.escrowTokenSymbol}`}
                      disabled
                      value={ltokenValue || ''}
                      onChange={noop}
                    />
                  </div>
                  <FormikSubmitButton
                    css={styles.submitButton}
                    fullWidth
                    disabled={escrowLoading}
                    loading={escrowLoading}
                    enabledLabel={t('lockLTOKEN.stakeLTOKEN', {
                      rewardToken: brand.rewardTokenSymbol,
                      escrowToken: brand.escrowTokenSymbol,
                    })}
                  />
                </>
              );
            }}
          </AmountForm>
        </EnableToken>
      </ConnectWallet>
    </div>
  );
};

export default Convert;
