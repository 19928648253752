/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  ClaimDSDRewardButton,
  ConnectWallet, // EnableToken,
  FormikSubmitButton,
  FormikTokenTextField,
  Icon,
  LabeledInlineContent,
  TokenIcon,
  TokenTextField,
  toast,
} from 'components';
import { ContractReceipt } from 'ethers';
import noop from 'noop-ts';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'translation';
import {
  convertTokensToWei,
  convertWeiToTokens,
  formatTokensToReadableValue, // getContractAddress,
} from 'utilities';

import { useGetDSDReward } from 'clients/api';
import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import { AmountForm, ErrorCode } from 'containers/AmountForm';
import { useAuth } from 'context/AuthContext';
import { DisableLunaUstWarningContext } from 'context/DisableLunaUstWarning';
import { VError } from 'errors/VError';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { useStyles } from '../styles';

export interface ConvertProps {
  ltokenToVrtConversionRatio: BigNumber | undefined;
  userVrtBalanceWei: BigNumber | undefined;
  convertEsLTOKENLoading: boolean;
  vestDurationInDays: number;
  convertEsLTOKEN: (amount: string) => Promise<ContractReceipt>;
}

const Convert: React.FC<ConvertProps> = ({
  ltokenToVrtConversionRatio,
  userVrtBalanceWei,
  convertEsLTOKENLoading,
  vestDurationInDays,
  convertEsLTOKEN,
}) => {
  const styles = useStyles();
  const { t, Trans } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const { hasLunaOrUstCollateralEnabled, openLunaUstWarningModal } = useContext(
    DisableLunaUstWarningContext,
  );

  // const readableLtokenAvailable = useConvertWeiToReadableTokenString({
  //   valueWei: ltokenToVrtConversionRatio && userVrtBalanceWei?.times(ltokenToVrtConversionRatio),
  //   token: TOKENS.ltoken,
  // });
  const { accountAddress } = useAuth();

  const { data: dsdRewardData } = useGetDSDReward({
    accountAddress,
  });

  const readableUserVrtBalance = useMemo(() => {
    const userVrtBalanceTokens =
      userVrtBalanceWei &&
      convertWeiToTokens({
        valueWei: userVrtBalanceWei,
        token: TOKENS.esLTOKEN,
      });

    return formatTokensToReadableValue({
      value: userVrtBalanceTokens,
      token: TOKENS.esLTOKEN,
    });
  }, [userVrtBalanceWei?.toFixed()]);

  useConvertWeiToReadableTokenString({
    valueWei: userVrtBalanceWei,
    token: TOKENS.esLTOKEN,
  });

  const calculateLtokenFromVrt = useCallback(
    (value: BigNumber) =>
      !value.isNaN() && !value.isZero() && ltokenToVrtConversionRatio
        ? new BigNumber(value).times(ltokenToVrtConversionRatio)
        : undefined,
    [ltokenToVrtConversionRatio],
  );

  const onSubmit = async (vrtAmount: string) => {
    // Block action is user has LUNA or UST enabled as collateral
    if (hasLunaOrUstCollateralEnabled) {
      openLunaUstWarningModal();
      return;
    }

    try {
      const vrtAmountWei = convertTokensToWei({
        value: new BigNumber(vrtAmount),
        token: TOKENS.esLTOKEN,
      });

      const contractReceipt = await convertEsLTOKEN(vrtAmountWei.toFixed());
      // Display successful transaction modal
      if (!ltokenToVrtConversionRatio) {
        // This should never happen because the form is not rendered without successfully fetching this
        throw new VError({
          type: 'unexpected',
          code: 'internalErrorLtokenToVrtConversionRatioUndefined',
        });
      }

      const ltokenAmountWei = calculateLtokenFromVrt(vrtAmountWei);

      openSuccessfulTransactionModal({
        title: t('convertVrt.successfulConvertTransactionModal.title'),
        transactionHash: contractReceipt.transactionHash,
        content: (
          <div css={styles.successModalConversionAmounts}>
            <TokenIcon token={TOKENS.esLTOKEN} css={styles.successModalToken} />

            <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
              {convertWeiToTokens({
                valueWei: vrtAmountWei,
                token: TOKENS.esLTOKEN,
                returnInReadableFormat: true,
              })}
            </Typography>
            <Icon name="arrowShaft" css={styles.successModalArrow} />

            <TokenIcon token={TOKENS.ltoken} css={styles.successModalToken} />

            <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
              {ltokenAmountWei &&
                convertWeiToTokens({
                  valueWei: ltokenAmountWei,
                  token: TOKENS.ltoken,
                  returnInReadableFormat: true,
                })}
            </Typography>
          </div>
        ),
      });
    } catch (err) {
      toast.error({ message: (err as Error).message });
    }
  };

  const userVrtBalance =
    userVrtBalanceWei &&
    convertWeiToTokens({ valueWei: userVrtBalanceWei, token: TOKENS.esLTOKEN }).toFixed();

  return (
    <div css={styles.root}>
      <ConnectWallet
        message={t('convertVrt.connectWalletToConvertVrtToLtoken', {
          rewardToken: brand.rewardTokenSymbol,
          escrowToken: brand.escrowTokenSymbol,
        })}
      >
        <AmountForm onSubmit={onSubmit} maxAmount={userVrtBalance} css={styles.form}>
          {({ values, setFieldValue }) => {
            const ltokenValue = calculateLtokenFromVrt(new BigNumber(values.amount))
              ?.dp(TOKENS.esLTOKEN.decimals)
              .toFixed();
            return (
              <>
                <div css={styles.inputSection}>
                  <Typography variant="small2" css={[styles.inputLabel, styles.fontWeight600]}>
                    {t('convertVrt.convertVrt', { escrowToken: brand.escrowTokenSymbol })}
                  </Typography>
                  <FormikTokenTextField
                    token={TOKENS.esLTOKEN}
                    name="amount"
                    css={styles.input}
                    description={
                      <Trans
                        i18nKey="convertVrt.balance"
                        components={{
                          White: <span css={styles.whiteLabel} />,
                        }}
                        values={{
                          amount: readableUserVrtBalance,
                        }}
                      />
                    }
                    rightMaxButton={
                      userVrtBalance
                        ? {
                            label: t('convertVrt.max'),
                            onClick: () => setFieldValue('amount', userVrtBalance),
                          }
                        : undefined
                    }
                    displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
                  />
                </div>
                <div css={styles.inputSection}>
                  <Typography variant="small2" css={[styles.inputLabel, styles.fontWeight600]}>
                    {t('convertVrt.youWillReceive')}
                  </Typography>
                  <TokenTextField
                    token={TOKENS.ltoken}
                    name="ltoken"
                    css={styles.input}
                    description={t('convertVrt.vrtEqualsLtoken', {
                      ltokenToVrtConversionRatio: ltokenToVrtConversionRatio?.toFixed(),
                      escrowToken: brand.escrowTokenSymbol,
                      rewardToken: brand.rewardTokenSymbol,
                    })}
                    disabled
                    value={ltokenValue || ''}
                    onChange={noop}
                  />

                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="small2">
                      Your tokens will be converted to {brand.rewardTokenSymbol} over a duration of{' '}
                      {vestDurationInDays > 30
                        ? `${vestDurationInDays / 30} Months`
                        : `${vestDurationInDays} Days`}
                    </Typography>
                  </Box>
                </div>
                {dsdRewardData && dsdRewardData?.earnedDSD && !dsdRewardData?.earnedDSD.isZero() && (
                  <LabeledInlineContent
                    iconSrc={TOKENS.dsd}
                    label={`DSD rewards: ${convertWeiToTokens({
                      valueWei: dsdRewardData.earnedDSD,
                      token: TOKENS.dsd,
                      returnInReadableFormat: true,
                      minimizeDecimals: 4,
                      shortenLargeValue: true,
                    })}`}
                    tooltip="User will receive DSD rewards for holding esLTOKEN"
                    css={styles.reward}
                  >
                    <ClaimDSDRewardButton />
                  </LabeledInlineContent>
                )}

                <FormikSubmitButton
                  css={styles.submitButton}
                  fullWidth
                  loading={convertEsLTOKENLoading}
                  enabledLabel={t('convertVrt.convertVrtToLtoken', {
                    rewardToken: brand.rewardTokenSymbol,
                    escrowToken: brand.escrowTokenSymbol,
                  })}
                  disabled={convertEsLTOKENLoading}
                />
              </>
            );
          }}
        </AmountForm>
      </ConnectWallet>
    </div>
  );
};

export default Convert;
