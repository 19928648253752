import { abi as poolLensAbi } from '@venusprotocol/isolated-pools/artifacts/contracts/Lens/PoolLens.sol/PoolLens.json';
import { ContractCallContext, ContractCallResults } from 'ethereum-multicall';
import { getContractAddress } from 'utilities';

// import dsdVaultAbi from 'constants/contracts/abis/dsdVault.json';
import lsLensAbi from 'constants/contracts/abis/lsLens.json';
// import vrtVaultAbi from 'constants/contracts/abis/vrtVault.json';
// import ltokenVaultAbi from 'constants/contracts/abis/ltokenVault.json';
// import { TOKENS } from 'constants/tokens';

import formatOutput from './formatOutput';
import { GetPendingRewardGroupsInput, GetPendingRewardGroupsOutput } from './types';

const lsLensAddress = getContractAddress('lsLens');
const poolLensAddress = getContractAddress('poolLens');
// const vrtVaultAddress = getContractAddress('vrtVaultProxy');
// const dsdVaultAddress = getContractAddress('dsdVault');
// const ltokenVaultAddress = getContractAddress('ltokenVaultProxy');

const getPendingRewardGroups = async ({
  mainPoolComptrollerAddress,
  isolatedPoolComptrollerAddresses,
  // ltokenVestingVaultPoolCount,
  multicall,
  accountAddress,
}: GetPendingRewardGroupsInput): Promise<GetPendingRewardGroupsOutput> => {
  // Generate call context
  const contractCallContext: ContractCallContext[] = [
    // Pending rewards from main pool
    {
      reference: 'lsLens',
      contractAddress: lsLensAddress,
      abi: lsLensAbi,
      calls: [
        {
          reference: 'pendingRewards',
          methodName: 'pendingRewards',
          methodParameters: [accountAddress, mainPoolComptrollerAddress],
        },
      ],
    },
    // Pending rewards from vaults
    // {
    //   reference: 'vrtVault',
    //   contractAddress: vrtVaultAddress,
    //   abi: vrtVaultAbi,
    //   calls: [
    //     {
    //       reference: 'getAccruedInterest',
    //       methodName: 'getAccruedInterest',
    //       methodParameters: [accountAddress],
    //     },
    //   ],
    // },
    // {
    //   reference: 'dsdVault',
    //   contractAddress: dsdVaultAddress,
    //   abi: dsdVaultAbi,
    //   calls: [
    //     {
    //       reference: 'pendingLTOKEN',
    //       methodName: 'pendingLTOKEN',
    //       methodParameters: [accountAddress],
    //     },
    //   ],
    // },
    // {
    //   reference: 'ltokenVestingVaults',
    //   contractAddress: ltokenVaultAddress,
    //   abi: ltokenVaultAbi,
    //   calls: new Array(ltokenVestingVaultPoolCount).fill(undefined).reduce(
    //     (acc, _item, poolIndex) =>
    //       acc.concat([
    //         {
    //           reference: `vault-${poolIndex}-poolInfos`,
    //           methodName: 'poolInfos',
    //           methodParameters: [TOKENS.ltoken.address, poolIndex],
    //         },
    //         {
    //           reference: `vault-${poolIndex}-pendingReward`,
    //           methodName: 'pendingReward',
    //           methodParameters: [TOKENS.ltoken.address, poolIndex, accountAddress],
    //         },
    //         {
    //           reference: `vault-${poolIndex}-pendingWithdrawalsBeforeUpgrade`,
    //           methodName: 'pendingWithdrawalsBeforeUpgrade',
    //           methodParameters: [TOKENS.ltoken.address, poolIndex, accountAddress],
    //         },
    //       ]),
    //     [],
    //   ),
    // },
  ];

  if (isolatedPoolComptrollerAddresses.length > 0) {
    // Pending rewards from isolated pools
    contractCallContext.push({
      reference: 'poolLens',
      contractAddress: poolLensAddress,
      abi: poolLensAbi,
      calls: isolatedPoolComptrollerAddresses.map(isolatedPoolComptrollerAddress => ({
        reference: 'getPendingRewards',
        methodName: 'getPendingRewards',
        methodParameters: [accountAddress, isolatedPoolComptrollerAddress],
      })),
    });
  }

  const contractCallResults: ContractCallResults = await multicall.call(contractCallContext);

  const pendingRewardGroups = formatOutput({
    contractCallResults,
  });

  return {
    pendingRewardGroups,
  };
};

export default getPendingRewardGroups;
