import BigNumber from 'bignumber.js';

import { Comptroller } from 'types/contracts';

export interface GetMintedDsdInput {
  comptrollerContract: Comptroller;
  accountAddress: string;
}

export type GetMintedDsdOutput = {
  mintedDsdWei: BigNumber;
};

const getMintedDsd = async ({
  comptrollerContract,
  accountAddress,
}: GetMintedDsdInput): Promise<GetMintedDsdOutput> => {
  const res = await comptrollerContract.mintedDSDs(accountAddress);

  return {
    mintedDsdWei: new BigNumber(res.toString()),
  };
};

export default getMintedDsd;
