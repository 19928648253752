import { MutationObserverOptions, useMutation } from 'react-query';

import { queryClient } from 'clients/api';
import { useEsLTOKENMinterContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

import claimEsLTOKENRewards, { ClaimEsLTOKENRewardInput, ClaimEsLTOKENRewardOutput } from '.';

type Options = MutationObserverOptions<
  ClaimEsLTOKENRewardOutput,
  Error,
  Omit<ClaimEsLTOKENRewardInput, 'esLTOKENMinterContract'>
>;

const useClaimEsLTOKENRewards = (options?: Options) => {
  const esLTOKENMinterContract = useEsLTOKENMinterContract();
  return useMutation(
    FunctionKey.CLAIM_REWARDS,
    (params: Omit<ClaimEsLTOKENRewardInput, 'esLTOKENMinterContract'>) =>
      claimEsLTOKENRewards({
        esLTOKENMinterContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([FunctionKey.GET_ESLTOKEN_REWARD]);
      },
    },
  );
};

export default useClaimEsLTOKENRewards;
