/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  // EnableToken,
  FormikSubmitButton,
  LabeledInlineContent,
  Spinner,
} from 'components';
import { ContractReceipt } from 'ethers';
import React, { useEffect, useMemo } from 'react';
import { Link as LocalLink } from 'react-router-dom';
import { useTranslation } from 'translation';
import { Asset } from 'types';
import {
  convertTokensToWei,
  convertWeiToTokens,
  formatPercentage,
  // getContractAddress,
  getTokenByAddress,
} from 'utilities';

import {
  useGetApyData,
  useGetBalanceOf,
  useGetDsdSupply,
  useGetDsdTreasuryPercentage,
  useGetMintableDsd,
  useGetPools,
  useMintDsd,
} from 'clients/api';
import brand from 'config/brand';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import PLACEHOLDER_KEY from 'constants/placeholderKey';
import { routes } from 'constants/routing';
import { TOKENS } from 'constants/tokens';
import { AmountForm, AmountFormProps } from 'containers/AmountForm';
import { useAuth } from 'context/AuthContext';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import DsdAccountData from '../DsdAccountData';
import FormikTokenTextFieldWithBalance from '../TextFieldWithBalance';
import { useStyles } from '../styles';

// import getReadableFeeDsd from './getReadableFeeDsd';

// const dsdControllerContractAddress = getContractAddress('dsdController');

export interface MintDsdUiProps {
  disabled: boolean;
  isInitialLoading: boolean;
  isSubmitting: boolean;
  mintDsd: (value: BigNumber) => Promise<ContractReceipt | undefined>;
  userBalanceWei?: BigNumber;
  apyPercentage?: BigNumber;
  interestRepay?: BigNumber;
  limitWei?: BigNumber;
  mintFeePercentage?: number;
}

export const MintDsdUi: React.FC<MintDsdUiProps> = ({
  disabled,
  limitWei,
  // mintFeePercentage,
  isInitialLoading,
  userBalanceWei,
  apyPercentage,
  // interestRepay,
  isSubmitting,
  mintDsd,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { accountAddress } = useAuth();
  const { data: getPoolsData, isLoading: isGetPoolsLoading } = useGetPools({
    accountAddress,
  });
  const assets = useMemo(
    () => getPoolsData?.pools.reduce((acc, pool) => [...acc, ...pool.assets], [] as Asset[]),
    [getPoolsData],
  );

  const handleTransactionMutation = useHandleTransactionMutation();

  const limitTokens = useMemo(
    () =>
      limitWei ? convertWeiToTokens({ valueWei: limitWei, token: TOKENS.dsd }).toFixed() : '0',
    [limitWei?.toFixed()],
  );

  // Convert limit into DSD
  const readableDsdLimit = useConvertWeiToReadableTokenString({
    valueWei: limitWei,
    token: TOKENS.dsd,
    minimizeDecimals: true,
  });

  const hasMintableDsd = limitWei?.isGreaterThan(0) || false;

  // const getReadableMintFee = useCallback(
  //   (valueWei: string) => {
  //     if (!valueWei) {
  //       return '0%';
  //     }
  //     if (!mintFeePercentage) {
  //       return PLACEHOLDER_KEY;
  //     }

  //     const readableFeeDsd = getReadableFeeDsd({
  //       valueWei: new BigNumber(valueWei || 0),
  //       mintFeePercentage,
  //     });
  //     return `${readableFeeDsd} (${mintFeePercentage}%)`;
  //   },
  //   [mintFeePercentage],
  // );

  const onSubmit: AmountFormProps['onSubmit'] = amountTokens => {
    const amountWei = convertTokensToWei({
      value: new BigNumber(amountTokens),
      token: TOKENS.dsd,
    });

    return handleTransactionMutation({
      mutate: () => mintDsd(amountWei),
      successTransactionModalProps: contractReceipt => ({
        title: t('dsd.mintDsd.successfulTransactionModal.title'),
        content: t('dsd.mintDsd.successfulTransactionModal.message'),
        amount: {
          valueWei: amountWei,
          token: TOKENS.dsd,
        },
        transactionHash: contractReceipt.transactionHash,
      }),
    });
  };

  return (
    <ConnectWallet
      message={t('dsd.mintDsd.connectWallet', { tokenSymbol: brand.stableCoinSymbol })}
    >
      {isInitialLoading ? (
        <Spinner />
      ) : (
        <AmountForm
          onSubmit={onSubmit}
          css={[styles.tabContentContainer, styles.border]}
          maxAmount={limitTokens}
        >
          {() => (
            <>
              <div css={styles.ctaContainer}>
                {!isGetPoolsLoading && assets && <DsdAccountData assets={assets} />}
                {!hasMintableDsd ? (
                  <>
                    <Typography variant="body2" style={{ textAlign: 'center', color: 'white' }}>
                      You have reached your minting limit.
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: '1em', textAlign: 'center', color: 'white' }}
                    >
                      <LocalLink
                        to={routes.addCollateral.path}
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                      >
                        Supply assets as collateral
                      </LocalLink>{' '}
                      to raise your DSD mint limit.
                    </Typography>
                  </>
                ) : (
                  <FormikTokenTextFieldWithBalance
                    disabled={disabled || isSubmitting || !hasMintableDsd}
                    maxValue={limitTokens}
                    userBalanceWei={userBalanceWei}
                    maxButtonLabel={t('dsd.mintDsd.rightMaxButtonLabel')}
                  />
                )}
                <LabeledInlineContent
                  css={styles.getRow({ isLast: false })}
                  iconSrc={TOKENS.dsd}
                  label={t('dsd.mintDsd.dsdLimitLabel', {
                    tokenSymbol: brand.stableCoinSymbol,
                  })}
                >
                  {readableDsdLimit}
                </LabeledInlineContent>

                <LabeledInlineContent
                  css={styles.getRow({ isLast: false })}
                  iconSrc="fee"
                  label={t('dsd.mintDsd.apy', { tokenSymbol: brand.stableCoinSymbol })}
                >
                  {apyPercentage ? `${formatPercentage(apyPercentage)}%` : PLACEHOLDER_KEY}
                </LabeledInlineContent>
              </div>
              {hasMintableDsd ? (
                <FormikSubmitButton
                  loading={isSubmitting}
                  disabled={disabled}
                  fullWidth
                  enabledLabel={t('dsd.mintDsd.submitButtonLabel')}
                  disabledLabel={t('dsd.mintDsd.submitButtonDisabledLabel')}
                />
              ) : null}
            </>
          )}
        </AmountForm>
      )}
    </ConnectWallet>
  );
};

const MintDsd: React.FC = () => {
  const { accountAddress } = useAuth();

  const { data: dsdSupply } = useGetDsdSupply();

  const { data: mintableDsdData, isLoading: isGetMintableDsdLoading } = useGetMintableDsd(
    {
      accountAddress,
    },
    {
      enabled: !!accountAddress,
    },
  );

  const { data: userDsdBalanceData, isLoading: isGetUserDsdBalance } = useGetBalanceOf(
    {
      accountAddress: accountAddress || '',
      token: TOKENS.dsd,
    },
    {
      enabled: !!accountAddress,
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
    },
  );

  const { data: getApyData } = useGetApyData();

  const { data: dsdTreasuryData, isLoading: isGetDsdTreasuryPercentageLoading } =
    useGetDsdTreasuryPercentage();

  const { mutateAsync: contractMintDsd, isLoading: isSubmitting } = useMintDsd();

  const mintDsd: MintDsdUiProps['mintDsd'] = async amountWei =>
    contractMintDsd({
      amountWei,
    });

  useEffect(() => {
    if (!getApyData || !dsdSupply) return;
    console.group('DSD Minting APY');
    console.debug(
      JSON.stringify(
        {
          dsdCirculatingSupply: dsdSupply.nonRebasingSupply.dividedBy(1e18).toFixed(),
          ...Object.entries(getApyData).reduce((p, [token, data]) => {
            const t = getTokenByAddress(token);
            return { ...p, [t?.symbol || token]: data };
          }, {}),
        },
        null,
        2,
      ),
    );
    console.groupEnd();
  }, [getApyData, dsdSupply]);

  return (
    <MintDsdUi
      disabled={!accountAddress || isGetDsdTreasuryPercentageLoading || isGetUserDsdBalance}
      limitWei={mintableDsdData?.mintableDsdWei}
      userBalanceWei={userDsdBalanceData?.balanceWei}
      mintFeePercentage={dsdTreasuryData?.percentage}
      isInitialLoading={isGetMintableDsdLoading}
      isSubmitting={isSubmitting}
      apyPercentage={new BigNumber(getApyData?.dsdMintingRewardsApy || 0)}
      mintDsd={mintDsd}
    />
  );
};

export default MintDsd;
