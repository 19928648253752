/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'translation';
import { LLToken } from 'types';

import { useClaimMultiRewards } from 'clients/api';
import { DisableLunaUstWarningContext } from 'context/DisableLunaUstWarning';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import { ButtonProps, PrimaryButton, SecondaryButton } from '../../Button';
import { Modal } from '../../Modal';
import TEST_IDS from '../testIds';
import { RewardGroup } from './RewardGroup';
import { Group } from './types';
import useGetGroups from './useGetGroups';

export interface ClaimMultiRewardButtonUiProps extends ClaimMultiRewardButtonProps {
  isModalOpen: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  onToggleGroup: (toggledGroup: Group) => void;
  groups: Group[];
  llToken: LLToken;
}

export const buttonAriaLabel = 'Button';

export const ClaimMultiRewardButtonUi: React.FC<ClaimMultiRewardButtonUiProps> = ({
  isModalOpen,
  onOpenModal,
  onCloseModal,
  onToggleGroup,
  groups,
  llToken,
  ...otherButtonProps
}) => {
  const { t } = useTranslation();
  const handleTransactionMutation = useHandleTransactionMutation();

  const { mutateAsync: claimMultiRewards, isLoading: isClaimingRewards } = useClaimMultiRewards(
    groups[0]?.multiRewardContractAddress,
    llToken.address,
  );

  const handleClaimReward = () =>
    handleTransactionMutation({
      mutate: async () => {
        const contractReceipt = await claimMultiRewards({});
        onCloseModal();
        return contractReceipt;
      },
      successTransactionModalProps: contractReceipt => ({
        title: t('claimReward.successfulTransactionModal.title'),
        content: t('claimReward.successfulTransactionModal.message'),
        transactionHash: contractReceipt.transactionHash,
      }),
    });

  if (!groups.length) {
    return <Typography>No Rewards</Typography>;
  }

  return (
    <>
      <SecondaryButton
        data-testid={TEST_IDS.claimRewardOpenModalButton}
        aria-label={buttonAriaLabel}
        onClick={onOpenModal}
        small
        {...otherButtonProps}
      >
        {t('claimReward.claimMultiReward.claim')}
      </SecondaryButton>

      <Modal
        isOpen={isModalOpen}
        handleClose={onCloseModal}
        title={<h4>{t('claimReward.modal.title')}</h4>}
      >
        <>
          <div data-testid={TEST_IDS.claimRewardBreakdown}>
            {groups.map(group => (
              <RewardGroup
                group={group}
                onCheckChange={() => onToggleGroup(group)}
                key={`claim-multireward-modal-reward-group-${group.id}-${group.name}`}
              />
            ))}
          </div>

          <PrimaryButton
            onClick={handleClaimReward}
            fullWidth
            data-testid={TEST_IDS.claimRewardSubmitButton}
            loading={isClaimingRewards}
          >
            {t('claimReward.claimButton.enabledLabel')}
          </PrimaryButton>
        </>
      </Modal>
    </>
  );
};

interface CustomButtonProps extends ButtonProps {
  lltoken: LLToken;
}

export type ClaimMultiRewardButtonProps = Omit<CustomButtonProps, 'onClick'>;

export const ClaimMultiRewardButton: React.FC<ClaimMultiRewardButtonProps> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { hasLunaOrUstCollateralEnabled, openLunaUstWarningModal } = useContext(
    DisableLunaUstWarningContext,
  );

  const { lltoken } = props;

  const groups = useGetGroups({ lltoken });

  if (!groups.length) {
    return <Typography>No Rewards</Typography>;
  }

  const handleOpenModal = () => {
    // Block action if user has LUNA or UST enabled as collateral
    if (hasLunaOrUstCollateralEnabled) {
      openLunaUstWarningModal();
      return;
    }

    return setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <ClaimMultiRewardButtonUi
      groups={groups}
      isModalOpen={isModalOpen}
      onOpenModal={handleOpenModal}
      onCloseModal={handleCloseModal}
      onToggleGroup={() => {}}
      llToken={lltoken}
      {...props}
    />
  );
};

export default ClaimMultiRewardButton;
