import { useMemo } from 'react';
// import { useTranslation } from 'translation';
import { LLToken } from 'types';
import { getContractAddress } from 'utilities';

import { useGetLtokenVaultPoolCount, useGetPendingMultiRewards, useGetPools } from 'clients/api';
import { useLLTokenContract } from 'clients/contracts';
import { LLTokenContract } from 'clients/contracts/types';
// import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';

import { Group } from './types';

const mainPoolComptrollerAddress = getContractAddress('comptroller');

const useGetGroups = ({ lltoken }: { lltoken: LLToken }) => {
  // const { t } = useTranslation();
  const { accountAddress } = useAuth();

  // Get LTOKEN vesting vault pool count
  const { data: getLtokenVaultPoolCountData, isLoading: isGetLtokenVaultPoolCountLoading } =
    useGetLtokenVaultPoolCount();

  // Get Comptroller addresses of isolated pools
  const { data: getPoolsData, isLoading: isGetPoolsLoading } = useGetPools({
    accountAddress,
  });

  const isolatedPoolComptrollerAddresses = useMemo(
    () =>
      (getPoolsData?.pools || []).reduce<string[]>(
        (acc, pool) => (pool.isIsolated ? [...acc, pool.comptrollerAddress] : acc),
        [],
      ),
    [getPoolsData?.pools],
  );

  const llTokenContract = useLLTokenContract(lltoken) as LLTokenContract;

  const { data: getPendingRewardsData } = useGetPendingMultiRewards(
    {
      llTokenContract,
      accountAddress: accountAddress || '',
      mainPoolComptrollerAddress,
      isolatedPoolComptrollerAddresses,
      ltokenVestingVaultPoolCount: getLtokenVaultPoolCountData?.poolCount || 0,
    },
    {
      enabled: !!accountAddress && !isGetPoolsLoading && !isGetLtokenVaultPoolCountLoading,
    },
  );

  // Format pending rewards into groups
  return useMemo(
    () =>
      (getPendingRewardsData?.pendingMultiRewardGroups || []).reduce<Group[]>(
        (acc, pendingRewardGroup) => {
          // Pools
          if (
            pendingRewardGroup.type !== 'mainPool' &&
            pendingRewardGroup.type !== 'isolatedPool'
          ) {
            return acc;
          }

          const pool = (getPoolsData?.pools || []).find(
            item =>
              item.comptrollerAddress.toLowerCase() ===
              pendingRewardGroup.comptrollerAddress.toLowerCase(),
          );

          if (!pool) {
            return acc;
          }

          const group: Group = {
            id: pendingRewardGroup.rewardToken.address,
            name: pendingRewardGroup.rewardToken.symbol,
            multiRewardContractAddress: pendingRewardGroup.multiRewardContractAddress,
            pendingRewards: {
              rewardToken: pendingRewardGroup.rewardToken,
              rewardAmountWei: pendingRewardGroup.rewardAmountWei,
            },
            claims: [
              {
                contract: 'mainPoolComptroller',
                llTokenAddressesWithPendingReward:
                  pendingRewardGroup.llTokenAddressesWithPendingReward,
              },
            ],
          };

          return [...acc, group];
        },
        [],
      ),
    [getPendingRewardsData, getPendingRewardsData?.pendingMultiRewardGroups, getPoolsData?.pools],
  );
};

export default useGetGroups;
