/** @jsxImportSource @emotion/react */
import { ConnectWallet, EnableToken, EnabledTokenChildProps, Spinner } from 'components';
import React from 'react';
import { Asset, LLToken, Pool } from 'types';
import { areTokensEqual } from 'utilities';

import { useGetPool } from 'clients/api';
import { useAuth } from 'context/AuthContext';
import useAssetInfo from 'hooks/useAssetInfo';

export interface AssetAccessorProps {
  llToken: LLToken;
  poolComptrollerAddress: string;
  connectWalletMessage: string;
  enableTokenMessage: string;
  assetInfoType: 'supply' | 'borrow';
  children: (props: { asset: Asset; pool: Pool } & EnabledTokenChildProps) => React.ReactElement;
}

const AssetAccessor: React.FC<AssetAccessorProps> = ({
  llToken,
  poolComptrollerAddress,
  children,
  connectWalletMessage,
  enableTokenMessage,
  assetInfoType,
}) => {
  const { accountAddress } = useAuth();

  const { data: getPoolData } = useGetPool({
    poolComptrollerAddress,
    accountAddress,
  });
  const pool = getPoolData?.pool;
  const asset = pool?.assets.find(item => areTokensEqual(item.llToken, llToken));

  const assetInfo = useAssetInfo({
    asset,
    type: assetInfoType,
  });

  return (
    <ConnectWallet message={connectWalletMessage}>
      {pool && asset ? (
        <EnableToken
          token={llToken.underlyingToken}
          spenderAddress={llToken.address}
          title={enableTokenMessage}
          assetInfo={assetInfo}
          noUi
        >
          {props => children({ asset, pool, ...props })}
        </EnableToken>
      ) : (
        <Spinner />
      )}
    </ConnectWallet>
  );
};

export default AssetAccessor;
