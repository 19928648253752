/** @jsxImportSource @emotion/react */
// import MuiAccordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as SuperchargePortfolioImg } from 'assets/img/SuperchargePortfolio.svg';
import { ReactComponent as GridImg } from 'assets/img/grid.svg';
import { Button } from 'components/Button';
import ConnectButton from 'components/Layout/ConnectButton';
import brand from 'config/brand';
import { routes } from 'constants/routing';
import { useAuth } from 'context/AuthContext';

import { useStyles } from './styles';

export const SuperchargePortfolio: React.FC = () => {
  const { accountAddress } = useAuth();
  const styles = useStyles();

  return (
    <div css={styles.root}>
      <div css={styles.child}>
        <SuperchargePortfolioImg />
        <Typography variant="h3" css={styles.subHeading}>
          {/* Earn <b css={{ color: '#04DE71' }}>24.67%</b> APY */}
          Which asset do you want next on {brand.name}?
        </Typography>

        <Typography variant="body1" css={styles.description}>
          Vote for your favorite ETH-backed collateral and get support for it added on {brand.name}.
        </Typography>

        <Link to={routes.addCollateral.path} css={styles.link}>
          <Button variant="primary">Deposit LP</Button>
        </Link>
        <br />

        <Typography variant="small1" css={styles.note}>
          Note: The top candidates will be voted through {brand.escrowTokenSymbol} governance on{' '}
          {brand.lastVotingDay}.
        </Typography>

        <br />
        {!accountAddress && <ConnectButton />}
      </div>

      <div css={styles.child}>
        <GridImg css={styles.gridImg} />
      </div>
    </div>
  );
};
