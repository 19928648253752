import { QueryObserverOptions, useQuery } from 'react-query';

import { GetDsdTreasuryPercentageOutput, getDsdTreasuryPercentage } from 'clients/api';
import { useDsdControllerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetDsdTreasuryPercentageOutput,
  Error,
  GetDsdTreasuryPercentageOutput,
  GetDsdTreasuryPercentageOutput,
  FunctionKey.GET_DSD_TREASURY_PERCENTAGE
>;

const useGetDsdTreasuryPercentage = (options?: Options) => {
  const dsdControllerContract = useDsdControllerContract();

  return useQuery(
    FunctionKey.GET_DSD_TREASURY_PERCENTAGE,
    () => getDsdTreasuryPercentage({ dsdControllerContract }),
    options,
  );
};

export default useGetDsdTreasuryPercentage;
