/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import { ConnectWallet, EnableToken, ModalProps, Spinner } from 'components';
import React from 'react';
import { useTranslation } from 'translation';
import { Asset, LLToken, Pool } from 'types';
import { areTokensEqual, convertTokensToWei } from 'utilities';

import {
  useGetHypotheticalAccountLiquidity,
  useGetLLTokenBalanceOf,
  useGetPool,
  useRedeem,
  useRedeemUnderlying,
} from 'clients/api';
import { NULL_ADDRESS } from 'constants/address';
import { AmountFormProps } from 'containers/AmountForm';
import { useAuth } from 'context/AuthContext';
// import useAssetInfo from 'hooks/useAssetInfo';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { useStyles } from '../styles';
import WithdrawForm from './form';

export interface WithdrawProps {
  onClose: ModalProps['handleClose'];
  llToken: LLToken;
  poolComptrollerAddress: string;
  forModal?: boolean;
}

export interface WithdrawUiProps extends Omit<WithdrawProps, 'llToken' | 'poolComptrollerAddress'> {
  onSubmit: AmountFormProps['onSubmit'];
  isLoading: boolean;
  className?: string;
  asset?: Asset;
  pool?: Pool;
  forModal?: boolean;
}

export const WithdrawUi: React.FC<WithdrawUiProps> = ({
  className,
  asset,
  pool,
  onSubmit,
  isLoading,
  // forModal,
}) => {
  const styles = useStyles();

  const { t } = useTranslation();

  const { data: liquidityData, isLoading: isLiquidityDataLoading } =
    useGetHypotheticalAccountLiquidity(NULL_ADDRESS, new BigNumber(0), new BigNumber(0));

  const tokensWithdrawable = React.useMemo(() => {
    if (!isLiquidityDataLoading && liquidityData && asset) {
      const amount = new BigNumber(
        liquidityData[1]
          .div(asset.tokenPriceDollars.multipliedBy(asset.collateralFactor))
          .div(1e18),
      );
      return BigNumber.min(new BigNumber(amount), asset.userSupplyBalanceTokens);
    }
    return new BigNumber(0);
  }, [liquidityData, asset]);

  const maxInput = React.useMemo(() => {
    if (
      !asset ||
      pool?.userBorrowBalanceCents === undefined ||
      pool?.userBorrowLimitCents === undefined
    ) {
      return new BigNumber(0);
    }

    let maxInputTokens;

    // If asset isn't used as collateral user can withdraw the entire supply
    // balance without affecting their borrow limit
    if (!asset.isCollateralOfUser) {
      maxInputTokens = asset.userSupplyBalanceTokens;
    } else {
      // Calculate how much token user can withdraw before they risk getting
      // liquidated (if their borrow balance goes above their borrow limit)

      // Return 0 if borrow limit has already been reached
      if (pool.userBorrowBalanceCents > pool.userBorrowLimitCents) {
        return new BigNumber(0);
      }

      const marginWithBorrowLimitDollars =
        (pool.userBorrowLimitCents - pool.userBorrowBalanceCents) / 100;

      const collateralAmountPerTokenDollars = asset.tokenPriceDollars.multipliedBy(
        asset.collateralFactor,
      );
      const maxTokensBeforeLiquidation = new BigNumber(marginWithBorrowLimitDollars)
        .dividedBy(collateralAmountPerTokenDollars)
        .dp(asset.llToken.underlyingToken.decimals, BigNumber.ROUND_DOWN);

      maxInputTokens = maxTokensBeforeLiquidation.isLessThanOrEqualTo(0)
        ? new BigNumber(0)
        : BigNumber.minimum(
            maxTokensBeforeLiquidation,
            tokensWithdrawable,
            asset.userSupplyBalanceTokens,
          );
    }

    return maxInputTokens;
  }, [asset, pool, tokensWithdrawable]);

  if (!asset) {
    return <></>;
  }

  return (
    <div className={className} css={styles.container}>
      <ConnectWallet message={t('supplyWithdrawModal.withdraw.connectWalletToWithdraw')}>
        {asset && pool ? (
          <EnableToken
            token={asset.llToken.underlyingToken}
            spenderAddress={asset.llToken.address}
            title={t('supplyWithdrawModal.withdraw.enableToWithdraw', {
              symbol: asset?.llToken.underlyingToken.symbol,
            })}
          >
            <WithdrawForm
              key="form-withdraw"
              asset={asset}
              pool={pool}
              forModal
              onSubmit={onSubmit}
              inputLabel={t('supplyWithdrawModal.withdraw.withdrawableAmount')}
              enabledButtonKey={t('supplyWithdrawModal.withdraw.submitButton.enabledLabel')}
              disabledButtonKey={t(
                'supplyWithdrawModal.withdraw.submitButton.enterValidAmountWithdrawLabel',
              )}
              maxInput={maxInput}
              isTransactionLoading={isLoading}
            />
          </EnableToken>
        ) : (
          <Spinner />
        )}
      </ConnectWallet>
    </div>
  );
};

const WithdrawModal: React.FC<WithdrawProps> = ({
  llToken,
  poolComptrollerAddress,
  onClose,
  // forModal,
}) => {
  const { accountAddress } = useAuth();
  const styles = useStyles();
  const { data: getPoolData } = useGetPool({ poolComptrollerAddress, accountAddress });
  const pool = getPoolData?.pool;
  const asset = pool?.assets.find(item => areTokensEqual(item.llToken, llToken));

  const { t } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const { data: getLLTokenBalanceData } = useGetLLTokenBalanceOf(
    {
      accountAddress,
      llToken,
    },
    {
      enabled: !!accountAddress,
    },
  );
  const llTokenBalanceWei = getLLTokenBalanceData?.balanceWei;

  const { mutateAsync: redeem, isLoading: isRedeemLoading } = useRedeem({
    llToken,
  });

  const { mutateAsync: redeemUnderlying, isLoading: isRedeemUnderlyingLoading } =
    useRedeemUnderlying({
      llToken,
    });

  const isWithdrawLoading = isRedeemLoading || isRedeemUnderlyingLoading;

  const onSubmit: AmountFormProps['onSubmit'] = async value => {
    if (!asset) {
      return;
    }

    const amount = new BigNumber(value);
    const amountEqualsSupplyBalance = amount.eq(asset.userSupplyBalanceTokens);
    let transactionHash;

    // Withdraw entire supply
    if (amountEqualsSupplyBalance && llTokenBalanceWei) {
      const res = await redeem({ amountWei: llTokenBalanceWei });

      ({ transactionHash } = res);
      // Successful transaction modal will display
    }

    // Withdraw partial supply
    if (!amountEqualsSupplyBalance) {
      const withdrawAmountWei = convertTokensToWei({
        value: new BigNumber(value),
        token: asset.llToken.underlyingToken,
      });

      const res = await redeemUnderlying({
        amountWei: withdrawAmountWei,
      });

      ({ transactionHash } = res);
    }

    onClose();

    if (transactionHash) {
      openSuccessfulTransactionModal({
        title: t('supplyWithdrawModal.withdraw.successfulWithdrawTransactionModal.title'),
        content: t('supplyWithdrawModal.withdraw.successfulWithdrawTransactionModal.message'),
        amount: {
          valueWei: convertTokensToWei({ value: amount, token: llToken.underlyingToken }),
          token: llToken.underlyingToken,
        },
        transactionHash,
      });
    }
  };
  return (
    <WithdrawUi
      css={styles.modal}
      onClose={onClose}
      asset={asset}
      pool={pool}
      forModal
      onSubmit={onSubmit}
      isLoading={isWithdrawLoading}
    />
  );
};

export default WithdrawModal;
