import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    marketLink: css`
      text-decoration: underline;
      color: ${theme.palette.text.primary};

      > * {
        color: inherit;
      }

      :hover {
        color: ${theme.palette.interactive.primary};
      }
    `,
    claimLtokenButton: css`
      margin-left: auto;
      font-size: 0.5em;
    `,
    // cardContentGrid: css`
    //   .table__table-cards__card-content {
    //     grid-template-columns: 1fr 1fr 1fr 1fr;
    //   }

    //   ${theme.breakpoints.down('sm')} {
    //     .table__table-cards__card-content {
    //       grid-template-columns: 1fr 1fr;
    //       grid-template-rows: 1fr;
    //       row-gap: ${theme.spacing(5)};
    //     }
    //   }
    // `,
    userPercentOfLimit: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > :first-of-type {
        margin-right: ${theme.spacing(2)};
      }
    `,
    percentOfLimitProgressBar: css`
      width: ${theme.spacing(13)};
    `,
    white: css`
      color: ${theme.palette.text.primary};
    `,
    cardContent: css`
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: ${theme.shape.borderRadius.medium}px;
      padding: ${theme.spacing(4)};
      width: 40%;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)};
    `,
    cardContentRow: css`
      gap: ${theme.spacing(2)};
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,
    cardCotainer: css`
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(6)};
    `,
    cardContentGrid: css`
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: ${theme.spacing(6)};
    `,
    watchTokenButton: css`
      min-width: 0;
      padding: ${theme.spacing(0.5)};
      background-color: transperent;
      border-radius: ${theme.shape.borderRadius.verySmall}px;
    `,
    spaced: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(2)};
    `,
    mmIcon: css`
    width: 24px;
    height: 24px;
    cursor: pointer;
  `,
  };
};
