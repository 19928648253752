import formatToOutput from './formatToOutput';
import { GetMintableDsdInput, GetMintableDsdOutput } from './types';

export * from './types';

const getMintableDsd = async ({
  dsdControllerContract,
  accountAddress,
}: GetMintableDsdInput): Promise<GetMintableDsdOutput> => {
  const res = await dsdControllerContract.getMintableDSD(accountAddress);

  return formatToOutput(res);
};

export default getMintableDsd;
