import BigNumber from 'bignumber.js';
import { checkForDsdControllerTransactionError } from 'errors';
import { ContractReceipt } from 'ethers';

import { DsdController } from 'types/contracts';

export interface RepayDsdInput {
  dsdControllerContract: DsdController;
  amountWei: BigNumber;
}

export type IRepayDsdOutput = ContractReceipt;

const repayDsd = async ({
  dsdControllerContract,
  amountWei,
}: RepayDsdInput): Promise<IRepayDsdOutput> => {
  const transaction = await dsdControllerContract.repayDSD(amountWei.toFixed());
  const receipt = await transaction.wait(1);
  return checkForDsdControllerTransactionError(receipt);
};

export default repayDsd;
