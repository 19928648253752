/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import {
  ActiveChip,
  Button,
  Chip,
  LayeredValues,
  MigrateButton, // ProgressBar,
  TableColumn, // Toggle,
  TokenIconWithSymbol,
} from 'components';
import React, { useMemo } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'translation';
import {
  compareBigNumbers, // compareBooleans,
  compareNumbers,
  convertWeiToTokens, // compareStrings,
  formatCentsToReadableValue,
  formatToReadablePercentage,
  formatTokensToReadableValue,
  getCombinedDistributionApys,
} from 'utilities';

import { useGetApyData } from 'clients/api';
// import ClaimMultiRewardButton from 'components/Layout/ClaimMultiRewardButton';
import PLACEHOLDER_KEY from 'constants/placeholderKey';

// import { routes } from 'constants/routing';
import { useStyles } from './styles';
import { ColumnKey, PoolAsset } from './types';

// Translation keys: do not remove this comment
// t('marketTable.columnKeys.asset')
// t('marketTable.columnKeys.supplyApyLtv')
// t('marketTable.columnKeys.labeledSupplyApyLtv')
// t('marketTable.columnKeys.borrowApy')
// t('marketTable.columnKeys.labeledBorrowApy')yar
// t('marketTable.columnKeys.pool')
// t('marketTable.columnKeys.collateral')
// t('marketTable.columnKeys.supplyBalance')
// t('marketTable.columnKeys.borrowBalance')
// t('marketTable.columnKeys.userBorrowBalance')
// t('marketTable.columnKeys.userSupplyBalance')
// t('marketTable.columnKeys.userWalletBalance')
// t('marketTable.columnKeys.userPercentOfLimit')
// t('marketTable.columnKeys.liquidity')
// t('marketTable.columnKets.manage)

const useGenerateColumns = ({
  poolAssets,
  columnKeys,
  withdrawModalOpen,
}: {
  poolAssets: PoolAsset[];
  columnKeys: ColumnKey[];
  withdrawModalOpen: (row: PoolAsset, onlySupply?: boolean, onlyWithdraw?: boolean) => void;
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { data: apyData } = useGetApyData();

  const columns: TableColumn<PoolAsset>[] = useMemo(
    () =>
      columnKeys.map((column, index) => ({
        key: column,
        label: t(`marketTable.columnKeys.${column}`),
        align: index === 0 || index === 1 ? 'left' : 'right',
        renderCell: poolAsset => {
          // For migrate page 🟡
          if (column === 'lpToken') {
            return <TokenIconWithSymbol token={poolAsset.llToken.underlyingToken} />;
          }

          if (column === 'differenceApy') {
            const finalApy =
              apyData?.tokenData && apyData?.tokenData?.[poolAsset.llToken.address]
                ? Object.values(apyData.tokenData[poolAsset.llToken.address]).reduce(
                    (netApy, value) => +value + netApy,
                    0,
                  )
                : 0;
            const supplyApy = `0.4% → ${formatToReadablePercentage(finalApy)}`;

            return (
              <b>
                <Chip text={supplyApy} />
              </b>
            );
          }

          if (column === 'differenceLimit') {
            const supplyApy = '990 → 1880%';

            return (
              <b>
                <Chip text={supplyApy} />
              </b>
            );
          }
          if (column === 'newAPY') {
            const esRewardApy =
              apyData?.tokenData?.[poolAsset.llToken.address]?.esTokenApyOnProvidingCollateral || 0;

            return (
              <b>
                <ActiveChip text={formatToReadablePercentage(esRewardApy)} />
              </b>
            );
          }
          if (column === 'migrate') {
            return (
              <MigrateButton
                css={{ margin: 'auto' }}
                lptoken={poolAsset.llToken.underlyingToken}
                pooltoken={poolAsset.poolToken}
              />
            );
          }
          if (column === 'asset') {
            return <TokenIconWithSymbol token={poolAsset.llToken.underlyingToken} />;
          }
          if (column === 'protocol') {
            return (
              <div css={styles.protocol}>
                {/* <img
                  src={poolAsset.llToken.protocol}
                  css={styles.icon}
                  alt={poolAsset.llToken.protocolSymbol}
                /> */}
                <Typography variant="body2" style={{ color: 'white' }}>
                  {poolAsset.llToken.protocolName}
                </Typography>
              </div>
            );
          }

          if (column === 'supplyApyLtv') {
            const combinedDistributionApys = getCombinedDistributionApys({ asset: poolAsset });

            const supplyApy = poolAsset.supplyApyPercentage.plus(
              combinedDistributionApys.supplyApyPercentage,
            );
            // const supplyApy = 4.59;

            return (
              <b>
                <ActiveChip text={formatToReadablePercentage(supplyApy)} />
              </b>
            );
          }

          if (column === 'manage') {
            return (
              <Button
                variant="secondary"
                onClick={() => withdrawModalOpen(poolAsset, false, false)}
              >
                Manage
              </Button>
            );
          }

          if (column === 'userWalletBalance') {
            return convertWeiToTokens({
              valueWei: poolAsset.userPoolTokenWalletBalance,
              token: poolAsset.poolToken,
              returnInReadableFormat: true,
              shortenLargeValue: true,
              minimizeDecimals: 2,
            });
          }

          if (column === 'userSupplyBalance') {
            return poolAsset.userSupplyBalanceTokens.isGreaterThan(0) ? (
              <LayeredValues
                topValue={formatTokensToReadableValue({
                  value: poolAsset.userSupplyBalanceTokens,
                  token: poolAsset.llToken.underlyingToken,
                  addSymbol: false,
                  shortenLargeValue: true,
                  minimizeDecimals: true,
                })}
                bottomValue={formatCentsToReadableValue({
                  value: poolAsset.userSupplyBalanceCents,
                  shortenLargeValue: true,
                })}
              />
            ) : (
              PLACEHOLDER_KEY
            );
          }

          if (column === 'supplyBalance') {
            return formatCentsToReadableValue({
              value: poolAsset.supplyBalanceCents,
              shortenLargeValue: true,
            });
          }

          if (column === 'borrowBalance') {
            return formatCentsToReadableValue({
              value: poolAsset.borrowBalanceCents,
              shortenLargeValue: true,
            });
          }
        },
        sortRows: (
          ['protocol', 'lpToken', 'asset', 'manage', 'mintMore', 'migrate'] as ColumnKey[]
        ).includes(column)
          ? undefined
          : (rowA, rowB, direction) => {
              if (column === 'supplyApyLtv') {
                const roaASupplyApy = rowA.supplyApyPercentage.plus(
                  getCombinedDistributionApys({ asset: rowA }).supplyApyPercentage,
                );
                const roaBSupplyApy = rowB.supplyApyPercentage.plus(
                  getCombinedDistributionApys({ asset: rowB }).supplyApyPercentage,
                );

                return compareBigNumbers(roaASupplyApy, roaBSupplyApy, direction);
              }
              if (column === 'collateralFactor') {
                return compareNumbers(rowA.collateralFactor, rowB.collateralFactor, direction);
              }

              if (column === 'assetPrice') {
                return compareBigNumbers(rowA.tokenPriceDollars, rowB.tokenPriceDollars, direction);
              }

              if (column === 'userWalletBalance') {
                return compareBigNumbers(
                  rowA.userWalletBalanceTokens,
                  rowB.userWalletBalanceTokens,
                  direction,
                );
              }

              if (column === 'userSupplyBalance') {
                return compareNumbers(
                  rowA.userSupplyBalanceCents,
                  rowB.userSupplyBalanceCents,
                  direction,
                );
              }

              if (column === 'supplyBalance') {
                return compareNumbers(rowA.supplyBalanceCents, rowB.supplyBalanceCents, direction);
              }

              return 0;
            },
      })),
    [poolAssets, columnKeys],
  );

  return columns;
};

export default useGenerateColumns;
