import { Provider } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';
import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { Signer } from 'ethers';
import { getContractAddress, getTokenByAddress } from 'utilities';

import dsdAbi from 'constants/contracts/abis/dsdToken.json';
import esLTokenMinterAbi from 'constants/contracts/abis/esLTOKENMinter.json';
import fundAbi from 'constants/contracts/abis/fund.json';
import MultiRewardAbi from 'constants/contracts/abis/multiReward.json';
import OracleAbi from 'constants/contracts/abis/oracle.json';
import LlTokenAbi from 'constants/contracts/abis/vErc20.json';
import { TOKENS } from 'constants/tokens';
import { Comptroller } from 'types/contracts';

import getEventsBasedData from './getEventsBasedData';
import getExternalApyData, {
  balancerPools,
  balancerUrl,
  curvePools,
  curveUrl,
  defiLlamaPools,
  defiLlamaUrl,
  yearnPools,
  yearnUrl,
} from './getExternalApyData';

interface GetCollateralRewardProps {
  comptroller: Comptroller;
  multicall: Multicall;
  blockNumber?: number;
  provider: Provider;
  signer?: Signer;
}

interface TokenData {
  exchangeRateCurrent: BigNumber;
  totalSupply: BigNumber;
  underlying: string;
  multiRewardShareInBps: BigNumber;
  esLTOKENShareInBps: BigNumber;
  multiReward: string;
  decimals: number;
  oraclePrice: BigNumber;
}

export interface TokenDataWithRewardsInfo extends TokenData {
  underlyingDecimals: number;
  dsdRewardAmountFor24Hours: BigNumber;
  esLTokenRewardAmountFor30Days: BigNumber;
}

// await lsFundContract.earned(accountAddress);
const tokenCalls = [
  { methodName: 'exchangeRateCurrent', methodParameters: [] },
  { methodName: 'totalSupply', methodParameters: [] },
  { methodName: 'underlying', methodParameters: [] },
  { methodName: 'multiRewardShareInBps', methodParameters: [] },
  { methodName: 'esLTOKENShareInBps', methodParameters: [] },
  { methodName: 'multiReward', methodParameters: [] },
  { methodName: 'decimals', methodParameters: [] },
];

const l = (t: string) => t.toLowerCase();

async function getCollateralRewards({
  comptroller,
  multicall,
  signer,
  provider,
}: GetCollateralRewardProps) {
  const oracleAddress = getContractAddress('oracle');
  const tokens = await comptroller.getAllMarkets();

  const rewardsData = await multicall.call([
    {
      contractAddress: oracleAddress,
      reference: 'oracleCalls',
      abi: OracleAbi,
      calls: tokens.map(tokenAddress => ({
        methodName: 'getUnderlyingPrice',
        methodParameters: [tokenAddress],
        reference: `getUnderlyingPrice_${tokenAddress}`,
      })),
    },
    ...tokens.map<ContractCallContext>(tokenAddress => ({
      contractAddress: tokenAddress,
      reference: `token_${tokenAddress}`,
      abi: LlTokenAbi,
      calls: tokenCalls.map(({ methodName, methodParameters }) => ({
        methodName,
        methodParameters,
        reference: `${methodName}_${tokenAddress}`,
      })),
    })),
  ]);

  const parsedData = tokens.reduce(
    (p, tokenAddress, index) => ({
      ...p,
      [tokenAddress]: {
        oraclePrice: new BigNumber(
          rewardsData.results.oracleCalls.callsReturnContext[index].returnValues[0].hex,
        ),
        ...tokenCalls.reduce(
          (r, { methodName }, innerIndex) => ({
            ...r,
            [methodName]: (() => {
              const returnValue =
                rewardsData.results[`token_${tokenAddress}`].callsReturnContext[innerIndex]
                  .returnValues[0];
              if (returnValue?.type === 'BigNumber') {
                return new BigNumber(returnValue.hex);
              }
              return returnValue;
            })(),
          }),
          {},
        ),
      },
    }),
    {},
  ) as Record<string, TokenData>;

  const otherRawData = await multicall.call([
    ...tokens.flatMap<ContractCallContext>(tokenAddress => [
      {
        contractAddress: parsedData[tokenAddress].underlying,
        reference: `underlyingDecimals_${tokenAddress}`,
        abi: LlTokenAbi,
        calls: [
          {
            methodName: 'decimals',
            methodParameters: [],
            reference: `decimals_${tokenAddress}`,
          },
        ],
      },
      {
        contractAddress: parsedData[tokenAddress].multiReward,
        reference: `rewardData_${tokenAddress}`,
        abi: MultiRewardAbi,
        calls: [
          {
            methodName: 'getRewardForDuration',
            methodParameters: [TOKENS.esLTOKEN.address],
            reference: `getRewardForDuration_${TOKENS.esLTOKEN.address}`,
          },
          {
            methodName: 'getRewardForDuration',
            methodParameters: [TOKENS.dsd.address],
            reference: `getRewardForDuration_${TOKENS.dsd.address}`,
          },
        ],
      },
    ]),
    {
      reference: 'dsdCalls',
      contractAddress: TOKENS.dsd.address,
      abi: dsdAbi,
      calls: [
        {
          methodName: 'totalSupply',
          reference: 'dsdTotalSupply',
          methodParameters: [],
        },
        {
          methodName: 'nonRebasingSupply',
          reference: 'dsdNonRebasingSupply',
          methodParameters: [],
        },
      ],
    },
    {
      contractAddress: oracleAddress,
      abi: OracleAbi,
      reference: 'dsdOraclePrice',
      calls: [
        {
          methodName: 'getUnderlyingPrice',
          reference: 'dsdUnderlyingPrice',
          methodParameters: [TOKENS.dsd.address],
        },
      ],
    },
    {
      contractAddress: getContractAddress('esLTOKENMinter'),
      abi: esLTokenMinterAbi,
      reference: 'esLTokenMinterRewardRate',
      calls: [
        {
          methodName: 'rewardRate',
          reference: 'rewardRate',
          methodParameters: [],
        },
      ],
    },
    {
      contractAddress: getContractAddress('fund'),
      abi: fundAbi,
      reference: 'fundRewardAmountFor24Hours',
      calls: [
        {
          methodName: 'rewardForDuration',
          reference: 'rewardForDuration',
          methodParameters: [],
        },
      ],
    },
    {
      contractAddress: TOKENS.esLTOKEN.address,
      abi: LlTokenAbi,
      reference: 'esLTokenTotalSupply',
      calls: [
        {
          methodName: 'totalSupply',
          reference: 'totalSupply',
          methodParameters: [],
        },
      ],
    },
  ]);

  const collateralData = tokens.reduce(
    (p, tokenAddress) => ({
      ...p,
      [tokenAddress]: {
        ...p[tokenAddress],
        underlyingDecimals:
          otherRawData.results[`underlyingDecimals_${tokenAddress}`].callsReturnContext[0]
            .returnValues[0],
        esLTokenRewardAmountFor30Days: new BigNumber(
          otherRawData.results[
            `rewardData_${tokenAddress}`
          ].callsReturnContext[0].returnValues[0].hex,
        ),
        dsdRewardAmountFor24Hours: new BigNumber(
          otherRawData.results[
            `rewardData_${tokenAddress}`
          ].callsReturnContext[1].returnValues[0].hex,
        ),
      },
    }),
    parsedData,
  ) as Record<string, TokenDataWithRewardsInfo>;

  const dsdCallData = otherRawData.results.dsdCalls.callsReturnContext;
  const dsdOraclePrice = new BigNumber(
    otherRawData.results.dsdOraclePrice.callsReturnContext[0].returnValues[0].hex,
  );
  const remainingData = {
    dsdOraclePrice,
    dsdTotalSupply: new BigNumber(dsdCallData[0].returnValues[0].hex),
    dsdNonRebasingSupply: new BigNumber(dsdCallData[1].returnValues[0].hex),
    esLTokenMinterRewardRate: new BigNumber(
      otherRawData.results.esLTokenMinterRewardRate.callsReturnContext[0].returnValues[0].hex,
    ),
    fundRewardAmountFor24Hours: new BigNumber(
      otherRawData.results.fundRewardAmountFor24Hours.callsReturnContext[0].returnValues[0].hex,
    ),
    esLTokenTotalSupply: new BigNumber(
      otherRawData.results.esLTokenTotalSupply.callsReturnContext[0].returnValues[0].hex,
    ),
    dsdCirculatingSupply: new BigNumber(dsdCallData[0].returnValues[0].hex)
      .minus(dsdCallData[1].returnValues[0].hex)
      .multipliedBy(dsdOraclePrice)
      .div(1e18),
  };

  const [externalApyRawData, eventsData] = await Promise.all([
    getExternalApyData(),
    getEventsBasedData(tokens, provider, signer),
  ]);

  const externalApyData = tokens.reduce((p, tokenAddress) => {
    let [source, url, readAs] = ['', '', ''];

    if (l(tokenAddress) in defiLlamaPools) {
      source = defiLlamaUrl;
      readAs = 'Last value from `data.apy`';
      url = `${defiLlamaUrl}?pool=${defiLlamaPools[l(tokenAddress)]}`;
    }
    if (l(tokenAddress) in balancerPools) {
      source = balancerUrl;
      readAs = `(Average of \`apr.min\` & \`apr.max\` for token) / 100 i.e. ${tokenAddress}`;
      url = `${balancerUrl}/${balancerPools[l(tokenAddress)]}`;
    }
    if (l(tokenAddress) in curvePools) {
      source = curveUrl;
      readAs = `\`latestWeeklyApy\` for token address i.e. ${tokenAddress}`;
      url = curveUrl;
    }
    if (l(tokenAddress) in yearnPools) {
      readAs = '`apy.net_apy` * 100';
      source = yearnUrl;
      url = yearnUrl;
    }

    if (!url) return p;

    return {
      ...p,
      [tokenAddress]: {
        url,
        source,
        name: `Projected APY from external source: ${getTokenByAddress(tokenAddress)?.symbol}`,
        value: externalApyRawData[l(tokenAddress)],
        readAs,
      },
    };
  }, {});

  return {
    remainingData,
    externalApyData,
    collateralData,
    eventsData,
  };
}

export default getCollateralRewards;
