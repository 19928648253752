import { MutationObserverOptions, useMutation } from 'react-query';
import { getContractAddress } from 'utilities';

import {
  StakeInDsdVaultInput,
  StakeInDsdVaultOutput,
  queryClient,
  stakeInDsdVault,
} from 'clients/api';
import { useDsdVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { TOKENS } from 'constants/tokens';

const DSD_VAULT_ADDRESS = getContractAddress('dsdVault');

type Options = MutationObserverOptions<
  StakeInDsdVaultOutput,
  Error,
  Omit<StakeInDsdVaultInput, 'dsdVaultContract'>
>;

const useStakeInDsdVault = (options?: Options) => {
  const dsdVaultContract = useDsdVaultContract();

  return useMutation(
    FunctionKey.STAKE_IN_DSD_VAULT,
    (params: Omit<StakeInDsdVaultInput, 'dsdVaultContract'>) =>
      stakeInDsdVault({
        dsdVaultContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        const accountAddress = await dsdVaultContract.signer.getAddress();

        // Invalidate cached user info, including pending reward
        queryClient.invalidateQueries([FunctionKey.GET_DSD_VAULT_USER_INFO, accountAddress]);

        // Invalidate cached user balance
        queryClient.invalidateQueries([
          FunctionKey.GET_BALANCE_OF,
          {
            accountAddress,
            tokenAddress: TOKENS.dsd.address,
          },
        ]);

        queryClient.invalidateQueries([
          FunctionKey.GET_TOKEN_BALANCES,
          {
            accountAddress,
          },
        ]);

        // Invalidate cached vault data
        queryClient.invalidateQueries([
          FunctionKey.GET_BALANCE_OF,
          {
            accountAddress: DSD_VAULT_ADDRESS,
            tokenAddress: TOKENS.dsd.address,
          },
        ]);

        queryClient.invalidateQueries(FunctionKey.GET_VENUS_DSD_VAULT_DAILY_RATE);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useStakeInDsdVault;
