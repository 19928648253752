import { QueryObserverOptions, useQuery } from 'react-query';
import { LLToken } from 'types';

import getLLTokenBalanceOf, {
  GetLLTokenBalanceOfInput,
  GetLLTokenBalanceOfOutput,
} from 'clients/api/queries/getLLTokenBalanceOf';
import { useLLTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

interface TrimmedParams extends Omit<GetLLTokenBalanceOfInput, 'llTokenContract'> {
  llToken: LLToken;
}

type Options = QueryObserverOptions<
  GetLLTokenBalanceOfOutput,
  Error,
  GetLLTokenBalanceOfOutput,
  GetLLTokenBalanceOfOutput,
  [FunctionKey.GET_V_TOKEN_BALANCE, { accountAddress: string; llTokenAddress: string }]
>;

const useGetLLTokenBalanceOf = ({ accountAddress, llToken }: TrimmedParams, options?: Options) => {
  const llTokenContract = useLLTokenContract(llToken);

  return useQuery(
    [FunctionKey.GET_V_TOKEN_BALANCE, { accountAddress, llTokenAddress: llToken.address }],
    () => getLLTokenBalanceOf({ llTokenContract, accountAddress }),
    options,
  );
};

export default useGetLLTokenBalanceOf;
