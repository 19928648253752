/** @jsxImportSource @emotion/react */
// import MuiAccordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
import { Typography } from '@mui/material';
import React from 'react';

import { Tooltip } from 'components/Tooltip';

import { Icon } from '../Icon';
import * as C from './styles.module.css';
import useGetData from './useGetData';

export const FlashTicker: React.FC = () => {
  const data = useGetData();

  return (
    <div className={C.hwrap}>
      <div className={C.hmove}>
        {data &&
          data.map(item => (
            <div key={item.title} className={C.hitem}>
              <Typography variant="small2" css={{ opacity: '60%' }}>
                {item.title}
              </Typography>
              <Tooltip title={item.info} css={{ cursor: 'pointer' }}>
                <Icon
                  name="info"
                  css={{ padding: '0.1em', opacity: '60%', marginRight: '0.1em' }}
                />
              </Tooltip>
              <Typography variant="small1" css={{ marginLeft: '0.1em' }}>
                <b>{item.value}</b>
              </Typography>
            </div>
          ))}
      </div>
    </div>
  );
};
