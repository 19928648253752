/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-alert */

/* eslint-disable no-console */
import {
  Box,
  FormControlLabel,
  Input,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import BigNumber from 'bignumber.js';
import { BscLink, Button, ConnectWallet, Spinner } from 'components';
import config from 'config';
import { ethers } from 'ethers';
import { parseEther, parseUnits } from 'ethers/lib/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Token } from 'types';

import brand from 'config/brand';
// import comptrollerAbi from 'constants/contracts/abis/comptroller.json';
import erc20Abi from 'constants/contracts/abis/erc20.json';
import oracleAbi from 'constants/contracts/abis/oracle.json';
import uniswapV2PairAbi from 'constants/contracts/abis/uniswapV2Pair.json';
import uniswapPoolAbi from 'constants/contracts/abis/uniswapV2Pool.json';
import llErc20 from 'constants/contracts/abis/vErc20.json';
import { comptroller, oracle } from 'constants/contracts/addresses/main.json';
import { MAINNET_TOKENS } from 'constants/tokens/common/mainnet';
import { useAuth } from 'context/AuthContext';
import useAddTokenToWallet from 'hooks/useAddTokenToWallet';

import { eventAbi, whales } from './constants';

const tokens = [
  MAINNET_TOKENS.LSstETH,
  MAINNET_TOKENS.LSsfrxETH,
  MAINNET_TOKENS.LScbETH,
  MAINNET_TOKENS.LSrETH,
  MAINNET_TOKENS.LSWETH,
  MAINNET_TOKENS.LSsteCRV,
  MAINNET_TOKENS.LSfrxETHCRV,
  MAINNET_TOKENS.LSSTETHETH_C_f,
  MAINNET_TOKENS.LSst_frxETH_f,
  MAINNET_TOKENS.LSyvWETH,
  MAINNET_TOKENS.LSwstETHrETHsfrxETHBPT,
  MAINNET_TOKENS.LSBrETHSTABLE,
  MAINNET_TOKENS.LSBstETHSTABLE,
  MAINNET_TOKENS.LSPTstETH26DEC2024,
  MAINNET_TOKENS.LSPTstETH30DEC2027,
  MAINNET_TOKENS.LSPTstETH25DEC2025,
  MAINNET_TOKENS.LSPTfrxETH26DEC2024,
  MAINNET_TOKENS.LSswETH,
  MAINNET_TOKENS.LSrETHf,
  {
    asset: '',
    symbol: `Native ${brand.currencySymbol}`,
    decimals: brand.decimals,
    address: ethers.constants.AddressZero,
  },
];

const rewardTokensInfo: { [id: string]: { address: string; whale: string } } = {
  CRV: {
    address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    whale: '0x5f3b5DfEb7B28CDbD7FAba78963EE202a494e2A2',
  },
  AURA: {
    address: '0xC0c293ce456fF0ED870ADd98a0828Dd4d2903DBF',
    whale: '0x38DB4dcFdD2fc1e2aB8EaaE5CdAa6f6CD6D89a5C',
  },
  BAL: {
    address: '0xba100000625a3754423978a60c9317c58a424e3d',
    whale: '0x8EdEbb8451F028Ba199322680A33068479b85014',
  },
  CVX: {
    address: '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
    whale: '0xCF50b810E57Ac33B91dCF525C6ddd9881B139332',
  },
};

const useMintFunctionFor = [''];

const Faucet = () => {
  // const [snapshotId] = useState('0x8ad');
  const { accountAddress, signer } = useAuth();
  const [inputValues, setInputValues] = useState(tokens.map(() => ({ main: '', underlying: '' })));
  const [rewardValues, setRewardValues] = useState(tokens.map(() => ({ token: '' })));
  const [priceInEth, setPriceInEth] = useState<any>([]);
  const [priceInUsd, setPriceInUsd] = useState<any>([]);
  const [directPriceInfo, setDirectPriceInfo] = useState({
    price: '',
    asset: MAINNET_TOKENS.LSWETH.symbol,
  });
  const [currTimestamp, setCurrTimestamp] = useState(0);
  const [ethPriceInUsd, setEthPriceInUsd] = useState('');
  const [loading, setLoading] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [msg, setMsg] = useState('');
  const [travelBy, setTravelBy] = useState('');
  const [staleDays, setStaleDays] = useState(0);
  const [revertSnapshotId, setRevertSnapshotId] = useState('');
  const [userSavedSnapshot, setUserSavedSnapshot] = useState('');
  const provider = useMemo(() => new ethers.providers.JsonRpcProvider(config.rpcUrl), []);

  const addTokenToWallet = useAddTokenToWallet();
  const llTokens: Record<string, Token> = {};

  const allUniswapLp = useMemo(
    () =>
      Object.keys(MAINNET_TOKENS)
        .map((token: string) => {
          const checIfLp =
            token.slice(-5).toLowerCase() === '_weth' || token.slice(-5).toLowerCase() === '_usdc';
          if (checIfLp) {
            return { ...MAINNET_TOKENS, type: 'uniswap' }[token];
          }
          if (token.slice(2).toLowerCase() === 'ls') {
            const t = { ...MAINNET_TOKENS, type: 'uniswap' }[token];
            if (t) llTokens[token] = t as Token;
          }
          return undefined;
        })
        .filter(token => token) as Token[],
    [MAINNET_TOKENS],
  );

  const { data: balances, error } = useQuery<ethers.BigNumber[]>(
    ['tokenBalance', accountAddress],
    () => {
      if (!accountAddress) throw new Error('No account address');
      const calldata = tokens.map(token => {
        const contract = new ethers.Contract(token.address, erc20Abi, provider);
        return token.address === ethers.constants.AddressZero
          ? provider.getBalance(accountAddress)
          : contract.balanceOf(accountAddress);
      });
      return Promise.all(calldata);
    },
    { refetchInterval: 3500 },
  );

  const transferRewardToLtokenStrategy = async (lltoken: Token, reward: string) => {
    if (!signer) {
      alert('no signer');
      return;
    }
    setLoading(true);
    setMsg(`transferring reward 1e18 ${reward} to ${lltoken.symbol} strategy`);
    const amountInEthers = (1e18).toString();
    console.log(reward, 'amount in ethers:', +amountInEthers);

    try {
      if ((await provider.getBalance(rewardTokensInfo[reward].whale)).lt(parseEther('10'))) {
        await provider.send('hardhat_setBalance', [
          rewardTokensInfo[reward].whale,
          parseEther('10').toHexString(),
        ]);
      }
      await provider.send('hardhat_impersonateAccount', [rewardTokensInfo[reward].whale]);
      const impersonatedWhaleSigner = provider.getSigner(rewardTokensInfo[reward].whale);

      const lltokenContract = new ethers.Contract(lltoken.address, llErc20, signer);
      const strategyAddr = await lltokenContract.strategy();

      const rewardTokenContract = new ethers.Contract(
        rewardTokensInfo[reward].address,
        llErc20,
        impersonatedWhaleSigner,
      );

      if (!strategyAddr) {
        setMsg('no strategy');
        console.log('NO STRATEGY FOUND');
        return;
      }

      let underlyingtokenBalance = await rewardTokenContract
        .connect(signer)
        .balanceOf(strategyAddr);

      setMsg(`transferring ${reward} 1e18 to ${strategyAddr}`);

      console.log(`strategy ${reward} token balance`, +underlyingtokenBalance);

      (
        await rewardTokenContract
          .connect(impersonatedWhaleSigner)
          .transfer(strategyAddr, amountInEthers)
      ).wait(1);

      underlyingtokenBalance = await rewardTokenContract.connect(signer).balanceOf(strategyAddr);

      console.log(`strategy ${reward} token balance::after`, +underlyingtokenBalance + +1e18);
    } catch (err) {
      alert('Err::transferUnderlyingToLtoken');
      // eslint-disable-next-line no-console
      console.error('transferUnderlyingToLtoken:', err);
    }
    setLoading(false);
  };

  const saveSnapshot = async () => {
    try {
      const savedSnapshot = await provider.send('evm_snapshot', []);
      console.log('@savedSnapshot', savedSnapshot);
      setUserSavedSnapshot(savedSnapshot);
      // await provider.send("evm_revert", [snapshotId]);
    } catch (err) {
      console.error('error at saveSnapshot', err);
    }
  };

  const revertSnapshot = async () => {
    try {
      await provider.send('evm_revert', [revertSnapshotId]);
      console.log('🟢 EVM snapshot reverted to', revertSnapshotId);
    } catch (err) {
      console.error('revertSnapshot', err);
    }
  };

  const { data: underlyingBalance } = useQuery<ethers.BigNumber[]>(
    ['underlyingTokenBalance', accountAddress],
    () => {
      if (!accountAddress) throw new Error('No account address');
      const calldata = tokens.map(token => {
        const llContract = new ethers.Contract(token.address, llErc20, provider);
        const underlying = llContract.underlying();
        const contract = new ethers.Contract(underlying, erc20Abi, provider);

        return token.address === ethers.constants.AddressZero
          ? provider.getBalance(accountAddress)
          : contract.balanceOf(accountAddress);
      });
      return Promise.all(calldata);
    },
    { refetchInterval: 3500 },
  );

  const getUnderlyingPriceInfoHandler = async () => {
    try {
      const oracleContract = new ethers.Contract(oracle[1], oracleAbi, provider);

      const underlyingPromises: Promise<string>[] = tokens.slice(0, -1).map(token => {
        const llContract = new ethers.Contract(token.address, llErc20, provider);
        return llContract.underlying();
      });

      Promise.all(underlyingPromises).then((underlyingTokens: string[]) => {
        const underlyingPriceInEth = underlyingTokens.map(token =>
          oracleContract.getPriceInETH(token),
        );
        const underlyingPriceInUsd = underlyingTokens.map(token => oracleContract.getPrice(token));

        Promise.all(underlyingPriceInEth).then(pricesInEth => setPriceInEth(pricesInEth));
        Promise.all(underlyingPriceInUsd).then(localpriceInUsd => setPriceInUsd(localpriceInUsd));
      });
    } catch (e) {
      console.error('error underlying price info', e);
    }
    console.log('✅got prices');
  };

  // const { data: underlyingEthPrice } = useQuery<ethers.BigNumber[]>(
  //   ['underlyingEthPriceFn', accountAddress],
  //   async () => {
  //     if (!accountAddress) throw new Error('No account address');
  //     const calldata = await tokens.slice(0, -1).map(token => {
  //       const llContract = new ethers.Contract(token.address, llErc20, provider);
  //       const underlying = llContract.underlying();
  //       const oracleContract = new ethers.Contract(oracle[1], oracleAbi, provider);

  //       return oracleContract.getPriceInETH(underlying);
  //     });
  //     return Promise.all(calldata);
  //   },
  //   { refetchInterval: 3500 },
  // );

  // const { data: underlyingUsdPrice } = useQuery<ethers.BigNumber[]>(
  //   ['underlyingUsdPriceFn', accountAddress],
  //   () => {
  //     if (!accountAddress) throw new Error('No account address');
  //     const calldata = tokens.slice(0, -1).map(token => {
  //       const llContract = new ethers.Contract(token.address, llErc20, provider);
  //       const underlying = llContract.underlying();
  //       const oracleContract = new ethers.Contract(oracle[1], oracleAbi, provider);

  //       return oracleContract.getPrice(underlying);
  //     });
  //     return Promise.all(calldata);
  //   },
  //   { refetchInterval: 3500 },
  // );

  const { data: lpBalances } = useQuery(
    ['lpBalance', accountAddress],
    () => {
      if (!accountAddress) throw new Error('No account address');
      const calldata = allUniswapLp.map(async (token: Token) => {
        const contract = new ethers.Contract(token.address, erc20Abi, provider);
        return contract.balanceOf(accountAddress);
      });
      return Promise.all(calldata);
    },
    { refetchInterval: 2500 },
  );

  if (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed fetching balances: ${error}`);
  }

  const getBlockNumber = async () => {
    const localBlock = await provider.getBlockNumber();
    const block = await provider.getBlock(localBlock);

    setCurrTimestamp(+block.timestamp);
    return localBlock;
  };

  const [rebaseInfo, setRebaseInfo] = useState({ mr: '', fund: '', dsd: '', symbol: '' });

  const llTokenRebaseHandler = async (token: Token) => {
    setLoading(true);
    setMsg(`Rebasing ${token.symbol} ${token.address}`);
    try {
      const comptrollerAddr = comptroller[1];
      const llToken = new ethers.Contract(token.address, llErc20, signer);
      await provider.send('hardhat_impersonateAccount', [comptrollerAddr]);
      const impersonatedComptrollerSigner = provider.getSigner(comptrollerAddr);
      console.log('impersonated', comptrollerAddr);

      if (comptrollerAddr && (await provider.getBalance(comptrollerAddr)).lt(parseEther('10'))) {
        await provider.send('hardhat_setBalance', [
          comptrollerAddr,
          parseEther('10').toHexString(),
        ]);
      }

      // lltoken
      console.log(`-------REBASING ${token.symbol}-------`);
      console.log(`calling:= ${llToken.address}.rebase();`);

      const tx = await llToken.connect(impersonatedComptrollerSigner).rebase();
      const receipt = await tx.wait(1);
      console.log('logs', receipt.logs);

      const eventTopic = ethers.utils.keccak256(
        ethers.utils.toUtf8Bytes('Distribution(address,uint256,uint256,uint256)'),
      );
      const eventinterface = new ethers.utils.Interface(eventAbi);
      receipt.logs.forEach((log: any) => {
        if (log.topics[0] === eventTopic) {
          const parsedLog = eventinterface.parseLog(log);
          console.log(
            `\nEvent: ${parsedLog.name}, MR: ${parsedLog.args.mr}, Fund: ${parsedLog.args.fund}, DSD: ${parsedLog.args.dsd}`,
          );
          setRebaseInfo({
            mr: parsedLog.args.mr,
            fund: parsedLog.args.fund,
            dsd: parsedLog.args.dsd,
            symbol: token.symbol,
          });
        }
      });
    } catch (err) {
      console.error(`error at ${token.symbol} rebase()`, err);
    }
    setLoading(false);
    console.log(`-------REBASED ${token.symbol}-------`);
  };

  const mint =
    (token: Token, index: number, amount?: number): React.FormEventHandler<HTMLFormElement> =>
    async e => {
      if (loading) alert('Request already in process');

      setLoading(true);
      setMsg('Minting');
      e.preventDefault();
      try {
        if (token.address === ethers.constants.AddressZero) {
          await provider.send('hardhat_setBalance', [
            accountAddress,
            `0x${(+parseUnits(inputValues[index].main, token.decimals)
              .add(balances?.[index] || 0)
              .toString()).toString(16)}`,
          ]);
        } else if (useMintFunctionFor.includes(token.address)) {
          const contract = new ethers.Contract(token.address, erc20Abi, signer);
          await contract.mint(accountAddress, parseUnits(inputValues[index].main, token.decimals));
        } else {
          const llToken = new ethers.Contract(token.address, llErc20, signer);
          const underlyingToken: string = await llToken.underlying();

          console.log('underlying:', underlyingToken.toLowerCase());
          await provider.send('hardhat_impersonateAccount', [
            whales[underlyingToken.toLowerCase()],
          ]);

          if (
            (await provider.getBalance(whales[underlyingToken.toLowerCase()])).lt(parseEther('10'))
          ) {
            await provider.send('hardhat_setBalance', [
              whales[underlyingToken.toLowerCase()],
              parseEther('10').toHexString(),
            ]);
          }

          const impersonatedSigner = provider.getSigner(whales[underlyingToken.toLowerCase()]);
          const contract = new ethers.Contract(
            underlyingToken.toLowerCase(),
            erc20Abi,
            impersonatedSigner,
          );
          const balance = await contract.balanceOf(await signer?.getAddress());
          console.log('Balance before mint::', +balance / 1e18);
          setMsg('Transferring Funds');
          if (amount && amount > 0) {
            await contract.transfer(accountAddress, parseUnits(amount.toString(), 18));
          } else {
            await contract.transfer(accountAddress, parseUnits(inputValues[index].main, 18));
          }
        }
        // eslint-disable-next-line no-alert
        if (amount && amount > 0) {
          console.log(`${amount.toString()} ${token.symbol} Minted`);
        } else {
          alert(`${inputValues[index].main} ${token.symbol} Minted`);
        }
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert('Minting failed, retry with smaller amount');
        // eslint-disable-next-line no-console
        console.error('mint:', err);
      }
      setLoading(false);
    };

  const extendStaleTime = async () => {
    setLoading(true);
    setMsg(`setting stale time: ${staleDays} days`);
    try {
      await provider.send('hardhat_impersonateAccount', [
        '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
      ]);
      const impersonatedDeployer = provider.getSigner('0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266');
      const oracleContract = new ethers.Contract(oracle[1], oracleAbi, impersonatedDeployer);
      await oracleContract
        .connect(impersonatedDeployer)
        .setMaxStalePeriod((staleDays * 24 * 3600).toString());
      const maxStale = await oracleContract.maxStalePeriod();
      console.log('max stale:: timestamp, days:', +maxStale, staleDays);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  // const rebaseHandler = async () => {
  //   setMsg('Rebasing');
  //   setLoading(true);
  //   await provider.send('hardhat_impersonateAccount', [
  //     '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
  //   ]);
  //   const impersonatedDeployer = provider.getSigner('0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266');

  //   const comptrollerContract = new ethers.Contract(
  //     comptroller[1],
  //     comptrollerAbi,
  //     impersonatedDeployer,
  //   );
  //   await comptrollerContract.rebase();
  //   setMsg('Rebased');
  //   setLoading(false);
  //   console.log('✅rebase::called');
  // };

  const getLToken = async () => {
    setLoading(true);
    setMsg('lToken is being transferred');
    try {
      const accountToImpersonate = '0x23618e81E3f5cdF7f54C3d65f7FBc0aBf5B21E8f';
      await provider.send('hardhat_impersonateAccount', [
        accountToImpersonate, // HH: 8 with ltoken
      ]);
      const impersonatedDeployer = provider.getSigner(accountToImpersonate);
      const ltokenContract = new ethers.Contract(
        MAINNET_TOKENS.ltoken.address,
        llErc20,
        impersonatedDeployer,
      );
      if ((await provider.getBalance(accountToImpersonate)).lt(parseEther('10'))) {
        await provider.send('hardhat_setBalance', [
          accountToImpersonate,
          parseEther('10').toHexString(),
        ]);
      }

      await ltokenContract.transfer(accountAddress, parseEther('10'));
      console.log('🟢Transferred 10 LToken');
    } catch (err) {
      console.error('error at travel5', err);
    }
    setLoading(false);
  };

  const travel5 = async (directHours?: string) => {
    setLoading(true);
    setMsg(`traveling ${directHours ? +directHours : +travelBy} hours`);
    try {
      // await extendStaleTime();
      let localBlock = await getBlockNumber();
      const blockBefore = await provider.getBlock(localBlock);
      console.log('block timestamp:before::timestamp', blockBefore.timestamp);
      if (directHours) {
        await provider.send('evm_increaseTime', [+directHours * 3600]);
      } else {
        await provider.send('evm_increaseTime', [+travelBy * 3600]);
      }

      // await provider.send('evm_mine', [blockBefore.timestamp + add]);
      await provider.send('evm_mine', []);

      localBlock = await getBlockNumber();
      const localBlockAfter = await provider.getBlock(localBlock);
      console.log('timestamp diff:', localBlockAfter.timestamp - blockBefore.timestamp);

      await getBlockNumber();
    } catch (err) {
      console.error('error at travel5', err);
    }
    setLoading(false);

    console.log(`🚀 traveled ${+travelBy} hours`);
  };

  const travel24 = async () => {
    saveSnapshot();
    travel5('24');
  };

  const transferUnderlyingToLtoken = async (lltoken: Token, amount: string) => {
    if (!signer) {
      alert('no signer');
      return;
    }
    setLoading(true);
    setMsg(`transferring underlying ${amount}ethers to ${lltoken.symbol}`);
    const amountInEthers = parseEther(amount);
    console.log('amount in ethers:', +amountInEthers);
    try {
      if ((await provider.getBalance(await signer?.getAddress())).lt(parseEther('10'))) {
        await provider.send('hardhat_setBalance', [
          await signer.getAddress(),
          parseEther('10').toHexString(),
        ]);
      }
      const lltokenContract = new ethers.Contract(lltoken.address, llErc20, signer);
      const underlyingTokenAddr = await lltokenContract.underlying();

      console.log('underlying token::', underlyingTokenAddr);
      const underlyingtoken = new ethers.Contract(underlyingTokenAddr, llErc20, signer);

      const underlyingtokenBalance = await underlyingtoken
        .connect(signer)
        .balanceOf(await signer.getAddress());

      console.log('Underlying token balance', +underlyingtokenBalance / 1e18);
      if (underlyingtokenBalance.lt(amountInEthers)) {
        alert('underlying token balance is less!');
        throw new Error('underlying balance < 1');
      }

      setMsg('transferring');
      (await underlyingtoken.connect(signer).transfer(lltoken.address, amountInEthers)).wait(1);
      console.log('transferred');

      const balance = await underlyingtoken.connect(signer).balanceOf(lltoken.address);
      console.log('lltoken balance::', (+balance + +amountInEthers) / 1e8);
    } catch (err) {
      alert('Err::transferUnderlyingToLtoken');
      // eslint-disable-next-line no-console
      console.error('transferUnderlyingToLtoken:', err);
    }
    setLoading(false);
  };

  const testModeHandler = async (e: any) => {
    setLoading(true);
    setMsg(`setting ${directPriceInfo.asset} = ${directPriceInfo.price}`);
    try {
      const oracleContract = new ethers.Contract(oracle[1], oracleAbi, signer);
      (await oracleContract.setTestMode(e.target.checked)).wait(1);
      setTestMode(!e.target.checked);
    } catch (err) {
      console.error(err);
      alert('Error while setting direct price');
    }
    setLoading(false);
  };

  const setDirectPrice = async () => {
    const price = parseEther(directPriceInfo.price);
    setLoading(true);
    setMsg(`setting ${directPriceInfo.asset} = ${directPriceInfo.price}`);
    const asset = tokens.find(token => token.symbol === directPriceInfo.asset);
    try {
      const oracleContract = new ethers.Contract(oracle[1], oracleAbi, signer);
      const lltokenContract = new ethers.Contract(
        asset?.address ? asset?.address : '',
        llErc20,
        signer,
      );
      const underlyingToken = await lltokenContract.underlying();
      setMsg(`setting underlying token price in ${price.toString()} Ether`);
      console.log(
        `setting lltoken's underlying price: ${
          asset?.address
        } ${underlyingToken} = ${price.toString()}`,
      );
      (await oracleContract.setDirectPrice(underlyingToken, price, true)).wait(1);
    } catch (e) {
      console.error(e);
      alert('Error while setting direct price');
    }
    setLoading(false);
  };

  const init = async () => {
    try {
      const oracleContract = new ethers.Contract(oracle[1], oracleAbi, provider);
      const isInTestMode = await oracleContract.testMode();
      console.log('IN TEST MODE::', isInTestMode);
      setTestMode(isInTestMode);
    } catch (err) {
      console.log('error at init:: getting Oracle.isTestMode', err);
    }
    await getBlockNumber();
    await getUnderlyingPriceInfoHandler();
  };

  const setEthDirectPriceInUsd = async () => {
    const price = parseEther(ethPriceInUsd);
    setLoading(true);
    setMsg(`setting Eth Price ${ethPriceInUsd}$ *1e18`);

    try {
      const oracleContract = new ethers.Contract(oracle[1], oracleAbi, signer);
      console.log(`setting eth price: ${price.toString()}`);
      const isInTestMode = await oracleContract.testMode();
      setTestMode(isInTestMode);
      if (!isInTestMode) {
        alert('TEST_MODE::DISABLED');
        console.log('TEST MODE: DISABLED');
        return;
      }
      await oracleContract.setDirectPrice(
        '0x0000000000000000000000000000000000000000',
        price,
        false,
      );
      console.log('ETH price set::', price.toString());
    } catch (e) {
      console.error(e);
      alert('Error while setting direct price');
    }
    setLoading(false);
  };

  const addLiquidity =
    (lpToken: Token): React.FormEventHandler<HTMLFormElement> =>
    async e => {
      e.preventDefault();
      if (!signer) {
        alert('no signer');
        return;
      }
      setLoading(true);
      try {
        const uniswapRouter = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';

        await provider.send('hardhat_impersonateAccount', [await signer.getAddress()]);
        const impersonatedSigner = provider.getSigner(await signer.getAddress());

        if ((await provider.getBalance(await signer?.getAddress())).lt(parseEther('10'))) {
          await provider.send('hardhat_setBalance', [
            await signer.getAddress(),
            parseEther('10').toHexString(),
          ]);
        }

        const latestBlock = await provider.getBlockNumber();
        const now = (await provider.getBlock(latestBlock)).timestamp;
        const tenMinsFromNow = now + 60 * 60 * 10;

        // pair abi + pair address
        const lpPair = await new ethers.Contract(
          lpToken.address,
          uniswapV2PairAbi,
          impersonatedSigner,
        );

        const token0 = await lpPair.token0();
        const token1 = await lpPair.token1();

        console.log('token0 addr', token0);
        console.log('token1 addr', token1);

        const token0Contract = new ethers.Contract(token0, llErc20, impersonatedSigner);
        const token1Contract = new ethers.Contract(token1, erc20Abi, impersonatedSigner);

        const token0decimals: BigNumber = await token0Contract.decimals();
        const token1decimals: BigNumber = await token1Contract.decimals();

        console.log('token0decimals addr', token0decimals.toString());
        console.log('token1decimals addr', token1decimals.toString());

        const token0Balance: BigNumber = await token0Contract.balanceOf(await signer.getAddress());
        console.log('token0 balance:', +token0Balance, +token0decimals);
        if (+token0Balance < 10 ** +token0decimals) {
          console.log('error:', +token0Balance, 10 ** +token0decimals);
          alert(`mint ${token0} for addLiquidity`);
          throw new Error(`token0: ${token0} balance < 1`);
        }

        const token1Balance: BigNumber = await token1Contract.balanceOf(await signer.getAddress());
        console.log('token1 balance:', token1Balance.toString());
        if (+token1Balance < 10 ** +token1decimals) {
          console.log('error:', +token1Balance, 10 ** +token1decimals);

          alert(`mint ${token1} for addLiquidity`);
          throw new Error(`token1: ${token1} balance < 1`);
        }

        const uniswapRouterContract = new ethers.Contract(
          uniswapRouter,
          uniswapPoolAbi,
          impersonatedSigner,
        );
        console.log('uniswapRouterContract', uniswapRouterContract.address);

        await token0Contract.connect(impersonatedSigner).approve(uniswapRouter, parseEther('1000'));
        await token1Contract.connect(impersonatedSigner).approve(uniswapRouter, parseEther('1000'));

        const token0Allowance = await token0Contract
          .connect(impersonatedSigner)
          .allowance(await impersonatedSigner?.getAddress(), uniswapRouter);

        const token1Allowance = await token1Contract
          .connect(impersonatedSigner)
          .allowance(await impersonatedSigner?.getAddress(), uniswapRouter);

        console.log('@allowance: lltokenAllowance', +token0Allowance);
        console.log('@allowance: wethTokenAllowance', +token1Allowance);

        let reserves = await lpPair.getReserves();
        console.log('PAIR:: reserve0', +reserves[0]);
        console.log('PAIR:: reserve1', +reserves[1]);
        const signerAddr = await signer.getAddress();
        setLoading(true);
        setMsg('Adding Liquidity');

        console.log(
          'add liquidity',
          uniswapRouterContract.address,
          await impersonatedSigner.getAddress(),
        );

        const amount0 = new BigNumber(10 ** +token0decimals);
        const amount1 = new BigNumber(10 ** +token1decimals);

        console.log('amount0:', amount0, amount0.toString());
        console.log('amount1:', amount1, amount1.toString());

        await uniswapRouterContract.connect(impersonatedSigner).addLiquidity(
          token0,
          token1,
          amount0.toString(),
          amount1.toString(),
          0, // minimum
          0, // minimum
          signerAddr,
          tenMinsFromNow, // deadline for this tx
        );

        reserves = await lpPair.getReserves();
        console.log('PAIR:: reserve0::after', +reserves[0]);
        console.log('PAIR:: reserve1::after', +reserves[1]);

        const lltokenWethPairBalance = await lpPair.balanceOf(signerAddr);
        console.log('@pair balance of deployer:', +lltokenWethPairBalance / 1e18);
      } catch (err) {
        alert('Minting failed, retry with smaller amount');
        // eslint-disable-next-line no-console
        console.error('mint:', err);
      }
      setLoading(false);
    };

  useEffect(() => {
    init();
    const interval = setInterval(() => {
      if (signer && provider) {
        init();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Paper>
      <ConnectWallet message="Connect wallet to mint tokens">
        <Box display="flex" flex={1} flexDirection="row" gap={2}>
          <Box flex={1} display="flex" flexDirection="column" flexWrap="wrap" gap="3">
            <Typography variant="h1">Faucet</Typography>
            <Typography variant="body2">
              block timestamp {new Date(currTimestamp * 1000).toUTCString()} - {currTimestamp}
              <br />
              <b>NOTE::Do another snapshot & save, once you revert</b>
              {userSavedSnapshot && `Your Last saved snapshot: ${userSavedSnapshot}`}
            </Typography>
            <Button variant="primary" style={{ maxWidth: '200px' }} onClick={saveSnapshot}>
              save snapshot
            </Button>
            <br />

            <Box display="flex" flexDirection="row" gap="1em">
              <Box display="flex" flexDirection="column" gap="1em">
                <Input
                  value={travelBy}
                  placeholder="set travelBy time +/-"
                  onChange={({ target }) => setTravelBy(() => target.value)}
                />

                <Button onClick={() => travel5()} variant="primary">
                  <span role="img" aria-label="rocket">
                    🚀
                  </span>{' '}
                  Travel by hours
                </Button>
              </Box>
              <Box display="flex" flexDirection="column" gap="1em">
                <Input
                  value={staleDays}
                  placeholder="Amount to mint"
                  onChange={({ target }) => setStaleDays(() => +target.value)}
                />
                <Button onClick={extendStaleTime}>Set Stale Days</Button>
              </Box>
              <Box display="flex" flexDirection="column" gap="1em">
                <Input
                  value={revertSnapshotId}
                  placeholder="Revert To Snapshot Id"
                  onChange={({ target }) => setRevertSnapshotId(() => target.value)}
                />

                <Button onClick={revertSnapshot} variant="primary">
                  Revert to Snapshot
                </Button>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flex={1}>
            {rebaseInfo.symbol && (
              <Typography
                variant="body1"
                style={{
                  padding: '1em',
                  margin: '1em 0',
                  width: '100%',
                  border: '1px solid grey',
                  borderRadius: '16px',
                  textAlign: 'center',
                  background: 'rgba(0,0,0,0.2)',
                }}
              >
                <h2>Rebase Info:</h2> token: <b> {rebaseInfo.symbol}</b> <br /> MR:{' '}
                <b> ${(+rebaseInfo.mr / 1e18).toFixed(4)}</b>
                <br /> DSD: <b>${(+rebaseInfo.dsd / 1e18).toFixed(4)}</b>
                <br />
                Fund: <b>${(+rebaseInfo.fund / 1e18).toFixed(4)}</b>
              </Typography>
            )}

            <Box
              mt={2}
              border="1px solid grey"
              style={{ borderRadius: '1em' }}
              p={4}
              width="400px"
              flexDirection="column"
              display="flex"
              flexWrap="wrap"
              gap={4}
            >
              <Typography align="center" variant="h3">
                Oracle
              </Typography>
              <FormControlLabel
                control={<Switch onChange={testModeHandler} checked={testMode} />}
                label="Test Mode"
              />
              <Input
                value={directPriceInfo.price}
                placeholder="set lltoken's underlying direct price in ethers"
                onChange={({ target }) => setDirectPriceInfo(v => ({ ...v, price: target.value }))}
              />
              <Input
                value={ethPriceInUsd}
                placeholder="set ETH price USD: 1 -> 1e18"
                onChange={({ target }) => setEthPriceInUsd(() => target.value)}
              />
              select lltoken for setting direct price:
              <Select
                id="Direct Price Asset selector"
                value={directPriceInfo.asset}
                onChange={({ target }) => setDirectPriceInfo(v => ({ ...v, asset: target.value }))}
              >
                {tokens.map(token => (
                  <MenuItem key={token.symbol} value={token.symbol}>
                    {token.symbol}
                  </MenuItem>
                ))}
              </Select>
              <Button disabled={loading} variant="secondary" onClick={setDirectPrice}>
                set direct price (lltoken)
              </Button>
              <Button disabled={loading} variant="secondary" onClick={setEthDirectPriceInUsd}>
                set ETH Price in USD: requires Test Mode
              </Button>
            </Box>
          </Box>
        </Box>
        <br />
        <Box display="flex" flexDirection="row" gap={4}>
          <Button variant="primary" style={{ maxWidth: '200px' }} onClick={getLToken}>
            Get some Ltoken
          </Button>

          <Button variant="secondary" style={{ maxWidth: '200px' }} onClick={travel24}>
            time travel a day
          </Button>
        </Box>
        <br />

        {loading && (
          <Typography
            align="center"
            variant="body1"
            style={{
              border: '1px solid grey',
              padding: '1em 0',
              margin: '1em 0',
              borderRadius: '16px',
              background: 'rgba(0,0.4,0,0.5)',
            }}
          >
            <Spinner /> {msg}
          </Typography>
        )}

        <Box display="flex" flexWrap="wrap" gap={4} mr={2}>
          {tokens.map((token, index) => (
            <Paper
              component="form"
              style={{ padding: 16 }}
              key={token.address}
              onSubmit={mint(token, index)}
            >
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{token.symbol}</Typography>
                <BscLink hash={token.address} urlType="address" />
                <Typography>
                  Balance:{' '}
                  {new BigNumber(balances?.[index].toString() || 0)
                    .dividedBy(10 ** token.decimals)
                    .toFixed(3)}{' '}
                  {token.symbol}
                </Typography>
                <Typography>
                  Underlying:{' '}
                  {new BigNumber(underlyingBalance?.[index].toString() || 0)
                    .dividedBy(10 ** 18)
                    .toFixed(3)}{' '}
                  {token.symbol.slice(2)}
                </Typography>

                <Typography variant="h4">Prices:</Typography>

                <Typography>
                  in ETH: {/* {JSON.stringify(priceInEth)} */}
                  {+priceInEth[index] === 0 && '0.00'}
                  {+priceInEth[index] > 0 && +priceInEth?.[index]
                    ? (+priceInEth?.[index] / 1e18).toFixed(3)
                    : '...'}
                </Typography>

                <Typography>
                  in USD:{' $'}
                  {+priceInUsd[index] === 0 && '0.00'}
                  {+priceInUsd[index] > 0 && +priceInUsd?.[index]
                    ? (+priceInUsd?.[index] / 1e18).toFixed(3)
                    : '...'}
                </Typography>
                <Input
                  value={inputValues[index].main}
                  placeholder="Amount to mint"
                  onChange={({ target }) =>
                    setInputValues(v =>
                      v.map((value, i) => (i === index ? { ...value, main: target.value } : value)),
                    )
                  }
                />

                <Input
                  value={inputValues[index].underlying}
                  placeholder="transfer underlying to lltoken: in ethers i.e: 1.2, 0.002"
                  onChange={({ target }) =>
                    setInputValues(v =>
                      v.map((value, i) =>
                        i === index ? { ...value, underlying: target.value } : value,
                      ),
                    )
                  }
                />

                <RadioGroup
                  aria-labelledby={`lslend-radio-buttons-group-label-${index}`}
                  // checked={}
                  value={rewardValues[index].token}
                  name={`radio-buttons-group-${index}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setRewardValues(v =>
                      v.map((value, i) =>
                        i === index ? { ...value, token: event.target.value } : value,
                      ),
                    );
                  }}
                  row
                >
                  <FormControlLabel value="CRV" control={<Radio />} label="CRV" />
                  <FormControlLabel value="AURA" control={<Radio />} label="AURA" />
                  <FormControlLabel value="BAL" control={<Radio />} label="BAL" />
                  <FormControlLabel value="CVX" control={<Radio />} label="CVX" />
                </RadioGroup>

                <Button
                  disabled={loading}
                  onClick={() => transferUnderlyingToLtoken(token, inputValues[index].underlying)}
                >
                  Transfer underlying
                </Button>
                <Button
                  disabled={loading || !rewardValues[index].token}
                  onClick={() => transferRewardToLtokenStrategy(token, rewardValues[index].token)}
                >
                  Transfer reward to strategy
                </Button>
                <Box display="flex" gap={2}>
                  <Button disabled={loading} variant="secondary" type="submit">
                    Mint
                  </Button>
                  <Button
                    disabled={loading}
                    variant="secondary"
                    onClick={() => llTokenRebaseHandler(token)}
                  >
                    rebase
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    disabled={!token.asset}
                    onClick={() => addTokenToWallet(token)}
                  >
                    Add to Wallet
                  </Button>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>

        {/* ALL Uniswap Lps */}
        <hr />
        {allUniswapLp.length && (
          <Typography variant="h2" align="center">
            Uniswap
          </Typography>
        )}
        <Box display="flex" flexWrap="wrap" gap={4}>
          {allUniswapLp &&
            allUniswapLp.map((token, index) => (
              <Paper
                component="form"
                style={{ padding: 4 }}
                key={token?.address}
                onSubmit={addLiquidity(token)}
              >
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="h5">{token.symbol}</Typography>
                  <BscLink hash={token.address} urlType="address" />
                  <Typography>
                    Balance:
                    {/* {lpBalances && lpBalances[0][token.symbol]
                      ? lpBalances[0][token.symbol]?.then((val: BigNumber) => +val)
                      : '-'} */}
                    {new BigNumber(lpBalances?.[index].toString() || 0)
                      .dividedBy(10 ** token.decimals)
                      .toFixed(10)}{' '}
                  </Typography>
                  <Box display="flex" gap={2}>
                    <Button disabled={loading} variant="secondary" type="submit">
                      Add Liquidity
                    </Button>
                    <Button
                      type="button"
                      variant="text"
                      disabled={!token.asset}
                      onClick={() => addTokenToWallet(token)}
                    >
                      Metamask
                    </Button>
                  </Box>
                </Box>
              </Paper>
            ))}
        </Box>
      </ConnectWallet>
    </Paper>
  );
};

export default Faucet;
