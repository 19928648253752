import { Provider } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { formatToReadablePercentage, formatTokensToReadableValue } from 'utilities';

import { useGetMainMarkets } from 'clients/api';
import { useTokenContract } from 'clients/contracts';
import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';

const getChainLinkPricefeed = (provider: Provider) =>
  new ethers.Contract(
    '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
    [
      {
        inputs: [],
        name: 'latestAnswer',
        outputs: [{ internalType: 'int256', name: '', type: 'int256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    provider,
  );

const useGetData = () => {
  const dsdContract = useTokenContract(TOKENS.dsd);
  const { data: marketsData, isLoading } = useGetMainMarkets();
  const { provider } = useAuth();
  const priceFeedContract = getChainLinkPricefeed(provider);
  const { data: ethPrice } = useQuery(
    ['eth-feed-price'],
    async () => {
      const ethPriceUSD = await priceFeedContract.latestAnswer();
      return new BigNumber(ethPriceUSD.toString()).div(1e8).toFixed(2);
    },
    {
      refetchInterval: 30000,
    },
  );

  const { data: circulatingDsd, isLoading: isCirculatingDsdloading } = useQuery(
    ['dsd-totalSupply'],
    async (): Promise<BigNumber> => {
      const totalSupplyDsd = await dsdContract.totalSupply();
      return new BigNumber(totalSupplyDsd.toString()).div(1e18);
    },
    {
      refetchInterval: 30000,
    },
  );

  const data = useMemo(() => {
    if (!isLoading && !isCirculatingDsdloading && marketsData && marketsData.markets) {
      const totalSupplyUSD: BigNumber = marketsData.markets.reduce(
        (amt: BigNumber, token: { totalSupplyUsd: BigNumber.Value }) =>
          amt.plus(token.totalSupplyUsd),
        new BigNumber(0),
      );

      return {
        circulatingDsd: formatTokensToReadableValue({
          value: new BigNumber(circulatingDsd || 0),
          token: TOKENS.dsd,
          minimizeDecimals: true,
          shortenLargeValue: true,
        }),
        totalSupplyInETH: formatTokensToReadableValue({
          value: totalSupplyUSD.div(ethPrice || 1),
          token: TOKENS.eth,
          minimizeDecimals: true,
          shortenLargeValue: true,
        }),
        overallCR: formatToReadablePercentage(
          new BigNumber(circulatingDsd || 0).div(totalSupplyUSD).multipliedBy(100),
        ),
      };
    }
    return {
      circulatingDsd: '-.-',
      totalSupplyInETH: '-.-',
      overallCR: '-.-',
    };
  }, [marketsData, ethPrice, circulatingDsd]);

  return [
    {
      title: `${brand.stableCoinSymbol} in Circulation`,
      info: 'Total amount of DSD minted.',
      value: data.circulatingDsd || '-.-',
    },
    {
      title: 'Overall CR',
      info: 'The collateral ratio of the protocol or the ratio of value of all minted DSD to value of all collateral deposited.',
      value: data.overallCR || '-.-',
    },
    {
      title: `${brand.currencySymbol} in Collateral`,
      info: 'Current value of all combined collateral, expressed in terms of ETH.',
      value: data.totalSupplyInETH || '-.-',
    },
  ];
};

export default useGetData;
