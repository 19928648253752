import BigNumber from 'bignumber.js';

import { EsLTOKEN } from 'types/contracts';

export interface GetLtokenWithdrawableAmountInput {
  esLTOKENContract: EsLTOKEN;
  accountAddress: string;
}

export interface GetLtokenWithdrawableAmountOutput {
  totalWithdrawableAmount: BigNumber;
  totalVestedAmount: BigNumber;
  totalWithdrawnAmount: BigNumber;
}

const getLtokenWithdrawableAmount = async ({
  esLTOKENContract,
  accountAddress,
}: GetLtokenWithdrawableAmountInput): Promise<GetLtokenWithdrawableAmountOutput> => {
  const resp = await esLTOKENContract.getRedeemableAmount(accountAddress);
  return {
    totalWithdrawableAmount: new BigNumber(resp.totalWithdrawableAmount.toString()),
    totalVestedAmount: new BigNumber(resp.totalVestedAmount.toString()),
    totalWithdrawnAmount: new BigNumber(resp.totalWithdrawnAmount.toString()),
  };
};

export default getLtokenWithdrawableAmount;
