import { useMemo } from 'react';
// import { getContractAddress } from 'utilities';

import brand from 'config/brand';
import { routes } from 'constants/routing';
import { useAuth } from 'context/AuthContext';

import { MenuItem } from '../types';

const useGetMenuItems = () => {
  const { accountAddress } = useAuth();
  // const MAIN_POOL_COMPTROLLER_ADDRESS = getContractAddress('comptroller');

  return useMemo(() => {
    const menuItems: MenuItem[] = [
      {
        href: routes.dashboard.path,
        // Translation key: do not remove this comment
        // t('layout.menuItems.dashboard')
        i18nKey: 'layout.menuItems.dashboard',
        icon: 'dashboard',
      },
    ];

    // // Insert account page if wallet is connected
    // if (accountAddress || isReconnecting) {
    //   menuItems.push({
    //     href: routes.account.path,
    //     // Translation key: do not remove this comment
    //     // t('layout.menuItems.account')
    //     i18nKey: 'layout.menuItems.account',
    //     icon: 'person',
    //   });
    // }

    menuItems.push({
      href: routes.markets.path,
      // Translation key: do not remove this comment
      // t('layout.menuItems.markets')
      i18nKey: 'layout.menuItems.markets',
      icon: 'market',
    });

    menuItems.push(
      {
        href: routes.addCollateral.path,
        // Translation key: do not remove this comment
        // t('layout.menuItems.vaults')
        i18nKey: 'layout.menuItems.add-collateral',
        icon: 'vault',
      },
      // {
      //   href: routes.swap.path,
      //   // Translation key: do not remove this comment
      //   // t('layout.menuItems.swap')
      //   i18nKey: 'layout.menuItems.swap',
      //   icon: 'convert',
      // },
      // {
      //   href: routes.history.path,
      //   // Translation key: do not remove this comment
      //   // t('layout.menuItems.history')
      //   i18nKey: 'layout.menuItems.history',
      //   icon: 'history',
      // },
      // {
      //   href: routes.governance.path,
      //   // Translation key: do not remove this comment
      //   // t('layout.menuItems.governance')
      //   i18nKey: 'layout.menuItems.governance',
      //   icon: 'vote',
      // },
      // {
      //   href: routes.ltoken.path,
      //   // Translation key: do not remove this comment
      //   // t('layout.menuItems.ltoken')
      //   i18nKey: 'layout.menuItems.ltoken',
      //   icon: 'ltokenOutlined',
      // },
      {
        href: routes.farm.path,
        // Translation key: do not remove this comment
        // t('layout.menuItems.convertVrt')
        // t('layout.menuItems.convertVrtTitle')
        i18nKey: 'layout.menuItems.farm',
        icon: 'market',
      },
      {
        href: routes.dsd.path,
        // Translation key: do not remove this comment
        // t('layout.menuItems.dsd')
        i18nKey: 'layout.menuItems.dsd',
        i18nVars: {
          stableCoinToken: brand.stableCoinSymbol,
        },
        icon: 'dsdOutline',
      },
      {
        href: routes.vest.path,
        // Translation key: do not remove this comment
        // t('layout.menuItems.convertVrt')
        // t('layout.menuItems.convertVrtTitle')
        i18nKey: 'layout.menuItems.vest',
        i18nVars: {
          rewardToken: brand.rewardTokenSymbol,
        },
        icon: 'convert',
      },
      // {
      //   href: routes.stake.path,
      //   // Translation key: do not remove this comment
      //   // t('layout.menuItems.convertVrt')
      //   // t('layout.menuItems.convertVrtTitle')
      //   i18nKey: 'layout.menuItems.stake',
      //   i18nVars: {
      //     rewardToken: brand.rewardTokenSymbol,
      //   },
      //   icon: 'convert',
      // },
      // {
      //   href: routes.redeem.path,
      //   // Translation key: do not remove this comment
      //   // t('layout.menuItems.convertVrt')
      //   // t('layout.menuItems.convertVrtTitle')
      //   i18nKey: 'layout.menuItems.redeem',
      //   icon: 'convert',
      // },

      {
        href: routes.migrate.path,
        // Translation key: do not remove this comment
        // t('layout.menuItems.convertVrt')
        // t('layout.menuItems.convertVrtTitle')
        i18nKey: 'layout.menuItems.migrate',
        icon: 'convert',
      },

      // {
      //   href: 'https://prdt.finance/Application/Pro/BSC?partnerCode=Venus',
      //   // Translation key: do not remove this comment
      //   // t('layout.menuItems.predictions')
      //   i18nKey: 'layout.menuItems.predictions',
      //   icon: 'predictions',
      // },
    );

    return menuItems;
  }, [accountAddress]);
};

export default useGetMenuItems;
