import BigNumber from 'bignumber.js';
import { Multicall } from 'ethereum-multicall';
import { LLToken } from 'types';
import { getLLTokenByAddress } from 'utilities';

import vErc20Abi from 'constants/contracts/abis/vErc20.json';
import { LLERC_TOKENS } from 'constants/tokens';

export interface RedeemInfo {
  lltoken: LLToken | undefined;
  redeemableInEther: BigNumber;
  redeemableInUnderlying: BigNumber;
}

const getRedeemDsdData = async (multicall: Multicall): Promise<RedeemInfo[]> => {
  const callsReturnContext = Object.keys(LLERC_TOKENS).map((lltoken: string) => ({
    reference: lltoken,
    contractAddress: lltoken,
    abi: vErc20Abi,
    calls: [
      {
        reference: 'redeemableInEther',
        methodName: 'redeemableInEther',
        methodParameters: [],
      },
      {
        reference: 'redeemableInUnderlying',
        methodName: 'redeemableInUnderlying',
        methodParameters: [],
      },
    ],
  }));

  const { results } = await multicall.call(callsReturnContext);
  const redeemInfo: RedeemInfo[] = Object.values(results).map(lltoken => ({
    lltoken: getLLTokenByAddress(lltoken.originalContractCallContext.contractAddress),
    redeemableInEther: new BigNumber(lltoken.callsReturnContext[0].returnValues[0].hex || 0),
    redeemableInUnderlying: new BigNumber(lltoken.callsReturnContext[1].returnValues[0].hex || 0),
  }));

  return redeemInfo;
};

export default getRedeemDsdData;
