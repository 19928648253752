import { QueryObserverOptions, useQuery } from 'react-query';

import GetEsltokenFees, {
  GetEsltokenFeesInput,
  GetEsltokenFeesOutput,
} from 'clients/api/queries/getEsLTOKENFees';
import { useLsFundContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetEsltokenFeesOutput,
  Error,
  GetEsltokenFeesOutput,
  GetEsltokenFeesOutput,
  FunctionKey.GET_ESLTOKEN_FEES
>;

const useGetEsLTOKENFees = (
  { accountAddress }: Omit<GetEsltokenFeesInput, 'lsFundContract'>,
  options?: Options,
) => {
  const lsFundContract = useLsFundContract();

  return useQuery(
    FunctionKey.GET_ESLTOKEN_FEES,
    () => GetEsltokenFees({ accountAddress, lsFundContract }),
    options,
  );
};

export default useGetEsLTOKENFees;
