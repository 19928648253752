import { MutationObserverOptions, useMutation } from 'react-query';

import { queryClient, stakeInFarm } from 'clients/api';
import { useFarmContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

import { StakeInFarmInput, StakeInFarmOutput } from '.';

type Options = MutationObserverOptions<
  StakeInFarmOutput,
  Error,
  Omit<StakeInFarmInput, 'farmContract'>
>;

const useStakeInFarm = (
  farmAddress: string,
  stakedToken: string,
  rewardToken: string,
  options?: Options,
) => {
  const farmContract = useFarmContract(farmAddress);

  return useMutation(
    FunctionKey.STAKE_IN_FARM,
    (params: Omit<StakeInFarmInput, 'farmContract'>) =>
      stakeInFarm({
        farmContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        const accountAddress = await farmContract.signer.getAddress();

        // Invalidate cached user info, including pending reward
        queryClient.invalidateQueries([FunctionKey.GET_DSD_VAULT_USER_INFO, accountAddress]);

        // Invalidate cached user balance
        queryClient.invalidateQueries([
          FunctionKey.GET_BALANCE_OF,
          {
            accountAddress,
            tokenAddress: stakedToken,
          },
        ]);
        queryClient.invalidateQueries([
          FunctionKey.GET_BALANCE_OF,
          {
            accountAddress,
            tokenAddress: rewardToken,
          },
        ]);

        queryClient.invalidateQueries([
          FunctionKey.GET_TOKEN_BALANCES,
          {
            accountAddress,
          },
        ]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useStakeInFarm;
