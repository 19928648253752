import { MutationObserverOptions, useMutation } from 'react-query';

import { IRepayDsdOutput, RepayDsdInput, queryClient, repayDsd } from 'clients/api';
import { useDsdControllerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
  IRepayDsdOutput,
  Error,
  Omit<RepayDsdInput, 'dsdControllerContract'>
>;

const useRepayDsd = (options?: Options) => {
  const dsdControllerContract = useDsdControllerContract();

  return useMutation(
    FunctionKey.REPAY_DSD,
    (params: Omit<RepayDsdInput, 'dsdControllerContract'>) =>
      repayDsd({
        dsdControllerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // Invalidate queries related to fetching the user minted DSD amount
        queryClient.invalidateQueries(FunctionKey.GET_MINTED_DSD);
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useRepayDsd;
