import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import { useAuth } from 'context/AuthContext';

import useGetPools from '../useGetPools';

const useGetUnderlyingPriceAllToken = () => {
  const { accountAddress } = useAuth();
  const { data: getPools, isLoading } = useGetPools({ accountAddress });
  const tokenPrices = useMemo(() => {
    if (!getPools) return {};
    const allTokenPrices = getPools.pools[0].assets.reduce(
      (obj, token) => ({ ...obj, [token.llToken.underlyingToken.address]: token.tokenPriceDollars }),
      {} as Record<string, BigNumber>,
    );
    return allTokenPrices;
  }, [getPools, accountAddress]);

  return {
    tokenPrices,
    isLoading,
  };
};

export default useGetUnderlyingPriceAllToken;

// var object = arr.reduce(
// (obj, item) => (obj[item.key] = item.value, obj) ,
// {}
// );
