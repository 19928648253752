/* eslint-disable arrow-body-style */

/** @jsxImportSource @emotion/react */
import React from 'react';
import { MigrationPool } from 'types';

import { MigratorTable } from 'containers/MigratorTable';

export interface TableProps {
  pools: MigrationPool[];
}

export const Table: React.FC<TableProps> = ({ pools }) => {
  console.log(pools);
  return (
    <MigratorTable
      breakpoint="xl"
      pools={pools}
      columns={[
        'protocol',
        'lpToken',
        'userWalletBalance',
        'userSupplyBalance',
        'differenceApy',
        'differenceLimit',
        'newAPY',
        'migrate',
      ]}
      initialOrder={{
        orderBy: 'userSupplyBalance',
        orderDirection: 'desc',
      }}
    />
  );
};

export default Table;
