import BigNumber from 'bignumber.js';
import { useQuery } from 'react-query';
import { getContractAddress } from 'utilities';

import { getHypotheticalAccountLiquidity } from 'clients/api';
import { useComptrollerContract } from 'clients/contracts';
import { useAuth } from 'context/AuthContext';

const useGetHypotheticalAccountLiquidity = (
  llTokenAddress: string,
  llTokenBalanceOfWei: BigNumber,
  llTokenBorrowAmountWei: BigNumber,
) => {
  const { accountAddress } = useAuth();
  const comptrollerAddress = getContractAddress('comptroller');
  const comptrollerContract = useComptrollerContract(comptrollerAddress);

  return useQuery(['GET_ACCOUNT_LIQUIDITY'], () =>
    getHypotheticalAccountLiquidity({
      comptrollerContract,
      accountAddress,
      llTokenAddress,
      llTokenBalanceOfWei,
      llTokenBorrowAmountWei,
    }),
  );
};

export default useGetHypotheticalAccountLiquidity;
