import { checkForComptrollerTransactionError } from 'errors/transactionErrors';

import { ClaimDSDRewardInput, ClaimDSDRewardOutput } from './types';

export * from './types';

const claimDSDRewards = async ({
  lsFundContract,
}: ClaimDSDRewardInput): Promise<ClaimDSDRewardOutput> => {
  const transaction = await lsFundContract.getReward();
  const receipt = await transaction.wait(1);
  checkForComptrollerTransactionError(receipt);
  return receipt;
};

export default claimDSDRewards;
