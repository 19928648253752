/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import { Button, Table, TableProps } from 'components';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Pool } from 'types';

import { useGetApyData } from 'clients/api';
import useGetGroups from 'components/Layout/ClaimMultiRewardButton/useGetGroupsArray';
import brand from 'config/brand';
import { routes } from 'constants/routing';
import useBorrowRepayModal from 'hooks/useBorrowRepayModal';
import useSupplyWithdrawModal from 'hooks/useSupplyWithdrawModal';

import { useStyles } from './styles';
import { ColumnKey, PoolAsset } from './types';
import useGenerateColumns from './useGenerateColumns';

export interface MarketTableProps
  extends Partial<
      Omit<TableProps<PoolAsset>, 'columns' | 'rowKeyIndex' | 'breakpoint' | 'initialOrder'>
    >,
    Pick<TableProps<PoolAsset>, 'breakpoint'> {
  pools: Pool[];
  columns: ColumnKey[];
  initialOrder?: {
    orderBy: ColumnKey;
    orderDirection: 'asc' | 'desc';
  };
  marketType?: 'supply' | 'borrow';
  className?: string;
  onlyMyCollaterals?: boolean;
  testId?: string;
}

export const MarketTable: React.FC<MarketTableProps> = ({
  pools,
  marketType,
  columns: columnKeys,
  getRowHref,
  initialOrder,
  testId,
  onlyMyCollaterals,
  ...otherTableProps
}) => {
  const styles = useStyles();

  const { BorrowRepayModal } = useBorrowRepayModal();
  const { SupplyWithdrawModal, openSupplyWithdrawModal } = useSupplyWithdrawModal();

  // const { hasLunaOrUstCollateralEnabled, openLunaUstWarningModal } = useContext(
  //   DisableLunaUstWarningContext,
  // );

  const poolAssets = useMemo(
    () =>
      pools.reduce((acc, pool) => {
        const newPoolAssets: PoolAsset[] = pool.assets
          .filter(asset => {
            if (!onlyMyCollaterals) return true;
            return onlyMyCollaterals && asset.userSupplyBalanceTokens.toNumber() > 0;
          })
          .map(asset => ({
            ...asset,
            pool,
          }));

        return acc.concat(newPoolAssets);
      }, [] as PoolAsset[]),
    [pools],
  );

  const withdrawModalOpen = (row: PoolAsset, onlySupply?: boolean, onlyWithdraw?: boolean) => {
    // Block action and show warning modal if user has LUNA or UST enabled as
    // collateral and is attempting to open the supply modal of other assets

    // Do nothing if user clicked on switch (the switch element will handle the
    // click event)

    // if (marketType === 'borrow') {
    //   openBorrowRepayModal({
    //     llToken: row.llToken,
    //     poolComptrollerAddress: row.pool.comptrollerAddress,
    //   });
    // } else {
    openSupplyWithdrawModal({
      llToken: row.llToken,
      poolComptrollerAddress: row.pool.comptrollerAddress,
      onlySupply,
      onlyWithdraw,
    });
    // }
  };

  // minor fix as lltoken is Constant:: can redo too.
  const lltokens = useMemo(() => poolAssets.map(({ llToken }) => llToken), [poolAssets.length]);

  const { data: apyData } = useGetApyData();
  const { data: groups } = useGetGroups({ lltokens });

  const columns = useGenerateColumns({
    poolAssets,
    columnKeys,
    withdrawModalOpen,
    apyData,
    groups,
    collateralOnChange: () => {},
  });

  const formattedInitialOrder = useMemo(() => {
    if (!initialOrder) {
      return undefined;
    }

    const oderByColumn = columns.find(column => column.key === initialOrder.orderBy);

    return (
      oderByColumn && {
        orderBy: oderByColumn,
        orderDirection: initialOrder.orderDirection,
      }
    );
  }, [columns, initialOrder]);

  return (
    <div data-testid={testId} css={styles.body(poolAssets.length > 0)}>
      {poolAssets.length === 0 && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1em',
            minHeight: '400px',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h4">You do not have any opened positions</Typography>
          <Typography variant="body2">
            {brand.name} uses novel technology to help you continue earning supplied asset&apos;s
            yield while freeing its value in the form of ${brand.stableCoinSymbol}
          </Typography>
          <Link css={styles.link} to={routes.addCollateral.path}>
            <Button variant="primary">+Add position</Button>
          </Link>
        </div>
      )}

      {poolAssets.length > 0 && (
        <>
          <Table
            columns={columns}
            data={poolAssets}
            css={styles.cardContentGrid}
            rowKeyExtractor={row => `market-table-row-${marketType}-${row.llToken.address}`}
            // rowOnClick={getRowHref ? undefined : rowOnClick}
            rowOnClick={() => {}}
            getRowHref={getRowHref}
            onlyMyCollaterals={onlyMyCollaterals}
            initialOrder={formattedInitialOrder}
            {...otherTableProps}
          />

          <BorrowRepayModal />
          <SupplyWithdrawModal />
        </>
      )}
    </div>
  );
};
