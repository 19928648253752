import { MutationObserverOptions, useMutation } from 'react-query';

import { queryClient } from 'clients/api';
import { useEsLTOKENContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

import convertEsLTOKEN, { ConvertEsLTOKENInput, ConvertEsLTOKENOutput } from '.';

const useConvertEsLTOKEN = (
  options?: MutationObserverOptions<
    ConvertEsLTOKENOutput,
    Error,
    Omit<ConvertEsLTOKENInput, 'esLTOKENContract'>
  >,
) => {
  const esLTOKENContract = useEsLTOKENContract();

  return useMutation(
    FunctionKey.CONVERT_ESLTOKEN,
    params =>
      convertEsLTOKEN({
        esLTOKENContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        const accountAddress = await esLTOKENContract.signer.getAddress();

        /* queryClient.invalidateQueries([
          FunctionKey.GET_BALANCE_OF,
          {
            accountAddress,
            tokenAddress: TOKENS.vrt.address,
          },
        ]); */

        /* queryClient.invalidateQueries([
          FunctionKey.GET_V_TOKEN_BALANCE,
          { accountAddress, llTokenAddress: TOKENS.vrt.address },
        ]); */

        queryClient.invalidateQueries([
          FunctionKey.GET_TOKEN_BALANCES,
          {
            accountAddress,
          },
        ]);

        queryClient.invalidateQueries([
          FunctionKey.GET_TOKEN_BALANCES,
          {
            accountAddress,
          },
        ]);

        queryClient.invalidateQueries([
          FunctionKey.GET_TOKEN_BALANCES,
          {
            accountAddress,
          },
        ]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useConvertEsLTOKEN;
