import { checkForComptrollerTransactionError } from 'errors/transactionErrors';

import { ClaimFarmRewardsInput, ClaimFarmRewardsOutput } from './types';

export * from './types';

const claimFarmRewards = async ({
  farmContract,
}: ClaimFarmRewardsInput): Promise<ClaimFarmRewardsOutput> => {
  const transaction = await farmContract.getReward();
  const receipt = await transaction.wait(1);
  checkForComptrollerTransactionError(receipt);
  return receipt;
};

export default claimFarmRewards;
