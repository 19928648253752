import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { MigrationAsset, MigrationPool } from 'types';
import { getTokenByAddress } from 'utilities';

// import { isFeatureEnabled } from 'utilities';
import { useGetMainPool, useGetTokenBalances, useGetWhitelistedLPs } from 'clients/api';

export interface UseGetMigrationPoolsInput {
  accountAddress?: string;
}

export interface UseGetMigrationPoolsOutput {
  isLoading: boolean;
  data?: {
    pools: MigrationPool[];
  };
}

const useGetMigrationPools = ({
  accountAddress,
}: UseGetMigrationPoolsInput): UseGetMigrationPoolsOutput => {
  const { data: getMainPoolData, isLoading: isGetMainPoolDataLoading } = useGetMainPool({
    accountAddress,
  });
  const { data: getWhiteListedLPs, isLoading: isGetWhiteListedLPsLoading } = useGetWhitelistedLPs();

  const isLoading = isGetMainPoolDataLoading || isGetWhiteListedLPsLoading;

  const filteredAssets =
    getMainPoolData?.pool.assets.filter(({ llToken: { underlyingToken } }) =>
      getWhiteListedLPs?.whitelistedLPs?.some(
        address => address.toLowerCase() === underlyingToken.address.toLowerCase(),
      ),
    ) || [];

  const assets = useMemo(
    () =>
      !getWhiteListedLPs
        ? []
        : filteredAssets.reduce<Omit<MigrationAsset, 'userPoolTokenWalletBalance'>[]>(
            (p, asset, index) => {
              const token = getTokenByAddress(getWhiteListedLPs.poolTokens[index]);
              if (token) p.push({ ...asset, poolToken: token });
              return p;
            },
            [],
          ),
    [filteredAssets],
  );

  const { data: poolTokenWalletBalance } = useGetTokenBalances({
    accountAddress: accountAddress || '',
    tokens: assets.map(({ poolToken }) => poolToken),
  });

  if (isLoading || !getWhiteListedLPs || !getMainPoolData) {
    return {
      isLoading: true,
    };
  }

  const newAssets: MigrationAsset[] = assets.map((asset, index) => ({
    ...asset,
    userPoolTokenWalletBalance:
      poolTokenWalletBalance?.tokenBalances[index].balanceWei || new BigNumber(0),
  }));

  const pools: MigrationPool[] = [
    {
      ...getMainPoolData.pool,
      assets: newAssets,
    },
  ];

  return {
    isLoading: false,
    data: {
      pools,
    },
  };
};

export default useGetMigrationPools;
