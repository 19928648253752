import { LLToken } from 'types';

import { LLERC_TOKENS } from 'constants/tokens';

const getLLTokenByAddress = (address: string) =>
  address.toLowerCase() in LLERC_TOKENS
    ? (LLERC_TOKENS[address.toLowerCase() as keyof typeof LLERC_TOKENS] as LLToken)
    : undefined;

export default getLLTokenByAddress;
