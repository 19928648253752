import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    root: css`
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      max-width: 1400px;
      padding: ${theme.spacing(10)};
      margin: 2rem auto;
      width: 95%;

      ${theme.breakpoints.down('xl')} {
        margin: 2rem 0;
        width: 100%;
      }

      ${theme.breakpoints.down('md')} {
        flex-direction: column;
      }
    `,
    child: css`
      flex: 1;
      justify-content: center;
      align-items: center;
    `,
    gridImg: css`
      width: 100%;

      ${theme.breakpoints.down('xl')} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: ${theme.spacing(2)};
      }

      /* ${theme.breakpoints.down('md')} {
        grid-template-columns: 1fr;
      } */
    `,
    subHeading: css`
      color: #fff;
      font-size: 24px;
      font-family: Alliance No.1;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      padding: 0.5em 0 0 0;
    `,
    description: css`
      color: rgba(255, 255, 255, 0.6);
      font-size: 18px;
      font-family: Alliance No.1;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      padding: 0.5em 0;
    `,
    note: css`
      font-size: 16px;
      // font-family: Alliance No.1;
      font-style: normal;
      // font-weight: 700;
      // line-height: 120%;
      padding: 0.5em 0;
    `,
    link: css`
      text-decoration: none;
      padding: 0.5em 0;
      &:hover {
        text-decoration: none;
      }
    `,
  };
};
