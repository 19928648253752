import { MutationObserverOptions, useMutation } from 'react-query';

import { queryClient } from 'clients/api';
import { useLsFundContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

import claimDSDRewards, { ClaimDSDRewardInput, ClaimDSDRewardOutput } from '.';

type Options = MutationObserverOptions<
  ClaimDSDRewardOutput,
  Error,
  Omit<ClaimDSDRewardInput, 'lsFundContract'>
>;

const useClaimDSDRewards = (options?: Options) => {
  const lsFundContract = useLsFundContract();
  return useMutation(
    'CLAIM_DSD_REWARDS',
    (params: Omit<ClaimDSDRewardInput, 'lsFundContract'>) =>
      claimDSDRewards({
        lsFundContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([FunctionKey.GET_DSD_REWARD]);
      },
    },
  );
};

export default useClaimDSDRewards;
