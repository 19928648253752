/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import {
  ActiveChip,
  Button,
  Chip,
  LayeredValues,
  MigrateButton,
  ProgressBar,
  TableColumn,
  Toggle,
  TokenIconWithSymbol,
} from 'components';
import { isObject } from 'lodash';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translation';
import {
  compareBigNumbers,
  compareBooleans,
  compareNumbers,
  compareStrings,
  convertWeiToTokens,
  formatCentsToReadableValue,
  formatPercentage,
  formatToReadablePercentage,
  formatTokensToReadableValue,
  getCombinedDistributionApys,
} from 'utilities';

import { APYData } from 'clients/api';
import ClaimMultiRewardButton from 'components/Layout/ClaimMultiRewardButton';
import { RewardsGroup } from 'components/Layout/ClaimMultiRewardButton/types';
import PLACEHOLDER_KEY from 'constants/placeholderKey';
import { routes } from 'constants/routing';
import { TOKENS } from 'constants/tokens';

import { useStyles } from './styles';
import { ColumnKey, PoolAsset } from './types';

// Translation keys: do not remove this comment
// t('marketTable.columnKeys.asset')
// t('marketTable.columnKeys.supplyApyLtv')
// t('marketTable.columnKeys.labeledSupplyApyLtv')
// t('marketTable.columnKeys.borrowApy')
// t('marketTable.columnKeys.labeledBorrowApy')yar
// t('marketTable.columnKeys.pool')
// t('marketTable.columnKeys.collateral')
// t('marketTable.columnKeys.supplyBalance')
// t('marketTable.columnKeys.borrowBalance')
// t('marketTable.columnKeys.userBorrowBalance')
// t('marketTable.columnKeys.userSupplyBalance')
// t('marketTable.columnKeys.userWalletBalance')
// t('marketTable.columnKeys.userPercentOfLimit')
// t('marketTable.columnKeys.liquidity')
// t('marketTable.columnKets.manage)

const useGenerateColumns = ({
  poolAssets,
  columnKeys,
  withdrawModalOpen,
  collateralOnChange,
  apyData,
  groups,
}: {
  poolAssets: PoolAsset[];
  columnKeys: ColumnKey[];
  withdrawModalOpen: (row: PoolAsset, onlySupply?: boolean, onlyWithdraw?: boolean) => void;
  collateralOnChange: (poolAsset: PoolAsset) => void;
  apyData?: APYData;
  groups: RewardsGroup[];
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const columns: TableColumn<PoolAsset>[] = useMemo(
    () =>
      columnKeys.map((column, index) => ({
        key: column,
        label: t(`marketTable.columnKeys.${column}`),
        align: index === 0 || index === 1 ? 'left' : 'right',
        renderCell: poolAsset => {
          const rewardsData = groups.find(
            reward => reward.id.toLowerCase() === poolAsset.llToken.address,
          );

          if (column === 'asset') {
            return <TokenIconWithSymbol token={poolAsset.llToken.underlyingToken} />;
          }

          if (column === 'protocol') {
            return (
              <div css={styles.protocol}>
                {/* <img
                  src={poolAsset.llToken.protocol}
                  css={styles.icon}
                  alt={poolAsset.llToken.protocolName}
                /> */}
                <Typography variant="body2" style={{ color: 'white' }}>
                  {poolAsset.llToken.protocolName}
                </Typography>
              </div>
            );
          }

          if (column === 'borrowApy' || column === 'labeledBorrowApy') {
            const combinedDistributionApys = getCombinedDistributionApys({ asset: poolAsset });

            const borrowApy = poolAsset.borrowApyPercentage.plus(
              combinedDistributionApys.borrowApyPercentage,
            );

            return formatToReadablePercentage(borrowApy);
          }

          if (column === 'supplyApyLtv' || column === 'labeledSupplyApyLtv') {
            // const combinedDistributionApys = getCombinedDistributionApys({ asset: poolAsset });
            // Object.values(obj);

            // const sum = values.reduce((accumulator, value) => {
            //   return accumulator + value;
            // }, 0);
            const supplyApy =
              apyData?.tokenData && apyData?.tokenData?.[poolAsset.llToken.address]
                ? Object.values(apyData.tokenData[poolAsset.llToken.address]).reduce(
                    (netApy, value) => {
                      if (isObject(value)) return netApy;
                      return +value + netApy;
                    },
                    0,
                  ) - +apyData.tokenData[poolAsset.llToken.address].tokenUsdPrice || 0
                : 0;

            return (
              <b>
                <ActiveChip text={formatToReadablePercentage(supplyApy).toString()} />
              </b>
            );
          }

          if (column === 'collateral') {
            return poolAsset.collateralFactor || poolAsset.isCollateralOfUser ? (
              <Toggle
                onChange={() => collateralOnChange(poolAsset)}
                value={poolAsset.isCollateralOfUser}
              />
            ) : (
              PLACEHOLDER_KEY
            );
          }

          if (column === 'liquidity') {
            return formatCentsToReadableValue({
              value: poolAsset.liquidityCents,
              shortenLargeValue: true,
            });
          }

          if (column === 'collateralFactor') {
            return formatToReadablePercentage(poolAsset.collateralFactor * 100);
          }

          if (column === 'assetPrice') {
            return `$${poolAsset.tokenPriceDollars.toFixed(2)}`;
          }

          if (column === 'reward') {
            return (
              <ClaimMultiRewardButton css={styles.claimLtokenButton} lltoken={poolAsset.llToken} />
            );
          }

          if (column === 'yield') {
            const yieldApy = apyData?.tokenData?.[poolAsset.llToken.address]?.dsdRewardsApy || 0;

            return (
              <LayeredValues
                topValue={
                  rewardsData
                    ? convertWeiToTokens({
                        valueWei: rewardsData.rewards[1].rewardAmountWei,
                        token: rewardsData.rewards[1].rewardToken || TOKENS.dsd,
                        returnInReadableFormat: true,
                        minimizeDecimals: true,
                        shortenLargeValue: true,
                      })
                    : '-'
                }
                bottomValue={`${formatPercentage(yieldApy)}%`}
              />
            );
          }
          if (column === 'yieldLabel') {
            const yieldApy = apyData?.tokenData?.[poolAsset.llToken.address]?.dsdRewardsApy;

            return yieldApy ? `${formatPercentage(yieldApy)}%` : PLACEHOLDER_KEY;
          }

          if (column === 'esRewards') {
            const esRewardApy =
              apyData?.tokenData?.[poolAsset.llToken.address]?.esTokenApyOnProvidingCollateral || 0;
            return (
              <LayeredValues
                topValue={
                  rewardsData
                    ? convertWeiToTokens({
                        valueWei: rewardsData.rewards[0].rewardAmountWei,
                        token: rewardsData.rewards[0].rewardToken || TOKENS.esLTOKEN,
                        returnInReadableFormat: true,
                        minimizeDecimals: true,
                        shortenLargeValue: true,
                      })
                    : '-'
                }
                bottomValue={`${`${formatPercentage(esRewardApy)}%`}`}
              />
            );
          }
          if (column === 'esRewardsLabel') {
            const esRewardApy =
              apyData?.tokenData?.[poolAsset.llToken.address]?.esTokenApyOnProvidingCollateral;
            return esRewardApy ? `${`${formatPercentage(esRewardApy)}%`}` : PLACEHOLDER_KEY;
          }

          if (column === 'manage') {
            return (
              <Button
                variant="secondary"
                onClick={() => withdrawModalOpen(poolAsset, false, false)}
                css={styles.claimLtokenButton}
                small
              >
                Manage
              </Button>
              // <div>
              //   <Link
              //     to={routes.pool.path.replace(
              //       ':poolComptrollerAddress',
              //       poolAsset.pool.comptrollerAddress,
              //     )}
              //     css={styles.marketLink}
              //   >
              //     <Typography variant="small2">manage</Typography>
              //   </Link>
              // </div>
            );
          }

          if (column === 'mintMore') {
            return (
              <Link css={styles.link} to={routes.dsd.path}>
                <Button variant="secondary">Mint+</Button>
              </Link>
              // <div>
              //   <Link
              //     to={routes.pool.path.replace(
              //       ':poolComptrollerAddress',
              //       poolAsset.pool.comptrollerAddress,
              //     )}
              //     css={styles.marketLink}
              //   >
              //     <Typography variant="small2">manage</Typography>
              //   </Link>
              // </div>
            );
          }

          if (column === 'pool') {
            return (
              <div>
                <Link
                  to={routes.pool.path.replace(
                    ':poolComptrollerAddress',
                    poolAsset.pool.comptrollerAddress,
                  )}
                  css={styles.marketLink}
                >
                  <Typography variant="small2">{poolAsset.pool.name}</Typography>
                </Link>
              </div>
            );
          }

          if (column === 'userWalletBalance') {
            return (
              <LayeredValues
                topValue={formatTokensToReadableValue({
                  value: poolAsset.userWalletBalanceTokens,
                  token: poolAsset.llToken.underlyingToken,
                  shortenLargeValue: true,
                  minimizeDecimals: true,
                  addSymbol: false,
                })}
                bottomValue={formatCentsToReadableValue({
                  value: poolAsset.userWalletBalanceCents,
                  shortenLargeValue: true,
                })}
              />
            );
          }

          if (column === 'userSupplyBalance') {
            return poolAsset.userSupplyBalanceTokens.isGreaterThan(0) ? (
              <LayeredValues
                topValue={`${+poolAsset.userSupplyBalanceTokens.toFixed(2)}`}
                bottomValue={formatCentsToReadableValue({
                  value: poolAsset.userSupplyBalanceCents,
                  shortenLargeValue: true,
                })}
              />
            ) : (
              PLACEHOLDER_KEY
            );
          }

          if (column === 'userBorrowBalance') {
            return poolAsset.userBorrowBalanceTokens.isGreaterThan(0) ? (
              <LayeredValues
                topValue={formatTokensToReadableValue({
                  value: poolAsset.userBorrowBalanceTokens,
                  token: poolAsset.llToken.underlyingToken,
                  shortenLargeValue: true,
                })}
                bottomValue={formatCentsToReadableValue({
                  value: poolAsset.userBorrowBalanceCents,
                  shortenLargeValue: true,
                })}
              />
            ) : (
              PLACEHOLDER_KEY
            );
          }

          if (column === 'supplyBalance') {
            return (
              <LayeredValues
                topValue={`${+poolAsset.supplyBalanceTokens.toFixed(2)}`}
                bottomValue={formatCentsToReadableValue({
                  value: poolAsset.supplyBalanceCents,
                  shortenLargeValue: true,
                })}
              />
            );
          }

          if (column === 'borrowBalance') {
            return formatCentsToReadableValue({
              value: poolAsset.borrowBalanceCents,
              shortenLargeValue: true,
            });
          }

          if (column === 'userPercentOfLimit') {
            return (
              <div css={styles.userPercentOfLimit}>
                <ProgressBar
                  min={0}
                  max={100}
                  value={poolAsset.userPercentOfLimit}
                  step={1}
                  ariaLabel={t('marketTable.columnKeys.userPercentOfLimit')}
                  css={styles.percentOfLimitProgressBar}
                />

                <Typography variant="small2" css={styles.white}>
                  {formatToReadablePercentage(poolAsset.userPercentOfLimit)}
                </Typography>
              </div>
            );
          }
          // For migrate page 🟡
          if (column === 'lpToken') {
            return <TokenIconWithSymbol token={poolAsset.llToken.underlyingToken} />;
          }

          // if (column === 'protocol') {
          //   return (
          //     <div>
          //       <Link
          //         // to={routes.pool.path.replace(
          //         //   ':poolComptrollerAddress',
          //         //   poolAsset.pool.comptrollerAddress,
          //         // )}
          //         to="https://www.convexfinance.com/"
          //         css={styles.marketLink}
          //       >
          //         <Typography variant="small2">Staked on Convex</Typography>
          //       </Link>
          //     </div>
          //   );
          // }

          if (column === 'differenceApy') {
            const supplyApy = '6% → 24%';

            return (
              <b>
                <Chip text={supplyApy} />
              </b>
            );
          }

          if (column === 'differenceLimit') {
            const supplyApy = '990 → 1880%';

            return (
              <b>
                <Chip text={supplyApy} />
              </b>
            );
          }
          if (column === 'mintable') {
            return `$${(100.326).toFixed(2)}`;
          }
          if (column === 'newAPY') {
            const supplyApy = 4.59;

            return (
              <b>
                <ActiveChip text={formatToReadablePercentage(supplyApy)} />
              </b>
            );
          }
          if (column === 'newBorrowLimit') {
            return `$${(100.326).toFixed(2)}`;
          }

          if (column === 'migrate') {
            return (
              <MigrateButton
                lptoken={poolAsset.llToken.underlyingToken}
                // TODO: poolToken below might be wrong
                pooltoken={poolAsset.llToken}
              />
            );
          }
        },
        sortRows: (['protocol', 'lpToken', 'asset', 'manage', 'reward'] as ColumnKey[]).includes(
          column,
        )
          ? undefined
          : (rowA, rowB, direction) => {
              if (column === 'borrowApy' || column === 'labeledBorrowApy') {
                const roaABorrowApy = rowA.borrowApyPercentage.plus(
                  getCombinedDistributionApys({ asset: rowA }).borrowApyPercentage,
                );
                const roaBBorrowApy = rowB.borrowApyPercentage.plus(
                  getCombinedDistributionApys({ asset: rowB }).borrowApyPercentage,
                );

                return compareBigNumbers(roaABorrowApy, roaBBorrowApy, direction);
              }

              if (column === 'supplyApyLtv' || column === 'labeledSupplyApyLtv') {
                const roaASupplyApy = rowA.supplyApyPercentage.plus(
                  getCombinedDistributionApys({ asset: rowA }).supplyApyPercentage,
                );
                const roaBSupplyApy = rowB.supplyApyPercentage.plus(
                  getCombinedDistributionApys({ asset: rowB }).supplyApyPercentage,
                );

                return compareBigNumbers(roaASupplyApy, roaBSupplyApy, direction);
              }

              // Put rows of tokens that can't be enabled as collateral at the
              // bottom of the list
              if (column === 'collateral' && rowA.collateralFactor === 0) return 1;
              if (column === 'collateral' && rowB.collateralFactor === 0) return -1;
              // Sort other rows normally
              if (column === 'collateral') {
                return compareBooleans(rowA.isCollateralOfUser, rowB.isCollateralOfUser, direction);
              }

              if (column === 'liquidity') {
                return compareNumbers(rowA.liquidityCents, rowB.liquidityCents, direction);
              }

              if (column === 'collateralFactor') {
                return compareNumbers(rowA.collateralFactor, rowB.collateralFactor, direction);
              }

              if (column === 'assetPrice') {
                return compareBigNumbers(rowA.tokenPriceDollars, rowB.tokenPriceDollars, direction);
              }

              if (column === 'pool') {
                return compareStrings(rowA.pool.name, rowB.pool.name, direction);
              }

              if (column === 'userWalletBalance') {
                return compareBigNumbers(
                  rowA.userWalletBalanceTokens,
                  rowB.userWalletBalanceTokens,
                  direction,
                );
              }

              if (column === 'userSupplyBalance') {
                return compareNumbers(
                  rowA.userSupplyBalanceCents,
                  rowB.userSupplyBalanceCents,
                  direction,
                );
              }

              if (column === 'userBorrowBalance' || column === 'userPercentOfLimit') {
                return compareNumbers(
                  rowA.userBorrowBalanceCents,
                  rowB.userBorrowBalanceCents,
                  direction,
                );
              }

              if (column === 'supplyBalance') {
                return compareNumbers(rowA.supplyBalanceCents, rowB.supplyBalanceCents, direction);
              }

              if (column === 'borrowBalance') {
                return compareNumbers(rowA.borrowBalanceCents, rowB.borrowBalanceCents, direction);
              }

              return 0;
            },
      })),
    [poolAssets, columnKeys],
  );

  return columns;
};

export default useGenerateColumns;
