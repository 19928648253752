/** @jsxImportSource @emotion/react */
import React from 'react';
import { Pool } from 'types';

import Summary from '../Summary';
import { useStyles } from './styles';

export interface PoolBreakdownProps {
  pool: Pool;
  className?: string;
}

export const PoolBreakdown: React.FC<PoolBreakdownProps> = ({ pool }) => {
  const styles = useStyles();

  return <Summary pools={[pool]} displayAccountHealth css={styles.summary} />;
};

export default PoolBreakdown;
