import BigNumber from 'bignumber.js';
import { Token } from 'types';

import { useWithdrawFromFarm } from 'clients/api';

export interface UseWithdrawFarmsInput {
  stakedToken: Token;
  rewardToken: Token;
  farmAddress: string;
}

interface WithdrawInput {
  amountWei: BigNumber;
}

const useWithdrawFromFarms = ({ stakedToken, rewardToken, farmAddress }: UseWithdrawFarmsInput) => {
  const { mutateAsync: WithdrawFromFarm, isLoading } = useWithdrawFromFarm(
    farmAddress,
    stakedToken.address,
    rewardToken.address,
  );

  const withdraw = async ({ amountWei }: WithdrawInput) =>
    WithdrawFromFarm({
      amountWei,
    });

  return {
    isLoading,
    withdraw,
  };
};

export default useWithdrawFromFarms;
