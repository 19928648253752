import BigNumber from 'bignumber.js';
import { Token } from 'types';

import PLACEHOLDER_KEY from 'constants/placeholderKey';

import { shortenValueWithSuffix } from './shortenValueWithSuffix';

export interface FormatTokensToReadableValueInput {
  value: BigNumber | undefined;
  token: Token;
  minimizeDecimals?: boolean | number;
  shortenLargeValue?: boolean;
  addSymbol?: boolean;
}

export const formatTokensToReadableValue = ({
  value,
  token,
  minimizeDecimals,
  shortenLargeValue = false,
  addSymbol = true,
}: FormatTokensToReadableValueInput) => {
  if (value === undefined) {
    return PLACEHOLDER_KEY;
  }

  let decimalPlaces = 2;

  if (typeof minimizeDecimals === 'number') {
    decimalPlaces = minimizeDecimals;
  }

  let symbolPlacement = '';
  if (addSymbol) {
    symbolPlacement = ` ${token.symbol}`;
  }

  if (shortenLargeValue) {
    return `${shortenValueWithSuffix({
      value,
    })}${symbolPlacement}`;
  }
  return `${value.dp(decimalPlaces).toFormat()}${symbolPlacement}`;
};

export default formatTokensToReadableValue;
