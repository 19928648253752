// import BigNumber from 'bignumber.js';
import BigNumber from 'bignumber.js';
import { ContractCallResults } from 'ethereum-multicall';
import { getTokenByAddress } from 'utilities';

import { MultiReward, VErc20 } from 'types/contracts';

import {
  PendingMultiRewardGroup, // VaultPendingMultiRewardGroup,
  // LtokenVestingVaultPendingMultiRewardGroup,
} from '../types';

const formatOutput = ({
  contractCallResults,
  comptrollerAddress,
  llTokenContract,
  multiRewardContract,
}: {
  contractCallResults: ContractCallResults;
  comptrollerAddress: string;
  llTokenContract: VErc20;
  multiRewardContract: MultiReward;
}): PendingMultiRewardGroup[] => {
  const { callsReturnContext, originalContractCallContext } =
    contractCallResults.results.pendingMultiRewards;

  const earned = callsReturnContext.map(({ returnValues }, index) => {
    const rewardToken = getTokenByAddress(
      originalContractCallContext.calls[index].methodParameters[1],
    );
    if (!rewardToken) return;
    return {
      rewardToken,
      type: 'mainPool',
      comptrollerAddress,
      rewardAmountWei: new BigNumber(returnValues[0].hex),
      llTokenAddressesWithPendingReward: [llTokenContract.address],
      multiRewardContractAddress: multiRewardContract.address,
    };
  });

  return earned.filter(Boolean) as PendingMultiRewardGroup[];
};

export default formatOutput;
