import { QueryObserverOptions, useQuery } from 'react-query';

// import { useLLTokenContract } from 'clients/contracts';
import { useMulticall } from 'clients/web3';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
// import FunctionKey from 'constants/functionKey';
import { useAuth } from 'context/AuthContext';

import getPendingMultiRewardGroups from '.';
import { GetPendingMultiRewardGroupsInput, GetPendingMultiRewardGroupsOutput } from './types';

type Options = QueryObserverOptions<
  GetPendingMultiRewardGroupsOutput,
  Error,
  GetPendingMultiRewardGroupsOutput,
  GetPendingMultiRewardGroupsOutput,
  [string, string, number, ...string[]]
>;

const useGetPendingMultiRewards = (
  {
    llTokenContract,
    accountAddress,
    mainPoolComptrollerAddress,
    isolatedPoolComptrollerAddresses,
    ltokenVestingVaultPoolCount,
  }: Omit<GetPendingMultiRewardGroupsInput, 'multicall'>,
  options?: Options,
) => {
  const multicall = useMulticall();
  const { signer } = useAuth();

  // Sort addresses to output the same data when providing them in a different
  // order. This prevents unnecessary queries
  const sortedIsolatedPoolComptrollerAddresses = [...isolatedPoolComptrollerAddresses].sort();

  return useQuery(
    [
      `GET_PENDING_MULTIREWARDS${llTokenContract.address}`,
      accountAddress,
      ltokenVestingVaultPoolCount,
      mainPoolComptrollerAddress,
      ...sortedIsolatedPoolComptrollerAddresses,
    ],
    () =>
      getPendingMultiRewardGroups({
        signer,
        llTokenContract,
        mainPoolComptrollerAddress,
        isolatedPoolComptrollerAddresses,
        ltokenVestingVaultPoolCount,
        multicall,
        accountAddress,
      }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetPendingMultiRewards;
