/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import React from 'react';

import DisclaimerModal from 'components/DisclaimerModal';
import { FlashTicker } from 'components/FlashTicker';

import Header from './Header';
import { PageContainer } from './PageContainer';
import Sidebar from './Sidebar';
import { useStyles } from './styles';

export const Layout: React.FC = ({ children }) => {
  const styles = useStyles();

  return (
    <>
      <FlashTicker />
      <div css={styles.layout}>
        <Sidebar />

        <Box display="flex" flexDirection="column" flex="1">
          <Header />
          <DisclaimerModal />
          <PageContainer>{children}</PageContainer>
        </Box>
      </div>
    </>
  );
};
