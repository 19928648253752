import BigNumber from 'bignumber.js';
import { Asset, Pool } from 'types';
import {
  addUserPropsToPool,
  areAddressesEqual,
  areTokensEqual,
  calculateApy,
  convertDollarsToCents,
  convertWeiToTokens,
  getLLTokenByAddress,
} from 'utilities';

import { BLOCKS_PER_DAY } from 'constants/eth';
import { COMPOUND_DECIMALS } from 'constants/compoundMantissa';
import { logError } from 'context/ErrorLogger';

import { FormatToPoolInput } from '../types';
import convertFactorFromSmartContract from './convertFactorFromSmartContract';
import formatToDistributions from './formatToDistributions';

const formatToPools = ({
  poolsResults,
  poolParticipantsCountResult,
  comptrollerResults,
  rewardsDistributorsResults,
  poolLensResult,
  userWalletTokenBalances,
  accountAddress,
}: FormatToPoolInput) => {
  // Map distributions by llToken address
  const llTokenDistributions = formatToDistributions(rewardsDistributorsResults);

  // Get llToken addresses of user collaterals
  const userCollateralLLTokenAddresses = comptrollerResults.reduce<string[]>(
    (acc, res) =>
      acc.concat(
        res.callsReturnContext[1]
          ? res.callsReturnContext[1].returnValues.map(item => item.toLowerCase())
          : [],
      ),
    [],
  );

  const pools: Pool[] = poolsResults.map(poolResult => {
    const llTokenAddresses: string[] = poolResult.llTokens.map(item => item.llToken);

    const subgraphPool = poolParticipantsCountResult.pools.find(pool =>
      areAddressesEqual(pool.id, poolResult.comptroller),
    );

    const assets = llTokenAddresses.reduce<Asset[]>((acc, llTokenAddress) => {
      const llToken = getLLTokenByAddress(llTokenAddress);

      if (!llToken) {
        logError(`Record missing for llToken: ${llTokenAddress}`);
        return acc;
      }

      const poolLensResults = poolLensResult.callsReturnContext;
      const llTokenMetaData = poolResult.llTokens.find(
        item => item.isListed && areAddressesEqual(item.llToken, llTokenAddress),
      );

      const tokenPriceRecord = poolLensResults[0].returnValues.find(item =>
        areAddressesEqual(item[0], llTokenAddress),
      );

      // Skip llToken if we couldn't fetch sufficient data or if llToken has been
      // unlisted
      if (!llTokenMetaData || !tokenPriceRecord) {
        return acc;
      }

      const llTokenUserBalances =
        accountAddress &&
        poolLensResults[poolLensResults.length - 1].returnValues.find(userBalances =>
          areAddressesEqual(userBalances[0], llTokenAddress),
        );

      const tokenPriceDollars = new BigNumber(tokenPriceRecord[1].hex)
        .dividedBy(new BigNumber(10).pow(36 - llToken.underlyingToken.decimals))
        .dp(2);

      // Extract supplierCount and borrowerCount from subgraph result
      const subgraphPoolMarket = subgraphPool?.markets.find(market =>
        areAddressesEqual(market.id, llTokenAddress),
      );
      const supplierCount = +(subgraphPoolMarket?.supplierCount || 0);
      const borrowerCount = +(subgraphPoolMarket?.borrowerCount || 0);

      const borrowCapTokens = convertWeiToTokens({
        valueWei: new BigNumber(llTokenMetaData.borrowCaps.toString()),
        token: llToken.underlyingToken,
      });

      const supplyCapTokens = convertWeiToTokens({
        valueWei: new BigNumber(llTokenMetaData.supplyCaps.toString()),
        token: llToken.underlyingToken,
      });

      const reserveFactor = convertFactorFromSmartContract({
        factor: new BigNumber(llTokenMetaData.reserveFactorMantissa.toString()),
      });

      const collateralFactor = convertFactorFromSmartContract({
        factor: new BigNumber(llTokenMetaData.collateralFactorMantissa.toString()),
      });

      const cashTokens = convertWeiToTokens({
        valueWei: new BigNumber(llTokenMetaData.totalCash.toString()),
        token: llToken.underlyingToken,
      });

      const liquidityCents = convertDollarsToCents(cashTokens.multipliedBy(tokenPriceDollars));

      const reserveTokens = convertWeiToTokens({
        valueWei: new BigNumber(llTokenMetaData.totalReserves.toString()),
        token: llToken.underlyingToken,
      });

      const exchangeRateLLTokens = new BigNumber(1).div(
        new BigNumber(llTokenMetaData.exchangeRateCurrent.toString()).div(
          new BigNumber(10).pow(
            COMPOUND_DECIMALS + llToken.underlyingToken.decimals - llToken.decimals,
          ),
        ),
      );

      const {
        apyPercentage: supplyApyPercentage,
        dailyDistributedTokens: supplyDailyDistributedTokens,
      } = calculateApy(new BigNumber(llTokenMetaData.supplyRatePerBlock.toString()));

      const {
        apyPercentage: borrowApyPercentage,
        dailyDistributedTokens: borrowDailyDistributedTokens,
      } = calculateApy(new BigNumber(llTokenMetaData.borrowRatePerBlock.toString()));

      const supplyRatePerBlockTokens = supplyDailyDistributedTokens.dividedBy(BLOCKS_PER_DAY);
      const borrowRatePerBlockTokens = borrowDailyDistributedTokens.dividedBy(BLOCKS_PER_DAY);

      const supplyBalanceLLTokens = convertWeiToTokens({
        valueWei: new BigNumber(llTokenMetaData.totalSupply.toString()),
        token: llToken,
      });
      const supplyBalanceTokens = supplyBalanceLLTokens.dividedBy(exchangeRateLLTokens);
      const supplyBalanceCents = convertDollarsToCents(
        supplyBalanceTokens.multipliedBy(tokenPriceDollars),
      );

      const borrowBalanceTokens = convertWeiToTokens({
        valueWei: new BigNumber(llTokenMetaData.totalBorrows.toString()),
        token: llToken.underlyingToken,
      });

      const borrowBalanceCents = convertDollarsToCents(
        borrowBalanceTokens.multipliedBy(tokenPriceDollars),
      );

      // User-specific props
      const userBorrowBalanceTokens = llTokenUserBalances
        ? convertWeiToTokens({
            valueWei: new BigNumber(llTokenUserBalances[2].hex),
            token: llToken.underlyingToken,
          })
        : new BigNumber(0);

      const userSupplyBalanceTokens = llTokenUserBalances
        ? convertWeiToTokens({
            valueWei: new BigNumber(llTokenUserBalances[3].hex),
            token: llToken.underlyingToken,
          })
        : new BigNumber(0);

      const tokenBalanceRes = userWalletTokenBalances?.tokenBalances.find(tokenBalance =>
        areTokensEqual(tokenBalance.token, llToken.underlyingToken),
      );

      const userWalletBalanceTokens = tokenBalanceRes
        ? convertWeiToTokens({
            valueWei: tokenBalanceRes.balanceWei,
            token: tokenBalanceRes.token,
          })
        : new BigNumber(0);

      const userSupplyBalanceCents = convertDollarsToCents(
        userSupplyBalanceTokens.multipliedBy(tokenPriceDollars),
      );
      const userBorrowBalanceCents = convertDollarsToCents(
        userBorrowBalanceTokens.multipliedBy(tokenPriceDollars),
      );
      const userWalletBalanceCents = convertDollarsToCents(
        userWalletBalanceTokens.multipliedBy(tokenPriceDollars),
      );

      const isCollateralOfUser = userCollateralLLTokenAddresses.includes(
        llTokenAddress.toLowerCase(),
      );

      const distributions = llTokenDistributions[llToken.address.toLowerCase()] || [];

      const asset: Asset = {
        llToken,
        tokenPriceDollars,
        reserveFactor,
        collateralFactor,
        cashTokens,
        liquidityCents,
        reserveTokens,
        exchangeRateLLTokens,
        supplierCount,
        borrowerCount,
        borrowApyPercentage,
        supplyApyPercentage,
        supplyRatePerBlockTokens,
        borrowRatePerBlockTokens,
        supplyBalanceTokens,
        supplyBalanceCents,
        borrowBalanceTokens,
        borrowBalanceCents,
        borrowCapTokens,
        supplyCapTokens,
        distributions,
        userSupplyBalanceTokens,
        userSupplyBalanceCents,
        userBorrowBalanceTokens,
        userBorrowBalanceCents,
        userWalletBalanceTokens,
        userWalletBalanceCents,
        // This will be calculated after all assets have been formatted
        userPercentOfLimit: 0,
        isCollateralOfUser,
      };

      return [...acc, asset];
    }, []);

    const pool: Pool = addUserPropsToPool({
      name: poolResult.name,
      description: poolResult.description,
      comptrollerAddress: poolResult.comptroller,
      isIsolated: true,
      assets,
    });

    // Calculate userPercentOfLimit for each asset
    const formattedAssets: Asset[] = assets.map(asset => ({
      ...asset,
      userPercentOfLimit: pool.userBorrowLimitCents
        ? new BigNumber(asset.userBorrowBalanceCents)
            .times(100)
            .div(pool.userBorrowLimitCents)
            .dp(2)
            .toNumber()
        : 0,
    }));

    return {
      ...pool,
      assets: formattedAssets,
    };
  });

  return pools;
};

export default formatToPools;
