import BigNumber from 'bignumber.js';

import { VErc20, VBnbToken } from 'types/contracts';

export interface GetLLTokenBalanceOfInput {
  llTokenContract: VErc20 | VBnbToken;
  accountAddress: string;
}

export type GetLLTokenBalanceOfOutput = {
  balanceWei: BigNumber;
};

const getLLTokenBalanceOf = async ({
  llTokenContract,
  accountAddress,
}: GetLLTokenBalanceOfInput): Promise<GetLLTokenBalanceOfOutput> => {
  const res = await llTokenContract.balanceOf(accountAddress);

  return {
    balanceWei: new BigNumber(res.toString()),
  };
};

export default getLLTokenBalanceOf;
