import { useQuery } from 'react-query';

import { useMulticall } from 'clients/web3';
import { BLOCK_TIME_MS } from 'constants/eth';

// import FunctionKey from 'constants/functionKey';
import getRedeemDsdData from '.';

const useGetRedeemDsdData = () => {
  const multicall = useMulticall();
  return useQuery(['GET_REDEEMABLE_DSD', 'getRedeemDsdData'], () => getRedeemDsdData(multicall), {
    refetchInterval: BLOCK_TIME_MS,
  });
};

export default useGetRedeemDsdData;
