import BigNumber from 'bignumber.js';

import { Fund } from 'types/contracts';

export interface GetEsltokenFeesInput {
  lsFundContract: Fund;
  accountAddress: string;
}

export type GetEsltokenFeesOutput = {
  feesEarned: BigNumber;
};

const GetEsltokenFees = async ({
  lsFundContract,
  accountAddress,
}: GetEsltokenFeesInput): Promise<GetEsltokenFeesOutput> => {
  const res = await lsFundContract.earned(accountAddress);

  console.log('@FEES:: fees earned for keeping esltoken; in DSD:', +res / 1e18);

  return {
    feesEarned: new BigNumber(res.toString()),
  };
};

export default GetEsltokenFees;
