import { MAINNET_TOKENS } from 'constants/tokens';

export const whales = {
  [MAINNET_TOKENS.stETH.address]: '0x1982b2F5814301d4e9a8b0201555376e62F82428',
  [MAINNET_TOKENS.sfrxETH.address]: '0x40093c0156cD8d1DAEFb5A5465D17FcC6467Aa31',
  [MAINNET_TOKENS.cbETH.address]: '0xED1F7bb04D2BA2b6EbE087026F03C96Ea2c357A8',
  [MAINNET_TOKENS.rETH.address]: '0xCc9EE9483f662091a1de4795249E24aC0aC2630f',
  [MAINNET_TOKENS.steCRV.address]: '0x99ac10631F69C753DDb595D074422a0922D9056B',
  [MAINNET_TOKENS.frxETHCRV.address]: '0x2932a86df44Fe8D2A706d8e9c5d51c24883423F5',
  [MAINNET_TOKENS.STETHETH_C_f.address]: '0xF668E6D326945d499e5B35E7CD2E82aCFbcFE6f0',
  [MAINNET_TOKENS.st_frxETH_f.address]: '0x821529Bb07c83803C9CC7763e5974386e9eFEdC7',
  [MAINNET_TOKENS.wstETHrETHsfrxETHBPT.address]: '0xF5BCE5077908a1b7370B9ae04AdC565EBd643966',
  [MAINNET_TOKENS.BrETHSTABLE.address]: '0xBA12222222228d8Ba445958a75a0704d566BF2C8',
  [MAINNET_TOKENS.BrETHSTABLE.address]: '0xA57b8d98dAE62B26Ec3bcC4a365338157060B234',
  [MAINNET_TOKENS.BstETHSTABLE.address]: '0xA57b8d98dAE62B26Ec3bcC4a365338157060B234',
  [MAINNET_TOKENS.PTstETH26DEC2024.address]: '0x6E799758CEE75DAe3d84e09D40dc416eCf713652',
  [MAINNET_TOKENS.wETH.address]: '0x741AA7CFB2c7bF2A1E7D4dA2e3Df6a56cA4131F3',
  [MAINNET_TOKENS.PTstETH30DEC2027.address]: '0x64627901dAdb46eD7f275fD4FC87d086cfF1e6E3',
  [MAINNET_TOKENS.PTstETH25DEC2025.address]: '0x6E799758CEE75DAe3d84e09D40dc416eCf713652',
  [MAINNET_TOKENS.PTfrxETH26DEC2024.address]: '0x86E7a8Eb3B9854aF92e0B3e71669E449fCe2b737',
  [MAINNET_TOKENS.swETH.address]: '0xBA12222222228d8Ba445958a75a0704d566BF2C8',
  [MAINNET_TOKENS.rETHf.address]: '0x9d4D981d8a9066f5db8532A5816543dE8819d4A8',
};

export const eventAbi = `[{
  "anonymous": false,
  "inputs": [
    {
      "indexed": false,
      "internalType": "address",
      "name": "llToken",
      "type": "address"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "mr",
      "type": "uint256"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "fund",
      "type": "uint256"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "dsd",
      "type": "uint256"
    }
  ],
  "name": "Distribution",
  "type": "event"
}]`;
