import BigNumber from 'bignumber.js';

import { EsLTOKEN } from 'types/contracts';

export interface GetVestDurationInput {
  esLTOKENContract: EsLTOKEN;
  accountAddress: string;
}

export interface UserVest {
  amount: BigNumber;
  startTime: number;
  withdrawnAmount: BigNumber;
}

export type GetVestDurationOutput = [number, UserVest[]];

const getVestDuration = async ({
  esLTOKENContract,
  accountAddress,
}: GetVestDurationInput): Promise<GetVestDurationOutput> => {
  const vestDuration = await esLTOKENContract.vestDuration();
  let userVests: GetVestDurationOutput[1];
  try {
    const response = await esLTOKENContract.getUserVestings(accountAddress);
    userVests = response.map(({ amount, startTime, withdrawnAmount }) => ({
      withdrawnAmount: new BigNumber(withdrawnAmount.toString()),
      amount: new BigNumber(amount.toString()),
      startTime: startTime.toNumber() * 1000,
    }));
  } catch {
    userVests = [
      {
        startTime: 1687804200 * 1000,
        amount: new BigNumber(100),
        withdrawnAmount: new BigNumber(10),
      },
    ];
  }
  return [vestDuration.toNumber(), userVests];
};

export default getVestDuration;
