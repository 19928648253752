import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    theme,
    container: css`
      margin: ${theme.spacing(0, 4)};
      padding: 0 ${theme.spacing(4)};
      max-height: 360px;
      ${theme.breakpoints.down('md')} {
        margin: ${theme.spacing(-2, 4, 0)};
      }
    `,
    walletList: css`
      max-height: 290px;
      padding: 0 ${theme.spacing(4)};
      overflow-y: scroll;
      margin-bottom: ${theme.spacing(8)};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(4)};
      align-items: start;

      ${theme.breakpoints.down('md')} {
        row-gap: ${theme.spacing(2)};
        column-gap: ${theme.spacing(0)};
        margin-bottom: ${theme.spacing(4)};
      }
    `,
    getListItem: ({ isSelected }: { isSelected: boolean }) => css`
      background-color: transparent;
      box-shadow: none;
      border: 1px solid rgba(35, 162, 139, 0.3)
      border-radius: ${theme.spacing(2)};
      padding: ${theme.spacing(2)};
      color: ${theme.palette.text.primary};
      text-align: center;

      > span {
        display: flex;
        width: 100%;
        gap: ${theme.spacing(2)};
        align-items: center;
        justify-content: flex-start;
      }
      :hover {
        background-color: ${theme.palette.button.medium};
      }

      ${
        isSelected &&
        css`
          cursor: pointer;
          border: 1.6px solid rgba(75, 185, 165, 1);
          background-color: ${theme.palette.button.main};
        `
      }
    `,
    walletLogo: css`
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      display: block;

      ${theme.breakpoints.down('md')} {
        width: ${theme.spacing(6)};
      }
    `,
    comingSoonText: css`
      color: ${theme.palette.text.secondary};
    `,
    footer: css`
      text-align: center;
      padding: ${theme.spacing(0, 0)};
    `,
  };
};
