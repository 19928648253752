import { EthChainId, Token } from 'types';

import balancer from 'assets/img/tokens/balancer.svg';
import cbeth from 'assets/img/tokens/cbeth.svg';
import crv from 'assets/img/tokens/curve.svg';
import dsd from 'assets/img/tokens/dsd.svg';
import eth from 'assets/img/tokens/eth.svg';
import ltoken from 'assets/img/tokens/ltoken.svg';
import pendle from 'assets/img/tokens/pendle.svg';
import reth from 'assets/img/tokens/reth.svg';
import sfrxETH from 'assets/img/tokens/sfrxETH.svg';
import steth from 'assets/img/tokens/steth.png';
import usdc from 'assets/img/tokens/usdc.svg';
import weth from 'assets/img/tokens/weth.svg';
import yearn from 'assets/img/tokens/yearn.svg';
import brand from 'config/brand';
import llTokens_TOKENS from 'constants/contracts/addresses/llTokens.json';
import ourTOKENS from 'constants/contracts/addresses/main.json';
import MAIN_TOKENS from 'constants/contracts/addresses/tokens.json';

// These are the actual underlying tokens, not the LLTokens & thus doesn't need to replaced
export const MAINNET_TOKENS = {
  stETH: {
    address: MAIN_TOKENS.stETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'stETH',
    asset: steth,
    isNative: false,
  } as Token,
  sfrxETH: {
    address: MAIN_TOKENS.sfrxETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'sfrxETH',
    asset: sfrxETH,
    isNative: false,
  } as Token,
  cbETH: {
    address: MAIN_TOKENS.cbETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'cbETH',
    asset: cbeth,
    isNative: false,
  } as Token,
  rETH: {
    address: MAIN_TOKENS.rETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'rETH',
    asset: reth,
    isNative: false,
  } as Token,
  usdc: {
    address: MAIN_TOKENS.usdc[EthChainId.MAINNET].toLowerCase(),
    decimals: 6,
    symbol: 'USDC',
    asset: usdc,
    isNative: false,
  } as Token,
  esLTOKEN: {
    address: ourTOKENS.esLTOKEN[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: brand.escrowTokenSymbol,
    asset: ltoken,
  } as Token,
  ltoken: {
    address: llTokens_TOKENS.LTOKEN[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: brand.rewardTokenSymbol,
    asset: ltoken,
  } as Token,
  dsd: {
    address: llTokens_TOKENS.DSD[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: brand.stableCoinSymbol,
    asset: dsd,
  } as Token,
  wETH: {
    address: MAIN_TOKENS.wETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'wETH',
    asset: weth,
  } as Token,
  eth: {
    address: '',
    decimals: 18,
    symbol: 'ETH',
    asset: eth,
    isNative: true,
  } as Token,
  steCRV: {
    address: MAIN_TOKENS.steCRV[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Curve stETH',
    asset: crv,
  } as Token,
  frxETHCRV: {
    address: MAIN_TOKENS.frxETHCRV[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Curve frxETH',
    asset: crv,
  } as Token,
  STETHETH_C_f: {
    address: MAIN_TOKENS.STETHETH_C_f[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'stETH Concentrated',
    asset: crv,
  } as Token,
  st_frxETH_f: {
    address: MAIN_TOKENS.st_frxETH_f[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'stETH-frxETH',
    asset: crv,
  } as Token,
  yvWETH: {
    address: MAIN_TOKENS.yvWETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'yvWETH',
    asset: yearn,
  } as Token,
  wstETHrETHsfrxETHBPT: {
    address: MAIN_TOKENS.wstETHrETHsfrxETHBPT[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Balancer Liquid Staking',
    asset: balancer,
  } as Token,
  BrETHSTABLE: {
    address: MAIN_TOKENS.BrETHSTABLE[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Balancer RETH-WETH',
    asset: balancer,
  } as Token,
  BstETHSTABLE: {
    address: MAIN_TOKENS.BstETHSTABLE[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Balancer stETH-WETH',
    asset: balancer,
  } as Token,
  PTstETH26DEC2024: {
    address: MAIN_TOKENS.PTstETH26DEC2024[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Pendle Lido 26 Dec 24',
    asset: pendle,
  } as Token,
  PTstETH30DEC2027: {
    address: MAIN_TOKENS.PTstETH30DEC2027[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Pendle Lido 30 Dec 27',
    asset: pendle,
  } as Token,
  PTstETH25DEC2025: {
    address: MAIN_TOKENS.PTstETH25DEC2025[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Pendle Lido 25 Dec 25',
    asset: pendle,
  } as Token,
  PTfrxETH26DEC2024: {
    address: MAIN_TOKENS.PTfrxETH26DEC2024[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Pendle Frax 26 Dec 24',
    asset: pendle,
  } as Token,
  swETH: {
    address: MAIN_TOKENS.swETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'swETH',
    asset: eth,
  } as Token,
  rETHf: {
    address: MAIN_TOKENS.rETHf[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'rETH-f',
    asset: crv,
  } as Token,

  // 🌕 add::all LlTokens + Uniswap LP
  LToken_WETH: {
    address: llTokens_TOKENS.LToken_WETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Ltoken-Weth',
    asset: eth,
  } as Token,
  DSD_USDC: {
    address: llTokens_TOKENS.DSD_USDC[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'DSD-USDC',
    asset: dsd,
  } as Token,
  LSstETH: {
    address: llTokens_TOKENS.LSstETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'stETH',
    asset: eth,
  } as Token,
  LSsfrxETH: {
    address: llTokens_TOKENS.LSsfrxETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'sfrxETH',
    asset: eth,
  } as Token,
  LScbETH: {
    address: llTokens_TOKENS.LScbETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'cbETH',
    asset: eth,
  } as Token,
  LSrETH: {
    address: llTokens_TOKENS.LSrETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'rETH',
    asset: eth,
  } as Token,
  LSWETH: {
    address: llTokens_TOKENS.LSWETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'wETH',
    asset: eth,
  } as Token,
  LSsteCRV: {
    address: llTokens_TOKENS.LSsteCRV[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Curve stETH',
    asset: eth,
  } as Token,
  LSfrxETHCRV: {
    address: llTokens_TOKENS.LSfrxETHCRV[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Curve frxETH',
    asset: eth,
  } as Token,
  LSSTETHETH_C_f: {
    address: llTokens_TOKENS.LSSTETHETH_C_f[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Curve stETH Concentrated',
    asset: eth,
  } as Token,
  LSst_frxETH_f: {
    address: llTokens_TOKENS.LSst_frxETH_f[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Curve stETH-frxETH',
    asset: eth,
  } as Token,
  LSyvWETH: {
    address: llTokens_TOKENS.LSyvWETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Yearn yETH',
    asset: eth,
  } as Token,
  LSwstETHrETHsfrxETHBPT: {
    address: llTokens_TOKENS.LSwstETHrETHsfrxETHBPT[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Balancer Liquid Staking',
    asset: eth,
  } as Token,
  LSBrETHSTABLE: {
    address: llTokens_TOKENS.LSBrETHSTABLE[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Balancer RETH-WETH',
    asset: eth,
  } as Token,
  LSBstETHSTABLE: {
    address: llTokens_TOKENS.LSBstETHSTABLE[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Balancer stETH-WETH',
    asset: eth,
  } as Token,
  LSPTstETH26DEC2024: {
    address: llTokens_TOKENS.LSPTstETH26DEC2024[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Pendle Lido 26 Dec 24',
    asset: pendle,
  } as Token,
  LSPTstETH30DEC2027: {
    address: llTokens_TOKENS.LSPTstETH30DEC2027[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Pendle Lido 30 Dec 27',
    asset: pendle,
  } as Token,
  LSPTstETH25DEC2025: {
    address: llTokens_TOKENS.LSPTstETH25DEC2025[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Pendle Lido 25 Dec 25',
    asset: pendle,
  } as Token,
  LSPTfrxETH26DEC2024: {
    address: llTokens_TOKENS.LSPTfrxETH26DEC2024[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'Pendle Frax 26 Dec 24',
    asset: pendle,
  } as Token,
  LSswETH: {
    address: llTokens_TOKENS.LSswETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'swETH',
    asset: eth,
  } as Token,
  LSrETHf: {
    address: llTokens_TOKENS.LSrETHf[EthChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'LSrETHf',
    asset: crv,
  } as Token,
  crvPoolToken0: {
    address: '0x9518c9063eB0262D791f38d8d6Eb0aca33c63ed0',
    decimals: 18,
    symbol: 'cvxsteCRV',
    asset: crv,
  } as Token,
  crvPoolToken1: {
    address: '0xC07e540DbFecCF7431EA2478Eb28A03918c1C30E',
    decimals: 18,
    symbol: 'cvxfrxETHCRV',
    asset: crv,
  } as Token,
  crvPoolToken2: {
    address: '0x2Fa3DbB59EaFFaf32A310717728C7c800A8F1fb4',
    decimals: 18,
    symbol: 'cvxSTETHETH',
    asset: crv,
  } as Token,
};
