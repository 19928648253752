import { ContractReceipt } from 'ethers';

import { EsLTOKEN } from 'types/contracts';

export interface EscrowLTokenInput {
  esLtokenContract: EsLTOKEN;
  amount: string;
}

export type EscrowLTokenOutput = ContractReceipt;

const escrowLToken = async ({
  esLtokenContract,
  amount,
}: EscrowLTokenInput): Promise<EscrowLTokenOutput> => {
  const accountAddress = await esLtokenContract.signer.getAddress();

  const transaction = await esLtokenContract.escrow(accountAddress, amount);
  return transaction.wait(1);
};

export default escrowLToken;
