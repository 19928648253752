import { checkForTokenTransactionError } from 'errors';
import { ContractReceipt } from 'ethers';
import { Token } from 'types';

import { Migrator } from 'types/contracts';

export interface MigrateInput {
  migratorContract: Migrator;
  lptoken: Token;
}

export type MigrateOutput = ContractReceipt;

const migrate = async ({ migratorContract, lptoken }: MigrateInput): Promise<MigrateOutput> => {
  const transaction = await migratorContract.migrate(lptoken.address);
  const receipt = await transaction.wait(1);
  return checkForTokenTransactionError(receipt);
};

export default migrate;
