import { QueryObserverOptions, useQuery } from 'react-query';

import getMintableDsd, {
  GetMintableDsdInput,
  GetMintableDsdOutput,
} from 'clients/api/queries/getMintableDsd';
import { useDsdControllerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetMintableDsdOutput,
  Error,
  GetMintableDsdOutput,
  GetMintableDsdOutput,
  [FunctionKey.GET_MINTABLE_DSD, Omit<GetMintableDsdInput, 'dsdControllerContract'>]
>;

const useGetMintableDsd = (
  params: Omit<GetMintableDsdInput, 'dsdControllerContract'>,
  options?: Options,
) => {
  const dsdControllerContract = useDsdControllerContract();

  return useQuery(
    [FunctionKey.GET_MINTABLE_DSD, params],
    () => getMintableDsd({ dsdControllerContract, ...params }),
    options,
  );
};

export default useGetMintableDsd;
