import { MutationObserverOptions, useMutation } from 'react-query';

import {
  ClaimFarmRewardsInput,
  ClaimFarmRewardsOutput,
  claimFarmRewards,
  queryClient,
} from 'clients/api';
import { useFarmContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
  ClaimFarmRewardsOutput,
  Error,
  Omit<ClaimFarmRewardsInput, 'farmContract'>
>;

const useClaimFarmRewards = (farmAddress: string, options?: Options) => {
  const farmContract = useFarmContract(farmAddress);
  return useMutation(
    FunctionKey.CLAIM_REWARDS,
    (params: Omit<ClaimFarmRewardsInput, 'farmContract'>) =>
      claimFarmRewards({
        farmContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([FunctionKey.GET_FARMS, 'GETFARMS']);
      },
    },
  );
};

export default useClaimFarmRewards;
