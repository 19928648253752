import { MutationObserverOptions, useMutation } from 'react-query';
// import { LLToken } from 'types';

import { MigrateInput, MigrateOutput, migrate, queryClient } from 'clients/api';
import { useMigratorContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
  MigrateOutput,
  Error,
  Omit<MigrateInput, 'migratorContract'>
>;

const useMigrate = (options?: Options) => {
  const migratorContract = useMigratorContract();
  return useMutation(
    FunctionKey.MIGRATE,
    params =>
      migrate({
        migratorContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);
        queryClient.invalidateQueries(FunctionKey.GET_MAIN_MARKETS);
        queryClient.invalidateQueries(FunctionKey.GET_ISOLATED_POOLS);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useMigrate;
