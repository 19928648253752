import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { Vault } from 'types';
import { areTokensEqual, convertWeiToTokens, getContractAddress } from 'utilities';

import {
  useGetBalanceOf,
  useGetMainAssets,
  useGetDsdVaultUserInfo,
  useGetVenusDsdVaultDailyRate,
} from 'clients/api';
import { DAYS_PER_YEAR } from 'constants/daysPerYear';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import { TOKENS } from 'constants/tokens';

const DSD_VAULT_ADDRESS = getContractAddress('dsdVault');

export interface UseGetDsdVaultOutput {
  isLoading: boolean;
  data: Vault | undefined;
}

const useGetDsdVault = ({ accountAddress }: { accountAddress?: string }): UseGetDsdVaultOutput => {
  const { data: totalDsdStakedData, isLoading: isGetTotalDsdStakedWeiLoading } = useGetBalanceOf(
    {
      accountAddress: DSD_VAULT_ADDRESS,
      token: TOKENS.dsd,
    },
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
    },
  );

  const { data: dsdVaultUserInfo, isLoading: isGetDsdVaultUserInfoLoading } =
    useGetDsdVaultUserInfo(
      {
        accountAddress: accountAddress || '',
      },
      {
        enabled: !!accountAddress,
      },
    );

  const { data: dsdVaultDailyRateData, isLoading: isGetDsdVaultDailyRateWeiLoading } =
    useGetVenusDsdVaultDailyRate();

  const { data: getMainAssetsData, isLoading: isGetMainAssetsLoading } = useGetMainAssets({
    accountAddress,
  });
  const ltokenPriceDollars: BigNumber | undefined = useMemo(
    () =>
      (getMainAssetsData?.assets || []).find(asset =>
        areTokensEqual(asset.llToken.underlyingToken, TOKENS.ltoken),
      )?.tokenPriceDollars,
    [getMainAssetsData?.assets],
  );

  const data: Vault | undefined = useMemo(() => {
    if (!totalDsdStakedData || !dsdVaultDailyRateData || !ltokenPriceDollars) {
      return undefined;
    }

    const stakingAprPercentage = convertWeiToTokens({
      valueWei: dsdVaultDailyRateData.dailyRateWei,
      token: TOKENS.ltoken,
    })
      .multipliedBy(ltokenPriceDollars) // We assume 1 DSD = 1 dollar
      .multipliedBy(DAYS_PER_YEAR)
      .dividedBy(
        convertWeiToTokens({
          valueWei: totalDsdStakedData.balanceWei,
          token: TOKENS.dsd,
        }),
      )
      .multipliedBy(100)
      .toNumber();

    return {
      rewardToken: TOKENS.ltoken,
      stakedToken: TOKENS.dsd,
      dailyEmissionWei: dsdVaultDailyRateData.dailyRateWei,
      totalStakedWei: totalDsdStakedData.balanceWei,
      stakingAprPercentage,
      userStakedWei: dsdVaultUserInfo?.stakedDsdWei,
    };
  }, [
    totalDsdStakedData?.balanceWei.toFixed(),
    dsdVaultDailyRateData?.dailyRateWei.toFixed(),
    ltokenPriceDollars?.toFixed(),
    JSON.stringify(dsdVaultUserInfo),
  ]);

  const isLoading =
    isGetTotalDsdStakedWeiLoading ||
    isGetDsdVaultDailyRateWeiLoading ||
    isGetMainAssetsLoading ||
    isGetDsdVaultUserInfoLoading;

  return {
    data,
    isLoading,
  };
};

export default useGetDsdVault;
