import BigNumber from 'bignumber.js';

import { DsdController } from 'types/contracts';

export interface GetDsdTreasuryPercentageInput {
  dsdControllerContract: DsdController;
}

export type GetDsdTreasuryPercentageOutput = {
  percentage: number;
};

const getDsdTreasuryPercentage = async ({
  dsdControllerContract,
}: GetDsdTreasuryPercentageInput): Promise<GetDsdTreasuryPercentageOutput> => {
  const treasuryPercentage = await dsdControllerContract.treasuryPercent();
  const formattedTreasuryPercentage = new BigNumber(treasuryPercentage.toString())
    .times(100)
    .div(1e18)
    .toNumber();

  return {
    percentage: formattedTreasuryPercentage,
  };
};

export default getDsdTreasuryPercentage;
