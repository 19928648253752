/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import config from 'config';
import React from 'react';

// import { useTranslation } from 'translation';
import { Connector } from 'clients/web3/types';
import { PrimaryButton, SecondaryButton } from 'components/Button';

import {
  // INTEGRATED_WALLETS,
  // UPCOMING_WALLETS,
  // VENUS_TERMS_OF_SERVICE_URL,
  WALLETS,
} from '../constants';
import { useStyles } from './styles';

export interface WalletListProps {
  onLogin: (connector: Connector) => void;
}

export const WalletList: React.FC<WalletListProps> = ({ onLogin }) => {
  const styles = useStyles();
  // const { t, Trans } = useTranslation();
  const [walletConnector, setWalletConnector] = React.useState<Connector | undefined>();

  return (
    <div css={styles.container}>
      <div css={styles.walletList}>
        {WALLETS.filter(({ mainnetOnly }) => !mainnetOnly || !config.isOnTestnet).map(
          ({ name, connector, Logo }) => (
            <SecondaryButton
              css={styles.getListItem({ isSelected: connector === walletConnector })}
              key={`wallet-${name}`}
              onClick={() => setWalletConnector(connector)}
              fullWidth
            >
              <Logo css={styles.walletLogo} />

              <Typography variant="body2" component="div">
                {name}
              </Typography>
            </SecondaryButton>
          ),
        )}

        {/* {INTEGRATED_WALLETS.map(({ name, Logo, linkUrl }) => (
          <a
            css={styles.getListItem({ isActionable: true })}
            key={`wallet-${name}`}
            href={linkUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Logo css={styles.walletLogo} />

            <Typography variant="tiny" component="div">
              {name}
            </Typography>
          </a>
        ))} */}

        {/* {UPCOMING_WALLETS.map(({ name, Logo }) => (
          <div css={styles.getListItem({ isActionable: false })} key={`upcoming-wallet-${name}`}>
            <Logo css={styles.walletLogo} />

            <Typography variant="tiny" css={styles.comingSoonText} component="div">
              {t('authModal.walletList.comingSoon')}
            </Typography>
          </div>
        ))} */}
      </div>
      {walletConnector && (
        <div css={styles.footer}>
          <PrimaryButton
            onClick={() => onLogin(walletConnector)}
            fullWidth
            disabled={!walletConnector}
          >
            Connect Wallet
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};
