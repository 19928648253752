/** @jsxImportSource @emotion/react */
import { Paper, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { BorrowBalanceAccountHealth, Cell, Icon, Tooltip } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'translation';
import { Asset, Pool } from 'types';
import {
  convertWeiToTokens,
  formatCentsToReadableValue,
  formatToReadablePercentage,
} from 'utilities';

import { useGetApyData, useGetMintedDsd } from 'clients/api';
import { SAFE_BORROW_LIMIT_PERCENTAGE } from 'constants/safeBorrowLimitPercentage';
import { TOKENS } from 'constants/tokens';
import { PoolAsset } from 'containers/MarketTable/types';
import { useAuth } from 'context/AuthContext';

import CellGroup from './CellGroup';
import { useStyles } from './styles';
import TEST_IDS from './testIds';
import useExtractData from './useExtractData';

export interface SummaryProps {
  pools: Pool[];
  displayAccountHealth?: boolean;
  className?: string;
}

export const Summary: React.FC<SummaryProps> = ({ pools, displayAccountHealth, className }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { accountAddress } = useAuth();

  const assets = useMemo(
    () => pools.reduce((acc, pool) => [...acc, ...pool.assets], [] as Asset[]),
    [pools],
  );

  const poolAssets = useMemo(
    () =>
      pools.reduce<PoolAsset[]>((acc, pool) => {
        const newPoolAssets: PoolAsset[] = pool.assets
          .filter(asset => asset.userSupplyBalanceCents > 0)
          .map(asset => ({
            ...asset,
            pool,
          }));

        return acc.concat(newPoolAssets);
      }, []),
    [pools],
  );

  const avgCollateralRatio = useMemo(() => {
    let avgRatio = 0;
    let count = 0;

    assets.forEach((asset: Asset) => {
      if (asset.userSupplyBalanceCents > 0 || asset.userBorrowBalanceCents > 0) {
        avgRatio += asset.collateralFactor;
        count += 1;
      }
    });

    if (!count) return 0;

    return (avgRatio / count) * 100;
  }, [assets, accountAddress]);

  const { totalSupplyCents, borrowLimitCents, readableSafeBorrowLimit, safeBorrowLimitPercentage } =
    useExtractData({
      assets,
    });

  const { data: mintedDsdData, isLoading: isGetMintedDsdLoading } = useGetMintedDsd(
    {
      accountAddress,
    },
    {
      enabled: !!accountAddress,
    },
  );

  const { data: apyData } = useGetApyData();

  const rebaseApy = useMemo(
    () =>
      Object.values(apyData?.tokenData || {}).reduce(
        (apy, asset) => +asset.esTokenRebaseApy + apy,
        0,
      ),
    [apyData],
  );

  const esRewardApy = useMemo(
    () =>
      Object.values(apyData?.tokenData || {}).reduce(
        (apy, asset) => +asset.esTokenApyOnProvidingCollateral + apy,
        0,
      ),
    [apyData],
  );

  const netRewardApy = useMemo(
    () => (apyData ? +apyData?.dsdMintingRewardsApy : 0) + rebaseApy + esRewardApy,
    [apyData, rebaseApy, esRewardApy],
  );

  const mintedDsd = convertWeiToTokens({
    valueWei: new BigNumber(mintedDsdData?.mintedDsdWei || 0),
    token: TOKENS.dsd,
  }).toFixed();

  const [totalBorrowCents, setTotalBorrowCents] = useState(new BigNumber(0));

  useEffect(() => {
    if (!isGetMintedDsdLoading) {
      setTotalBorrowCents(() => new BigNumber(mintedDsd || 0).multipliedBy(100));
    }
  }, [mintedDsdData]);

  const cells: Cell[] = [
    {
      label: t('account.marketBreakdown.cellGroup.collateralRatio'),
      value: formatToReadablePercentage(avgCollateralRatio),
      tooltip: t('account.marketBreakdown.cellGroup.collateralRatioTip'),
      // color: styles.getNetApyColor({ netApyPercentage: netApyPercentage || 0 }),
    },
    {
      label: t('account.marketBreakdown.cellGroup.suppliedAssets'),
      value: formatCentsToReadableValue({ value: totalSupplyCents }),
      tooltip: t('account.marketBreakdown.cellGroup.suppliedAssetsTip'),
    },
    {
      label: t('account.marketBreakdown.cellGroup.totalBorrow'),
      value: formatCentsToReadableValue({ value: totalBorrowCents }),
      tooltip: t('account.marketBreakdown.cellGroup.totalBorrowTip'),
    },
    {
      label: t('account.marketBreakdown.cellGroup.borrowBalance'),
      value: formatCentsToReadableValue({
        value: borrowLimitCents.toNumber() - totalBorrowCents.toNumber(),
      }),
      tooltip: t('account.marketBreakdown.cellGroup.borrowBalanceTip'),
    },
  ];

  return (
    <Paper css={styles.container} className={className} data-testid={TEST_IDS.container}>
      {displayAccountHealth && (
        <div css={styles.accountHealthConatiner}>
          <div css={styles.accountHealth} data-testid={TEST_IDS.accountHealth}>
            <BorrowBalanceAccountHealth
              variant="borrowBalance"
              borrowBalanceCents={totalBorrowCents.toNumber()}
              borrowLimitCents={borrowLimitCents.toNumber()}
              safeBorrowLimitPercentage={SAFE_BORROW_LIMIT_PERCENTAGE}
              css={styles.accountHealthProgressBar}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '4',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex' }}>
                <Typography variant="tiny" style={{ color: 'inherit' }}>
                  {formatToReadablePercentage(apyData?.dsdMintingRewardsApy || 0)} APR in esLTOKEN
                  <br />
                  {formatToReadablePercentage(rebaseApy || 0)} through rebase
                </Typography>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Icon name="shield" css={styles.shieldIcon} />
                <Typography component="span" variant="small1" css={styles.inlineLabel}>
                  {t('myAccount.safeLimit')}
                </Typography>

                <Typography
                  component="span"
                  variant="body1"
                  color="text.primary"
                  css={styles.safeLimit}
                >
                  {readableSafeBorrowLimit}
                </Typography>
                <Tooltip
                  css={styles.tooltip}
                  title={t('myAccount.safeLimitTooltip', { safeBorrowLimitPercentage })}
                >
                  <Icon css={styles.infoIcon} name="info" />
                </Tooltip>
              </div>
            </div>
            {/* <div css={styles.accountHealthFooter}>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1em' }}>
                <Typography variant="tiny" style={{ color: 'inherit', display: 'flex', flex: 1 }}>
                  {formatToReadablePercentage(apyData?.dsdMintingRewardsApy || 0)} APR in esLTOKEN
                  <br />
                  Y% through rebase
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                  <Icon name="shield" css={styles.shieldIcon} />
                  <Typography component="span" variant="small1" css={styles.inlineLabel}>
                    {t('myAccount.safeLimit')}
                  </Typography>

                  <Typography
                    component="span"
                    variant="body1"
                    color="text.primary"
                    css={styles.safeLimit}
                  >
                    {readableSafeBorrowLimit}
                  </Typography>
                  <Tooltip
                    css={styles.tooltip}
                    title={t('myAccount.safeLimitTooltip', { safeBorrowLimitPercentage })}
                  >
                    <Icon css={styles.infoIcon} name="info" />
                  </Tooltip>
                </div>
              </div>
            </div> */}
          </div>
          <div css={styles.accountHealth}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '2px',
                  alignItems: 'center',
                }}
              >
                <Typography component="span" variant="body2" css={styles.inlineLabel}>
                  Rewards APR
                </Typography>
                <Tooltip css={styles.tooltip} title="Reward APR">
                  <Icon css={styles.infoIcon} name="info" />
                </Tooltip>
              </div>

              <Typography component="span" variant="body2" css={styles.inlineLabel}>
                <div css={styles.listItemNewBadge}>
                  <Typography variant="body2" css={styles.listItemNewBadgeText}>
                    {formatToReadablePercentage(
                      netRewardApy >= 100000000 ? '∞' : netRewardApy || '-',
                    )}
                  </Typography>
                </div>
              </Typography>
            </div>
          </div>
        </div>
      )}
      <CellGroup
        smallValues={displayAccountHealth}
        cells={cells}
        css={styles.cellGroup}
        poolAssets={poolAssets}
        data-testid={TEST_IDS.stats}
      />
    </Paper>
  );
};

export default Summary;
