import BigNumber from 'bignumber.js';

const LARGEST_VALUE = 100000000;

const formatPercentage = (value: string | number | BigNumber) => {
  const valueBn = new BigNumber(value);

  if (!valueBn.isFinite() || valueBn.absoluteValue().isGreaterThanOrEqualTo(LARGEST_VALUE)) {
    console.log('LARGE_VALUE_ALERT::', valueBn.toString());
    return new BigNumber(Infinity);
  }

  return +valueBn.dp(2).toFixed();
};

export default formatPercentage;
