/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import { ConnectWallet, EnableToken, ModalProps, Spinner } from 'components';
import React from 'react';
import { useTranslation } from 'translation';
import { Asset, Pool, LLToken } from 'types';
import { areTokensEqual, convertTokensToWei } from 'utilities';

import { useGetPool, useSupply } from 'clients/api';
import { AmountFormProps } from 'containers/AmountForm';
import { useAuth } from 'context/AuthContext';
// import useAssetInfo from 'hooks/useAssetInfo';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { useStyles } from '../styles';
import SupplyForm from './form';

export interface SupplyProps {
  onClose: ModalProps['handleClose'];
  llToken: LLToken;
  poolComptrollerAddress: string;
  forModal?: boolean;
}

export interface SupplyUiProps extends Omit<SupplyProps, 'llToken' | 'poolComptrollerAddress'> {
  onSubmit: AmountFormProps['onSubmit'];
  isLoading: boolean;
  className?: string;
  asset?: Asset;
  pool?: Pool;
  forModal?: boolean;
}

export const SupplyUi: React.FC<SupplyUiProps> = ({
  className,
  asset,
  pool,
  onSubmit,
  isLoading,
  // forModal,
}) => {
  const styles = useStyles();

  const { t } = useTranslation();

  const maxInput = React.useMemo(
    () => asset?.userWalletBalanceTokens || new BigNumber(0),
    [asset, pool],
  );

  if (!asset) {
    return <></>;
  }

  return (
    <div className={className} css={styles.container}>
      <ConnectWallet message={t('supplyWithdrawModal.supply.connectWalletToWithdraw')}>
        {asset && pool ? (
          <EnableToken
            token={asset.llToken.underlyingToken}
            spenderAddress={asset.llToken.address}
            title={t('supplyWithdrawModal.supply.enableToken.title', {
              symbol: asset?.llToken.underlyingToken.symbol,
            })}
          >
            <SupplyForm
              key="form-supply"
              asset={asset}
              pool={pool}
              forModal
              onSubmit={onSubmit}
              inputLabel={t('supplyWithdrawModal.supply.withdrawableAmount')}
              enabledButtonKey={t('supplyWithdrawModal.supply.submitButtonLabel.supply')}
              disabledButtonKey={t('supplyWithdrawModal.supply.submitButtonLabel.enterValidAmount')}
              maxInput={maxInput}
              isTransactionLoading={isLoading}
            />
          </EnableToken>
        ) : (
          <Spinner />
        )}
      </ConnectWallet>
    </div>
  );
};

const SupplyModal: React.FC<SupplyProps> = ({
  llToken,
  poolComptrollerAddress,
  onClose,
  // forModal,
}) => {
  const { accountAddress } = useAuth();
  const styles = useStyles();
  const { data: getPoolData } = useGetPool({ poolComptrollerAddress, accountAddress });
  const pool = getPoolData?.pool;
  const asset = pool?.assets.find(item => areTokensEqual(item.llToken, llToken));

  const { t } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  // const { mutateAsync: redeem, isLoading: isRedeemLoading } = useRedeem({
  //   llToken,
  // });

  // const { mutateAsync: redeemUnderlying, isLoading: isRedeemUnderlyingLoading } =
  //   useRedeemUnderlying({
  //     llToken,
  //   });

  const { mutateAsync: supply, isLoading: isSupplyLoading } = useSupply({
    llToken,
  });

  const onSubmit: AmountFormProps['onSubmit'] = async value => {
    if (!asset) {
      return;
    }

    const amount = new BigNumber(value);

    const supplyAmountWei = convertTokensToWei({
      value: new BigNumber(value),
      token: asset.llToken.underlyingToken,
    });

    const res = await supply({
      amountWei: supplyAmountWei,
    });

    const { transactionHash } = res;

    onClose();

    if (transactionHash) {
      openSuccessfulTransactionModal({
        title: t('supplyWithdrawModal.supply.successfulTransactionModal.title'),
        content: t('supplyWithdrawModal.supply.successfulTransactionModal.message'),
        amount: {
          valueWei: convertTokensToWei({ value: amount, token: llToken.underlyingToken }),
          token: llToken.underlyingToken,
        },
        transactionHash,
      });
    }
  };
  return (
    <SupplyUi
      css={styles.modal}
      onClose={onClose}
      asset={asset}
      pool={pool}
      forModal
      onSubmit={onSubmit}
      isLoading={isSupplyLoading}
    />
  );
};

export default SupplyModal;
