import { useCallback } from 'react';
import { Token } from 'types';

const useAddTokenToWallet = () =>
  useCallback(
    (token?: Token) =>
      token &&
      window.ethereum?.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token.address,
            symbol: token.symbol,
            decimals: token.decimals,
          },
        },
      }),
    [],
  );

export default useAddTokenToWallet;
