import BigNumber from 'bignumber.js';

import { DsdController } from 'types/contracts';

import { GetMintableDsdOutput } from './types';

const formatToProposal = (
  response: Awaited<ReturnType<DsdController['getMintableDSD']>>,
): GetMintableDsdOutput => ({
  mintableDsdWei: new BigNumber(response[1].toString()),
});

export default formatToProposal;
