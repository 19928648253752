import { QueryObserverOptions, useQuery } from 'react-query';

import getDSDReward, {
  GetDSDRewardInput,
  GetDSDRewardOutput,
} from 'clients/api/queries/getDSDReward';
import { useLsFundContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetDSDRewardOutput,
  Error,
  GetDSDRewardOutput,
  GetDSDRewardOutput,
  FunctionKey.GET_DSD_REWARD
>;

const useGetDSDReward = (
  { accountAddress }: Omit<GetDSDRewardInput, 'lsFundContract'>,
  options?: Options,
) => {
  const lsFundContract = useLsFundContract();

  return useQuery(
    FunctionKey.GET_DSD_REWARD,
    () => getDSDReward({ accountAddress, lsFundContract }),
    options,
  );
};

export default useGetDSDReward;
