/** @jsxImportSource @emotion/react */
import { useMediaQuery } from '@mui/material';
import { Spinner } from 'components';
import React from 'react';
import { useTranslation } from 'translation';

import { FarmInfo, useGetFarms } from 'clients/api';
// import useLtokenVaultPoolsInfo from 'clients/api/queries/useLtokenVaultPoolsInfo';
// import useLtokenVaultUserInfoV1 from 'clients/api/queries/useLtokenVaultUserInfoV1';
import { FarmTable } from 'containers/FarmTable';
import { useHideXlDownCss, useShowXlDownCss } from 'hooks/responsive';
import { BREAKPOINTS } from 'theme/MuiThemeProvider/muiTheme';

import { useStyles } from './styles';

interface FarmUiProps {
  farms: FarmInfo[];
}

export const FarmUi: React.FC<FarmUiProps> = ({ farms }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const showXlDownCss = useShowXlDownCss();
  const hideXlDownCss = useHideXlDownCss();
  const showXlDown = useMediaQuery(`(max-width: ${BREAKPOINTS.values.xl}px)`);

  return showXlDown ? (
    <div css={showXlDownCss}>
      <FarmTable vaults={farms} key="dashboard-supply-market-table" />
    </div>
  ) : (
    <div css={[styles.desktopFarmTables, hideXlDownCss]}>
      <FarmTable vaults={farms} title={t('farm.title')} />
    </div>
  );
};

const Farm: React.FC = () => {
  //   const { data: poolsInfo, isLoading: poolsLoading } = useLtokenVaultPoolsInfo();
  // const { data: userInfo, isLoading: userInfoLoading } = useLtokenVaultUserInfoV1();
  const { data: farmInfo, isLoading: farmIsLoading, error } = useGetFarms();
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.debug({ farmInfo });
  if (farmIsLoading) return <Spinner />;
  if (error || !farmInfo) return <h2>No Farm found</h2>;
  return <FarmUi farms={farmInfo || []} />;
};

export default Farm;
