enum FunctionKey {
  // Queries
  GET_BLOCK_NUMBER = 'GET_BLOCK_NUMBER',
  GET_DSD_CALCULATE_REPAY_AMOUNT = 'GET_DSD_CALCULATE_REPAY_AMOUNT',
  GET_DSD_REPAY_AMOUNT_WITH_INTERESTS = 'GET_DSD_REPAY_AMOUNT_WITH_INTERESTS',
  GET_DSD_TREASURY_PERCENTAGE = 'GET_DSD_TREASURY_PERCENTAGE',
  GET_MAIN_MARKETS = 'GET_MAIN_MARKETS',
  GET_MARKET_HISTORY = 'GET_MARKET_HISTORY',
  GET_MAIN_ASSETS_IN_ACCOUNT = 'GET_MAIN_ASSETS_IN_ACCOUNT',
  GET_SUBGRAPH_ISOLATED_POOLS = 'GET_SUBGRAPH_ISOLATED_POOLS',
  GET_VENUS_DSD_STATE = 'GET_VENUS_DSD_STATE',
  GET_MINTED_DSD = 'GET_MINTED_DSD',
  GET_WHITELISTED_LPS = 'GET_WHITELISTED_LPS',
  GET_ESLTOKEN_REWARD = 'GET_ESLTOKEN_REWARD',
  GET_DSD_REWARD = 'GET_DSD_REWARD',
  GET_PENDING_REWARDS = 'GET_PENDING_REWARDS',
  GET_PENDING_MULTIREWARDS = 'GET_PENDING_MULTIREWARDS',
  GET_TOKEN_ALLOWANCE = 'GET_TOKEN_ALLOWANCE',
  GET_BALANCE_OF = 'GET_BALANCE_OF',
  GET_TOKEN_BALANCES = 'GET_TOKEN_BALANCES',
  GET_MINTABLE_DSD = 'GET_MINTABLE_DSD',
  GET_VRT_CONVERSION_END_TIME = 'GET_VRT_CONVERSION_END_TIME',
  GET_VRT_CONVERSION_RATIO = 'GET_VRT_CONVERSION_RATIO',
  GET_LTOKEN_WITHDRAWABLE_AMOUNT = 'GET_LTOKEN_WITHDRAWABLE_AMOUNT',
  GET_VENUS_DSD_VAULT_DAILY_RATE = 'GET_VENUS_DSD_VAULT_DAILY_RATE',
  GET_VENUS_DSD_VAULT_RATE = 'GET_VENUS_DSD_VAULT_RATE',
  GET_V_TOKEN_BALANCE = 'GET_V_TOKEN_BALANCE',
  GET_V_TOKEN_BALANCES_ALL = 'GET_V_TOKEN_BALANCES_ALL',
  GET_V_TOKEN_INTEREST_RATE_MODEL = 'GET_V_TOKEN_INTEREST_RATE_MODEL',
  GET_V_TOKEN_APY_SIMULATIONS = 'GET_V_TOKEN_APY_SIMULATIONS',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_LTOKEN_VAULT_REWARD_PER_BLOCK = 'GET_LTOKEN_VAULT_REWARD_PER_BLOCK',
  GET_LTOKEN_VAULT_TOTAL_ALLOCATION_POINTS = 'GET_LTOKEN_VAULT_TOTAL_ALLOCATION_POINTS',
  GET_LTOKEN_VAULT_POOL_INFOS = 'GET_LTOKEN_VAULT_POOL_INFOS',
  GET_LTOKEN_VAULT_USER_INFO = 'GET_LTOKEN_VAULT_USER_INFO',
  GET_LTOKEN_VAULT_PENDING_WITHDRAWALS_FROM_BEFORE_UPGRADE = 'GET_LTOKEN_VAULT_PENDING_WITHDRAWALS_FROM_BEFORE_UPGRADE',
  GET_LTOKEN_VAULT_POOLS_COUNT = 'GET_LTOKEN_VAULT_POOLS_COUNT',
  GET_LTOKEN_VAULT_WITHDRAWAL_REQUESTS = 'GET_LTOKEN_VAULT_WITHDRAWAL_REQUESTS',
  GET_CURRENT_VOTES = 'GET_CURRENT_VOTES',
  GET_VOTERS = 'GET_VOTERS',
  GET_PENDING_LTOKEN = 'GET_PENDING_LTOKEN',
  GET_PROPOSALS = 'GET_PROPOSALS',
  GET_PROPOSAL = 'GET_PROPOSAL',
  GET_VOTE_RECEIPT = 'GET_VOTE_RECEIPT',
  GET_DSD_VAULT_USER_INFO = 'GET_DSD_VAULT_USER_INFO',
  GET_VOTE_DELEGATE_ADDRESS = 'GET_VOTE_DELEGATE_ADDRESS',
  GET_PROPOSAL_STATE = 'GET_PROPOSAL_STATE',
  GET_LATEST_PROPOSAL_ID_BY_PROPOSER = 'GET_LATEST_PROPOSAL_ID_BY_PROPOSER',
  GET_VRT_VAULT_INTEREST_RATE_PER_BLOCK = 'GET_VRT_VAULT_INTEREST_RATE_PER_BLOCK',
  GET_VRT_VAULT_USER_INFO = 'GET_VRT_VAULT_USER_INFO',
  GET_VRT_VAULT_ACCRUED_INTEREST = 'GET_VRT_VAULT_ACCRUED_INTEREST',
  GET_VOTER_ACCOUNTS = 'GET_VOTER_ACCOUNTS',
  GET_VOTER_DETAILS = 'GET_VOTER_DETAILS',
  GET_VOTER_HISTORY = 'GET_VOTER_HISTORY',
  GET_PROPOSAL_THRESHOLD = 'GET_PROPOSAL_THRESHOLD',
  GET_PROPOSAL_ETA = 'GET_PROPOSAL_ETA',
  GET_PANCAKE_SWAP_PAIRS = 'GET_PANCAKE_SWAP_PAIRS',
  GET_DSD_REPAY_APY = 'GET_DSD_REPAY_APY',
  GET_ISOLATED_POOLS = 'GET_ISOLATED_POOLS',
  GET_FARMS = 'GET_FARMS',
  GET_DSD_REDEEMABLES = 'GET_DSD_REDEEMABLES',
  GET_GET_ESLTOKEN_BOOST_DATA = 'GET_ESLTOKEN_BOOST_DATA',
  GET_VEST_DURATION = 'GET_VEST_DURATION',
  GET_DSD_SUPPLY = 'GET_DSD_SUPPLY',
  GET_ESLTOKEN_FEES = 'GET_ESLTOKEN_FEES',
  GET_TOKEN_PRICES = 'GET_TOKEN_PRICES',

  // Mutations
  MINT_DSD = 'MINT_DSD',
  ENTER_MARKETS = 'ENTER_MARKETS',
  EXIT_MARKET = 'EXIT_MARKET',
  REPAY_DSD = 'REPAY_DSD',
  APPROVE_TOKEN = 'APPROVE_TOKEN',
  APPROVE_VRT = 'APPROVE_VRT',
  CONVERT_VRT = 'CONVERT_VRT',
  CONVERT_ESLTOKEN = 'CONVERT_ESLTOKEN',
  REDEEM_DSD = 'REDEEM_DSD',
  SUPPLY = 'SUPPLY',
  REDEEM = 'REDEEM',
  REDEEM_UNDERLYING = 'REDEEM_UNDERLYING',
  REPAY = 'REPAY',
  BORROW = 'BORROW',
  MIGRATE = 'MIGRATE',
  WITHDRAW_LTOKEN = 'WITHDRAW_LTOKEN',
  CREATE_PROPOSAL = 'CREATE_PROPOSAL',
  EXECUTE_PROPOSAL = 'EXECUTE_PROPOSAL',
  QUEUE_PROPOSAL = 'QUEUE_PROPOSAL',
  CANCEL_PROPOSAL = 'CANCEL_PROPOSAL',
  SET_VOTE_DELEGATE = 'SET_VOTE_DELEGATE',
  CAST_VOTE = 'CAST_VOTE',
  CAST_VOTE_WITH_REASON = 'CAST_VOTE_WITH_REASON',
  STAKE_IN_LTOKEN_VAULT = 'STAKE_IN_LTOKEN_VAULT',
  STAKE_IN_DSD_VAULT = 'STAKE_IN_DSD_VAULT',
  STAKE_IN_FARM = 'STAKE_IN_FARM',
  STAKE_IN_VRT_VAULT = 'STAKE_IN_VRT_VAULT',
  WITHDRAW_FROM_FARM = 'WITHDRAW_FROM_FARM',
  WITHDRAW_FROM_DSD_VAULT = 'WITHDRAW_FROM_DSD_VAULT',
  WITHDRAW_FROM_VRT_VAULT = 'WITHDRAW_FROM_VRT_VAULT',
  REQUEST_WITHDRAWAL_FROM_LTOKEN_VAULT = 'REQUEST_WITHDRAWAL_FROM_LTOKEN_VAULT',
  EXECUTE_WITHDRAWAL_FROM_LTOKEN_VAULT = 'EXECUTE_WITHDRAWAL_FROM_LTOKEN_VAULT',
  SWAP_TOKENS = 'SWAP_TOKENS',
  SWAP_TOKENS_AND_REPAY = 'SWAP_TOKENS_AND_REPAY',
  SWAP_TOKENS_AND_SUPPLY = 'SWAP_TOKENS_AND_SUPPLY',
  CLAIM_REWARDS = 'CLAIM_REWARDS',
  LOCK_LTOKEN = 'LOCK_LTOKEN',
  UNLOCK_LTOKEN = 'UNLOCK_LTOKEN',
}

export default FunctionKey;
