import { useQuery } from 'react-query';
import { restService } from 'utilities';

import { useAuth } from 'context/AuthContext';

export interface APYData {
  apyForHoldingDsd: string;
  dsdMintingRewardsApy: string;
  tokenData: {
    [token: string]: {
      dsdRewardsApy: string;
      generalRebaseApy: string;
      esTokenRebaseApy: string;
      esTokenApyOnProvidingCollateral: string;
      tokenUsdPrice: string;
    };
  };
}

const useGetApyData = () => {
  const { accountAddress } = useAuth();
  return useQuery(['apys', accountAddress], async () => {
    const { data, status } = await restService<APYData>({
      endpoint: '/apy',
      method: 'GET',
    });
    if (status !== 200 || !data?.status) throw new Error('Failed APY fetching...');
    console.log(data.data);
    return data.data;
  });
};

export default useGetApyData;
