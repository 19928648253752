/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material';
import { ContractReceipt } from 'ethers';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'translation';
import { convertTokensToWei, convertWeiToTokens } from 'utilities';

import { claimMultiRewards, queryClient, useClaimEsLTOKENRewards } from 'clients/api';
import { getMultiRewardContract } from 'clients/contracts';
import FunctionKey from 'constants/functionKey';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import { ButtonProps, PrimaryButton } from '../../Button';
import { Modal } from '../../Modal';
import { TokenIcon } from '../../TokenIcon';
import { PendingReward } from '../ClaimMultiRewardButton/types';
import TEST_IDS from '../testIds';
import { useStyles } from './styles';

export interface ClaimEsLTOKENRewardButtonUiProps extends ClaimEsLTOKENRewardButtonProps {
  isModalOpen: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
}

export const ClaimEsLTOKENRewardButtonUi: React.FC<ClaimEsLTOKENRewardButtonUiProps> = ({
  rewards,
  isModalOpen,
  onOpenModal,
  onCloseModal,
  ...otherButtonProps
}) => {
  const { accountAddress, signer } = useAuth();
  const { t } = useTranslation();
  const styles = useStyles();
  const handleTransactionMutation = useHandleTransactionMutation();

  const { mutateAsync: claimEsLTOKENRewards, isLoading: isClaimingRewards } =
    useClaimEsLTOKENRewards();

  const handleClaimReward = () => {
    rewards.forEach(({ rewardToken, rewardAmountWei, fromMinter, multiRewardContractAddress }) => {
      if (fromMinter || !multiRewardContractAddress) {
        handleTransactionMutation({
          mutate: async () => {
            const contractReceipt: ContractReceipt = await claimEsLTOKENRewards({});
            onCloseModal();
            return contractReceipt;
          },
          successTransactionModalProps: (contractReceipt: ContractReceipt) => ({
            title: t('claimReward.successfulTransactionModal.title'),
            content: t('claimReward.successfulTransactionModal.message'),
            amount: {
              valueWei: convertTokensToWei({
                value: rewardAmountWei.div(1e18),
                token: TOKENS.esLTOKEN,
              }),
              token: TOKENS.esLTOKEN,
            },
            transactionHash: contractReceipt.transactionHash,
          }),
        });
        queryClient.invalidateQueries([FunctionKey.GET_ESLTOKEN_REWARD]);
      } else {
        const multiRewardContract = getMultiRewardContract(multiRewardContractAddress, signer);
        handleTransactionMutation({
          mutate: async () => {
            const contractReceipt = await claimMultiRewards({ multiRewardContract });
            return contractReceipt;
          },
          successTransactionModalProps: contractReceipt => ({
            title: t('claimReward.successfulTransactionModal.title'),
            content: t('claimReward.successfulTransactionModal.message'),
            transactionHash: contractReceipt.transactionHash,
            amount: {
              valueWei: convertTokensToWei({
                value: rewardAmountWei.div(1e18),
                token: rewardToken,
              }),
              token: rewardToken,
            },
          }),
        });
        queryClient.invalidateQueries([
          `GET_PENDING_MULTIREWARDS${rewardToken.address}`,
          accountAddress,
        ]);
      }
    });
  };

  return (
    <>
      <PrimaryButton
        data-testid={TEST_IDS.claimRewardOpenModalButton}
        onClick={onOpenModal}
        {...otherButtonProps}
      >
        {t('claimReward.openModalButton.label')}
      </PrimaryButton>

      <Modal
        isOpen={isModalOpen}
        handleClose={onCloseModal}
        title={<h5>{t('claimReward.modal.title')}</h5>}
      >
        <>
          <div css={styles.groupItem}>
            {rewards.map(({ rewardToken, rewardAmountWei, fromMinter, fromFees }) => (
              <Fragment key={rewardToken.address}>
                <Box width="100%" display="flex" gap={2} alignItems="center">
                  <TokenIcon token={TOKENS.esLTOKEN} css={styles.rewardTokenIcon} />
                  <Typography css={styles.rewardAmount} variant="body2">
                    {convertWeiToTokens({
                      valueWei: rewardAmountWei,
                      token: rewardToken,
                      returnInReadableFormat: true,
                      minimizeDecimals: true,
                    })}
                  </Typography>
                  {fromMinter && '(DSD Minting Rewards)'}
                  {fromFees && '(Protocol Fees)'}
                </Box>
              </Fragment>
            ))}
          </div>

          <PrimaryButton
            onClick={handleClaimReward}
            fullWidth
            data-testid={TEST_IDS.claimRewardSubmitButton}
            loading={isClaimingRewards}
          >
            {t('claimReward.claimButton.enabledLabel')}
          </PrimaryButton>
        </>
      </Modal>
    </>
  );
};

export type ClaimEsLTOKENRewardButtonProps = Omit<ButtonProps, 'onClick'> & {
  rewards: (PendingReward & {
    fromMinter?: boolean;
    multiRewardContractAddress?: string;
    fromFees?: boolean;
  })[];
};

export const ClaimEsLTOKENRewardButton: React.FC<ClaimEsLTOKENRewardButtonProps> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <ClaimEsLTOKENRewardButtonUi
      isModalOpen={isModalOpen}
      onOpenModal={handleOpenModal}
      onCloseModal={handleCloseModal}
      {...props}
    />
  );
};

export default ClaimEsLTOKENRewardButton;
