import BigNumber from 'bignumber.js';
import { getTokenByAddress } from 'utilities';

import { TokenDataWithRewardsInfo } from './getCollateralRewards';
import { DistributionEventSignature } from './getEventsBasedData';

const d = (v: BigNumber) => v.dp(18, BigNumber.ROUND_DOWN).toFixed();
const BnZero = new BigNumber(0);

type Log = {
  name: string;
  value?: string;
  calculatedAs: string;
};

type RewardData = {
  collateralData: Record<string, TokenDataWithRewardsInfo>;
  eventsData: {
    dsdTotalSupplyDiff: BigNumber;
    distributionDataForLastDay: {
      [tokenAddress: string]: DistributionEventSignature;
    };
  };
  externalApyData: {
    [tokenAddress: string]: Omit<Log, 'calculatedAs' | 'value'> & {
      url: string;
      source: string;
      readAs: string;
      value: number;
    };
  };
  remainingData: {
    dsdOraclePrice: BigNumber;
    dsdTotalSupply: BigNumber;
    dsdNonRebasingSupply: BigNumber;
    esLTokenTotalSupply: BigNumber;
    esLTokenMinterRewardRate: BigNumber;
    dsdCirculatingSupply: BigNumber;
    fundRewardAmountFor24Hours: BigNumber;
  };
};

const logCollateralRewards = (
  {
    collateralData,
    remainingData,
    externalApyData,
    eventsData: { dsdTotalSupplyDiff, distributionDataForLastDay },
  }: RewardData,
  esTokenOraclePrice: BigNumber,
) => {
  const logData = Object.entries(collateralData).map(
    ([
      tokenAddress,
      {
        exchangeRateCurrent,
        oraclePrice,
        decimals,
        totalSupply,
        underlyingDecimals,
        esLTOKENShareInBps,
        multiRewardShareInBps,
        dsdRewardAmountFor24Hours,
        esLTokenRewardAmountFor30Days,
      },
    ]) => {
      const token = getTokenByAddress(tokenAddress);
      const generalRebaseShareInBps = new BigNumber(1e4).minus(
        multiRewardShareInBps.plus(esLTOKENShareInBps),
      );
      const collateralInUsd = totalSupply
        .multipliedBy(exchangeRateCurrent)
        .multipliedBy(oraclePrice)
        .multipliedBy(10 ** (18 - underlyingDecimals))
        .div(1e36);
      const tokenPrice = exchangeRateCurrent
        .multipliedBy(oraclePrice)
        .dividedBy(1e36)
        .dividedBy(10 ** (18 - decimals) /* LLToken decimals */);
      return {
        [tokenAddress]: [
          {
            name: 'Token Symbol {symbol}',
            calculatedAs: 'token.symbol',
            value: token?.symbol,
          },
          {
            name: '$ price of token {tokenPrice}',
            calculatedAs:
              '(exchangeRate {in 1e18} * oraclePrice {in 1e18}) / 1e36 / 10 ^ (18 - tokenDecimals) / 1e18 {to make readable}',
            value: d(tokenPrice),
          },
          {
            name: 'Total Supply of token {totalSupply}',
            calculatedAs: '`totalSupply` from contract',
            value: d(totalSupply),
          },
          {
            name: 'Total Supply converted to USD {collateralInUsd}',
            calculatedAs:
              '(totalSupply {in 1e18} * oraclePrice {in 1e18}) * 10 ^ (18 - underlyingTokenDecimals) / 1e36',
            value: d(collateralInUsd),
          },
          {
            name: 'Amount that represents total share for reward distribution {totalRebaseShare}',
            calculatedAs: 'constant (1e4)',
            value: 1e4,
          },
          {
            name: 'Share assigned to MultiReward contract for this token {multiRewardShare}',
            calculatedAs: '`multiRewardShareInBps` method on token contract',
            value: d(multiRewardShareInBps),
          },
          {
            name: 'Share assigned to EsLToken contract for this token {EsLTokenRewardShare}',
            calculatedAs: '`esLTOKENShareInBps` method on token contract',
            value: d(esLTOKENShareInBps),
          },
          {
            name: 'Share left that will be distributed as general rebase among all collateral providers {generalRebaseShare}',
            calculatedAs:
              '`totalRebaseShare` {from previous step} - (`multiRewardShare` {from previous step} + `EsLTokenRewardShare` {from previous step})',
            value: d(generalRebaseShareInBps),
          },
          // MultiReward reward amounts for different tokens
          {
            name: 'EsLToken 30 days distribution amount based on MultiReward {EsLTokenDistributedPerMonth_FromMultiReward}',
            calculatedAs:
              '`getRewardForDuration` with rewardToken i.e. esLToken token address on MultiReward of this token contract',
            value: d(esLTokenRewardAmountFor30Days),
          },
          {
            name: 'DSD daily distribution amount based on MultiReward {DsdDistributedPerDay_FromMultiReward}',
            calculatedAs:
              '`getRewardForDuration` with rewardToken i.e. DSD token address on MultiReward of this token contract',
            value: d(dsdRewardAmountFor24Hours),
          },
          // Amount from Fund contract
          {
            name: 'DSD daily distribution amount based on Fund contract {DsdDistributedPerDay_FromFund}',
            calculatedAs: '`rewardForDuration` getter on fund contract',
            value: d(remainingData.fundRewardAmountFor24Hours),
          },
          // MultiReward APRs for different tokens
          {
            name: 'EsLToken APR based on the formula provided in clickup {EsLTokenApyOnProvidingCollateral}',
            calculatedAs:
              '`EsLTokenDistributedPerMonth_FromMultiReward` {from step above} * EsTokenOrcalePrice {taken as $0.1 in 1e18 units} * 12 {Number of times it is updated in a year} / `collateralInUsd` {from previous step} / 1e18 {to normalize decimals} * 100 {to convert to percentage}',
            value: d(
              esLTokenRewardAmountFor30Days
                .multipliedBy(esTokenOraclePrice)
                .multipliedBy(12)
                .dividedBy(collateralInUsd)
                .dividedBy(1e18)
                .multipliedBy(100),
            ),
          },
          {
            name: 'DSD APR based on the formula provided in clickup {DsdApyOnProvidingCollateral}',
            calculatedAs:
              '`DsdDistributedPerDay_FromMultiReward` {from step above} * DsdOrcalePrice {read from Oracle & if fixed $1 in 1e18 units there} * 365 {Number of times it is updated in a year} / `collateralInUsd` {from previous step} / 1e18 {to normalize decimals} * 100 {to convert to percentage}',
            value: d(
              dsdRewardAmountFor24Hours
                .multipliedBy(remainingData.dsdOraclePrice)
                .multipliedBy(365)
                .dividedBy(collateralInUsd)
                .dividedBy(1e18)
                .multipliedBy(100),
            ),
          },
          // Projected APR/APY below this
          {
            name: 'APY from external source (externalAPY)',
            calculatedAs: 'Read from the protocol websites or API directly',
            value: externalApyData[tokenAddress]?.value,
          },
          {
            name: 'MultiReward projected APY (projected multiRewardsApy)',
            calculatedAs:
              'APY from 3rd party * `multiRewardShare` {from previous step} / 1e4 {totalRebaseShare from previous step}',
            value: d(
              new BigNumber(externalApyData[tokenAddress]?.value || BnZero)
                .multipliedBy(multiRewardShareInBps)
                .dividedBy(1e4),
            ),
          },
          {
            name: 'EsLToken projected APY (projected EsLTokenRewardsAPY)',
            calculatedAs:
              'APY from 3rd party * `EsLTokenRewardShare` {from previous step} / 1e4 {totalRebaseShare from previous step}',
            value: d(
              new BigNumber(externalApyData[tokenAddress]?.value || BnZero)
                .multipliedBy(esLTOKENShareInBps)
                .dividedBy(1e4),
            ),
          },
          {
            name: 'General rebase projected APY (projected generalRebaseAPY)',
            calculatedAs:
              'APY from 3rd party * `generalRebaseShare` {from previous step} / 1e4 {totalRebaseShare from previous step}',
            value: d(
              new BigNumber(externalApyData[tokenAddress]?.value || BnZero)
                .multipliedBy(generalRebaseShareInBps)
                .dividedBy(1e4),
            ),
          },
          // Events data below this
          {
            name: 'Latest amount to MultiReward from Distributed event of token (multiRewardDistributionAmount)',
            calculatedAs:
              'Amount emitted for MultiReward {`mr` value from Distribution event on token }',
            value: d(
              new BigNumber(distributionDataForLastDay[tokenAddress]?.mrRebaseAmount || BnZero),
            ),
          },
          {
            name: 'Latest amount to Fund from Distributed event of token (fundDistributionAmount)',
            calculatedAs:
              'Amount emitted for Fund contract, to be sent as fee -AND/OR- Rewards on holding esLToken {`fund` value from Distribution event on token }',
            value: d(
              new BigNumber(distributionDataForLastDay[tokenAddress]?.fundRebaseAmount || BnZero),
            ),
          },
          {
            name: 'Latest amount to send as general rebase from Distributed event of token (generalDistributionAmount)',
            calculatedAs:
              'Amount emitted for General rebase {`dsd` value from Distribution event on token }',
            value: d(
              new BigNumber(distributionDataForLastDay[tokenAddress]?.dsdRebaseAmount || BnZero),
            ),
          },
        ] as Log[],
      };
    },
  );

  const otherLogs: Log[] = [
    {
      name: 'DSD total supply (dsdTotalSupply)',
      value: d(remainingData.dsdTotalSupply),
      calculatedAs: '`totalSupply` function on DSD contract',
    },
    {
      name: 'DSD non-rebasing supply (dsdNonRebasingSupply)',
      value: d(remainingData.dsdNonRebasingSupply),
      calculatedAs: '`totalSupply` function on DSD contract',
    },
    {
      name: '$ price of DSD (dsdOraclePrice)',
      value: d(remainingData.dsdOraclePrice),
      calculatedAs: 'Fetched directly from self owned Oracle',
    },
    {
      name: '$ DSD in circulation (dsdInCirculationUsd)',
      value: d(remainingData.dsdCirculatingSupply),
      calculatedAs:
        '(`dsdTotalSupply` {from previous step} - `dsdNonRebasingSupply` {from previous step}) * `dsdOraclePrice` {from previous step} / 1e18 {to normalize decimals}',
    },
    {
      name: 'EsLToken emitted per day from EsLTokenMinter (esLTokenDailyEmission)',
      value: d(remainingData.esLTokenMinterRewardRate.multipliedBy(86400)),
      calculatedAs:
        '`rewardRate` function on EsLTokenMinter contract * 86400 {Number of seconds in a day}',
    },
    {
      name: 'APY for DSD minting rewards, based on `EsLTokenMinter` (dsdMintingRewards)',
      value: d(
        remainingData.esLTokenMinterRewardRate
          .multipliedBy(86400)
          .multipliedBy(esTokenOraclePrice)
          .multipliedBy(12)
          .dividedBy(remainingData.dsdCirculatingSupply)
          .multipliedBy(100),
      ),
      calculatedAs:
        '`esLTokenDailyEmission` {from previous step} * `dsdInCirculationUsd` {from previous step} * 12 {Number of months as the value is updated monthly} * 100 {to convert to percentage}',
    },
    // APR for fund emission
    {
      name: 'DSD APR based on the formula provided in clickup {DsdApyOnFeesAsRewardFromYield}',
      calculatedAs:
        '`DsdDistributedPerDay_FromFund` {from step above} * 365 {Number of times it is updated in a year} / `esLTokenTotalSupplyInUsd` {esLToken totalSupply * esLToken oracle price, currently constant as $0.1 / 1e18 to normalize decimals} / 1e18 {to normalize decimals} * 100 {to convert to percentage}',
      value: d(
        remainingData.fundRewardAmountFor24Hours
          .multipliedBy(365)
          .dividedBy(remainingData.esLTokenTotalSupply.multipliedBy(esTokenOraclePrice).div(1e18))
          .dividedBy(1e18)
          .multipliedBy(100),
      ),
    },
    {
      name: 'DSD totalSupply difference due to rebase {dsdRebaseIncrease}',
      calculatedAs:
        '`TokenSupplyChanged` event on DSD read till same as time as of now for last day & reading the last value, then getting a difference from current totalSupply',
      value: d(dsdTotalSupplyDiff),
    },
    {
      name: 'General rebase APR based on the formula provided in clickup {DsdGeneralRebaseAPYFromYield}',
      calculatedAs:
        '`dsdRebaseIncrease` {from previous step} * DsdOrcalePrice {read from Oracle & is fixed $1 in 1e18 units there} * 365 {Number of times it is updated in a year} / `dsdInCirculationUsd` {from previous step} / 1e18 {to normalize decimals} * 100 {to convert to percentage}',
      value: d(
        dsdTotalSupplyDiff
          .multipliedBy(remainingData.dsdOraclePrice)
          .multipliedBy(365)
          .dividedBy(remainingData.dsdCirculatingSupply)
          .dividedBy(1e18)
          .multipliedBy(100),
      ),
    },
  ];

  console.group('External APY data');
  console.debug(JSON.stringify(externalApyData, null, 2));
  console.groupEnd();

  console.group('Collateral Rewards');
  logData.forEach(v => console.debug(JSON.stringify(v, null, 2)));
  console.groupEnd();

  console.group('DSD & Minter data');
  otherLogs.forEach(v => console.debug(JSON.stringify(v, null, 2)));
  console.groupEnd();
};

export default logCollateralRewards;
