import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: ({ forModal }: { forModal: boolean | undefined }) => css`
      display: flex;
      flex-direction: ${forModal ? 'column' : 'row'};
      gap: ${forModal ? '1em' : '0'};
      align-items: center;
    `,
    icon: ({ forModal }: { forModal: boolean | undefined }) => css`
      margin-right: ${theme.spacing(2)};
      width: ${theme.shape.iconSize[forModal ? 'xxLarge' : 'large']}px;
      height: ${theme.shape.iconSize[forModal ? 'xxLarge' : 'large']}px;
    `,
  };
};
