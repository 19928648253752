import BigNumber from 'bignumber.js';
import config from 'config';
import { checkForDsdControllerTransactionError } from 'errors';
import { ContractReceipt, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { LLToken } from 'types';

import brand from 'config/brand';
import vErc20Abi from 'constants/contracts/abis/vErc20.json';
import { DsdController } from 'types/contracts';

export interface RedeemDsdInput {
  dsdControllerContract: DsdController;
  llToken: LLToken;
  amountWei: BigNumber;
  useTeamAsRedemptionProvider: boolean;
}

export type RedeemDsdOutput = ContractReceipt;

const redeemDsd = async ({
  dsdControllerContract,
  llToken,
  amountWei,
  useTeamAsRedemptionProvider,
}: RedeemDsdInput): Promise<RedeemDsdOutput> => {
  let transaction;
  const userAddress = await dsdControllerContract.signer.getAddress();

  if (brand.customRedemptionProvider.enable) {
    // 🟢 use custom Redemption Provider
    const provider = new ethers.providers.JsonRpcProvider(config.rpcUrl);
    console.log('@redemptionProvider:: enabled:: impersonating team address');
    const teamAddr = brand.customRedemptionProvider.teamAddress;
    const teamAddrSigner = provider.getSigner(teamAddr);
    await provider.send('hardhat_impersonateAccount', [teamAddr]);

    if ((await provider.getBalance(teamAddr)).lt(parseEther('10'))) {
      await provider.send('hardhat_setBalance', [teamAddr, parseEther('10').toHexString()]);
    }

    const lltoken = new ethers.Contract(llToken.address, vErc20Abi, teamAddrSigner);
    try {
      await lltoken.connect(teamAddrSigner).becomeRedemptionProvider();
      console.log(
        '@redemptionProvider::',
        '='.repeat(20),
        `Team Address ${teamAddr} is set as Redemption Provider`,
        '='.repeat(20),
      );
    } catch (e) {
      console.log('@redemptionProvider:: assuming: already a provider');
    }
  }

  if (useTeamAsRedemptionProvider) {
    console.log(
      '@redemptionProvider::useTeamAsRedemptionProvider call:: _provider:',
      brand.customRedemptionProvider.teamAddress,
    );
    transaction = await dsdControllerContract.rigidRedeem(
      brand.customRedemptionProvider.teamAddress,
      userAddress,
      amountWei.toFixed(),
    );
  } else {
    transaction = await dsdControllerContract.redeemFromYield(llToken.address, amountWei.toFixed());
  }
  const receipt = transaction.wait(1);
  checkForDsdControllerTransactionError(await receipt);
  return receipt;
};

export default redeemDsd;
