/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import React from 'react';
import { convertWeiToTokens } from 'utilities';

import { TOKENS } from 'constants/tokens';

import { TokenIcon } from '../../../TokenIcon';
import { useStyles } from './styles';

export interface RewardGroupProps {
  pendingReward: BigNumber;
}

export const RewardGroup: React.FC<RewardGroupProps> = ({ pendingReward }) => {
  const styles = useStyles();

  return (
    <div css={styles.container}>
      <div css={styles.groupItem} key={`reward-group-${TOKENS.dsd.address}`}>
        <TokenIcon token={TOKENS.dsd} css={styles.rewardTokenIcon} />

        <Typography css={styles.rewardAmount}>
          {convertWeiToTokens({
            valueWei: pendingReward,
            token: TOKENS.dsd,
            returnInReadableFormat: true,
          })}
        </Typography>
      </div>
    </div>
  );
};
