/** @jsxImportSource @emotion/react */
// import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Spinner } from 'components';

import React from 'react';
// import { useTranslation } from 'translation';
// import { ReactComponent as MetamaskIcon } from 'assets/img/metamask.svg';
import { useEscrowLToken, useGetBalanceOf } from 'clients/api';
// import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';

// import useAddTokenToWallet from 'hooks/useAddTokenToWallet';
import Convert, { ConvertProps } from './Stake';

// import { useStyles } from './styles';

export const EscrowLTokenUi = ({ userLTOKENBalanceWei, escrowLoading, escrow }: ConvertProps) => (
  <>
    <Paper>
      {/* <div css={{ marginBottom: '1em' }}>
          <Typography
            variant="h2"
            css={{ width: 'fit-content', margin: 'auto', position: 'relative' }}
          >
            {t('lockLTOKEN.stakeLTOKEN', { rewardToken: brand.rewardTokenSymbol })}
            <MetamaskIcon css={styles.mmIcon} onClick={() => addTokenToWallet(TOKENS.ltoken)} />
          </Typography>
        </div> */}
      <Convert
        escrow={escrow}
        escrowLoading={escrowLoading}
        userLTOKENBalanceWei={userLTOKENBalanceWei}
      />
    </Paper>
  </>
);

const EscrowLToken = () => {
  const { accountAddress } = useAuth();
  const { mutateAsync: escrowLToken, isLoading: isEscrowLoading } = useEscrowLToken();

  const { data: userLTOKENBalanceData } = useGetBalanceOf(
    { accountAddress: accountAddress || '', token: TOKENS.ltoken },
    { enabled: !!accountAddress },
  );

  if (userLTOKENBalanceData) {
    return (
      <EscrowLTokenUi
        escrow={escrowLToken}
        escrowLoading={isEscrowLoading}
        userLTOKENBalanceWei={userLTOKENBalanceData.balanceWei}
      />
    );
  }

  // TODO - Handle error state
  return <Spinner />;
};

export default EscrowLToken;
