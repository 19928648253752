/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@mui/material';
import { TextField } from 'components';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Asset } from 'types';

import balancerImg from 'assets/img/tokens/balancer.svg';
import crvImg from 'assets/img/tokens/curve.svg';
import ethImg from 'assets/img/tokens/eth.svg';
import yearnImg from 'assets/img/tokens/yearn.svg';
import brand from 'config/brand';
import { routes } from 'constants/routing';
import { PoolAsset } from 'containers/MarketTable/types';
import pendleImg from 'assets/img/tokens/pendle.svg';

import * as C from './styles.module.css';

interface CollateralSelectorProps {
  searchValue: string;
  onSearchInputChange: (newValue: string) => void;
  poolAssets: PoolAsset[];
  isFetchingPools?: boolean;
}

interface CollateralSelectUiProps {
  searchValue: string;
  handleSearchInputChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  poolAssets: PoolAsset[];
}

const CollateralTypeSelector: React.FC<{
  setCollateralTypeHandler: (_type: string) => void;
  typesWithColls: Record<string, unknown>;
}> = ({ setCollateralTypeHandler, typesWithColls }) => (
  <>
    <div className={C.selectorDiv}>
      {typesWithColls &&
        Object.keys(typesWithColls)
          .filter(
            _type =>
              _type &&
              _type.length > 0 &&
              _type.toLowerCase() !== 'undefined' &&
              _type.toLowerCase() !== 'uniswap',
          ) // need to debug; only coming on production url.
          .map(_type => (
            <div
              onClick={() => setCollateralTypeHandler(_type)}
              className={C.typeButtonDiv}
              key={_type}
            >
              <div className={C.assetImgDiv}>
                {_type.trim().toLowerCase() === 'curve lps' && (
                  <img className={C.assetImg} src={crvImg} alt="curve::type_token" />
                )}
                {_type.trim().toLowerCase() === 'eth' && (
                  <img className={C.assetImg} src={ethImg} alt="eth::type_token" />
                )}
                {_type.trim().toLowerCase() === 'balancer lps' && (
                  <img className={C.assetImg} src={balancerImg} alt="balancer::type_token" />
                )}
                {_type.trim().toLowerCase() === 'yearn' && (
                  <img className={C.assetImg} src={yearnImg} alt="yearn::type_token" />
                )}
                {_type.trim().toLowerCase() === 'pendle' && (
                  <img className={C.assetImg} src={pendleImg} alt="pendle::type_token" />
                )}
              </div>
              <div className={C.assetName}>
                {_type
                  .split(' ')
                  .map(l => l.charAt(0).toUpperCase() + l.slice(1))
                  .join(' ')}
              </div>
            </div>
          ))}
    </div>
  </>
);

const CollateralSelectUi: React.FC<CollateralSelectUiProps> = ({
  searchValue,
  handleSearchInputChange,
  poolAssets,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* search input */}
      <TextField
        value={searchValue}
        onChange={handleSearchInputChange}
        placeholder={t('dashboard.searchInput.placeholder')}
        leftIconSrc="magnifier"
      />

      <br />
      {!poolAssets.length && (
        <Typography css={{ textAlign: 'center' }} variant="h2">
          No Asset found.
        </Typography>
      )}
      <div className={C.assetContainer}>
        {poolAssets.length > 0 &&
          poolAssets.map((pool: PoolAsset) => (
            <div key={pool.llToken.address}>
              <Link
                className={C.link}
                to={routes.manageCollateral.path.replace(
                  ':underlying',
                  pool.llToken.underlyingToken.address,
                )}
              >
                <img
                  className={C.img}
                  src={pool.llToken.underlyingToken.asset}
                  alt={pool.llToken.symbol}
                />
                <br />
                <Typography css={{ textAlign: 'center' }} variant="body1">
                  <b>{pool.llToken.symbol}</b>
                </Typography>
                <Typography>
                  {+pool.userWalletBalanceTokens < 1
                    ? '0.00'
                    : +pool.userWalletBalanceTokens.toFixed(2)}
                </Typography>
              </Link>
            </div>
          ))}
      </div>
    </>
  );
};

const CollateralSelector: React.FC<CollateralSelectorProps> = ({
  searchValue,
  onSearchInputChange,
  poolAssets,
}) => {
  const [collateralType, setCollateralType] = useState('');
  const [typesWithColls, setTypesWithColls] = useState({} as { [key: string]: any });
  const [selectedPoolAssets, setSelectedPoolAssets] = useState(poolAssets);

  const handleSearchInputChange: InputHTMLAttributes<HTMLInputElement>['onChange'] =
    changeEvent => {
      const { value } = changeEvent.currentTarget;
      if (!value || value.length === 0) {
        // selectedPoolAssets = poolAssets in case of undefined.
        setSelectedPoolAssets(poolAssets);
      }
      onSearchInputChange(value);
    };

  async function initTypesWithColls() {
    const collateralTypes: { [key: string]: any } = {};
    // eslint-disable-next-line no-console

    poolAssets.forEach((token: Asset) => {
      if (token.llToken.protocolName in collateralTypes) {
        collateralTypes[token.llToken.protocolName].push(
          token.llToken.underlyingToken.address.toLowerCase(),
        );
      } else {
        collateralTypes[token.llToken.protocolName] = [
          token.llToken.underlyingToken.address.toLowerCase(),
        ];
      }
    });

    // Object.values(T).forEach(token =>
    //   Object.values(token).forEach((_t: any) => {
    //     if (!_t[config.chainId]) return;
    //     if (!(_t.type in collateralTypes)) {
    //       collateralTypes[_t.type] = [_t[config.chainId].toLowerCase()];
    //     } else {
    //       collateralTypes[_t.type].push(_t[config.chainId].toLowerCase());
    //     }
    //   }),
    // );

    setTypesWithColls(() => collateralTypes);
  }

  useEffect(() => {
    initTypesWithColls();
  }, [poolAssets]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    // console.log('@poolasset:', poolAssets.length, poolAssets);
    // eslint-disable-next-line no-console
    // console.log('@poolasset: typesWithColls', typesWithColls);

    if (!collateralType) {
      return;
    }

    setSelectedPoolAssets(() =>
      poolAssets.filter(pool =>
        typesWithColls[collateralType].includes(pool.llToken.underlyingToken.address),
      ),
    );
  }, [poolAssets.length, typesWithColls, collateralType]);

  return (
    <>
      <div className={C.main}>
        <Typography variant="h2">
          {collateralType
            ? 'Select an asset you would like to deposit'
            : 'Select the type of collateral'}
        </Typography>
        {/* {collateralType && ( */}
        <Typography
          variant="body2"
          style={{ maxWidth: '60em', textAlign: 'center', marginBottom: '1em' }}
        >
          {brand.name} supports {brand.currencySymbol} backed assets with both single and multiple
          token exposure. Real yield earned through deposited collateral is given back to depositors
          in the form of {brand.stableCoinSymbol} rewards.
        </Typography>
        {/* )} */}
      </div>

      {/* if *no collateral type is selected */}
      {!collateralType && (
        <CollateralTypeSelector
          typesWithColls={typesWithColls}
          setCollateralTypeHandler={setCollateralType}
        />
      )}

      {/* collateral type: selected */}
      {collateralType && (
        <CollateralSelectUi
          searchValue={searchValue}
          handleSearchInputChange={handleSearchInputChange}
          poolAssets={selectedPoolAssets}
        />
      )}
    </>
  );
};

export default CollateralSelector;
