import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'translation';
import { getContractAddress } from 'utilities';

import {
  getPendingMultiRewards,
  useGetEsLTOKENFees,
  useGetEsLTOKENReward,
  useGetLtokenVaultPoolCount,
  useGetPools,
} from 'clients/api';
import { getLLTokenContract } from 'clients/contracts';
import { LLTokenContract } from 'clients/contracts/types';
import { useMulticall } from 'clients/web3';
import ORACLE_ABI from 'constants/contracts/abis/oracle.json';
import { TOKENS } from 'constants/tokens';
import { PoolAsset } from 'containers/MarketTable/types';
// import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';

import { Group, PendingReward } from './types';

type PendingFromCollateral = PendingReward & {
  fromMinter?: boolean;
  fromFees?: boolean;
  multiRewardContractAddress?: string;
  usdValue?: string;
};

const mainPoolComptrollerAddress = getContractAddress('comptroller');

const useGetGroups = ({
  poolAssets,
  uncheckedGroupIds = [],
}: {
  uncheckedGroupIds?: string[];
  poolAssets: PoolAsset[];
}) => {
  const { t } = useTranslation();
  const { accountAddress } = useAuth();
  const multicall = useMulticall();

  const oracleAddress = getContractAddress('oracle');

  const { data: esLTOKENRewardFromMinter } = useGetEsLTOKENReward({
    accountAddress,
  });

  const { data: esLTOKENFees } = useGetEsLTOKENFees({ accountAddress });

  // Get LTOKEN vesting vault pool count
  const { data: getLtokenVaultPoolCountData } = useGetLtokenVaultPoolCount();

  // Get Comptroller addresses of isolated pools
  const { data: getPoolsData } = useGetPools({
    accountAddress,
  });

  const getPools = useMemo(() => getPoolsData, [getPoolsData]);

  const isolatedPoolComptrollerAddresses = useMemo(
    () =>
      (getPoolsData?.pools || []).reduce<string[]>(
        (acc, pool) => (pool.isIsolated ? [...acc, pool.comptrollerAddress] : acc),
        [],
      ),
    [getPoolsData?.pools],
  );

  const { data, isLoading } = useQuery(
    [
      'allPendingRewards',
      accountAddress,
      JSON.stringify(getPools?.pools),
      esLTOKENRewardFromMinter,
      esLTOKENFees,
    ],
    async () => {
      const pendingRewardsData = await Promise.all(
        poolAssets.map(async ({ llToken }) => {
          const llTokenContract = getLLTokenContract(llToken) as LLTokenContract;
          return getPendingMultiRewards({
            llTokenContract,
            accountAddress,
            multicall,
            mainPoolComptrollerAddress,
            isolatedPoolComptrollerAddresses,
            ltokenVestingVaultPoolCount: getLtokenVaultPoolCountData?.poolCount || 0,
          });
        }),
      );

      const groups = pendingRewardsData.reduce<Group[]>(
        (p, r) => [
          ...p,
          ...r.pendingMultiRewardGroups.reduce<Group[]>((acc, pendingRewardGroup) => {
            // Pools
            if (
              pendingRewardGroup.type !== 'mainPool' &&
              pendingRewardGroup.type !== 'isolatedPool'
            ) {
              return acc;
            }

            const pool = (getPoolsData?.pools || []).find(
              item =>
                item.comptrollerAddress.toLowerCase() ===
                pendingRewardGroup.comptrollerAddress.toLowerCase(),
            );

            if (!pool) {
              return acc;
            }

            const id = 'main-pool';

            const group: Group = {
              id,
              name: t('layout.claimRewardModal.poolGroup', { poolName: pool.name }),
              isChecked: !uncheckedGroupIds.includes(id),
              multiRewardContractAddress: pendingRewardGroup.multiRewardContractAddress,
              pendingRewards: [
                {
                  rewardToken: pendingRewardGroup.rewardToken,
                  rewardAmountWei: pendingRewardGroup.rewardAmountWei,
                },
              ],
              claims: [
                {
                  contract: 'mainPoolComptroller',
                  llTokenAddressesWithPendingReward:
                    pendingRewardGroup.llTokenAddressesWithPendingReward,
                },
              ],
            };

            return [...acc, group];
          }, []),
        ],
        [],
      );

      let pendingFromCollaterals = groups
        .flatMap(group =>
          group.pendingRewards.map(r => ({
            ...r,
            multiRewardContractAddress: group.multiRewardContractAddress,
          })),
        )
        .filter(r => r.rewardAmountWei.gt(0))
        .reduce<PendingFromCollateral[]>(
          (p, c) =>
            p.some(r => r.rewardToken.address === c.rewardToken.address)
              ? p.map(({ rewardAmountWei, ...rest }) => ({
                  ...rest,
                  rewardAmountWei: rewardAmountWei.plus(c.rewardAmountWei),
                }))
              : p.concat(c),
          [],
        );

      if (esLTOKENRewardFromMinter && esLTOKENRewardFromMinter.earnedEsLTOKEN.gt(0)) {
        pendingFromCollaterals.push({
          fromMinter: true,
          rewardToken: TOKENS.esLTOKEN,
          rewardAmountWei: esLTOKENRewardFromMinter.earnedEsLTOKEN,
        });
      }

      if (esLTOKENFees && esLTOKENFees.feesEarned.gt(0)) {
        pendingFromCollaterals.push({
          fromFees: true,
          rewardToken: TOKENS.dsd,
          rewardAmountWei: esLTOKENFees.feesEarned,
        });
      }

      try {
        const { results } = await multicall.call([
          {
            abi: ORACLE_ABI,
            contractAddress: oracleAddress,
            reference: 'tokenPrices',
            calls: pendingFromCollaterals.map(({ rewardToken: { address } }) => ({
              methodName: 'getUnderlyingPrice',
              methodParameters: [address],
              reference: `tokenPrice_${address}`,
            })),
          },
        ]);

        pendingFromCollaterals = pendingFromCollaterals.map((p, index) => ({
          ...p,
          usdValue: (() => {
            const [returnValue] = results.tokenPrices.callsReturnContext[index].returnValues;
            return new BigNumber(
              // @TODO replace esLTOKEN price
              p.rewardToken.address === TOKENS.esLTOKEN.address ? 1e18 : returnValue.hex,
            )
              .dividedBy(1e18)
              .multipliedBy(p.rewardAmountWei.dividedBy(1e18))
              .toFixed(3);
          })(),
        }));
      } catch (err) {
        // console.error('Oracle call failed:', err);
      }

      return pendingFromCollaterals;
    },
  );

  return useMemo(
    () => ({
      data: data || [],
      isLoading,
    }),
    [data],
  );
};

export default useGetGroups;
