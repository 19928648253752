import { QueryObserverOptions, useQuery } from 'react-query';

import getEsLTOKENReward, {
  GetEsLTOKENRewardInput,
  GetEsLTOKENRewardOutput,
} from 'clients/api/queries/getEsLTOKENReward';
import { useEsLTOKENMinterContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetEsLTOKENRewardOutput,
  Error,
  GetEsLTOKENRewardOutput,
  GetEsLTOKENRewardOutput,
  FunctionKey.GET_ESLTOKEN_REWARD
>;

const useGetEsLTOKENReward = (
  { accountAddress }: Omit<GetEsLTOKENRewardInput, 'esLTOKENMinterContract'>,
  options?: Options,
) => {
  const esLTOKENMinterContract = useEsLTOKENMinterContract();

  return useQuery(
    FunctionKey.GET_ESLTOKEN_REWARD,
    () => getEsLTOKENReward({ accountAddress, esLTOKENMinterContract }),
    options,
  );
};

export default useGetEsLTOKENReward;
