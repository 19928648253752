import { MutationObserverOptions, useMutation } from 'react-query';

import { MintDsdInput, MintDsdOutput, mintDsd, queryClient } from 'clients/api';
import { useDsdControllerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
  MintDsdOutput,
  Error,
  Omit<MintDsdInput, 'dsdControllerContract'>
>;

const useMintDsd = (options?: Options) => {
  const dsdControllerContract = useDsdControllerContract();

  return useMutation(
    FunctionKey.MINT_DSD,
    (params: Omit<MintDsdInput, 'dsdControllerContract'>) =>
      mintDsd({
        dsdControllerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // Invalidate queries related to fetching the user minted DSD amount
        queryClient.invalidateQueries(FunctionKey.GET_MINTED_DSD);
        queryClient.invalidateQueries(FunctionKey.GET_MINTABLE_DSD);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useMintDsd;
