import BigNumber from 'bignumber.js';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getContractAddress } from 'utilities';

import { useGetBlockNumber } from 'clients/api';
import { useComptrollerContract } from 'clients/contracts';
import { useMulticall } from 'clients/web3';
import { useAuth } from 'context/AuthContext';

import getCollateralRewards from './getCollateralRewards';
import logCollateralRewards from './logCollateralRewards';

const useCollateralRewards = () => {
  const { signer, provider } = useAuth();
  const multicall = useMulticall();
  const { data: blockNumberOutput } = useGetBlockNumber();
  const comptrollerAddress = getContractAddress('comptroller');
  const comptroller = useComptrollerContract(comptrollerAddress);
  const esTokenOraclePrice = new BigNumber(0.1e18);

  const { data: collateralRewards, error } = useQuery(
    ['CollateralRewards', blockNumberOutput?.blockNumber],
    () =>
      getCollateralRewards({
        multicall,
        comptroller,
        provider,
        signer,
        blockNumber: blockNumberOutput?.blockNumber,
      }),
    {
      refetchInterval: 30 * 1000, // 30 Seconds
    },
  );

  useEffect(() => {
    if (!collateralRewards || error) {
      if (error) console.error('CollateralRewards:', error);
    } else {
      logCollateralRewards(collateralRewards, esTokenOraclePrice);
    }
  }, [collateralRewards, blockNumberOutput]);
};

export default useCollateralRewards;
