import BigNumber from 'bignumber.js';
import { ContractReceipt } from 'ethers';

import { Farm } from 'types/contracts';

export interface WithdrawFromFarmInput {
  farmContract: Farm;
  amountWei: BigNumber;
}

export type WithdrawFromFarmOutput = ContractReceipt;

const withdrawFromFarm = async ({
  farmContract,
  amountWei,
}: WithdrawFromFarmInput): Promise<WithdrawFromFarmOutput> => {
  const transaction = await farmContract.withdraw(amountWei.toFixed());
  return transaction.wait(1);
};

export default withdrawFromFarm;
