import { QueryObserverOptions, useQuery } from 'react-query';
import { getContractAddress } from 'utilities';

import { GetVenusDsdVaultDailyRateOutput, getVenusDsdVaultDailyRate } from 'clients/api';
import { useComptrollerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetVenusDsdVaultDailyRateOutput,
  Error,
  GetVenusDsdVaultDailyRateOutput,
  GetVenusDsdVaultDailyRateOutput,
  FunctionKey.GET_VENUS_DSD_VAULT_DAILY_RATE
>;

const mainPoolComptrollerAddress = getContractAddress('comptroller');

const useGetVenusDsdVaultDailyRate = (options?: Options) => {
  const comptrollerContract = useComptrollerContract(mainPoolComptrollerAddress);

  return useQuery(
    FunctionKey.GET_VENUS_DSD_VAULT_DAILY_RATE,
    () => getVenusDsdVaultDailyRate({ comptrollerContract }),
    options,
  );
};

export default useGetVenusDsdVaultDailyRate;
