import BigNumber from 'bignumber.js';
import { checkForLtokenVaultProxyTransactionError } from 'errors';
import { ContractReceipt } from 'ethers';

import { Farm } from 'types/contracts';

export interface StakeInFarmInput {
  farmContract: Farm;
  amountWei: BigNumber;
}

export type StakeInFarmOutput = ContractReceipt;

const stakeInLtokenVault = async ({
  farmContract,
  amountWei,
}: StakeInFarmInput): Promise<StakeInFarmOutput> => {
  const transaction = await farmContract.deposit(amountWei.toFixed());
  const receipt = await transaction.wait(1);
  return checkForLtokenVaultProxyTransactionError(receipt);
};

export default stakeInLtokenVault;
