import { EthChainId, LLToken } from 'types';

import balancer from 'assets/img/tokens/balancer.svg';
import crv from 'assets/img/tokens/curve.svg';
import eth from 'assets/img/tokens/eth.svg';
import pendle from 'assets/img/tokens/pendle.svg';
import vLtoken from 'assets/img/tokens/vLtoken.svg';
import yearn from 'assets/img/tokens/yearn.svg';
import LLTokens from 'constants/contracts/addresses/llTokens.json';

import { MAINNET_TOKENS } from '../common/mainnet';

export const MAINNET_LLERC_TOKENS = {
  [LLTokens.LSstETH[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSstETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'stETH',
    asset: vLtoken,
    protocol: eth,
    protocolSymbol: 'eth',
    protocolName: 'ETH',
    underlyingToken: MAINNET_TOKENS.stETH,
  } as LLToken,
  [LLTokens.LSsfrxETH[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSsfrxETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'sfrxETH',
    asset: vLtoken,
    protocol: eth,
    protocolSymbol: 'eth',
    protocolName: 'ETH',
    underlyingToken: MAINNET_TOKENS.sfrxETH,
  } as LLToken,
  [LLTokens.LScbETH[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LScbETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'cbETH',
    asset: vLtoken,
    protocol: eth,
    protocolSymbol: 'eth',
    protocolName: 'ETH',
    underlyingToken: MAINNET_TOKENS.cbETH,
  } as LLToken,
  [LLTokens.LSrETH[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSrETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'rETH',
    asset: vLtoken,
    protocol: eth,
    protocolSymbol: 'eth',
    protocolName: 'ETH',
    underlyingToken: MAINNET_TOKENS.rETH,
  } as LLToken,
  [LLTokens.LSWETH[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSWETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'wETH',
    asset: vLtoken,
    protocol: eth,
    protocolSymbol: 'eth',
    protocolName: 'ETH',
    underlyingToken: MAINNET_TOKENS.wETH,
  } as LLToken,
  [LLTokens.LSsteCRV[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSsteCRV[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Curve stETH',
    asset: vLtoken,
    protocol: crv,
    protocolSymbol: 'crv',
    protocolName: 'Curve LPs',
    underlyingToken: MAINNET_TOKENS.steCRV,
  } as LLToken,
  [LLTokens.LSfrxETHCRV[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSfrxETHCRV[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Curve frxETH',
    asset: vLtoken,
    protocol: crv,
    protocolSymbol: 'crv',
    protocolName: 'Curve LPs',
    underlyingToken: MAINNET_TOKENS.frxETHCRV,
  } as LLToken,
  [LLTokens.LSSTETHETH_C_f[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSSTETHETH_C_f[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Curve stETH Concentrated',
    asset: vLtoken,
    protocol: crv,
    protocolSymbol: 'crv',
    protocolName: 'Curve LPs',
    underlyingToken: MAINNET_TOKENS.STETHETH_C_f,
  } as LLToken,
  [LLTokens.LSst_frxETH_f[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSst_frxETH_f[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Curve stETH-frxETH',
    asset: vLtoken,
    protocol: crv,
    protocolSymbol: 'crv',
    protocolName: 'Curve LPs',
    underlyingToken: MAINNET_TOKENS.st_frxETH_f,
  } as LLToken,
  [LLTokens.LSyvWETH[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSyvWETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Yearn yETH',
    asset: vLtoken,
    protocol: yearn,
    protocolSymbol: 'yearn',
    protocolName: 'Yearn',
    underlyingToken: MAINNET_TOKENS.yvWETH,
  } as LLToken,
  [LLTokens.LSwstETHrETHsfrxETHBPT[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSwstETHrETHsfrxETHBPT[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Balancer Liquid Staking',
    asset: vLtoken,
    protocol: balancer,
    protocolSymbol: 'balancer',
    protocolName: 'Balancer LPs',
    underlyingToken: MAINNET_TOKENS.wstETHrETHsfrxETHBPT,
  } as LLToken,
  [LLTokens.LSBrETHSTABLE[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSBrETHSTABLE[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Balancer RETH-WETH',
    asset: vLtoken,
    protocol: balancer,
    protocolSymbol: 'balancer',
    protocolName: 'Balancer LPs',

    underlyingToken: MAINNET_TOKENS.BrETHSTABLE,
  } as LLToken,
  [LLTokens.LSBstETHSTABLE[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSBstETHSTABLE[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Balancer stETH-WETH',
    asset: vLtoken,
    protocol: balancer,
    protocolSymbol: 'balancer',
    protocolName: 'Balancer LPs',
    underlyingToken: MAINNET_TOKENS.BstETHSTABLE,
  } as LLToken,
  [LLTokens.LSPTstETH26DEC2024[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSPTstETH26DEC2024[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Pendle Lido 26 Dec 24',
    asset: vLtoken,
    protocol: pendle,
    protocolSymbol: 'pendle',
    protocolName: 'Pendle',
    underlyingToken: MAINNET_TOKENS.PTstETH26DEC2024,
  } as LLToken,
  [LLTokens.LSPTstETH30DEC2027[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSPTstETH30DEC2027[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Pendle Lido 30 Dec 27',
    asset: vLtoken,
    protocol: pendle,
    protocolSymbol: 'pendle',
    protocolName: 'Pendle',
    underlyingToken: MAINNET_TOKENS.PTstETH30DEC2027,
  } as LLToken,
  [LLTokens.LSPTstETH25DEC2025[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSPTstETH25DEC2025[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Pendle Lido 25 Dec 25',
    asset: vLtoken,
    protocol: pendle,
    protocolSymbol: 'pendle',
    protocolName: 'Pendle',
    underlyingToken: MAINNET_TOKENS.PTstETH25DEC2025,
  } as LLToken,
  [LLTokens.LSPTfrxETH26DEC2024[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSPTfrxETH26DEC2024[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Pendle Frax 26 Dec 24',
    asset: vLtoken,
    protocol: pendle,
    protocolSymbol: 'pendle',
    protocolName: 'Pendle',
    underlyingToken: MAINNET_TOKENS.PTfrxETH26DEC2024,
  } as LLToken,
  [LLTokens.LSswETH[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSswETH[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'swETH',
    asset: vLtoken,
    protocol: eth,
    protocolSymbol: 'eth',
    protocolName: 'ETH',
    underlyingToken: MAINNET_TOKENS.swETH,
  } as LLToken,
  [LLTokens.LSrETHf[EthChainId.MAINNET].toLowerCase()]: {
    address: LLTokens.LSrETHf[EthChainId.MAINNET].toLowerCase(),
    decimals: 8,
    symbol: 'Curve rETHf',
    asset: vLtoken,
    protocol: crv,
    protocolSymbol: 'crv',
    protocolName: 'Curve LPs',
    underlyingToken: MAINNET_TOKENS.rETHf,
  } as LLToken,
};
