import { ContractReceipt } from 'ethers';

import { EsLTOKEN } from 'types/contracts';

export interface WithdrawLtokenInput {
  esLTOKENContract: EsLTOKEN;
}

export type WithdrawLtokenOutput = ContractReceipt;

const withdrawLtoken = async ({ esLTOKENContract }: WithdrawLtokenInput): Promise<WithdrawLtokenOutput> => {
  const transaction = await esLTOKENContract.redeem();
  return transaction.wait(1);
};

export default withdrawLtoken;
