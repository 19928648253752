/** @jsxImportSource @emotion/react */
import { useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTableRow from '@mui/material/TableRow';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { BREAKPOINTS } from 'theme/MuiThemeProvider/muiTheme';

// import { routes } from 'constants/routing';
// import { Button } from '../Button';
import { Spinner } from '../Spinner';
import Head from './Head';
import TableCards from './TableCards';
import { useStyles } from './styles';
import { Order, TableColumn, TableProps } from './types';

export * from './types';

export function Table<R>({
  columns,
  cardColumns,
  data,
  title,
  minWidth,
  initialOrder,
  rowOnClick,
  getRowHref,
  rowKeyExtractor,
  className,
  breakpoint,
  isFetching,
}: // onlyMyCollaterals,
TableProps<R>) {
  const styles = useStyles();
  const showXlDown = useMediaQuery(`(max-width: ${BREAKPOINTS.values.xl}px)`);

  const [order, setOrder] = React.useState<Order<R> | undefined>(initialOrder);

  const onRequestOrder = (column: TableColumn<R>) => {
    let newOrderDirection: 'asc' | 'desc' = 'desc';

    if (column.key === order?.orderBy.key) {
      newOrderDirection = order?.orderDirection === 'asc' ? 'desc' : 'asc';
    }

    setOrder({
      orderBy: column,
      orderDirection: newOrderDirection,
    });
  };

  const sortedData = useMemo(() => {
    if (!order || !order.orderBy.sortRows) {
      return data;
    }

    return [...data].sort((rowA, rowB) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      order.orderBy.sortRows!(rowA, rowB, order.orderDirection),
    );
  }, [data, order]);

  return (
    <Paper css={styles.getRoot({ breakpoint })} className={className}>
      {/* {title && <h4 css={styles.getTitle({ breakpoint })}>{title}</h4>} */}

      {isFetching && <Spinner css={styles.loader} />}

      {showXlDown ? (
        <TableCards
          data={data}
          rowKeyExtractor={rowKeyExtractor}
          rowOnClick={rowOnClick}
          getRowHref={getRowHref}
          columns={cardColumns || columns}
          breakpoint={breakpoint}
          order={order}
          onOrderChange={setOrder}
        />
      ) : (
        <MuiTableContainer css={styles.getTableContainer({ breakpoint })}>
          <MuiTable css={styles.table({ minWidth: minWidth ?? '0' })} aria-label={title}>
            <Head
              columns={columns}
              orderBy={order?.orderBy}
              orderDirection={order?.orderDirection}
              onRequestOrder={onRequestOrder}
              css={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
            />

            <MuiTableBody>
              {sortedData.map((row, rowIndex) => {
                const rowKey = rowKeyExtractor(row);

                return (
                  <MuiTableRow
                    hover
                    key={rowKey}
                    css={styles.getTableRow({ clickable: !!rowOnClick })}
                    onClick={
                      !getRowHref && rowOnClick
                        ? (e: React.MouseEvent<HTMLDivElement>) => rowOnClick(e, row)
                        : undefined
                    }
                  >
                    {columns.map(column => {
                      const cellContent = column.renderCell(row, rowIndex);
                      const cellTitle = typeof cellContent === 'string' ? cellContent : undefined;

                      return (
                        <MuiTableCell
                          css={styles.getCellWrapper({ containsLink: !!getRowHref })}
                          key={`${rowKey}-${column.key}`}
                          title={cellTitle}
                          align={column.align}
                        >
                          {getRowHref ? (
                            <Link css={styles.link} to={getRowHref(row)}>
                              {cellContent}
                            </Link>
                          ) : (
                            cellContent
                          )}
                        </MuiTableCell>
                      );
                    })}
                  </MuiTableRow>
                );
              })}
            </MuiTableBody>
          </MuiTable>

          {/* {onlyMyCollaterals && (
          <div css={[styles.buttonDiv]}>
            <Link to={routes.addCollateral.path} css={[styles.link]}>
              <Button>+ Supply Collateral</Button>
            </Link>
          </div>
        )} */}
        </MuiTableContainer>
      )}
    </Paper>
  );
}
