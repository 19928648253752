/** @jsxImportSource @emotion/react */
// import { Typography } from '@mui/material';
import {
  // Button,
  Table,
  TableProps,
} from 'components';
import React, { useMemo } from 'react';
// import { Link } from 'react-router-dom';
import { MigrationPool } from 'types';

// import { routes } from 'constants/routing';
import useBorrowRepayModal from 'hooks/useBorrowRepayModal';
import useSupplyWithdrawModal from 'hooks/useSupplyWithdrawModal';

import { useStyles } from './styles';
import { ColumnKey, PoolAsset } from './types';
import useGenerateColumns from './useGenerateColumns';

export interface MigratorTableProps
  extends Partial<
      Omit<TableProps<PoolAsset>, 'columns' | 'rowKeyIndex' | 'breakpoint' | 'initialOrder'>
    >,
    Pick<TableProps<PoolAsset>, 'breakpoint'> {
  pools: MigrationPool[];
  columns: ColumnKey[];
  initialOrder?: {
    orderBy: ColumnKey;
    orderDirection: 'asc' | 'desc';
  };
  marketType?: 'supply' | 'borrow';
  className?: string;
  onlyMyCollaterals?: boolean;
  testId?: string;
}

export const MigratorTable: React.FC<MigratorTableProps> = ({
  pools,
  marketType,
  columns: columnKeys,
  getRowHref,
  initialOrder,
  testId,
  onlyMyCollaterals,
  ...otherTableProps
}) => {
  const styles = useStyles();

  const { BorrowRepayModal } = useBorrowRepayModal();
  const { SupplyWithdrawModal, openSupplyWithdrawModal } = useSupplyWithdrawModal();

  const poolAssets = useMemo(
    () =>
      pools.reduce((acc, pool) => {
        const newPoolAssets: PoolAsset[] = pool.assets
          .filter(asset => {
            if (!onlyMyCollaterals) return true;
            return onlyMyCollaterals && asset.userSupplyBalanceCents > 0;
          })
          .map(asset => ({
            ...asset,
            pool,
          }));

        return acc.concat(newPoolAssets);
      }, [] as PoolAsset[]),
    [pools],
  );

  const withdrawModalOpen = (row: PoolAsset, onlySupply?: boolean, onlyWithdraw?: boolean) => {
    // Block action and show warning modal if user has LUNA or UST enabled as
    // collateral and is attempting to open the supply modal of other assets

    // Do nothing if user clicked on switch (the switch element will handle the
    // click event)

    // if (marketType === 'borrow') {
    //   openBorrowRepayModal({
    //     llToken: row.llToken,
    //     poolComptrollerAddress: row.pool.comptrollerAddress,
    //   });
    // } else {
    openSupplyWithdrawModal({
      llToken: row.llToken,
      poolComptrollerAddress: row.pool.comptrollerAddress,
      onlySupply,
      onlyWithdraw,
    });
    // }
  };

  const columns = useGenerateColumns({
    poolAssets,
    columnKeys,
    withdrawModalOpen,
  });

  const formattedInitialOrder = useMemo(() => {
    if (!initialOrder) {
      return undefined;
    }

    const oderByColumn = columns.find(column => column.key === initialOrder.orderBy);

    return (
      oderByColumn && {
        orderBy: oderByColumn,
        orderDirection: initialOrder.orderDirection,
      }
    );
  }, [columns, initialOrder]);

  return (
    <div data-testid={testId} css={styles.body(false)}>
      <>
        <Table
          columns={columns}
          data={poolAssets}
          css={styles.cardContentGrid}
          rowKeyExtractor={row => `market-table-row-${marketType}-${row.llToken.address}`}
          // rowOnClick={getRowHref ? undefined : rowOnClick}
          rowOnClick={() => {}}
          getRowHref={getRowHref}
          onlyMyCollaterals={onlyMyCollaterals}
          initialOrder={formattedInitialOrder}
          {...otherTableProps}
        />
        <BorrowRepayModal />
        <SupplyWithdrawModal />
      </>
    </div>
  );
};
