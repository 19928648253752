import { TOKENS } from 'constants/tokens';
import { LtokenVault } from 'types/contracts';

export interface GetLtokenVaultPoolCountInput {
  ltokenVaultContract: LtokenVault;
}

export type GetLtokenVaultPoolCountOutput = {
  poolCount: number;
};

const getLtokenVaultPoolCount = async ({
  ltokenVaultContract,
}: GetLtokenVaultPoolCountInput): Promise<GetLtokenVaultPoolCountOutput> => {
  const ltokenTokenAddress = TOKENS.ltoken.address;
  const ltokenVaultPoolLength = await ltokenVaultContract.poolLength(ltokenTokenAddress);

  return {
    poolCount: ltokenVaultPoolLength.toNumber(),
  };
};

export default getLtokenVaultPoolCount;
