/** @jsxImportSource @emotion/react */
import { Checkbox, FormLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ConnectWallet } from 'components/ConnectWallet';
import { Modal } from 'components/Modal';

import { useStyles } from './styles';

const LS_KEY = 'disclaimer_closed';

const sections = {
  RISK: [
    'Using the DeFi app involves risks, including: Smart contract flaws: DeFi protocols are built on smart contracts, which are computer programs that are stored on a blockchain. If a smart contract has a flaw, it could allow hackers to steal your funds.',
    'Vulnerability to bad actors: DeFi protocols are open to anyone, which means that there is a risk that bad actors could exploit them.',
    'Impermanent loss: When you provide liquidity to a DeFi protocol, you may lose money due to impermanent loss. Impermanent loss occurs when the price of the assets you provide liquidity for changes.',
    'Complexity risks: DeFi protocols can be complex and difficult to understand. If you do not understand how a DeFi protocol works, you could make mistakes that could result in you losing money.',
    'Regulatory risks: DeFi protocols are not regulated by any government or financial institution. This means that there is no guarantee that you will be able to recover your funds if you lose them.',
  ],
  INVESTMENT:
    'The DeFi app is not an investment advisor. The information on this website is not financial advice and should not be relied upon as such. You should consult with a financial advisor before making any investment decisions.',
};

const DisclaimerModal = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const style = useStyles();

  useEffect(() => {
    const currentValue = window.localStorage.getItem(LS_KEY);
    if (!currentValue && !isOpen) window.localStorage.setItem(LS_KEY, 'true');
    if (currentValue === 'true') setIsOpen(false);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title="Disclaimer"
      handleClose={() => checked && setIsOpen(false)}
      onBackdropClick={() => checked && setIsOpen(false)}
    >
      <div css={style.container}>
        <div css={style.subContainer}>
          <Typography variant="body1" css={style.title}>
            The DeFi app on this website is a decentralized application that allows you to interact
            with various DeFi protocols. The app is not affiliated with or sponsored by any
            government or financial institution.
          </Typography>
          {Object.entries(sections).map(([title, body]) => (
            <div key={title}>
              <Typography variant="h4" css={style.title}>
                {title} :
              </Typography>
              {typeof body === 'string' ? (
                <Typography variant="body1" css={style.title}>
                  {body}
                </Typography>
              ) : (
                <ul>
                  {body.map(text => (
                    <li key={text}>
                      <Typography variant="body1">{text}</Typography>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>

        <FormLabel htmlFor={LS_KEY} css={style.checkbox}>
          <Checkbox id={LS_KEY} checked={checked} onChange={(_, c) => setChecked(c)} />
          <Typography>I accept the terms and conditions.</Typography>
        </FormLabel>

        <ConnectWallet disabled={!checked} onClickCallback={() => setIsOpen(false)} />
      </div>
    </Modal>
  );
};

export default DisclaimerModal;
