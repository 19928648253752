import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    bottomNotice: css`
      display: flex;
      padding: ${theme.spacing(0)};
      margin-bottom: ${theme.spacing(4)};
      gap: ${theme.spacing(2)};
    `,
    apr: css`
      display: flex;
      padding: ${theme.spacing(2)} ${theme.spacing(6)};
      border-radius: ${theme.spacing(2)};
      border: 1px solid rgba(255, 255, 255, 0.1);
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
    `,
    valueUpdateContainer: css`
      display: flex;
      padding: ${theme.spacing(4)};
      gap:${theme.spacing(6)};
      margin-bottom: ${theme.spacing(4)};
      border-radius: ${theme.spacing(4)};
      border: 1px solid rgba(255, 255, 255, 0.1);
      align-items: center;
      justify-content: space-around;
    `,
    getRow: ({ isLast }: { isLast: boolean }) => css`
      margin-bottom: ${theme.spacing(isLast ? 1 : 3)};

      ${theme.breakpoints.down('md')} {
        span {
          font-size: ${theme.typography.small1.fontSize};
        }
      }
    `,
  };
};
