import BigNumber from 'bignumber.js';
import { Multicall } from 'ethereum-multicall';
import { Token } from 'types';
import { getTokenByAddress } from 'utilities';

import farmAbi from 'constants/contracts/abis/farm.json';
import farms from 'constants/contracts/addresses/farms.json';

export interface Call {
  reference: string;
  methodName: string;
  methodParameters: unknown[];
}
export interface FarmInfo {
  contract: string;
  rewardToken: Token | undefined;
  stakedToken: Token | undefined;
  periodFinish: BigNumber;
  rewardRate: BigNumber;
  lastUpdateTime: BigNumber;
  rewardPerTokenStored: BigNumber;
  totalSupply: BigNumber;
  stakedAmount: BigNumber;
  earnedReward: BigNumber;
}

const getFarms = async (multicall: Multicall, accountAddress: string): Promise<FarmInfo[]> => {
  const callsReturnContext = farms.map(farm => {
    let contractCalls: Call[] = [
      {
        reference: 'getFarmInfo',
        methodName: 'getFarmInfo',
        methodParameters: [],
      },
    ];

    if (accountAddress) {
      contractCalls = [
        ...contractCalls,
        {
          reference: 'balanceOf',
          methodName: 'balanceOf',
          methodParameters: [accountAddress],
        },
        {
          reference: 'earned',
          methodName: 'earned',
          methodParameters: [accountAddress],
        },
      ];
    }

    return {
      reference: `farm${farm.stakedTokenSymbol}`,
      contractAddress: farm.farmAddress,
      abi: farmAbi,
      calls: contractCalls,
    };
  });

  const { results } = await multicall.call(callsReturnContext);

  console.log('@FARM RESULT::', results);
  const farmInfo: FarmInfo[] = Object.values(results).map(farm => ({
    contract: farm.originalContractCallContext.contractAddress,
    rewardToken: getTokenByAddress(farm.callsReturnContext[0].returnValues[0]),
    stakedToken: getTokenByAddress(farm.callsReturnContext[0].returnValues[1]),
    periodFinish: new BigNumber(farm.callsReturnContext[0].returnValues[2].hex || 0),
    rewardRate: new BigNumber(farm.callsReturnContext[0].returnValues[3].hex || 0),
    lastUpdateTime: new BigNumber(farm.callsReturnContext[0].returnValues[4].hex || 0),
    rewardPerTokenStored: new BigNumber(farm.callsReturnContext[0].returnValues[5].hex || 0),
    totalSupply: new BigNumber(farm.callsReturnContext[0].returnValues[6].hex || 0),
    stakedAmount: new BigNumber(farm.callsReturnContext[1]?.returnValues[0].hex || 0),
    earnedReward: new BigNumber(farm.callsReturnContext[2]?.returnValues[0].hex || 0),
  }));

  // console.log("@FARM INFO:", farmInfo);
  return farmInfo;
};

export default getFarms;

/**
 *  ///@notice get farmsInfo
    function getFarmInfo()
        external
        view
        returns (address, address, uint256, uint256, uint256, uint256, uint256, uint256, uint256)
    {
        return (
            address(rewardToken),
            address(TOKEN),
            periodFinish,
            rewardRate,
            lastUpdateTime,
            rewardPerTokenStored,
            fees0,
            fees1,
            _totalSupply
        );
    }
 */
