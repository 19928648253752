import { LsLens } from 'types/contracts';

export interface GetLLTokenBalancesAllInput {
  lsLensContract: LsLens;
  account: string;
  llTokenAddresses: string[];
}

interface Balance {
  balanceOf: string;
  balanceOfUnderlying: string;
  borrowBalanceCurrent: string;
  tokenAllowance: string;
  tokenBalance: string;
  llToken: string;
}

export type GetLLTokenBalancesAllOutput = {
  balances: Balance[];
};

const getLLTokenBalancesAll = async ({
  lsLensContract,
  llTokenAddresses,
  account,
}: GetLLTokenBalancesAllInput): Promise<GetLLTokenBalancesAllOutput> => {
  const response = await lsLensContract.callStatic.llTokenBalancesAll(
    llTokenAddresses,
    account?.toLowerCase(),
  );

  // This is original returned as an array with these properties but at some
  // point the properties are getting removed from the type
  const balances = response.map(item => ({
    balanceOf: item.balanceOf.toString(),
    balanceOfUnderlying: item.balanceOfUnderlying.toString(),
    borrowBalanceCurrent: item.borrowBalanceCurrent.toString(),
    tokenAllowance: item.tokenAllowance.toString(),
    tokenBalance: item.tokenBalance.toString(),
    llToken: item.llToken,
  }));

  return { balances };
};

export default getLLTokenBalancesAll;
