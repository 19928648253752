import BigNumber from 'bignumber.js';
import { VError, checkForTokenTransactionError } from 'errors';
import { ContractReceipt, Signer } from 'ethers';
import { LLToken } from 'types';

import { getLLTokenContract } from 'clients/contracts';
import { VBnbToken } from 'types/contracts';

export interface RepayInput {
  signer?: Signer;
  llToken: LLToken;
  amountWei: BigNumber;
  isRepayingFullLoan?: boolean;
}

export type RepayOutput = ContractReceipt;

export const REPAYMENT_BNB_BUFFER_PERCENTAGE = 0.001;

const repay = async ({
  signer,
  llToken,
  amountWei,
  isRepayingFullLoan = false,
}: RepayInput): Promise<RepayOutput> => {
  if (isRepayingFullLoan && !signer) {
    throw new VError({ type: 'unexpected', code: 'somethingWentWrong' });
  }

  // Handle repaying tokens other than BNB
  const llTokenContract = getLLTokenContract(llToken, signer) as VBnbToken;

  const transaction = await llTokenContract.repayBorrow(amountWei.toFixed());
  const receipt = await transaction.wait(1);
  return checkForTokenTransactionError(receipt);
};

export default repay;
