import BigNumber from 'bignumber.js';
import { useQuery } from 'react-query';

import { getTokenContract } from 'clients/contracts';
import FunctionKey from 'constants/functionKey';
import { TOKENS } from 'constants/tokens';
import { DsdToken } from 'types/contracts';

export type GetDsdDSupplyOutput = {
  isLoading: boolean;
  data?: {
    totalSupply: BigNumber;
    nonRebasingSupply: BigNumber;
    circulatingSupply: BigNumber;
  };
};

const useGetDsdSupply = (): GetDsdDSupplyOutput => {
  const dsdToken = getTokenContract(TOKENS.dsd) as DsdToken;
  const { isLoading, data } = useQuery([FunctionKey.GET_DSD_SUPPLY, dsdToken], () =>
    Promise.all([dsdToken.totalSupply(), dsdToken.nonRebasingSupply()]),
  );

  if (isLoading || !data) return { isLoading, data };

  return {
    isLoading,
    data: {
      totalSupply: new BigNumber(data[0].toString()),
      nonRebasingSupply: new BigNumber(data[1].toString()),
      circulatingSupply: new BigNumber(data[0].sub(data[1]).toString()),
    },
  };
};

export default useGetDsdSupply;
