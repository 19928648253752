/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import React from 'react';

import { useStyles } from './styles';

export interface Protocol {
  symbol: string;
  name: string;
  asset: string;
}
export interface ProtocolListProps {
  options: Protocol[];
  onProtocolClick: (option: Protocol) => void;
}

export const ProtocolList: React.FC<ProtocolListProps> = ({ options, onProtocolClick }) => {
  const styles = useStyles();
  return (
    <div css={styles.container}>
      <div css={styles.list}>
        {options.map(protocolOption => (
          <div
            css={styles.item}
            onClick={() => onProtocolClick(protocolOption)}
            key={`select-token-text-field-item-${protocolOption.symbol}`}
          >
            <div css={styles.protocol}>
              <img src={protocolOption.asset} css={styles.icon} alt={protocolOption.symbol} />
              <Typography variant="body2" style={{ color: 'white' }}>
                {protocolOption.name}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProtocolList;
