import { ContractCallResults } from 'ethereum-multicall';

import { getMultiRewardContract } from 'clients/contracts';
import multiRewardAbi from 'constants/contracts/abis/multiReward.json';

import formatOutput from './formatOutput';
import {
  GetPendingMultiRewardGroupsInput,
  GetPendingMultiRewardGroupsOutput,
  PendingMultiRewardGroup,
} from './types';

const getPendingMultiRewardGroups = async ({
  signer,
  llTokenContract,
  mainPoolComptrollerAddress,
  multicall,
  accountAddress,
}: GetPendingMultiRewardGroupsInput): Promise<GetPendingMultiRewardGroupsOutput> => {
  // Generate call context
  const multiRewardAddress = await llTokenContract.multiReward();
  const multiRewardContract = getMultiRewardContract(multiRewardAddress, signer);
  const rewardTokensLength = await multiRewardContract.rewardTokensLength();

  if (!rewardTokensLength) {
    return {
      pendingMultiRewardGroups: [] as PendingMultiRewardGroup[],
    };
  }
  const { results }: ContractCallResults = await multicall.call([
    {
      reference: 'rewardTokens',
      contractAddress: multiRewardContract.address,
      abi: multiRewardAbi,
      calls: Array.from({ length: +rewardTokensLength }).map((_, index) => ({
        reference: `rewardToken_${index}`,
        methodName: 'rewardTokens',
        methodParameters: [index],
      })),
    },
  ]);

  const contractCallResults: ContractCallResults = await multicall.call([
    {
      reference: 'pendingMultiRewards',
      contractAddress: multiRewardContract.address,
      abi: multiRewardAbi,
      calls: results.rewardTokens.callsReturnContext.map(({ returnValues }, index) => ({
        reference: `earned_${index}`,
        methodName: 'earned',
        methodParameters: [accountAddress, returnValues[0]],
      })),
    },
  ]);

  const pendingMultiRewardGroups = formatOutput({
    comptrollerAddress: mainPoolComptrollerAddress,
    contractCallResults,
    llTokenContract,
    multiRewardContract,
  });

  return {
    pendingMultiRewardGroups,
  };
};

export default getPendingMultiRewardGroups;
