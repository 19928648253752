/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Button, Spinner } from 'components';
import { VError, formatVErrorToReadableString } from 'errors';
import React from 'react';
import { toast } from 'react-toastify';
import { convertTokensToWei } from 'utilities';

import { GetEsltokenFeesOutput, useClaimFeesEsLTOKEN, useGetEsLTOKENFees } from 'clients/api';
import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

export const ClaimFeesUi = ({
  feesEarnedInfo,
}: {
  feesEarnedInfo: GetEsltokenFeesOutput | undefined;
}) => {
  const { mutateAsync: claimEsltokenFees, isLoading } = useClaimFeesEsLTOKEN();

  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const handleClaimFees = async () => {
    try {
      const res = await claimEsltokenFees({});
      const { transactionHash } = res;
      if (transactionHash) {
        openSuccessfulTransactionModal({
          title: 'Fees claimed',
          content: `You successfully claimed Fees for holding ${brand.escrowTokenSymbol}`,
          amount: {
            valueWei: convertTokensToWei({
              value: new BigNumber(feesEarnedInfo?.feesEarned || '0'),
              token: TOKENS.dsd,
            }),
            token: TOKENS.dsd,
          },
          transactionHash,
        });
      }
    } catch (error) {
      let { message } = error as Error;

      if (error instanceof VError) {
        message = formatVErrorToReadableString(error);
      }

      toast.error({ message });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="4" justifyContent="center" alignItems="center">
      <Typography textAlign="center" variant="h2">
        Earned ${feesEarnedInfo ? feesEarnedInfo.feesEarned.toString() : '-.--'}{' '}
        {brand.stableCoinSymbol}
      </Typography>
      <Button
        variant="primary"
        disabled={feesEarnedInfo?.feesEarned.isZero() || isLoading}
        onClick={() => handleClaimFees()}
      >
        Claim Fees
      </Button>
    </Box>
  );
};

const ClaimFees = () => {
  const { accountAddress } = useAuth();

  const { data: feesEarnedInfo, isLoading: loadingFees } = useGetEsLTOKENFees({ accountAddress });

  if (loadingFees) {
    return <Spinner />;
  }

  return <ClaimFeesUi feesEarnedInfo={feesEarnedInfo} />;
};

export default ClaimFees;
