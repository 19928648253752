import BigNumber from 'bignumber.js';
import { TransactionCategory, TransactionEvent } from 'types';
import { convertTokensToWei, getLLTokenByAddress } from 'utilities';

import { ltoken } from 'constants/contracts/addresses/main.json';

import { TransactionResponse } from './types';

const MAIN_POOL_VLTOKEN_ADDRESS = ltoken[1];

const formatTransaction = ({
  amount,
  category,
  event,
  llTokenAddress,
  timestamp,
  ...rest
}: TransactionResponse) => {
  const llToken = getLLTokenByAddress(llTokenAddress || MAIN_POOL_VLTOKEN_ADDRESS);

  if (!llToken) {
    return undefined;
  }

  return {
    ...rest,
    amountWei: convertTokensToWei({ value: new BigNumber(amount), token: llToken?.underlyingToken }),
    category: category as TransactionCategory,
    event: event as TransactionEvent,
    llTokenAddress: llToken.address,
    timestamp: new Date(timestamp * 1000), // Convert timestamp to milliseconds
  };
};
export default formatTransaction;
