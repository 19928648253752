/* eslint-disable import/no-cycle */

/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { isObject } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'translation';
import { Asset, Pool, Swap } from 'types';
import {
  formatCentsToReadableValue,
  formatToReadablePercentage,
  formatTokensToReadableValue,
} from 'utilities';

import { useGetApyData, useGetHypotheticalAccountLiquidity } from 'clients/api';
import { NULL_ADDRESS } from 'constants/address';
// import { SAFE_BORROW_LIMIT_PERCENTAGE } from 'constants/safeBorrowLimitPercentage';
import useAssetInfo from 'hooks/useAssetInfo';

import { LabeledBoxContent } from '../LabeledBoxContent';
// import { Delimiter } from '../Delimiter';
import { LabeledInlineContent } from '../LabeledInlineContent';
// import { BorrowBalanceAccountHealth } from '../ProgressBar/AccountHealth';
import { ValueUpdate } from '../ValueUpdate';
import { useStyles } from './styles';
import useGetValues from './useGetValues';

export interface AccountDataProps {
  asset: Asset;
  pool: Pool;
  action: 'supply' | 'withdraw' | 'borrow' | 'repay';
  amountTokens: BigNumber;
  swap?: Swap;
  forModal?: boolean;
  balance?: BigNumber;
}

export const AccountData: React.FC<AccountDataProps> = ({
  asset,
  pool,
  action,
  amountTokens,
  swap,
  forModal,
  balance,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    poolUserBorrowLimitUsedPercentage,
    hypotheticalUserSupplyBalanceTokens,
    hypotheticalUserBorrowBalanceTokens,
    // hypotheticalPoolUserBorrowBalanceCents,
    hypotheticalPoolUserBorrowLimitCents,
    hypotheticalPoolUserBorrowLimitUsedPercentage,
  } = useGetValues({ asset, pool, swap, amountTokens, action });

  const { data: liquidityData, isLoading } = useGetHypotheticalAccountLiquidity(
    NULL_ADDRESS,
    new BigNumber(0),
    new BigNumber(0),
  );

  const tokensWithdrawable = useMemo(() => {
    if (!isLoading && liquidityData && balance) {
      const amount = new BigNumber(
        liquidityData[1]
          .div(asset.tokenPriceDollars.multipliedBy(asset.collateralFactor))
          .div(1e18),
      );
      return BigNumber.min(new BigNumber(amount), balance);
    }
    return new BigNumber(0);
  }, [liquidityData, asset]);
  const { data: apyData } = useGetApyData();

  const netApy = React.useMemo(
    () =>
      apyData?.tokenData && apyData?.tokenData?.[asset.llToken.address]
        ? Object.values(apyData.tokenData[asset.llToken.address]).reduce((apy, value) => {
            if (isObject(value)) return apy;
            return +value + apy;
          }, 0) - +apyData.tokenData[asset.llToken.address].tokenUsdPrice || 0
        : 0,
    [apyData],
  );

  const emissionApr = React.useMemo(
    () =>
      apyData?.tokenData && apyData?.tokenData?.[asset.llToken.address]
        ? apyData.tokenData[asset.llToken.address].esTokenApyOnProvidingCollateral
        : netApy,
    [apyData, netApy],
  );

  const dsdApr = React.useMemo(
    () =>
      apyData?.tokenData && apyData?.tokenData?.[asset.llToken.address]
        ? apyData.tokenData[asset.llToken.address].dsdRewardsApy
        : netApy,
    [apyData, netApy],
  );

  console.log('%cAccount Data', 'color: black; background: silver; font-size: 1.2em');

  const consoleInfo = {
    'DSD APR': {
      value: formatToReadablePercentage(dsdApr),
      formula: 'userSupplyBalanceTokens, underlyingToken',
    },
    'esLTOKEN emission APR': {
      value: formatToReadablePercentage(emissionApr),
      formula: 'supplyBalanceTokens, underlyingToken',
    },
    'Net APR': {
      value: formatToReadablePercentage(netApy),
      formula: 'supplyBalanceTokens, underlyingToken',
    },
  };

  console.table(consoleInfo);
  const assetInfo = useAssetInfo({
    asset,
    type: action === 'borrow' || action === 'repay' ? 'borrow' : 'supply',
  });

  if (forModal) {
    return (
      <div css={{ display: 'flex', flexDirection: 'row', gap: '0.2em', padding: '1em' }}>
        <div css={{ flex: 1, textAlign: 'center', borderRight: '2px solid rgba(255,255,255,0.1)' }}>
          <Typography
            variant="h4"
            css={{ color: 'rgba(255, 255, 255, 0.6)', fontWeight: 400, fontSize: '16px' }}
          >
            Deposited collateral
          </Typography>
          <Typography variant="h4" css={{ color: '#FFFFFF', fontWeight: 700, fontSize: '28px' }}>
            {formatTokensToReadableValue({
              value: balance,
              token: asset.llToken.underlyingToken,
              minimizeDecimals: true,
              addSymbol: false,
            })}
          </Typography>
        </div>

        <div css={{ flex: 1, textAlign: 'center' }}>
          <Typography
            variant="h4"
            css={{ color: 'rgba(255, 255, 255, 0.6)', fontWeight: 400, fontSize: '16px' }}
          >
            Withdrawable amount
          </Typography>
          <Typography variant="h4" css={{ color: '#FFFFFF', fontWeight: 700, fontSize: '28px' }}>
            {formatTokensToReadableValue({
              value: tokensWithdrawable,
              token: asset.llToken.underlyingToken,
              minimizeDecimals: true,
              addSymbol: false,
            })}
          </Typography>
        </div>
        {/* <ValueUpdate
            original={asset.userSupplyBalanceTokens}
            update={hypotheticalUserSupplyBalanceTokens}
            format={(value: BigNumber | undefined) =>
              formatTokensToReadableValue({
                value,
                token: asset.llToken.underlyingToken,
                minimizeDecimals: true,
                addSymbol: false,
              })
            }
          /> */}
      </div>
    );
  }

  console.log(assetInfo);

  return (
    <>
      <div css={styles.bottomNotice}>
        <div>
          <Typography color="white">Estimated APR</Typography>
          <Typography variant="small1">
            Your deposit will earn: DSD rewards ({formatToReadablePercentage(dsdApr)} APR), esLTOKEN
            rewards ({formatToReadablePercentage(emissionApr)} APR) and any other additional boosts
            as applicable.
          </Typography>
        </div>
        <div css={styles.apr}>
          <Typography variant="h3">{formatToReadablePercentage(netApy)} APR</Typography>
        </div>
      </div>
      {assetInfo.map(row => (
        <LabeledInlineContent
          css={styles.getRow({ isLast: false })}
          className="info-row"
          {...row}
          key={row.label}
        />
      ))}

      <div css={styles.valueUpdateContainer}>
        {action === 'supply' || action === 'withdraw' ? (
          <LabeledBoxContent
            label={t('accountData.supplyBalance')}
            css={styles.getRow({ isLast: true })}
            tooltip="supplyBalance"
          >
            <ValueUpdate
              original={asset.userSupplyBalanceTokens}
              update={hypotheticalUserSupplyBalanceTokens}
              format={(value: BigNumber | undefined) =>
                formatTokensToReadableValue({
                  value,
                  token: asset.llToken.underlyingToken,
                  minimizeDecimals: true,
                  addSymbol: false,
                })
              }
            />
          </LabeledBoxContent>
        ) : (
          <LabeledBoxContent
            label={t('accountData.borrowBalance', {
              tokenSymbol: asset.llToken.underlyingToken.symbol,
            })}
            css={styles.getRow({ isLast: true })}
            tooltip="borrowBalance"
          >
            <ValueUpdate
              original={asset.userBorrowBalanceTokens}
              update={hypotheticalUserBorrowBalanceTokens}
              positiveDirection="desc"
              format={(value: BigNumber | undefined) =>
                formatTokensToReadableValue({
                  value,
                  token: asset.llToken.underlyingToken,
                  minimizeDecimals: true,
                  addSymbol: false,
                })
              }
            />
          </LabeledBoxContent>
        )}

        {action === 'supply' || action === 'withdraw' ? (
          <LabeledBoxContent
            label={t('accountData.borrowLimit')}
            css={styles.getRow({ isLast: true })}
            tooltip="borrowLimit"
          >
            <ValueUpdate
              original={pool.userBorrowLimitCents}
              update={hypotheticalPoolUserBorrowLimitCents}
            />
          </LabeledBoxContent>
        ) : (
          <LabeledBoxContent
            label={t('accountData.borrowLimitUsed')}
            css={styles.getRow({ isLast: true })}
            tooltip="borrowLimitUsed"
          >
            <ValueUpdate
              original={poolUserBorrowLimitUsedPercentage}
              update={hypotheticalPoolUserBorrowLimitUsedPercentage}
              positiveDirection="desc"
              format={formatToReadablePercentage}
            />
          </LabeledBoxContent>
        )}
      </div>
      <Typography style={{ marginBottom: '1em' }}>
        You will be able to borrow a total{' '}
        {formatCentsToReadableValue({
          value: hypotheticalPoolUserBorrowLimitCents || pool.userBorrowLimitCents || 0,
        })}{' '}
        DSD after completing your collateral deposit.
      </Typography>
    </>
  );
};
