import { abi as poolLensAbi } from '@venusprotocol/isolated-pools/artifacts/contracts/Lens/PoolLens.sol/PoolLens.json';
import { Contract, ContractInterface, Signer } from 'ethers';
import { LLToken, Token } from 'types';
import { areTokensEqual, getContractAddress } from 'utilities';

import { chain, provider } from 'clients/web3';
import comptrollerAbi from 'constants/contracts/abis/comptroller.json';
import dsdControllerAbi from 'constants/contracts/abis/dsdController.json';
import dsdTokenAbi from 'constants/contracts/abis/dsdToken.json';
import dsdVaultAbi from 'constants/contracts/abis/dsdVault.json';
import erc20Abi from 'constants/contracts/abis/erc20.json';
import esLTOKENAbi from 'constants/contracts/abis/esLTOKEN.json';
import esLTOKENMinterAbi from 'constants/contracts/abis/esLTOKENMinter.json';
import farmAbi from 'constants/contracts/abis/farm.json';
import fundAbi from 'constants/contracts/abis/fund.json';
import lsLensAbi from 'constants/contracts/abis/lsLens.json';
import ltokenTokenAbi from 'constants/contracts/abis/ltokenToken.json';
import ltokenVaultAbi from 'constants/contracts/abis/ltokenVault.json';
import ltokenVaultStoreAbi from 'constants/contracts/abis/ltokenVaultStore.json';
import maximillionAbi from 'constants/contracts/abis/maximillion.json';
import migratorAbi from 'constants/contracts/abis/migrator.json';
import multiRewardAbi from 'constants/contracts/abis/multiReward.json';
import multicallAbi from 'constants/contracts/abis/multicall.json';
import pancakeRouterAbi from 'constants/contracts/abis/pancakeRouter.json';
import swapRouterAbi from 'constants/contracts/abis/swapRouter.json';
import vBnbTokenAbi from 'constants/contracts/abis/vBnbToken.json';
import vErc20Abi from 'constants/contracts/abis/vErc20.json';
// import vrtTokenAbi from 'constants/contracts/abis/vrtToken.json';
import vrtVaultAbi from 'constants/contracts/abis/vrtVault.json';
import { TOKENS } from 'constants/tokens';
import {
  Comptroller,
  DsdController,
  DsdVault,
  EsLTOKEN,
  EsLTOKENMinter,
  Farm,
  Fund,
  LsLens,
  LtokenVault,
  LtokenVaultStore,
  Maximillion,
  Migrator,
  MultiReward,
  Multicall,
  PancakeRouter,
  PoolLens,
  SwapRouter,
  VrtVault,
} from 'types/contracts';

import { LLTokenContract, TokenContract } from './types';

export const getContract = ({
  abi,
  address,
  signer,
}: {
  abi: ContractInterface;
  address: string;
  signer?: Signer;
}) => {
  const signerOrProvider = signer ?? provider({ chainId: chain.id });
  return new Contract(address, abi, signerOrProvider);
};

export const getTokenContract = (token: Token, signer?: Signer) => {
  if (areTokensEqual(token, TOKENS.ltoken)) {
    return getContract({
      abi: ltokenTokenAbi,
      address: token.address,
      signer,
    }) as TokenContract<'ltoken'>;
  }

  if (areTokensEqual(token, TOKENS.dsd)) {
    return getContract({
      abi: dsdTokenAbi,
      address: token.address,
      signer,
    }) as TokenContract<'dsd'>;
  }

  /* if (areTokensEqual(token, TOKENS.vrt)) {
    return getContract({
      abi: vrtTokenAbi,
      address: token.address,
      signer,
    }) as TokenContract<'vrt'>;
  } */

  return getContract({
    abi: erc20Abi,
    address: token.address,
    signer,
  }) as TokenContract;
};

export const getLLTokenContract = (llToken: LLToken, signer?: Signer) => {
  if (llToken.symbol === 'vBNB') {
    return getContract({
      abi: vBnbTokenAbi,
      address: llToken.address,
      signer,
    }) as LLTokenContract<'bnb'>;
  }

  return getContract({
    abi: vErc20Abi,
    address: llToken.address,
    signer,
  }) as LLTokenContract;
};

export const getDsdControllerContract = (signer?: Signer) =>
  getContract({
    abi: dsdControllerAbi,
    address: getContractAddress('dsdController'),
    signer,
  }) as DsdController;

export const getDsdVaultContract = (signer?: Signer) =>
  getContract({
    abi: dsdVaultAbi,
    address: getContractAddress('dsdVault'),
    signer,
  }) as DsdVault;

export const getLtokenVaultContract = (signer?: Signer) =>
  getContract({
    abi: ltokenVaultAbi,
    address: getContractAddress('ltokenVault'),
    signer,
  }) as LtokenVault;

export const getLtokenVaultProxyContract = (signer?: Signer) =>
  getContract({
    abi: ltokenVaultAbi,
    address: getContractAddress('ltokenVaultProxy'),
    signer,
  }) as LtokenVault;

export const getLtokenVaultStoreContract = (signer?: Signer) =>
  getContract({
    abi: ltokenVaultStoreAbi,
    address: getContractAddress('ltokenVaultStore'),
    signer,
  }) as LtokenVaultStore;

export const getComptrollerContract = (address: string, signer?: Signer) =>
  getContract({
    abi: comptrollerAbi,
    address,
    signer,
  }) as Comptroller;

export const getFarmContract = (address: string, signer?: Signer) =>
  getContract({
    abi: farmAbi,
    address,
    signer,
  }) as Farm;

export const getLsLensContract = (signer?: Signer) =>
  getContract({
    abi: lsLensAbi,
    address: getContractAddress('lsLens'),
    signer,
  }) as LsLens;

export const getMaximillionContract = (signer?: Signer) =>
  getContract({
    abi: maximillionAbi,
    address: getContractAddress('maximillion'),
    signer,
  }) as Maximillion;

// VRT vault
export const getVrtVaultProxyContract = (signer?: Signer) =>
  getContract({
    abi: vrtVaultAbi,
    address: getContractAddress('vrtVaultProxy'),
    signer,
  }) as VrtVault;

// PancakeSwap router
export const getPancakeRouterContract = (signer?: Signer) =>
  getContract({
    abi: pancakeRouterAbi,
    address: getContractAddress('pancakeRouter'),
    signer,
  }) as PancakeRouter;

// Swap router
export const getSwapRouterContract = (signer?: Signer) =>
  getContract({
    abi: swapRouterAbi,
    address: getContractAddress('swapRouter'),
    signer,
  }) as SwapRouter;

// Multicall
export const getMulticallContract = (signer?: Signer) =>
  getContract({
    abi: multicallAbi,
    address: getContractAddress('multicall'),
    signer,
  }) as Multicall;

export const getPoolLensContract = (signer?: Signer) =>
  getContract({
    abi: poolLensAbi,
    address: getContractAddress('poolLens'),
    signer,
  }) as PoolLens;

export const getMultiRewardContract = (address: string, signer?: Signer) =>
  getContract({
    address,
    signer,
    abi: multiRewardAbi,
  }) as MultiReward;

export const getEsLTOKENMinterContract = (signer?: Signer) =>
  getContract({
    address: getContractAddress('esLTOKENMinter'),
    signer,
    abi: esLTOKENMinterAbi,
  }) as EsLTOKENMinter;

export const getEsLTOKENContract = (signer?: Signer) =>
  getContract({
    address: getContractAddress('esLTOKEN'),
    signer,
    abi: esLTOKENAbi,
  }) as EsLTOKEN;

export const getMigratorContract = (signer?: Signer) =>
  getContract({
    address: getContractAddress('migrator'),
    signer,
    abi: migratorAbi,
  }) as Migrator;

export const getLsFundContract = (signer?: Signer) =>
  getContract({
    address: getContractAddress('fund'),
    signer,
    abi: fundAbi,
  }) as Fund;
