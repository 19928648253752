import { ContractCallContext, ContractCallResults } from 'ethereum-multicall';
import { getContractAddress } from 'utilities';

import dsdControllerAbi from 'constants/contracts/abis/dsdController.json';

import formatToOutput from './formatToOutput';
import { GetDsdRepayAmountWithInterestsInput, GetDsdRepayAmountWithInterestsOutput } from './types';

const dsdControllerAddress = getContractAddress('dsdController');

const getDsdCalculateRepayAmount = async ({
  multicall,
  accountAddress,
}: GetDsdRepayAmountWithInterestsInput): Promise<GetDsdRepayAmountWithInterestsOutput> => {
  // Generate call context
  const contractCallContext: ContractCallContext = {
    reference: 'getDsdRepayTotalAmount',
    contractAddress: dsdControllerAddress,
    abi: dsdControllerAbi,
    calls: [
      // Call (statically) accrueDSDInterest to calculate past accrued interests
      // before fetching all interests
      {
        reference: 'getDSDRepayAmount',
        methodName: 'getDSDRepayAmount',
        methodParameters: [accountAddress],
      },
    ],
  };

  const contractCallResults: ContractCallResults = await multicall.call(contractCallContext);

  return formatToOutput({
    contractCallResults,
  });
};

export default getDsdCalculateRepayAmount;
