import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    theme,
    container: css`
      max-width: 480px;
      overflow-y: hidden;
    `,
    heading: css`
    text-align: center;
    padding: ${theme.spacing(1)};
    padding-bottom:0;
      z-index: 100;
    `,
  };
};
