import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    appBar: css`
      background-image: none;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      ${theme.breakpoints.down('xl')} {
        display: none;
      }
    `,
    toolbar: css`
      padding: ${theme.spacing(8, 10, 0)} !important;
      justify-content: space-between;
      display: flex;

      ${theme.breakpoints.down('lg')} {
        padding-left: ${theme.spacing(6)} !important;
        padding-right: ${theme.spacing(6)} !important;
      }

      ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(6, 4, 0)} !important;
      }
    `,
    justifiedSpace: css`
      display: flex;
      gap: ${theme.spacing(2)};
      align-items: center;
    `,
    ethPrice: css`
      ${theme.breakpoints.down('xxl')} {
        display: none;
      }
    `,
    claimLtokenButton: css`
    `,
    ctaContainer: css`
      display: flex;
      align-items: center;

      ${theme.breakpoints.down('md')} {
        display: none;
      }
    `,
    socialIcon: css`
      display: grid;
      place-content: center;
      padding: ${theme.spacing(3)};
      border-radius: ${theme.shape.borderRadius.medium}px;
      border: 1px solid ${theme.palette.background.default};
    `,
    navbar: css`
      border-radius: ${theme.shape.borderRadius.medium}px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      padding: ${theme.spacing(1)};
      display: flex;
      gap: ${theme.spacing(1)};
    `,
    listItem: css`
      border-radius: ${theme.shape.borderRadius.small}px;
    `,
    listItemText: css`
      &:hover {
        text-decoration: none;
        outline: none;
      }
    `,
    listItemActive: css`
      background-color: rgba(255, 255, 255, 0.08);
    `,
  };
};
