/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Tabs } from 'components';
import React from 'react';
import { useTranslation } from 'translation';

import { ReactComponent as MetamaskIcon } from 'assets/img/metamask.svg';
import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import useAddTokenToWallet from 'hooks/useAddTokenToWallet';

import MintDsd from './MintDsd';
import RedeemDsd from './RedeemDsd';
import RepayDsd from './RepayDsd';
import { useStyles } from './styles';

export interface DsdProps {
  className?: string;
}

const Dsd: React.FC<DsdProps> = ({ className }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const addTokenToWallet = useAddTokenToWallet();

  const tabsContent = [
    { title: t('dsd.tabMint'), content: <MintDsd /> },
    { title: t('dsd.tabRepay'), content: <RepayDsd /> },
    { title: t('dsd.tabRedeem'), content: <RedeemDsd /> },
  ];

  return (
    <div css={[styles.container]}>
      <Paper className={className} css={[styles.tabs]}>
        <div css={{ marginBottom: '1em' }}>
          <Typography
            variant="h2"
            css={{ width: 'fit-content', margin: 'auto', position: 'relative' }}
          >
            {brand.stableCoinSymbol}
            <MetamaskIcon css={styles.mmIcon} onClick={() => addTokenToWallet(TOKENS.dsd)} />
          </Typography>
        </div>
        <Tabs tabsContent={tabsContent} />
      </Paper>
    </div>
  );
};

export default Dsd;
