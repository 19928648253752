/** @jsxImportSource @emotion/react */
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  EnableToken,
  FormikSubmitButton,
  FormikTokenTextField,
  Icon, // SelectToken,
  SelectTokenTextField,
  TokenIcon,
  toast,
} from 'components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'translation';
import { Token, TokenBalance } from 'types';
import {
  convertTokensToWei,
  convertWeiToTokens,
  formatTokensToReadableValue,
  getContractAddress,
  getLLTokenByToken,
} from 'utilities';

import { useGetRedeemDsdData, useGetUnderlyingPriceAllToken, useRedeemDsd } from 'clients/api';
import brand from 'config/brand';
import { TOKENS } from 'constants/tokens';
import { AmountForm, ErrorCode } from 'containers/AmountForm';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { useStyles } from '../styles';

export interface ConvertProps {
  userDsdBalanceWei: BigNumber | undefined;
}

const Convert: React.FC<ConvertProps> = ({
  userDsdBalanceWei,
  // convertDsdLoading,
  // convertDsd,
}) => {
  const styles = useStyles();
  const { t, Trans } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();
  const [selectedToken, setSelectedToken] = useState<Token>(TOKENS.stETH);
  const { tokenPrices } = useGetUnderlyingPriceAllToken();
  const readableUserDsdBalance = useMemo(() => {
    const userDsdBalanceTokens =
      userDsdBalanceWei &&
      convertWeiToTokens({
        valueWei: userDsdBalanceWei,
        token: TOKENS.dsd,
      });

    return formatTokensToReadableValue({
      value: userDsdBalanceTokens,
      token: TOKENS.dsd,
    });
  }, [userDsdBalanceWei?.toFixed()]);

  const { data: redeemableDsdData } = useGetRedeemDsdData();

  const dsdControllerContractAddress = getContractAddress('dsdController');

  const { mutateAsync: convertDsd, isLoading: convertDsdLoading } = useRedeemDsd();
  const [useTeamAsRedemption, stUseTeamAsRedemption] = useState(true);

  useEffect(() => {
    console.log('');
    console.log('='.repeat(20));
    redeemableDsdData?.forEach(data =>
      console.log(
        `@REDEEM_ABLE: ${data.lltoken?.symbol} ${+data.redeemableInEther / 1e18} ${
          +data.redeemableInUnderlying / 1e18
        }`,
      ),
    );
  }, [redeemableDsdData]);
  // const { mutateAsync: withdrawLtoken, isLoading: withdrawLtokenLoading } = useWithdrawLtoken();

  const handleConvertDsd = async (amountWei: string) => {
    const llToken = getLLTokenByToken(selectedToken);
    if (!llToken) return;
    return convertDsd({
      llToken,
      amountWei: new BigNumber(amountWei),
      useTeamAsRedemptionProvider: useTeamAsRedemption,
    });
  };

  const redeemableTokenBalances: TokenBalance[] = useMemo(() => {
    if (!redeemableDsdData) return [];

    const tokenBalances = redeemableDsdData
      .map(token => ({
        token: token.lltoken?.underlyingToken,
        balanceWei: token.redeemableInUnderlying,
      }))
      .filter(({ token }) => token !== undefined) as TokenBalance[];

    return tokenBalances;
  }, [redeemableDsdData]);

  useConvertWeiToReadableTokenString({
    valueWei: userDsdBalanceWei,
    token: TOKENS.dsd,
  });

  const calculateUnderlyingFromDsd = useCallback(
    (value: BigNumber) =>
      !value.isNaN() && !value.isZero() && tokenPrices && !((redeemableTokenBalances.find((balances) => balances.token === selectedToken))?.balanceWei.isZero())
        ? new BigNumber(value).div(tokenPrices[selectedToken.address] || 1)
        : new BigNumber(0),
    [tokenPrices],
  );

  const onSubmit = async (dsdAmount: string) => {
    try {
      const dsdAmountWei = convertTokensToWei({
        value: new BigNumber(dsdAmount),
        token: TOKENS.dsd,
      });

      const contractReceipt = await handleConvertDsd(dsdAmountWei.toFixed());
      // Display successful transaction modal
      const underlyingAmountWei = calculateUnderlyingFromDsd(dsdAmountWei);

      openSuccessfulTransactionModal({
        title: t('redeemDsd.successfulConvertTransactionModal.title'),
        transactionHash: contractReceipt?.transactionHash || '0x',
        content: (
          <div css={styles.successModalConversionAmounts}>
            <TokenIcon token={TOKENS.dsd} css={styles.successModalToken} />

            <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
              {convertWeiToTokens({
                valueWei: dsdAmountWei,
                token: TOKENS.dsd,
                returnInReadableFormat: true,
              })}
            </Typography>
            <Icon name="arrowShaft" css={styles.successModalArrow} />

            {/* <TokenIcon token={TOKENS.ltoken} css={styles.successModalToken} /> */}

            <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
              {underlyingAmountWei &&
                convertWeiToTokens({
                  valueWei: underlyingAmountWei,
                  token: selectedToken,
                  returnInReadableFormat: true,
                })}
            </Typography>
          </div>
        ),
      });
    } catch (err) {
      toast.error({ message: (err as Error).message });
    }
  };

  const userDsdBalance =
    userDsdBalanceWei &&
    convertWeiToTokens({ valueWei: userDsdBalanceWei, token: TOKENS.dsd }).toFixed();

  return (
    <div css={styles.root}>
      <ConnectWallet
        message={t('redeemDsd.connectWalletToRedeemDsd', { tokenSymbol: brand.stableCoinSymbol })}
      >
        <EnableToken
          title={t('redeemDsd.enableDsd', {
            brandName: brand.name,
            tokenSymbol: brand.stableCoinSymbol,
          })}
          token={TOKENS.dsd}
          spenderAddress={dsdControllerContractAddress}
          noUi
        >
          {({ enableToken, isTokenEnabled, isEnableTokenLoading }) => (
            <AmountForm
              onSubmit={isTokenEnabled ? onSubmit : enableToken}
              maxAmount={userDsdBalance}
              css={[styles.form, styles.tabContentContainer, styles.border]}
            >
              {({ values, setFieldValue }) => {
                const underlyingValue = calculateUnderlyingFromDsd(new BigNumber(values.amount))
                  ?.dp(selectedToken.decimals)
                  .toFixed();
                return (
                  <>
                    <div css={styles.inputSection}>
                      {/* <Typography variant="small2" css={[styles.inputLabel, styles.fontWeight600]}>
                    {t('redeemDsd.convertDsd', { tokenSymbol: brand.stableCoinSymbol })}
                  </Typography> */}
                      <FormikTokenTextField
                        token={TOKENS.dsd}
                        name="amount"
                        css={styles.input}
                        disabled={false}
                        description={
                          <Trans
                            i18nKey="redeemDsd.balance"
                            components={{
                              White: <span css={styles.whiteLabel} />,
                            }}
                            values={{
                              amount: readableUserDsdBalance,
                            }}
                          />
                        }
                        rightMaxButton={
                          userDsdBalance
                            ? {
                                label: t('redeemDsd.max'),
                                onClick: () => setFieldValue('amount', userDsdBalance),
                              }
                            : undefined
                        }
                        displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
                      />
                    </div>
                    <SelectTokenTextField
                      label={t('redeemDsd.youWillReceive')}
                      selectedToken={selectedToken}
                      value={underlyingValue}
                      displayTokenIcon={false}
                      onChange={() => {}}
                      disabled={false}
                      onChangeSelectedToken={value => setSelectedToken(value)}
                      tokenBalances={redeemableTokenBalances}
                    />

                    <br />
                    <Typography variant="body1">Redemption Provider:</Typography>
                    <RadioGroup
                      aria-labelledby="lslend-radio-buttons-group-label"
                      defaultValue="team"
                      name="radio-buttons-group"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        stUseTeamAsRedemption((event.target as HTMLInputElement).value === 'team');
                      }}
                      row
                    >
                      <FormControlLabel value="protocol" control={<Radio />} label="Protocol" />
                      <FormControlLabel value="team" control={<Radio />} label="Team" />
                    </RadioGroup>
                    <Typography variant="small2">
                      {useTeamAsRedemption ? 'Fees = 1%' : 'Fees = 0.5%'}
                    </Typography>

                    <br />
                    {/* submit button */}
                    <FormikSubmitButton
                      css={styles.submitButton}
                      fullWidth
                      disabled={!(+underlyingValue)}
                      loading={convertDsdLoading || isEnableTokenLoading}
                      enabledLabel={
                        isTokenEnabled
                          ? t('redeemDsd.convertDsd', {
                              tokenSymbol: brand.stableCoinSymbol,
                            })
                          : t('redeemDsd.enableDsd', {
                              brandName: brand.name,
                              tokenSymbol: brand.stableCoinSymbol,
                            })
                      }
                    />
                  </>
                );
              }}
            </AmountForm>
          )}
        </EnableToken>
      </ConnectWallet>
    </div>
  );
};

export default Convert;
