// import BigNumber from 'bignumber.js';
import { ContractCallResults } from 'ethereum-multicall';
// import { getTokenByAddress } from 'utilities';

// import { TOKENS } from 'constants/tokens';

import {
  PendingRewardGroup,
  // VaultPendingRewardGroup,
  // LtokenVestingVaultPendingRewardGroup,
} from '../types';
import formatToIsolatedPoolPendingRewardGroup from './formatToIsolatedPoolPendingRewardGroup';
import formatToMainPoolPendingRewardGroup from './formatToMainPoolPendingRewardGroup';

const formatOutput = ({
  contractCallResults,
}: {
  contractCallResults: ContractCallResults;
}): PendingRewardGroup[] => {
  const pendingRewardGroups: PendingRewardGroup[] = [];

  // Extract pending rewards from main pool
  const mainPoolPendingRewardGroup = formatToMainPoolPendingRewardGroup(
    contractCallResults.results.lsLens.callsReturnContext[0],
  );

  if (mainPoolPendingRewardGroup) {
    pendingRewardGroups.push(mainPoolPendingRewardGroup);
  }

  // Extract pending rewards from isolated pools
  const isolatedPoolPendingRewardGroups = (
    contractCallResults.results.poolLens?.callsReturnContext || []
  ).reduce<PendingRewardGroup[]>((acc, callsReturnContext) => {
    const isolatedPoolPendingRewardGroup =
      formatToIsolatedPoolPendingRewardGroup(callsReturnContext);

    return isolatedPoolPendingRewardGroup ? [...acc, isolatedPoolPendingRewardGroup] : acc;
  }, []);
  pendingRewardGroups.push(...isolatedPoolPendingRewardGroups);

  // Extract pending rewards from VRT vault
  /* const vrtVaultPendingRewardWei = new BigNumber(
    contractCallResults.results.vrtVault.callsReturnContext[0].returnValues[0].hex,
  ); */

  /* if (vrtVaultPendingRewardWei.isGreaterThan(0)) {
    const vrtVaultRewardGroup: VaultPendingRewardGroup = {
      type: 'vault',
      stakedToken: TOKENS.vrt,
      rewardToken: TOKENS.vrt,
      rewardAmountWei: vrtVaultPendingRewardWei,
    };

    pendingRewardGroups.push(vrtVaultRewardGroup);
  } */

  // Extract pending rewards from DSD vault
  // const dsdVaultPendingRewardWei = new BigNumber(
  //   contractCallResults.results.dsdVault.callsReturnContext[0].returnValues[0].hex,
  // );

  // if (dsdVaultPendingRewardWei.isGreaterThan(0)) {
  //   const dsdVaultRewardGroup: VaultPendingRewardGroup = {
  //     type: 'vault',
  //     stakedToken: TOKENS.dsd,
  //     rewardToken: TOKENS.ltoken,
  //     rewardAmountWei: dsdVaultPendingRewardWei,
  //   };

  //   pendingRewardGroups.push(dsdVaultRewardGroup);
  // }

  // Extract pending rewards from vesting vaults
  // const ltokenVestingVaultPendingRewardGroups: LtokenVestingVaultPendingRewardGroup[] = [];
  // const ltokenVestingVaultResults = contractCallResults.results.ltokenVestingVaults.callsReturnContext;

  // for (let v = 0; v < ltokenVestingVaultResults.length - 1; v += 3) {
  //   const stakedTokenAddress = ltokenVestingVaultResults[v].returnValues[0];
  //   const stakedToken = getTokenByAddress(stakedTokenAddress);

  //   const poolIndex = ltokenVestingVaultResults[v].methodParameters[1];

  //   const pendingRewardWei = new BigNumber(ltokenVestingVaultResults[v + 1].returnValues[0].hex);

  //   const hasPendingWithdrawalsFromBeforeUpgrade =
  //     !!ltokenVestingVaultResults[v].returnValues[2] &&
  //     new BigNumber(ltokenVestingVaultResults[v].returnValues[2].hex).isGreaterThan(0);

  //   if (
  //     !hasPendingWithdrawalsFromBeforeUpgrade &&
  //     stakedToken &&
  //     pendingRewardWei.isGreaterThan(0)
  //   ) {
  //     ltokenVestingVaultPendingRewardGroups.push({
  //       type: 'ltokenVestingVault',
  //       poolIndex,
  //       rewardToken: TOKENS.ltoken,
  //       rewardAmountWei: pendingRewardWei,
  //     });
  //   }
  // }

  // if (ltokenVestingVaultPendingRewardGroups.length > 0) {
  //   pendingRewardGroups.push(...ltokenVestingVaultPendingRewardGroups);
  // }

  return pendingRewardGroups;
};

export default formatOutput;
