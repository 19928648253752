/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { BorrowLimitUsedAccountHealth, Icon, Tooltip } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'translation';
import { Asset } from 'types';
import { convertWeiToTokens } from 'utilities';

import { useGetMintedDsd } from 'clients/api';
import { SAFE_BORROW_LIMIT_PERCENTAGE } from 'constants/safeBorrowLimitPercentage';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';

import { useStyles } from '../styles';
import useExtractData from './useExtractData';

const DsdAccountData = ({ assets }: { assets: Asset[] }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { accountAddress } = useAuth();

  const { borrowLimitCents, readableSafeBorrowLimit, safeBorrowLimitPercentage } = useExtractData({
    assets,
  });

  const { data: mintedDsdData, isLoading: isGetMintedDsdLoading } = useGetMintedDsd(
    {
      accountAddress,
    },
    {
      enabled: !!accountAddress,
    },
  );

  const [totalBorrowCents, setTotalBorrowCents] = useState<BigNumber>(new BigNumber(0));

  useEffect(() => {
    if (!isGetMintedDsdLoading) {
      setTotalBorrowCents(() =>
        convertWeiToTokens({
          valueWei: new BigNumber(mintedDsdData?.mintedDsdWei || 0),
          token: TOKENS.dsd,
        }).multipliedBy(100),
      );
    }
  }, [mintedDsdData]);

  return (
    <div css={styles.accountHealthConatiner}>
      <div css={styles.accountHealth}>
        <BorrowLimitUsedAccountHealth
          variant="borrowLimitUsed"
          borrowBalanceCents={totalBorrowCents.toNumber()}
          borrowLimitCents={borrowLimitCents.toNumber()}
          safeBorrowLimitPercentage={SAFE_BORROW_LIMIT_PERCENTAGE}
          css={styles.accountHealthProgressBar}
        />
        <div css={styles.accountHealthFooter}>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Icon name="shield" css={styles.shieldIcon} />
            <Typography component="span" variant="small1" css={styles.inlineLabel}>
              {t('myAccount.safeLimit')}
            </Typography>

            <Typography
              component="span"
              variant="small1"
              color="text.primary"
              css={styles.safeLimit}
            >
              {readableSafeBorrowLimit}
            </Typography>
            <Tooltip
              css={styles.tooltip}
              title={t('myAccount.safeLimitTooltip', { safeBorrowLimitPercentage })}
            >
              <Icon css={styles.infoIcon} name="info" />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DsdAccountData;
