/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'translation';

import { useClaimDSDRewards, useGetDSDReward } from 'clients/api';
import { useAuth } from 'context/AuthContext';
import { DisableLunaUstWarningContext } from 'context/DisableLunaUstWarning';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import { ButtonProps, PrimaryButton, SecondaryButton } from '../../Button';
import { Modal } from '../../Modal';
import TEST_IDS from '../testIds';
import { RewardGroup } from './RewardGroup';

export interface ClaimDSDRewardButtonUiProps extends ClaimDSDRewardButtonProps {
  isModalOpen: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  pendingReward: BigNumber;
}

export const ClaimDSDRewardButtonUi: React.FC<ClaimDSDRewardButtonUiProps> = ({
  isModalOpen,
  onOpenModal,
  onCloseModal,
  pendingReward,
  ...otherButtonProps
}) => {
  const { t } = useTranslation();
  const handleTransactionMutation = useHandleTransactionMutation();

  const { mutateAsync: claimDSDRewards, isLoading: isClaimingRewards } = useClaimDSDRewards();

  const handleClaimReward = () =>
    handleTransactionMutation({
      mutate: async () => {
        const contractReceipt = await claimDSDRewards({});
        onCloseModal();
        return contractReceipt;
      },
      successTransactionModalProps: contractReceipt => ({
        title: t('claimReward.successfulTransactionModal.title'),
        content: t('claimReward.successfulTransactionModal.message'),
        transactionHash: contractReceipt.transactionHash,
      }),
    });

  if (!pendingReward?.isZero()) {
    return <Typography>No Rewards</Typography>;
  }

  return (
    <>
      <SecondaryButton
        data-testid={TEST_IDS.claimRewardOpenModalButton}
        onClick={onOpenModal}
        small
        {...otherButtonProps}
      >
        {t('claimReward.claimMultiReward.claim')}
      </SecondaryButton>

      <Modal
        isOpen={isModalOpen}
        handleClose={onCloseModal}
        title={<h4>{t('claimReward.modal.title')}</h4>}
      >
        <>
          <div data-testid={TEST_IDS.claimRewardBreakdown}>
            <RewardGroup pendingReward={pendingReward} />
          </div>

          <PrimaryButton
            onClick={handleClaimReward}
            fullWidth
            data-testid={TEST_IDS.claimRewardSubmitButton}
            loading={isClaimingRewards}
          >
            {t('claimReward.claimButton.enabledLabel')}
          </PrimaryButton>
        </>
      </Modal>
    </>
  );
};

export type ClaimDSDRewardButtonProps = Omit<ButtonProps, 'onClick'>;

export const ClaimDSDRewardButton: React.FC<ClaimDSDRewardButtonProps> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { hasLunaOrUstCollateralEnabled, openLunaUstWarningModal } = useContext(
    DisableLunaUstWarningContext,
  );
  const { accountAddress } = useAuth();

  const { data, isLoading } = useGetDSDReward({ accountAddress });

  const handleOpenModal = () => {
    // Block action if user has LUNA or UST enabled as collateral
    if (hasLunaOrUstCollateralEnabled) {
      openLunaUstWarningModal();
      return;
    }

    return setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  if (!data || isLoading) {
    return <Typography>No Rewards</Typography>;
  }

  return (
    <ClaimDSDRewardButtonUi
      pendingReward={data?.earnedDSD}
      isModalOpen={isModalOpen}
      onOpenModal={handleOpenModal}
      onCloseModal={handleCloseModal}
      {...props}
    />
  );
};

export default ClaimDSDRewardButton;
