import { MAINNET_TOKENS } from './common/mainnet';
import { MAINNET_SWAP_TOKENS } from './swap/mainnet';
import { MAINNET_LLERC_TOKENS } from './llErc/mainnet';

export * from './common/mainnet';
export * from './swap/mainnet';
export * from './llErc/mainnet';

export const TOKENS = MAINNET_TOKENS;

export const LLERC_TOKENS = MAINNET_LLERC_TOKENS;

export const SWAP_TOKENS = MAINNET_SWAP_TOKENS;
