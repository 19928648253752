/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import React, { useState } from 'react';

import { PrimaryButton } from '../Button';
import { Icon } from '../Icon';
import ProtocolList from './TokenList';
import { useStyles } from './styles';

export interface Protocol {
  symbol: string;
  name: string;
  asset: string;
}

export interface SelectProtocolTextFieldProps {
  label: string;
  selectedProtocol: Protocol;
  options: Protocol[];
  onChangeSelectedProtocol: (option: Protocol) => void;
}

export const SelectProtocol: React.FC<SelectProtocolTextFieldProps> = ({
  label,
  selectedProtocol,
  options,
  onChangeSelectedProtocol,
}) => {
  const styles = useStyles();
  const [isProtocolListShown, setIsProtocolListShown] = useState(false);

  const handleButtonClick = () => setIsProtocolListShown(isShowing => !isShowing);

  const handleChangeSelectedProtocol = (newSelectedProtocol: Protocol) => {
    setIsProtocolListShown(false);
    onChangeSelectedProtocol(newSelectedProtocol);
  };

  return (
    <div>
      <Typography variant="body2">{label}</Typography>
      <PrimaryButton
        onClick={handleButtonClick}
        fullWidth
        css={styles.getButton({ isProtocolListShown })}
      >
        <div css={styles.protocol}>
          <img src={selectedProtocol.asset} css={styles.icon} alt={selectedProtocol.symbol} />
          <Typography variant="body2" style={{ color: 'white' }}>
            {selectedProtocol.name}
          </Typography>
        </div>

        <Icon css={styles.getArrowIcon({ isProtocolListShown })} name="arrowUp" />
      </PrimaryButton>

      <div css={styles.tokenListContainer}>
        {isProtocolListShown && (
          <ProtocolList
            options={options}
            onProtocolClick={handleChangeSelectedProtocol}
          />
        )}
      </div>

      <div
        css={styles.getBackdrop({ isProtocolListShown })}
        onClick={() => setIsProtocolListShown(false)}
      />
    </div>
  );
};
