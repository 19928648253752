import BigNumber from 'bignumber.js';
import { ContractReceipt } from 'ethers';

import { EsLTOKEN } from 'types/contracts';

export interface ConvertEsLTOKENInput {
  esLTOKENContract: EsLTOKEN;
  amountWei: BigNumber;
}

export type ConvertEsLTOKENOutput = ContractReceipt;

const convertEsLTOKEN = async ({
  esLTOKENContract,
  amountWei,
}: ConvertEsLTOKENInput): Promise<ConvertEsLTOKENOutput> => {
  const transaction = await esLTOKENContract.vestEsLTOKEN(amountWei.toFixed());
  return transaction.wait(1);
};

export default convertEsLTOKEN;
