/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
// import BigNumber from 'bignumber.js';
import { Button, Spinner, toast } from 'components';
import { VError, formatVErrorToReadableString } from 'errors';
import React, { useState } from 'react';
import { calculateApr, convertTokensToWei, convertWeiToTokens } from 'utilities';

import { ReactComponent as MetamaskIcon } from 'assets/img/metamask.svg';
import { FarmInfo, useClaimFarmRewards } from 'clients/api';
// import useTokenPrices from 'clients/api/queries/getTokenPrices/useFarmTokenPrice';
import { useAuth } from 'context/AuthContext';
import useAddTokenToWallet from 'hooks/useAddTokenToWallet';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { StakeModal, WithdrawModal } from './modals';
import { useStyles } from './styles';

// import calculateNetApy from 'pages/Account/Summary/calculateNetApy';

export interface FarmTableProps {
  vault: FarmInfo;
}
type ActiveModal = 'stake' | 'withdraw';

const FarmCard: React.FC<FarmTableProps> = ({ vault }) => {
  const styles = useStyles();
  const { accountAddress } = useAuth();
  const [activeModal, setActiveModal] = useState<ActiveModal | undefined>();
  const closeActiveModal = () => setActiveModal(undefined);
  const addTokenToWallet = useAddTokenToWallet();
  const { mutateAsync: claimFarmRewards, isLoading } = useClaimFarmRewards(vault.contract);
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();
  // const { data } = useTokenPrices();
  // console.log(data);
  const handleClaimReward = async () => {
    if (!vault.rewardToken) return;

    try {
      const res = await claimFarmRewards({});
      const { transactionHash } = res;
      if (transactionHash) {
        openSuccessfulTransactionModal({
          title: 'Claim Reward',
          content: 'You successfully claimed reward',
          amount: {
            valueWei: convertTokensToWei({ value: vault.earnedReward, token: vault.rewardToken }),
            token: vault.rewardToken,
          },
          transactionHash,
        });
      }
    } catch (error) {
      let { message } = error as Error;

      if (error instanceof VError) {
        message = formatVErrorToReadableString(error);
      }

      toast.error({ message });
    }
  };

  if (!vault.stakedToken || !vault.rewardToken) return <Spinner />;
  const rewardESLTokenPrice = 0.1;
  const lpTokenPrice = 3;

  console.log('-'.repeat(20));
  console.log(
    `%cLogs for ${vault.stakedToken?.symbol} LP FARM`,
    'color: green; background: yellow; font-size: 2em',
  );
  console.table({
    'Liquidity supplied': convertWeiToTokens({
      valueWei: vault.stakedAmount,
      token: vault.stakedToken,
      returnInReadableFormat: true,
    }),
    TVL: convertWeiToTokens({
      valueWei: vault.totalSupply,
      token: vault.stakedToken,
      returnInReadableFormat: true,
    }),
    'LP TOKEN PRICE USD:': lpTokenPrice,
    'esLTOKEN Emissions Yearly:': vault.rewardRate
      .multipliedBy(60 * 60 * 24 * 365)
      .div(1e18)
      .toString(),
    'esLTOKEN Emissions this week:': vault.rewardRate
      .multipliedBy(60 * 60 * 24 * 7)
      .div(1e18)
      .toString(),
    'Expected APR:': vault.rewardRate
      .multipliedBy(60 * 60 * 24 * 7)
      .multipliedBy(52)
      .multipliedBy(rewardESLTokenPrice)
      .multipliedBy(100)
      .div(vault.totalSupply.multipliedBy(lpTokenPrice))
      .toString(),
    'APR:': calculateApr(
      vault.rewardRate,
      rewardESLTokenPrice,
      convertWeiToTokens({
        valueWei: vault.totalSupply.multipliedBy(lpTokenPrice),
        token: vault.stakedToken,
        addSymbol: false,
      }),
    )
      .aprPercentage.dp(2)
      .toString(),
  });

  return (
    <div key={vault.contract} css={styles.cardContent}>
      <div css={styles.cardContentRow} style={{ marginBottom: 8 }}>
        <Typography variant="h5">
          <img
            css={{ width: '1.5em', objectFit: 'contain' }}
            src={vault.stakedToken?.asset}
            alt={vault.stakedToken?.symbol}
          />{' '}
          {vault.stakedToken?.symbol} LP Pool
        </Typography>
        <div css={styles.spaced}>
          <MetamaskIcon css={styles.mmIcon} onClick={() => addTokenToWallet(vault.stakedToken)} />
          <Typography variant="subtitle1">Add</Typography>
        </div>
      </div>

      <div css={styles.cardContentRow}>
        <Typography variant="body1">{vault.stakedToken?.symbol} Staked</Typography>
        <Typography variant="subtitle1">
          {convertWeiToTokens({
            valueWei: vault.stakedAmount,
            token: vault.stakedToken,
            returnInReadableFormat: true,
          })}{' '}
          ($0.00)
        </Typography>
      </div>

      <div css={styles.cardContentRow}>
        <Typography variant="body1">Total {vault.stakedToken?.symbol} Staked</Typography>
        <Typography variant="subtitle1">
          {convertWeiToTokens({
            valueWei: vault.totalSupply,
            token: vault.stakedToken,
            returnInReadableFormat: true,
          })}{' '}
          ($0.00)
        </Typography>
      </div>

      <div css={styles.cardContentRow}>
        <Typography variant="body1">APR</Typography>
        <Typography variant="subtitle1">
          {calculateApr(
            vault.rewardRate,
            rewardESLTokenPrice,
            convertWeiToTokens({
              valueWei: vault.totalSupply.multipliedBy(lpTokenPrice),
              token: vault.stakedToken,
              addSymbol: false,
            }),
          )
            .aprPercentage.dp(2)
            .toString()}
          %
        </Typography>
      </div>

      <div css={styles.cardContentRow}>
        <Typography variant="body1">Reward earned</Typography>
        <Typography variant="subtitle1">
          {convertWeiToTokens({
            valueWei: vault.earnedReward,
            token: vault.rewardToken,
            returnInReadableFormat: true,
          })}
        </Typography>
      </div>
      <div css={styles.cardContentRow} style={{ marginTop: 8 }}>
        <div css={styles.cardContentRow}>
          <Button variant="primary" onClick={() => setActiveModal('stake')}>
            Stake
          </Button>
          <Button variant="secondary" onClick={() => setActiveModal('withdraw')}>
            Unstake
          </Button>
        </div>
        <div css={styles.cardContentRow}>
          {!vault.earnedReward.isZero() && (
            <Button
              variant="secondary"
              disabled={!accountAddress || isLoading}
              onClick={() => handleClaimReward()}
            >
              Claim {vault.rewardToken.symbol}
            </Button>
          )}
        </div>
      </div>
      {activeModal === 'stake' && (
        <StakeModal
          stakedToken={vault.stakedToken}
          farmAddress={vault.contract}
          rewardToken={vault.rewardToken}
          handleClose={closeActiveModal}
        />
      )}
      {activeModal === 'withdraw' && (
        <WithdrawModal
          stakedToken={vault.stakedToken}
          stakedAmount={vault.stakedAmount}
          farmAddress={vault.contract}
          rewardToken={vault.rewardToken}
          handleClose={closeActiveModal}
        />
      )}
    </div>
  );
};

export default FarmCard;
