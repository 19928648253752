import { ContractCallResults, Multicall } from 'ethereum-multicall';

import migratorAbi from 'constants/contracts/abis/migrator.json';
import { Migrator } from 'types/contracts';

export interface GetWhitelistedLPsInput {
  migratorContract: Migrator;
  multiCallContract: Multicall;
}

export type GetWhitelistedLPsOutput = {
  whitelistedLPs: string[];
  poolTokens: string[];
};

const getWhitelistedLPs = async ({
  migratorContract,
  multiCallContract,
}: GetWhitelistedLPsInput): Promise<GetWhitelistedLPsOutput> => {
  const lpTokens = await migratorContract.getWhitelistedLPs();

  const contractCallResults: ContractCallResults = await multiCallContract.call([
    {
      reference: 'migratorTokens',
      contractAddress: migratorContract.address,
      abi: migratorAbi,
      calls: lpTokens.map((lptoken, index) => ({
        reference: `getWhitelistedPoolToken_${index}`,
        methodName: 'getWhitelistedPoolToken',
        methodParameters: [lptoken],
      })),
    },
  ]);

  const poolTokens = contractCallResults.results.migratorTokens.callsReturnContext.map(
    token => token.returnValues[0],
  );
  console.log(poolTokens);
  return {
    whitelistedLPs: lpTokens,
    poolTokens,
  };
};

export default getWhitelistedLPs;
