import { QueryObserverOptions, useQuery } from 'react-query';

import getWhitelistedLPs, { GetWhitelistedLPsOutput } from 'clients/api/queries/getWhitelistedLPs';
import { useMigratorContract } from 'clients/contracts/hooks';
import { useMulticall } from 'clients/web3';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetWhitelistedLPsOutput,
  Error,
  GetWhitelistedLPsOutput,
  GetWhitelistedLPsOutput,
  FunctionKey.GET_WHITELISTED_LPS
>;

const useGetMintedDsd = (options?: Options) => {
  const migratorContract = useMigratorContract();
  const multiCallContract = useMulticall();

  return useQuery(
    FunctionKey.GET_WHITELISTED_LPS,
    () => getWhitelistedLPs({ migratorContract, multiCallContract }),
    options,
  );
};

export default useGetMintedDsd;
