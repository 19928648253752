/* eslint-disable no-restricted-syntax */

/* eslint-disable no-constant-condition */

/* eslint-disable no-await-in-loop */
import { Provider } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';
import { Signer } from 'ethers';
import moment from 'moment';
import { getLLTokenByAddress } from 'utilities';

import { getLLTokenContract, getTokenContract } from 'clients/contracts';
import { TOKENS } from 'constants/tokens';
import { DsdToken } from 'types/contracts';

export type DistributionEventSignature = {
  mrRebaseAmount: BigNumber;
  fundRebaseAmount: BigNumber;
  dsdRebaseAmount: BigNumber;
};

const getDistributionEventData = async (
  contract: ReturnType<typeof getLLTokenContract>,
  provider: Provider,
  fromTS: number,
) => ({
  lastBlock: await contract.provider.getBlockNumber(),
  async *[Symbol.asyncIterator]() {
    while (true) {
      const data = await contract.queryFilter(
        contract.filters.Distribution(),
        this.lastBlock - 1023,
        this.lastBlock,
      );
      const blockOfLastEvent = await provider.getBlock(this.lastBlock);
      this.lastBlock -= 1023;
      if (blockOfLastEvent.timestamp <= fromTS) break;
      yield data.reduce<DistributionEventSignature>(
        (p, event) => {
          const { args } = contract.interface.parseLog(event);
          return {
            ...p,
            mrRebaseAmount: p.mrRebaseAmount.plus(args[1].toString()),
            fundRebaseAmount: p.fundRebaseAmount.plus(args[2].toString()),
            dsdRebaseAmount: p.dsdRebaseAmount.plus(args[3].toString()),
          };
        },
        {
          mrRebaseAmount: new BigNumber(0),
          fundRebaseAmount: new BigNumber(0),
          dsdRebaseAmount: new BigNumber(0),
        },
      );
    }
  },
});

/**
 * Although being a generator returns single value
 * @param contract DSD Token contract
 * @param fromTS Timestamp to search back till
 * @returns The difference in totalSupply from the given time till now
 */
const getTotalSupplyDiff = async (contract: DsdToken, provider: Provider, fromTS: number) => ({
  lastBlock: await contract.provider.getBlockNumber(),
  async *[Symbol.asyncIterator]() {
    while (true) {
      const data = await contract.queryFilter(
        contract.filters.TokenSupplyChanged(),
        this.lastBlock - 1023,
        this.lastBlock,
      );
      const blockOfLastEvent = await provider.getBlock(this.lastBlock);
      this.lastBlock -= 1023;
      if (blockOfLastEvent.timestamp <= fromTS) {
        const currentTotalSupply = await contract.totalSupply();
        yield new BigNumber(currentTotalSupply.toString()).minus(data[0].args[0].toString());
      }
    }
  },
});

const getEventsBasedData = async (tokens: string[], provider: Provider, signer?: Signer) => {
  const yesterdayTS = moment().subtract(1, 'day').unix();
  const dsdContract = getTokenContract(TOKENS.dsd, signer) as DsdToken;

  const [dsdTotalSupplyDiff, ...distributionDataForLastDay] = await Promise.all([
    (async () => {
      let localDiff = new BigNumber(0);
      try {
        for await (const diff of await getTotalSupplyDiff(dsdContract, provider, yesterdayTS)) {
          localDiff = diff;
        }
      } catch (err) {
        console.error('Failed getting totalSupplyDiff for DSD', err);
      }
      return localDiff;
    })(),
    ...tokens.map(async tokenAddress => {
      const aggregate: DistributionEventSignature = {
        mrRebaseAmount: new BigNumber(0),
        fundRebaseAmount: new BigNumber(0),
        dsdRebaseAmount: new BigNumber(0),
      };
      const token = getLLTokenByAddress(tokenAddress);
      if (!token) return aggregate;
      const contract = getLLTokenContract(token, signer);
      if (!contract) return aggregate;
      try {
        for await (const data of await getDistributionEventData(contract, provider, yesterdayTS)) {
          aggregate.mrRebaseAmount = aggregate.mrRebaseAmount.plus(data.mrRebaseAmount);
          aggregate.fundRebaseAmount = aggregate.fundRebaseAmount.plus(data.fundRebaseAmount);
          aggregate.dsdRebaseAmount = aggregate.dsdRebaseAmount.plus(data.dsdRebaseAmount);
        }
      } catch (err) {
        console.error('Failed getting distribution event data for', tokenAddress, err);
      }
      return aggregate;
    }),
  ]);

  return {
    dsdTotalSupplyDiff,
    distributionDataForLastDay: distributionDataForLastDay.reduce<{
      [tokenAddress: string]: DistributionEventSignature;
    }>((p, c, i) => ({ ...p, [tokens[i]]: c }), {}),
  };
};

export default getEventsBasedData;
