import { MutationObserverOptions, useMutation } from 'react-query';
import { Token } from 'types';
import { getContractAddress } from 'utilities';

import {
  ExecuteWithdrawalFromLtokenVaultInput,
  ExecuteWithdrawalFromLtokenVaultOutput,
  executeWithdrawalFromLtokenVault,
  queryClient,
} from 'clients/api';
import { useLtokenVaultProxyContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { TOKENS } from 'constants/tokens';

const LTOKEN_VAULT_PROXY_CONTRACT_ADDRESS = getContractAddress('ltokenVaultProxy');

type Options = MutationObserverOptions<
  ExecuteWithdrawalFromLtokenVaultOutput,
  Error,
  Omit<ExecuteWithdrawalFromLtokenVaultInput, 'ltokenVaultContract'>
>;

const useExecuteWithdrawalFromLtokenVault = (
  { stakedToken }: { stakedToken: Token },
  options?: Options,
) => {
  const ltokenVaultContract = useLtokenVaultProxyContract();

  return useMutation(
    FunctionKey.REQUEST_WITHDRAWAL_FROM_LTOKEN_VAULT,
    (params: Omit<ExecuteWithdrawalFromLtokenVaultInput, 'ltokenVaultContract'>) =>
      executeWithdrawalFromLtokenVault({
        ltokenVaultContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        const { poolIndex } = onSuccessParams[1];
        const accountAddress = await ltokenVaultContract.signer.getAddress();

        // Invalidate cached user info
        queryClient.invalidateQueries([
          FunctionKey.GET_LTOKEN_VAULT_USER_INFO,
          { accountAddress, rewardTokenAddress: TOKENS.ltoken.address, poolIndex },
        ]);

        // Invalidate cached user withdrawal requests
        queryClient.invalidateQueries([
          FunctionKey.GET_LTOKEN_VAULT_WITHDRAWAL_REQUESTS,
          {
            rewardTokenAddress: TOKENS.ltoken.address,
            poolIndex,
            accountAddress,
          },
        ]);

        // Invalidate cached user balance
        queryClient.invalidateQueries([
          FunctionKey.GET_BALANCE_OF,
          {
            accountAddress,
            tokenAddress: stakedToken.address,
          },
        ]);

        queryClient.invalidateQueries([
          FunctionKey.GET_TOKEN_BALANCES,
          {
            accountAddress,
          },
        ]);

        // Invalidate cached vault data
        queryClient.invalidateQueries([
          FunctionKey.GET_BALANCE_OF,
          {
            accountAddress: LTOKEN_VAULT_PROXY_CONTRACT_ADDRESS,
            tokenAddress: stakedToken.address,
          },
        ]);

        queryClient.invalidateQueries([
          FunctionKey.GET_LTOKEN_VAULT_POOL_INFOS,
          { rewardTokenAddress: TOKENS.ltoken.address, poolIndex },
        ]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useExecuteWithdrawalFromLtokenVault;
