import { ContractCallContext, ContractCallResults } from 'ethereum-multicall';
import { getContractAddress } from 'utilities';

import dsdControllerAbi from 'constants/contracts/abis/dsdController.json';

import formatToOutput from './formatToOutput';
import { GetDsdCalculateRepayAmountInput, GetDsdCalculateRepayAmountOutput } from './types';

const dsdControllerAddress = getContractAddress('dsdController');

const getDsdCalculateRepayAmount = async ({
  multicall,
  accountAddress,
  repayAmountWei,
}: GetDsdCalculateRepayAmountInput): Promise<GetDsdCalculateRepayAmountOutput> => {
  // Generate call context
  const contractCallContext: ContractCallContext = {
    reference: 'getDsdRepayInterests',
    contractAddress: dsdControllerAddress,
    abi: dsdControllerAbi,
    calls: [
      // Call (statically) accrueDSDInterest to calculate past accrued interests
      // before fetching all interests
      {
        reference: 'getDSDCalculateRepayAmount',
        methodName: 'getDSDCalculateRepayAmount',
        methodParameters: [accountAddress, repayAmountWei.toFixed()],
      },
    ],
  };

  const contractCallResults: ContractCallResults = await multicall.call(contractCallContext);

  return formatToOutput({
    repayAmountWei,
    contractCallResults,
  });
};

export default getDsdCalculateRepayAmount;
