import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    theme,
    container: css`
      display: flex;
      align-items: center;
      min-height: 100%;
      flex: 1;
      flex-direction: column;
    `,
    border: css`
      border: 1px solid rgba(255, 255, 255, 0.1);
    `,
    tabs: css`
      max-width: ${theme.spacing(180)};
      width: 100%;
      padding: ${theme.spacing(10)};

      ${theme.breakpoints.down('md')} {
        max-width: 100%;
        padding: ${theme.spacing(4)};
      }
    `,
    tabContentContainer: css`
      flex: 1;
      display: flex;
      padding: ${theme.spacing(6)}  ${theme.spacing(8)} ;
      border-radius: ${theme.spacing(4)};
      flex-direction: column;
    `,
    ctaContainer: css`
      flex: 1;
    `,
    textField: css`
      margin-bottom: ${theme.spacing(1)};
    `,
    getRow: ({ isLast }: { isLast: boolean }) => css`
      margin-bottom: ${theme.spacing(isLast ? 6 : 4)};
    `,
    whiteLabel: css`
      color: ${theme.palette.text.primary};
    `,
    greyLabel: css`
      color: ${theme.palette.text.secondary};
      margin-bottom: ${theme.spacing(8)};
    `,
    noticeWarning: css`
      margin-bottom: ${theme.spacing(8)};
    `,
    mmIcon: css`
      width: 24px;
      height: 24px;
      position: absolute;
      right: -36px;
      cursor: pointer;
      top: 20%;
    `,
    accountHealthConatiner: css`
      justify-content: space-between;
      ${theme.breakpoints.down('md')} {
        width: unset;
      }
    `,
    accountHealth: css`
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.04);
      padding: 1.5em;
      border-radius:  ${theme.spacing(2)};
      margin:${theme.spacing(3)} 0;
      ${theme.breakpoints.down('md')} {
        width: unset;
      }
    `,
    accountHealthProgressBar: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    accountHealthFooter: css`
      display: flex;
      // justify-content: space-between;
      align-items: center;
    `,
    link: css`
      &:hover {
        text-decoration: none;
      }
    `,
    tooltip: css`
      display: flex;
    `,
    infoIcon: css`
      cursor: help;
    `,
    inlineLabel: css`
      margin-right: ${theme.spacing(1)};
    `,
    progressBar: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    shieldIcon: css`
      margin-right: ${theme.spacing(2)};
    `,
    safeLimit: css`
      margin-right: ${theme.spacing(2)};
    `,
  };
};
