import { Token, LLToken } from 'types';

import { LLERC_TOKENS } from 'constants/tokens';

const getLLTokenByToken = (token: Token) => {
  let lltoken: LLToken | undefined;

  Object.keys(LLERC_TOKENS)
    .filter(key => Object.prototype.hasOwnProperty.call(LLERC_TOKENS, key))
    .forEach(tokenId => {
      const currentToken = LLERC_TOKENS[tokenId as keyof typeof LLERC_TOKENS];
      if (currentToken?.underlyingToken.address.toLowerCase() === token.address.toLowerCase()) {
        lltoken = currentToken;
      }
    });

  return lltoken;
};

export default getLLTokenByToken;
