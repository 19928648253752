import { ContractReceipt } from 'ethers';

import { Fund } from 'types/contracts';

export interface ClaimFeesEsLTOKENInput {
  fundContract: Fund;
}

export type ClaimFeesEsLTOKENOutput = ContractReceipt;

const claimFeesEsLTOKEN = async ({
  fundContract,
}: ClaimFeesEsLTOKENInput): Promise<ClaimFeesEsLTOKENOutput> => {
  const transaction = await fundContract.getReward();
  return transaction.wait(1);
};

export default claimFeesEsLTOKEN;
