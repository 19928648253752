import { QueryObserverOptions, useQuery } from 'react-query';

import getLtokenWithdrawableAmount, {
  GetLtokenWithdrawableAmountInput,
  GetLtokenWithdrawableAmountOutput,
} from 'clients/api/queries/getLtokenWithdrawableAmount';
import { useEsLTOKENContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetLtokenWithdrawableAmountOutput,
  Error,
  GetLtokenWithdrawableAmountOutput,
  GetLtokenWithdrawableAmountOutput,
  FunctionKey.GET_LTOKEN_WITHDRAWABLE_AMOUNT
>;

const useGetLtokenWithdrawableAmount = (
  { accountAddress }: Omit<GetLtokenWithdrawableAmountInput, 'esLTOKENContract'>,
  options?: Options,
) => {
  const esLTOKENContract = useEsLTOKENContract();

  return useQuery(
    FunctionKey.GET_LTOKEN_WITHDRAWABLE_AMOUNT,
    () => getLtokenWithdrawableAmount({ esLTOKENContract, accountAddress }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetLtokenWithdrawableAmount;
